<template>
  <form class="navbar-form navbar serch-bar mb-0">
    <div class="input-group mb-3">
      <input
        v-if="!$store.getters['auth/getISB2B']"
        v-model="searchData"
        :key="getSrcTxt"
        v-on:keyup.enter="startSearch"
        @keyup="setTimeReload"
        @blur="closeSuggest"
        foc
        type="search"
        class="form-control src-input-bar"
        autocomplete="off"
        placeholder="ฉันกำลังมองหา..."
        name="src"
      />
      <input
        v-else
        v-model="searchData"
        :key="getSrcTxt"
        v-on:keyup.enter="startSearchB2B"
        @keyup="suggestListB2B"
        @blur="closeSuggest"
        foc
        type="search"
        class="form-control src-input-bar"
        autocomplete="off"
        placeholder="ฉันกำลังมองหา..."
        name="src"
      />
      <div class="input-group-append">
        <button v-if="!$store.getters['auth/getISB2B']" @click="startSearch" class="input-group-text src-input-bar-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-search"
            viewBox="0 0 16 16"
          >
            <path
              d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
            />
          </svg>
        </button>
        <button v-else @click="startSearchB2B" class="input-group-text src-input-bar-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-search"
            viewBox="0 0 16 16"
          >
            <path
              d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
            />
          </svg>
        </button>
      </div>
    </div>
    <div v-if="isSearch" class="row suggest">
      <div v-if="!isLoading" class="col-12">
        <div class="row mx-1 py-2 border-end" style="border-bottom: 1px solid #ddd;">
          <router-link v-for="e in getCatProd" :to="`/Product/${(e.node+'0000').slice(0,6)}`" :key="e.catname" >
            <div :class="`rounded-pill btn-outline-${getBU} px-1 py-0 pointer m-1`" style="border: 1px solid;">{{e.catname}}</div>
          </router-link>
        </div>
        <router-link
          v-for="e in listData"
          :to="`/ProductDetail/${e.slugname}`"
          :key="e.skcode"
        >
          <div class="row cardlist pointer">
            <div class="col-2 p-0">
              <img
                class="w-100 p-0"
                :src="
                  `${
                    e.base64
                      ? 'data:image/jpeg;base64,' + e.base64
                      : (`${getBU}` == `bnb` ? '/img/no-imgbnb.png' : '/img/no-img.jpg')
                  }`
                "
                alt=""
              />
            </div>
            <div class="col-10">
              <p>{{ e.name }}</p>
            </div>
          </div>
        </router-link>
        <div class="row">
          <div class="col-12 w-100" v-if="isNotFound">
            <span>ไม่พบรายการที่ท่านค้นหา</span>
          </div>
        </div>
      </div>
      <div v-else class="col-12">
        <div class="row">
          <div
            v-for="index in 5"
            :key="index"
            class="col-12 fillpag "
            style="padding: 5px 5px;"
          >
            <div class="shadowDiv" style="overflow:hidden;">
              <div class="row">
                <div class="col-3 pr-0">
                  <!-- <span class="skeleton-box w-100" style="height:60px; "></span> -->
                  <img
                    class="w-100 px-2 pr-0"
                    style="height:80px;"
                    :src="`/img/loading.png`"
                    alt=""
                  />
                </div>
                <div class="col-9">
                  <p class="m-0 py-1">
                    <span class="skeleton-box" style="width:85%;"></span>
                    <span class="skeleton-box" style="width:95%;"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 w-100 showall-btn ">
        <div class="row">
          <div class="col-12">
            <h6
              v-if="!$store.getters['auth/getISB2B']"
              :class="`font-hover-${getBU} pointer text-center w-100 m-0 py-1`"
              @click="startSearch"
            >
              ดูทั้งหมด
            </h6>
            <h6
              v-else
              :class="`font-hover-${getBU} pointer text-center w-100 m-0 py-1`"
              @click="startSearchB2B"
            >
              ดูทั้งหมด
            </h6>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
  import * as serviceAPI from "../services/API.service";
  import * as serviceMain from "../services/main.service";
  import searchCatProd from '../../public/loadmenu/searchCatProd.json'

  export default {
    name: "SearchProduct",
    data: function() {
      return {
        dataIMG: "",
        isSearch: false,
        isLoading: true,
        searchData: "",
        listData: [],
        isNotFound: false,
        AllCatProd: searchCatProd,
        getCatProd: [],
        reloadSlotTime: '',
      };
    },
    components: {},
    mounted() {},
    props: ["src"],
    methods: {
      async testsearch(){
        const start1 = performance.now();

        await this.suggestList();
        // await suggestListMongo();

        const end1 = performance.now();
        const start2 = performance.now();

        // await suggestList();
        // await this.suggestListMongo();

        const end2 = performance.now();
        console.log(`mongo time: ${end1 - start1} ms`);
        console.log(`sql time: ${end2 - start2} ms`);
      },
      async suggestList() {
        try {
          if(this.searchData){
            if(this.searchData.length  > 2){
              this.isSearch = true;
              this.isLoading = true;
              let data = {
                STCODE: this.StoreCode || "026",
                SEARCH: this.searchData,
              };
              let getAPI = await serviceAPI.call_API(
                "post",
                `product/getsearchlist`,
                data,
                "auth"
              );
              this.listData = getAPI.data.dbitems;
              let getData = []
              for (let i = 0; i < this.AllCatProd.length; i++) {
                getData = getData.concat(this.AllCatProd[i].filter(word => word.catname.search(this.searchData)>=0))
              }
              this.getCatProd = getData.slice(0,12)
              this.isNotFound = this.listData.length == 0 && this.getCatProd.length == 0;
              this.isNotFound = false;
              this.isLoading = false;
            }
          }
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async suggestListMongo() {
        try {
          if(this.searchData){
            if(this.searchData.length  > 2){
              this.isSearch = true;
              this.isLoading = true;
              let data = {
                STCODE: this.StoreCode || "026",
                SEARCH: this.searchData,
              };
              let getAPI = await serviceAPI.call_API(
                "post",
                `product/searchProdMonGoDB`,
                data,
                "auth"
              );
              this.listsku = getAPI.data.data.dbitems;
              let row = getAPI.data.data.dbrows
              row = row<5 ? row : 5
              let skuSearch = ''
              for (let i = 0; i < row; i++) {
                skuSearch = skuSearch + (skuSearch ? `,${this.listsku[i].skCode}` : `${this.listsku[i].skCode}`)
              }
              data = {
                STCODE: this.StoreCode || "026",
                SEARCH: skuSearch,
              };
              let getAPIserach = await serviceAPI.call_API(
                "post",
                `product/getsearchlistV2`,
                data,
                "auth"
              );
              this.listData = getAPIserach.data.dbitems;
              let getData = []
              for (let i = 0; i < this.AllCatProd.length; i++) {
                getData = getData.concat(this.AllCatProd[i].filter(word => word.catname.search(this.searchData)>=0))
              }
              this.getCatProd = getData.slice(0,12)
              this.isNotFound = this.listData.length == 0 && this.getCatProd.length == 0;
              // this.isNotFound = false;
              this.isLoading = false;
            }
          }
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async suggestListB2B() {
        try {
          this.isSearch = true;
          this.isLoading = true;
          let data = {
            STCODE: this.StoreCode,
            SEARCH: this.searchData,
            UUID: this.getB2BUUID,
            START:0,
            END: 4,
          };
          let getAPI = await serviceAPI.call_API(
            "post",
            `b2b/product/getsearchlist`,
            data,
            "auth"
          );
          this.listData = getAPI.data.dbitems;
          this.isNotFound = this.listData.length == 0;
          this.isLoading = false;
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },

      async closeSuggest() {
        await serviceMain.Delay(200);
        this.isSearch = false;
      },
      async startSearch() {
        if (this.searchData.trim()) {
          this.$router.push({
            path: "/Product",
            query: { src: this.searchData.trim() },
          });
        }
      },
      async startSearchB2B() {
        console.log('sss');
        if (this.searchData) {
          this.$router.push({
            path: "/ProductSearchB2B",
            query: { src: this.searchData },
          });
        }
      },
      async setTimeReload(){
        let _this = this;
        clearTimeout(this.reloadSlotTime);
        // this.reloadSlotTime = setTimeout(function(){ _this.suggestListMongo()}, 1500);
        this.reloadSlotTime = setTimeout(function(){ _this.suggestList()}, 1500);
      },
    },
    computed: {
      StoreCode: function() {
        return this.$store.getters["service/getQTDFSTORE"];
      },
      getBU: function() {
        return this.$store.getters["customer/getQTBU"];
      },
      getB2BUUID: function() {
        return this.$store.getters["auth/getB2BUUID"];
      },
      getSrcTxt: function() {
        this.searchData = this.$route.query.src;
        return this.$route.query.src;
      },
    },
  };
</script>

<style scoped lang="scss">
  .serch-bar {
    position: relative;
  }
  a {
    color: black;
  }
  .serch-bar .src-input-bar {
    background-color: rgb(235, 235, 235);
    text-align: center;
    border: none;
    font-size: 26px;
    height: 1.5em;
  }
  .src-input-bar-btn {
    border: none;
  }
  .suggest {
    position: absolute;
    top: 47px;
    left: 0px;
    background: white;
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 20%);
    margin: 0px 23px;
    z-index: 1;
    border-radius: 0px 0px 8px 8px;
  }
  .cardlist:hover {
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 20%);
    font-weight: 900;
  }
  .showall-btn {
    border-radius: 0px 0px 8px 8px;
    background: lightgray;
  }

  .btn-outline-PWB {
    color: #55246c;
    border-color: #55246c
  }

  .btn-outline-PWB:hover {
      color: #fff;
      background-color: #55246c;
      border-color: #55246c
  }

  .btn-outline-PWB.focus,
  .btn-outline-PWB:focus {
      box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
  }

  .btn-outline-PWB.disabled,
  .btn-outline-PWB:disabled {
      color: #55246c;
      background-color: transparent
  }

  .btn-outline-PWB:not(:disabled):not(.disabled).active,
  .btn-outline-PWB:not(:disabled):not(.disabled):active,
  .show>.btn-outline-PWB.dropdown-toggle {
      color: #fff;
      background-color: #55246c;
      border-color: #55246c
  }

  .btn-outline-PWB:not(:disabled):not(.disabled).active:focus,
  .btn-outline-PWB:not(:disabled):not(.disabled):active:focus,
  .show>.btn-outline-PWB.dropdown-toggle:focus {
      box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5)
  }

  .btn-outline-bnb {
    color: #006e63;
    border-color: #006e63
  }

  .btn-outline-bnb:hover {
      color: #fff;
      background-color: #006e63;
      border-color: #006e63
  }

  .btn-outline-bnb.disabled,
  .btn-outline-bnb:disabled {
      color: #006e63;
      background-color: transparent
  }

  .btn-outline-bnb:not(:disabled):not(.disabled).active,
  .btn-outline-bnb:not(:disabled):not(.disabled):active,
  .show>.btn-outline-bnb.dropdown-toggle {
      color: #fff;
      background-color: #006e63;
      border-color: #006e63
  }
  
  .btn-outline-at1 {
    color: #3866af;
    border-color: #3866af
  }

  .btn-outline-at1:hover {
      color: #fff;
      background-color: #3866af;
      border-color: #3866af
  }

  .btn-outline-at1.disabled,
  .btn-outline-at1:disabled {
      color: #3866af;
      background-color: transparent
  }

  .btn-outline-at1:not(:disabled):not(.disabled).active,
  .btn-outline-at1:not(:disabled):not(.disabled):active,
  .show>.btn-outline-at1.dropdown-toggle {
      color: #fff;
      background-color: #3866af;
      border-color: #3866af
  }
  
  .btn-outline-vfix {
    color: #fbd900;
    border-color: #fbd900
  }

  .btn-outline-vfix:hover {
      color: #fff;
      background-color: #fbd900;
      border-color: #fbd900
  }

  .btn-outline-vfix.disabled,
  .btn-outline-vfix:disabled {
      color: #fbd900;
      background-color: transparent
  }

  .btn-outline-vfix:not(:disabled):not(.disabled).active,
  .btn-outline-vfix:not(:disabled):not(.disabled):active,
  .show>.btn-outline-vfix.dropdown-toggle {
      color: #fff;
      background-color: #fbd900;
      border-color: #fbd900
  }
  @media only screen and (max-width: 768px) {
    /* For tablets: */
    .suggest {
      left: -40%;
      width: 165%;
    }
  }
</style>
