<template>
  <div class="hide col-md-4 col-sm-12 mt-4">
    <div style="background-color: white;border-radius: 4px;">
      <div class="text-center">
        <img
          id="image"
          style="padding-top: 20px;height:auto;width:100%"
          v-bind:src="`${imageShape}`"
          class="w-80 px-2 pr-0"
        />
      </div>
      <p
        style="font-family: 'kittithadabold75regular'; text-align: center;padding: 0px;margin: 0px;font-size: 1.5em;"
      >
        {{ nameShape }}
      </p>
      <p
        style="font-family: 'kittithadabold75regular'; text-align: center;padding: 0px;margin: 0px;font-size: 1.5em;"
      >
        {{ sizeShape }}
      </p>

      <p
        style="margin: 0px 20px;text-align: center;margin-top: 0em;border-top: 1px dotted #C4C4C4;"
      >
        เลือกสี
      </p>
      <div class="row" style="margin-top: 5px;">
        <div class="col-md-12 col-sm-12">
          <p class="text-center m-0">โครงตู้</p>
          <div class="text-center">
            <button
              class="item"
              value="item1_white"
              @click="selectColor(whiteShapeId, whiteNameShape, whiteImageShape,WhitePrice)"
            >
              <img
                style="width:40%;cursor: pointer;"
                src="/img/prodboard/cabinet_white.svg"
              />
              <span class="caption">สีขาว</span>
            </button>
            <button
              class="item"
              value="item1_oak"
              @click="selectColor(oakShapeId, oakNameShape, oakImageShape,oakPrice)"
            >
              <img
                style="width:40%;cursor: pointer;"
                src="/img/prodboard/cabinet_oak.svg"
              />
              <span class="caption">สีโอ๊ค</span>
            </button>
            <button
              class="item"
              value="item1_walnut"
              @click="selectColor(walnutShapeId, walnutNameShape, walnutImageShape,walnutPrice)"
            >
              <img
                style="width:40%;cursor: pointer;"
                src="/img/prodboard/cabinet_walnut.svg"
              />
              <span class="caption">สีวอลนัท</span>
            </button>
          </div>
        </div>
      </div>
      <p
        style="text-align: center;border-bottom: 1px dotted #C4C4C4;margin-top: 5px;margin: 0px 20px;"
      ></p>
      <p
        style="text-align: center;font-size:2em;margin-bottom: 0px;"
        class="prizecard font-price font-color-PWB mt-2"
      >
        {{ price }}
      </p>
      <div class="row mb-2 mt-1 pb-2">
        <div class="col-6 text-center">
          <button
            @click="sentToCart(id)"
            style="background-color: #EAAD21; color: black; border: none;border-radius: 4px;"
          >
            <img
              style="margin-top: -5px;width: 20%;margin-right:5px"
              src="/img/prodboard/cart.svg"
            />
            <span style="font-family: 'kittithadabold75regular';"
              >ใส่รถเข็น</span
            >
          </button>
        </div>
        <div class="col-6 text-center p-0">
          <button
            @click="prodboard(id)"
            style="background-color: #EAAD21; color: black; border: none;border-radius: 4px;"
          >
            <img
              style="margin-top: -5px;width: 14%;margin-right: 5px;"
              src="/img/prodboard/LeadingIcon.svg"
            />
            <span style="font-family: 'kittithadabold75regular';"
              >ออกแบบเพิ่ม</span
            >
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="loading"></div>
</template>

<script>
  import * as serviceAPI from "@/services/API.service";
  import * as serviceMain from "@/services/main.service";
  export default {
    name: "WardrobeShape",
    data: function() {
      return {
        project: {},
        id: this.whiteShapeId,
        nameShape: this.whiteNameShape,
        imageShape: this.whiteImageShape,
        price: this.WhitePrice
      };
    },
    props: [
      "whiteNameShape",
      "oakNameShape",
      "walnutNameShape",
      "whiteImageShape",
      "oakImageShape",
      "walnutImageShape",
      "sizeShape",
      "WhitePrice",
      "oakPrice",
      "walnutPrice",
      "whiteShapeId",
      "oakShapeId",
      "walnutShapeId",
    ],
    async mounted() {
      $(".loading").hide();
    },
    methods: {
      async prodboard(id) {
        try {
          this.$router.push({
            name: "Prodboard",
            params: { getProdID: id },
          });
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async convert_currency(number) {
        var formatter = new Intl.NumberFormat("th-TH", {
          style: "currency",
          currency: "THB",
        });
        return formatter.format(number);
      },
      async selectColor(id, nameShape, imageShape, priceShape) {
        this.id = id;
        this.nameShape = nameShape;
        this.imageShape = imageShape;
        this.price = priceShape;
        console.log(this.id, nameShape, imageShape);
      },
      async sentToCart(id_) {
        let id = id_.split("-");
        id = id[0];
        // console.log(id);
        // return;
        $(".hide").hide();
        $(".loading").show();
        try {
          let dataInfo = {
            REF: this.CartRef,
          };
          let getAPIProjectInfo = await serviceAPI.call_API(
            "post",
            `prodboard/getProjectInfo`,
            dataInfo,
            "auth"
          );
          const data_items = await getAPIProjectInfo.data.dbitems;
          let exist = false;
          let project_array = [];
          if (data_items.length > 0) {
            for (var key in data_items) {
              if (data_items.hasOwnProperty(key)) {
                project_array.push(parseInt(data_items[key].PROJECT_ID));
              }
            }
          }

          let cntItems = 0;
          exist = project_array.includes(parseInt(id));
          if (!exist) {
            let getStoreId = this.getStore;
            const data = {
              id: id,
              store_id: getStoreId,
            };
            let getAPI = await serviceAPI.call_API(
              "post",
              `prodboard/getProjectByID`,
              data,
              "auth"
            );

            let res = getAPI.data.data;
            let prices = res.prices;
            if (prices.length > 0) {
              for (var key in prices) {
                if (prices.hasOwnProperty(key)) {
                  let db_price_float = parseFloat(
                    prices[key].db_price.replace(/,/g, "")
                  );
                  let price_qty = db_price_float * prices[key].quantity;
                  const final_price_qty = await this.convert_currency(
                    price_qty
                  );
                  const final_price = await this.convert_currency(
                    db_price_float
                  );
                  prices[key]["db_price_convert"] = final_price;
                  prices[key]["db_price_qty"] = final_price_qty;
                }
              }
              let items = res;
              let getStoreId = this.getStore;
              let ref = this.getRef;
              let prices_format = [];
              var _this = this;
              if (prices.length > 0) {
                for (var key in prices) {
                  if (prices.hasOwnProperty(key)) {
                    var innerObj = {};
                    innerObj["sku"] = prices[key].code;
                    innerObj["qty"] = prices[key].quantity;
                    cntItems += prices[key].quantity;
                    prices_format.push(innerObj);
                  }
                }
                this.qtShopItem = cntItems;
              }
              const data = {
                REF: ref,
                project_id: id,
                store: getStoreId,
                image_preview: items.preview,
                title: items.reference,
                sub_title: "ตู้เสื้อผ้า DIY", //fix
                items: prices_format,
              };
              let getAPI = await serviceAPI.call_API(
                "post",
                `prodboard/sentToCart`,
                data,
                "auth"
              );
              if (getAPI.status_code == 200) {
                //
                // console.log(getAPI);
                $(".loading").hide();
                _this.$router.push({
                  name: "Cart",
                });
              }
            }
          } else {
            for(let item of data_items) {
              let jsnitems = JSON.parse(item.JSNITEMS);
              for(let jsnitem of jsnitems) {
                cntItems += jsnitem.qty;
              }
            }
            this.qtShopItem = cntItems;
            this.$router.push({ name: "Cart" });
          }
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
    },
    computed: {
      getStore: function() {
        return this.$store.getters["service/getQTDFSTORE"];
      },
      CartRef: function() {
        return this.$store.getters["customer/getQTCUSTREF"];
      },
      getRef: function() {
        return this.$store.getters["customer/getQTCUSTREF"];
      },
      qtShopItem: {
          get(){
              return this.$store.getters['customer/getQTSHOPITEM']
          }, 
          set(value) {
              this.$store.dispatch('customer/setQTSHOPITEM', value)
          }
      },
    },
  };
</script>

<style scope>
  *:focus,
  .item:focus {
    outline: none;
  }
  .item {
    vertical-align: top;
    display: inline-block;
    text-align: center;
    width: 30%;
    background: none;
    border: none;
    padding: 0px;
  }
  .caption {
    display: block;
  }
  .loading {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background-color: #000000;
    background-image: url("/img/loadsmall.gif");
    background-size: 50px;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10000000;
    opacity: 0.2;
    filter: alpha(opacity=20);
  }
</style>
