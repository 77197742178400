<template>
  <div class="container">
    <ProdboardShapeListPageComp :prodShape="getProdShape"  />
  </div>
</template>

<script>
// @ is an alias to /src
import ProdboardShapeListPageComp from '@/components/Prodboard/ProdboardShapeListPageComp.vue'
export default {
  name: 'ProdboardShapeListPage',
  components: {
    ProdboardShapeListPageComp
  },
  mounted() {
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-CRY3HG283C')
    document.head.appendChild(recaptchaScript)
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CRY3HG283C');
  },
   props: {
    getProdShape: {
      type: String,
      required: true
    }
  },
}
</script>
