<template>
<section>
        <a @click="$router.push({ name: 'History', params: { whereBy: whereBy}, query: { QT: cartRef },})" class="pointer"><span class="back-detail"><i style='font-size:15px;transform: translateY(10%);' class='fas fa-chevron-left'></i> ย้อนกลับ </span></a>
        <div class="row">
          <div class="col-10">
            <p :class="`h3 mt-3 font-weight-bold font-color-${buCodeByOrder}`">OTP Request</p>
          </div>
          <div class="col-2 mt-3">
            <!-- <PrintQuotation :cartref="cartRef" :stcode="stcode" :dlvcode="ListQuotationHeader.dlvcode" :buCode="ListQuotationHeader.buCode" :sorderref="listData[0][0]['#sorderRef']" :listOTPDisc="listOTPDisc"/> -->
          </div>
        </div>
        <div class="row px-3 m-0 mt-3">
            <div class="col-12 mb-2 text-center">
              <VueBarcode :value="cartRef">
                ไม่สามารถสร้าง Barcode ได้
              </VueBarcode>
            </div>
          </div>
        <div :id='`history-detail-${cartRef}`' v-show="!isload">
          <div class="card">
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th class='text-center' scope="col">เลขที่รายการ</th>
                  <th class='text-center' scope="col">วันที่สั่งซื้อ</th>
                  <!-- <th class='text-center' scope="col">สถานะรายการ</th> -->
                  <!-- <th class='text-center' scope="col">จัดส่งโดย</th> -->
                  <th class='text-center' scope="col">สาขา</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th :class="`font-color-${buCodeByOrder} text-center`">{{ ListQuotationHeader["#ref"] }}</th>
                  <td class='text-center'>{{ ListQuotationHeader.qtdate }}</td>
                  <!-- <td>-</td> -->
                  <!-- <td>-</td> -->
                  <td class='text-center'>{{ ListQuotationHeader.stcode }} - {{ ListQuotationHeader.sttname }}</td>
                </tr>
              </tbody>
            </table>
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th class='text-center' scope="col">ชื่อลูกค้า</th>
                  <th class='text-center' scope="col">โทรศัพท์</th>
                  <th class='text-center' scope="col">T1 Card</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class='text-center'>{{ ListQuotationHeader.custname }}</td>
                  <td class='text-center'>{{ ListQuotationHeader.mobile }}</td>
                  <td class='text-center'>{{ ListQuotationHeader.The1 || '-' }}</td>
                </tr>
              </tbody>
            </table>
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th class='text-center' scope="col">ประเภทส่วนลด</th>
                  <th class='text-center' scope="col">ประเภทการชำระเงิน</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <select v-model="discType" class='form-control' :disabled="isConfirm">
                      <option value=''>ระบุประเภทส่วนลด</option>
                      <option v-for="item in listOTPDiscType" v-bind:key="item.DISCTYPE" v-bind:value="item.DISCTYPE"> {{ item.DISCTYNAME }} </option>
                      <!-- <option v-for="(item, index) in listOTPDiscType" :value="item">{{item}}</option> -->
                      <!-- <option value=''>บาท</option>
                      <option value=''>เปอร์เซ็น</option> -->
                    </select>
                  </td>
                  <td>
                    <select v-model="payType" class='form-control' :disabled="isConfirm">
                      <option value=''>ระบุประเภทการชำระเงิน</option>
                      <option v-for="item in listOTPPayType" v-bind:key="item.PAYTYPE" v-bind:value="item.PAYTYPE"> {{ item.PAYTYPENAME }} </option>
                      <!-- <option value=''>บาท</option>
                      <option value=''>เปอร์เซ็น</option> -->
                      <!-- <option v-for="(item, index) in listOTPPayType" :value="item">{{item}}</option> -->
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="px-3 pb-3">
              <label for="remark">Remark : </label>
              <!-- <input type="text" name="remark" class='form-control'> -->
              <textarea class="w-100" @change="updateRemark" rows="2" maxlength="200" placeholder="Remark........." name="Remark" :disabled="isConfirm" v-model="CommentData"></textarea>
            </div>
          </div>
          <div :class='`col-12 card mt-3 p-3 bg-${buCodeByOrder}`' v-if="sostatus">
          </div>
          <div class="row px-3 m-0 mt-3 card">
            <div class="col-12 mb-2 table-responsive">
              <p class="h5 mt-3 font-weight-bold">รายละเอียดสินค้า</p>
              <table class="table table-borderless" >
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <!-- <th scope="col"></th> -->
                    <th scope="col">สินค้า</th>
                    <!-- <th scope="col" v-if="salesource && salesource=='UV'">ทุน</th> -->
                    <th scope="col">ราคา</th>
                    <th scope="col">จำนวน</th>
                    <th scope="col">มูลค่าสินค้า</th>
                    <th scope="col">ส่วนลดOTP</th>
                    <th scope="col">บาท / เปอร์เซ็น</th>
                    <th scope="col">ยอดรวม</th>
                    <th scope="col">ราคาคู่แข่ง</th>
                    <th scope="col" v-show="salesource  == 'VP'">%Merch</th>
                    <th scope="col" v-show="salesource  == 'VP'">%Supplier</th>
                    <th scope="col">%Charge</th>
                    <th scope="col">Charge Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(e, index) in listData[1]" :key="index">
                    <td class="py-1">{{ index+1 }} </td>
                    <!-- <td class="p-0">
                      <ImageBySKU class="py-1" style="max-height: 10vh;" :sku="e.SKC" :key="e.SKC" v-if="!(e.SKC == '')"/>
                    </td> -->
                    <td class="py-1">{{ e.PRN }} <br>({{ e.SKC }}) </td>
                    <!-- <td class="py-1" v-if="salesource && salesource=='UV'">{{ e.COST }}</td> -->
                    <td class="py-1">{{ e.UPC }}</td>
                    <td class="py-1">{{ e.QNT }}</td>
                    <td class="py-1">{{ e.AMT }}</td>
                    <td class="py-1"><input type="text" v-model="OTPDis[index]" :disabled="DisEn[index] == false || isConfirm" size="10" :maxlength="maxLDis[index]" @input="updateValue($event,index)" @keypress="sumDis(index,$event)" @focusout="focusOutDis(index)" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"></td>
                     <!-- <td class="py-1"><input type="number" v-model="OTPDisB[index]" @input="updateValueB($event,index)" @focusout="OTPDisB[index] = financial(OTPDisB[index])" @keypress="sumDis(index,$event)"></td>
                    <td class="py-1"><input type="number" v-model="OTPDisP[index]" @input="updateValueP($event,index)" @focusout="OTPDisP[index] = financial(OTPDisP[index])" @keypress="sumDis(index,$event)"></td> -->
                    <td class="py-1">
                      <select  v-model="OTPDisType[index]" @change="changeMaxLDis($event,index)" class='form-control' :disabled="isConfirm || e.SKC == 154338">
                        <option value=''>ระบุประเภทส่วนลด</option>
                        <option value='B'>บาท</option>
                        <option value='P'>เปอร์เซ็น</option>
                      </select>
                    </td>
                    <td class="py-1">{{ AmountPrice[index] }}</td>
                    <td class="py-1"><input type="text" :disabled="isConfirm" v-model="priceCom[index]" size="10" maxlength="10" @focusout="financialCom(index)" onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"></td>
                    <td class="py-1" v-show="salesource  == 'VP'"><input type="text" :disabled="isConfirm" v-model="perMerch[index]" size="10" maxlength="10" @input="updatePerMerch($event,index)"  onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"></td>
                    <td class="py-1" v-show="salesource  == 'VP'"><input type="text" :disabled="isConfirm" v-model="perSupplier[index]" size="10" maxlength="10" @input="updatePerSupplier($event,index)"  onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"></td>
                    <td class="py-1">{{ e.PRCCHARGE }}</td>
                    <td class="py-1">{{ AmountCharge[index] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row p-0 m-0 mt-3 mb-3 " v-show="false">
            <div class="col-12 card">
                <div class='h-100'>
                    <p class="h5 mt-3 font-weight-bold">ส่วนลดที่มีผลกับ OTP</p>
                    <p>ส่วนลด BMGN Promotion <input type="checkbox" v-model="BMGN" :disabled="isConfirm"></p>
                    <div>
                    </div>
                    <p>ส่วนลด On Top <input type="checkbox" v-model="OnTOP" :disabled="isConfirm"></p>
                    <div v-if="OnTOP">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col text-center">ส่วนลด</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(e, index) in ListDisc" :key="index">
                          <td class="p-0 text-center">  
                            <input type="checkbox" v-model="OTPONTOP[index]" :disabled="isConfirm">
                          </td>
                          <td class="py-1">{{ e[''] }}</td>
                        </tr>
                      </tbody>
                    </table>
                    </div>
                    <p v-show="false" >ส่วนลดผ่อนชำระ <input type="checkbox" v-model="INST" :disabled="isConfirm"></p> 
                    <div v-show="false" v-if="INST">
                      <select v-model="bankType" class='form-control mb-1' :disabled="isConfirm">
                        <option value=''>ระบุประเภทบัตร</option>
                        <option v-for="item in listBankCard" v-bind:key="item.BANK_SNAME" v-bind:value="item.BANK_SNAME"> {{ item.BANK_FNAME }} </option>
                      </select>
                      <select v-model="bankPeriod" class='form-control mb-3' :disabled="isConfirm">
                        <option value=''>จำนวนงวด</option>
                        <option v-for="item in listIdPlan" v-bind:key="item.ID_PLAN" v-bind:value="item.ID_PLAN"> {{ item.PLAN_NAME }} </option>
                      </select>
                    </div>
                </div>
            </div>
          </div>
          <div class="row p-0 m-0 mt-3 mb-3 ">
            <div class="col-12 card">
                <div class='h-100'>
                    <p class="h5 mt-3 font-weight-bold">รายละเอียด</p>
                    <table class="table table-borderless">
                        <tbody>
                        <tr>
                            <td class='text-center'>จำนวนสินค้า</td>
                            <td class='text-center'>{{ listData[1].length }} ชิ้น</td>
                        </tr>
                        <tr>
                            <td class='text-center'>ยอดเงิน</td>
                            <td class='text-center'>{{ getSumAMT }} บาท</td>
                        </tr>
                        <tr>
                            <td class='text-center'>ส่วนลด OTP</td>
                            <td class='text-center'> {{ OTPDisc }} บาท</td>
                        </tr>
                        <tr>
                            <td class='text-center'>ส่วนลด PLPP</td>
                            <td class='text-center'>{{ PLPPDisc }} บาท</td>
                        </tr>
                        <tr>
                            <td class='text-center'>ส่วนลด ON TOP</td>
                            <td class='text-center'>{{ ONTOPDisc }} บาท</td>
                        </tr>
                        <tr>
                            <td class='text-center'>ยอดส่วนลดรวม</td>
                            <td class='text-center'>{{ getFinalDisc }} บาท</td>
                        </tr>
                        <tr>
                            <td class='text-center'>ยอดเงินสุทธิ</td>
                            <td class='text-center'> {{ getFinalPrice }} บาท</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
          </div>
          <div :class='`col-12 mt-3 mb-3 p-3`' v-show="!isConfirm && getQTOTP">
            <div class="row d-flex justify-content-end">
              <!-- <button @click="test()" type="button" :class="`col-2 btn-visible-${buCodeByOrder} bg-${buCodeByOrder} mx-1`" >test</button> -->
              <button @click="clearData()" type="button" :class="`col-2 btn-visible-${buCodeByOrder} bg-${buCodeByOrder} mx-1`" >ล้างข้อมูล</button>
              <button @click="saveInfo()" type="button" :class="`col-2 btn-visible-${buCodeByOrder} bg-${buCodeByOrder} mx-1`" >บันทึกข้อมูล & คำนวณส่วนลด</button>
              <!-- <button @click="saveInfo()" type="button" :class="`col-2 btn-visible-${buCodeByOrder} bg-${buCodeByOrder} mx-1`" >คำนวณส่วนลด</button> -->
              <button @click="conOTPQTModal()" :disabled="!isSaveOTP" type="button" :class="`col-2 btn-visible-${buCodeByOrder} bg-${buCodeByOrder} mx-1`" >ยืนยันOTP Request</button>
            </div>
          </div>
        </div>
        <OTPRequestDetailSkeleton v-show="isload" />
        <Modal ref="OTPreqModal" size="60">
          <template v-slot:header>
            <p class="text-left pl-2">รายละเอียด</p>
          </template>
          <template v-slot:body >
            <table class="table table-borderless">
              <tbody>
                <tr>
                    <td class='text-center'>จำนวนสินค้า</td>
                    <td class='text-center'>{{ listData[1].length }} รายการ</td>
                </tr>
                <tr>
                    <td class='text-center'>ยอดเงิน</td>
                    <td class='text-center'>{{ getSumAMT }} บาท</td>
                </tr>
                <tr>
                    <td class='text-center'>ส่วนลด OTP</td>
                    <td class='text-center'> {{ OTPDisc }} บาท</td>
                </tr>
                <tr>
                    <td class='text-center'>ส่วนลด PLPP</td>
                    <td class='text-center'>{{ PLPPDisc }} บาท</td>
                </tr>
                <tr>
                    <td class='text-center'>ส่วนลด ON TOP</td>
                    <td class='text-center'>{{ ONTOPDisc }} บาท</td>
                </tr>
                <tr>
                    <td class='text-center'>ยอดส่วนลดรวม</td>
                    <td class='text-center'>{{ getFinalDisc }} บาท</td>
                </tr>
                <tr>
                    <td class='text-center'>ยอดเงินสุทธิ</td>
                    <td class='text-center'> {{ getFinalPrice }} บาท</td>
                </tr>
              </tbody>
            </table>
            <div :class='`col-12 mt-3 mb-3 p-3`' v-show="!isConfirm">
              <div class="row d-flex justify-content-end">
                <button @click="conOTPQT()" :disabled="!isSaveOTP" type="button" :class="`col-4 btn-visible-${buCodeByOrder} bg-${buCodeByOrder} mx-1`" >ยืนยันOTP Request</button>
              </div>
            </div>
          </template>
        </Modal>
              <!-- v-if="[0,2].includes(pendingEtax) && sendEtax" -->
        <!-- <button type="button" :class="`btn bt w-100 bg-${getBU}`" data-dismiss="modal" @click="goPrint()">พิมพ์ใบยืนยันการชำระเงิน</button> -->
    </section>
</template>

<script>
import * as serviceAPI from '@/services/API.service';
import * as serviceMain from '@/services/main.service';
import * as constant from '@/services/constant';
import Modal from '@/components/Modal.vue'
import ImageBySKU from '@/components/ImageBySKU.vue';
import PrintQuotation from '@/components/Menu/PrintQuotation.vue'
import OTPRequestDetailSkeleton from '@/components/OtpRequest/OTPRequestDetailSkeleton.vue';
import { TransitionGroup } from '@vue/runtime-dom';


export default {
    name: "OTPRequestDetail",
    data: function () {
        return {
            listData: [[{
                    "#ref": "",
                    "#orderdate": "",
                    "#dscamt": Number,
                    "#discplpp": "",
                    "#discmkt": "",
                    "#gtotamt": Number,
                    "#amt706": Number,
                    "#cost706": Number,
                    "#gpamount": Number,
                    "#gp": Number,
                    "#custid": "",
                    "#custname": "",
                    "#mobile": "",
                    "#ticket": "",
                    "#postime": "",
                    "#sstatus": "",
                    "#sorderstore": "",
                    "#sorderstatus": "",
                    "#sttname": "",
                    "#dlvcode": "",
                    "#dlvtype": "",
                    "#stcode": "",
                    "#DiscID": Number,
                    "#DISCCODE": "",
                    "#DiscRate": Number,
                    "#DiscType": ""
                }],[{
                    "SEQ": Number,
                    "PRN": "",
                    "SKC": '',
                    "QNT": Number,
                    "UPC": Number,
                    "AMT": Number,
                    "SKUSEQ": Number,
                    "NORCOST": "",
                    "SHOWCOST": "",
                    "CAT_CODE": "",
                    "PR_VAT": "",
                    "COST706": Number,
                    "AMT706": Number,
                    "GP": Number,
                    "Remark": "",
                    "PRCDISC": Number,
                    "BHTDISC": Number,
                    "OTPDISC": Number,
                    "PRCCHARGE": Number,
                    "PRCCOMP": Number,
                    "amtcharge": Number,
                }]],
                ListQuotationHeader:[],
                // ListOrder:[],
                listRows:[],
                salesource: '',
                sostatus: '',
                buCodeByOrder: '',
                stcode:'',
                OTPDis:[],
                OTPDisType:[],
                AmountPrice:[],
                AmountCharge:[],
                priceCom:[],
                perMerch:[],
                perSupplier:[],
                maxLDis:[],
                DisEn:[],
                ListDisc:[],
                OTPONTOP:[],
                listOTPPayType:[],
                listOTPDiscType:[],
                listBankCard:[],
                listIdPlan:[],
                payType:'',
                discType:'',
                bankType:'',
                bankPeriod:'',
                jsnOTPrq:[],
                jsnItem:[],
                jsnONTOP:[],
                jnsINST:[],
                BMGN:'',
                OnTOP:'',
                INST:'',
                ONTOPDisc:0,
                PLPPDisc:0,
                OTPDisc:0,
                // listDataPlpp: [],
                isHavePlpp: false,
                // sumDisPlpp: 0,
                // listSKC:[],
                listsavedata:[],
                OTPreq:0,
                whereBy:'ByUser',
                isload:true,
                listOTPDisc:[],
                CommentData:'',
                jsnItemShare:[],
            }
        },
    components: {
        Modal,
        ImageBySKU,
        OTPRequestDetailSkeleton,
        PrintQuotation,
    },
    props: [
        "cartRef",
    ],
    mounted() {
        try {
            this.Init()
        } catch (error) {
            serviceMain.showErrorAlert(this,error.message)
        }
    },
    methods: {
        async Init(){
            this.isload = true;
            //ฟังชั่นทำงานเมื่อเปิด modal ให้โหลดข้อมูล จาก api
            let skc = this.cartRef;
            this.stcode = this.$route.query.stcode;
            this.OTPreq = this.$route.query.OTPreq;
            this.whereBy = this.$route.query.whereBy;
            // console.log(skc+' '+STCODE);
            await this.getQuotationHeader()
            // await this.otpOrderinfo()
            await this.detailHis(0);
            await this.detailHis(1);
            await this.getListDiscLV2(3);
            await this.otpDiscType()
            await this.otpPayType()
            await this.getBankCard()
            await this.getIdPlan()
            await this.setInit()
            // await this.showPLPP()
            this.salesource = this.qtSaleSource || this.listData[0][0]['#salesource'];
        },
        
        async detailHis(mode) {
            try {
                let data = {
                REF: this.cartRef,
                STCODE: this.stcode,
                mode: mode
                }
                let getAPI = await serviceAPI.call_API('post', `otp/otpGetQuotationDetail`, data, 'auth');
                this.listData[mode] = getAPI.data.dbitems;
                this.listRows[mode] = getAPI.data.dbrows;

                // if(mode == 0) {
                //     // this.sostatus = this.qtSoStatus || this.listData[0][0]['#sostatus'];
                //     this.salesource = this.qtSaleSource || this.listData[0][0]['#salesource'];
                //     this.buCodeByOrder = this.buCode || this.listData[0][0]['#buCode'];
                // }
                

            } catch (error) {
                serviceMain.showErrorAlert(this, error.message)
            }
        },

        async getQuotationHeader() {
            try {
                let data = {
                REF: this.cartRef,
                STCODE: this.stcode,
                }
                let getAPI = await serviceAPI.call_API('post', `otp/otpGetQuotationHeader`, data, 'auth');
                // console.log(getAPI);
                this.ListQuotationHeader = getAPI.data.dbitems[0];
                this.OTPDisc = this.ListQuotationHeader.OTPDisc
                this.PLPPDisc = this.ListQuotationHeader.PLPPDisc
                this.ONTOPDisc = this.ListQuotationHeader.ONTOPDisc
            } catch (error) {
                serviceMain.showErrorAlert(this, error.message)
            }
        },

        // async otpOrderinfo() {
        //     try {
        //         let data = {
        //         EMAIL: this.ListQuotationHeader.email,
        //         MBL: this.ListQuotationHeader.mobile,
        //         REF: this.cartRef,
        //         }
        //         let getAPI = await serviceAPI.call_API('post', `otp/otpOrderinfo`, data, 'auth');
        //         console.log(getAPI);
        //         this.ListOrder = getAPI.data.dbitems;
        //     } catch (error) {
        //         serviceMain.showErrorAlert(this, error.message)
        //     }
        // },

        // async showPLPP() {
        //   try {
        //     let data = {
        //       REF: this.cartRef,
        //       STC: this.stcode,
        //     };
        //     let getAPI = await serviceAPI.call_API("post", `cart/PLPP`, data, "auth" );
        //     this.isHavePlpp = getAPI.data.dbrows == 0 ? false : true;
        //     this.listDataPlpp = getAPI.data.dbitems;
        //     this.listSKC = this.ListOrder.map((e,i)=>{
        //       let temp = this.listDataPlpp.find(element=> element.SKUSEQ == e.SKUSEQ)
        //       if(temp) {
        //         if(temp.DSC) {
        //           e.DSC = temp.DSC;
        //           e.ATM = parseFloat(e.ATM) - parseFloat(temp.DSC)
        //         }
        //       }else{
        //         e.DSC  = 0
        //       }
        //       return e;
        //     })
        //     this.sumDisPLPP();
        //   } catch (error) {
        //     console.log('error');
        //     serviceMain.showErrorAlert(this, error.message);
        //   }
        // },
        // async sumDisPLPP() {
        //   try {
        //     let getSumDis = 0;
        //     for (let e of this.listDataPlpp) {
        //       if (e.typ == "B" || e.typ == "Q") {
        //         getSumDis = getSumDis + e.DSC;
        //       }
        //     }
        //     this.sumDisPlpp = getSumDis;
        //     // this.$emit("sumDisReturn", this.sumDisPlpp);
        //   } catch (error) {
        //     serviceMain.showErrorAlert(this, error.message);
        //   }
        // },

        async getListDiscLV2(mode) {
            try {
                let data = {
                MODE: `${mode}`,
                STCODE: this.stcode,
                }
                let getAPI = await serviceAPI.call_API('post', `otp/getListDiscLV2`, data, 'auth');
                // console.log(getAPI);
                this.ListDisc = getAPI.data.dbitems;
                for (let i = 0; i < this.ListDisc.length; i++) {
                  this.OTPONTOP[i] = ''
                }
            } catch (error) {
                serviceMain.showErrorAlert(this, error.message)
            }
        },

        async otpDiscType() {
            try {
                let data = {
                }
                let getAPI = await serviceAPI.call_API('post', `otp/otpDiscType`, data, 'auth');
                // console.log(getAPI);
                this.listOTPDiscType = getAPI.data.dbitems;
            } catch (error) {
                serviceMain.showErrorAlert(this, error.message)
            }
        },

        async otpPayType() {
            try {
                let data = {
                }
                let getAPI = await serviceAPI.call_API('post', `otp/otpPayType`, data, 'auth');
                // console.log(getAPI);
                this.listOTPPayType = getAPI.data.dbitems;
            } catch (error) {
                serviceMain.showErrorAlert(this, error.message)
            }
        },

        async getBankCard() {
            try {
                let data = {
                }
                let getAPI = await serviceAPI.call_API('post', `otp/getBankCard`, data, 'auth');
                // console.log(getAPI);
                this.listBankCard = getAPI.data.dbitems;
            } catch (error) {
                serviceMain.showErrorAlert(this, error.message)
            }
        },

        async getIdPlan() {
            try {
                let data = {
                }
                let getAPI = await serviceAPI.call_API('post', `otp/getIdPlan`, data, 'auth');
                // console.log(getAPI);
                this.listIdPlan = getAPI.data.dbitems;
            } catch (error) {
                serviceMain.showErrorAlert(this, error.message)
            }
        },

        async setInit(){
          this.buCodeByOrder = this.ListQuotationHeader.buCode || 'PWB'
          this.discType = this.ListQuotationHeader.JsnOTPRQ_Disctype || ''
          this.payType = this.ListQuotationHeader.JsnOTPRQ_Paytype || ''
          this.CommentData = this.ListQuotationHeader.JsnOTPRQ_Comment || ''
          this.BMGN = this.ListQuotationHeader.JsnOTPRQ_BMGN == 1 ? true : false
          this.OnTOP = this.ListQuotationHeader.JsnOTPRQ_OnTOP == 1 ? true : false
          this.INST = this.ListQuotationHeader.JsnOTPRQ_INST == 1 ? true : false
          this.listOTPDisc = []
          if(this.ListQuotationHeader.jsnOTPONTOP){
            this.ListDisc.map((e, i) => {
              JSON.parse(this.ListQuotationHeader.jsnOTPONTOP).map(element => {
                if(element.DISCID == e.DISID){
                  this.OTPONTOP[i] = true;
                }
              })
            })
          }
          if(this.ListQuotationHeader.jsnOTPINST){
            if(JSON.parse(this.ListQuotationHeader.jsnOTPINST).length != 0){
              this.bankType = JSON.parse(this.ListQuotationHeader.jsnOTPINST)[0].Bank ? JSON.parse(this.ListQuotationHeader.jsnOTPINST)[0].Bank : ''
              this.bankPeriod = JSON.parse(this.ListQuotationHeader.jsnOTPINST)[0].Period ? JSON.parse(this.ListQuotationHeader.jsnOTPINST)[0].Period : ''
            } else {
              this.bankType = ''
              this.bankPeriod = ''
            }
          }
          for (let i = 0; i < this.listData[1].length; i++) {
              this.OTPDis[i] = this.listData[1][i].PRCDISC ? this.listData[1][i].PRCDISC : this.listData[1][i].BHTDISC
              this.OTPDisType[i] = this.listData[1][i].PRCDISC ? 'P' : this.listData[1][i].BHTDISC ? 'B' : ''
              this.AmountPrice[i] = this.listData[1][i].PRCDISC ? this.listData[1][i].AMT * (100 - this.listData[1][i].PRCDISC)/100 : this.listData[1][i].AMT - this.listData[1][i].BHTDISC
              this.priceCom[i] = this.listData[1][i].PRCCOMP
              this.maxLDis[i] = '12'
              this.DisEn[i]= this.OTPDisType[i] == '' ? false : true
              this.AmountCharge[i] = this.AmountPrice[i]*this.listData[1][i].PRCCHARGE/100
              this.perMerch[i] = this.listData[1][i].MERCHSHARE
              this.perSupplier[i] = this.listData[1][i].SUPSHARE
              let OTPDISC = this.listData[1][i].PRCDISC ? this.listData[1][i].AMT * (this.listData[1][i].PRCDISC)/100 : this.listData[1][i].BHTDISC
              if(OTPDISC){
                this.listOTPDisc.push({
                  PRN: this.listData[1][i].PRN,
                  QNT: this.listData[1][i].QNT,
                  OTPDISC: OTPDISC
                })
              }
          }
          this.isload = false;
        },

        async saveInfo() {
            try {
              if(!this.discType){
                throw new Error("กรุณาเลือกประเภทส่วนลด")
              }
              if(!this.payType){
                throw new Error("กรุณาเลือกประเภทการชำระเงิน")
              }
              this.jsnOTPrq = {Disctype:this.discType,
                               Paytype:this.payType, 
                               BMGN:this.BMGN ? 1 :0,
                               OnTOP:this.OnTOP ? 1 :0,
                               INST:this.INST ? 1 :0,
                               Comment:this.CommentData
              }
              await this.validateDiseCount()
              // this.validateONTOP()
              if(this.BMGN){
                if(true){

                }else{
                  throw new Error("กรุณาเลือกส่วนลดBMGN")
                }
              }
              this.jsnONTOP = [];
              if(this.OnTOP){
                for (let i = 0; i < this.ListDisc.length; i++) {
                  if(this.OTPONTOP[i]){
                    this.jsnONTOP.push({"DISCID": this.ListDisc[i].DISID,
                                        "DISCCODE": this.ListDisc[i].DISCCODE})
                  }
                }
                if(this.jsnONTOP.length==0){
                  throw new Error("กรุณาเลือกส่วนลด ON TOP")
                }
              }
              this.jnsINST = [];
              if(this.INST){
                if(this.bankType != '' && this.bankPeriod != ''){
                  this.jnsINST = [{
                                  "Bank": this.bankType,
                                  "Period": this.bankPeriod
                  }]
                } else {
                  throw new Error("กรุณาเลือกส่วนลดผ่อนชำระ")
                }
              }
              // console.log(this.jsnItem);
              // console.log(this.jsnOTPrq);
              // console.log(this.jsnONTOP);
              // console.log(this.jnsINST);
              let data = {
                EMAIL:this.ListQuotationHeader.email,
                MOBILE:this.ListQuotationHeader.mobile,
                REF:this.cartRef,
                jsnOTPrq:this.jsnOTPrq,
                jsnItem:this.jsnItem,
                jsnONTOP:this.jsnONTOP,
                jsnINST:this.jnsINST,
              }
              // console.log(data);
              let _this = this
              this.$swal.fire({
                  html: '<h2>ต้องการบันทึกขอส่วนลดOTPหรือไม่?</h2>',
                  showCancelButton: true,
                  confirmButtonColor: this.getBuColor,
                  confirmButtonText: 'ตกลง'
              }).then(async (result) => {
                  if (result.value) {
                    let getAPI = await serviceAPI.call_API('post', `otp/otpSaveQTsorder`, data, 'auth');
                    _this.listsavedata = getAPI.data.dbitems;
                    // let dataref = {
                    //     "MCID": _this.$store.getters['customer/getQTMCID'],
                    //     "EMAIL": _this.getQTCUSTINFO.EMAIL,
                    //     "MOBILE": _this.getQTCUSTINFO.MOBILE ,
                    //     "USERID": _this.$store.getters['auth/getQTUSERID']
                    // }
                    // let getAPIref = await serviceAPI.call_API('post', 'user/getCustref', dataref, 'auth');
                    // let getData = getAPIref.data.dbitems[0];
                    // let getCUSTREF = getData.CUSTREF;
                    // _this.$store.dispatch('customer/setQTCUSTREF', getCUSTREF)
                    // _this.$store.dispatch("customer/setQTDLVTRTYPE", "");
                    // _this.qtShopItem = 0;
                    _this.Init()
                  }
              }).catch((error) => {
                  serviceMain.showErrorAlert(this, error)
              });
            } catch (error) {
                serviceMain.showErrorAlert(this, error.message)
            }
        },

        async conOTPQTModal(){
          try {
            try {
              if(!this.discType){
                throw new Error("กรุณาเลือกประเภทส่วนลด")
              }
              if(!this.payType){
                throw new Error("กรุณาเลือกประเภทการชำระเงิน")
              }
              this.jsnOTPrq = {Disctype:this.discType,
                               Paytype:this.payType, 
                               BMGN:this.BMGN ? 1 :0,
                               OnTOP:this.OnTOP ? 1 :0,
                               INST:this.INST ? 1 :0,
                               Comment:this.CommentData
              }
              await this.validateDiseCount()
              // this.validateONTOP()
              if(this.BMGN){
                if(true){

                }else{
                  throw new Error("กรุณาเลือกส่วนลดBMGN")
                }
              }
              this.jsnONTOP = [];
              if(this.OnTOP){
                for (let i = 0; i < this.ListDisc.length; i++) {
                  if(this.OTPONTOP[i]){
                    this.jsnONTOP.push({"DISCID": this.ListDisc[i].DISID,
                                        "DISCCODE": this.ListDisc[i].DISCCODE})
                  }
                }
                if(this.jsnONTOP.length==0){
                  throw new Error("กรุณาเลือกส่วนลด ON TOP")
                }
              }
              this.jnsINST = [];
              if(this.INST){
                if(this.bankType != '' && this.bankPeriod != ''){
                  this.jnsINST = [{
                                  "Bank": this.bankType,
                                  "Period": this.bankPeriod
                  }]
                } else {
                  throw new Error("กรุณาเลือกส่วนลดผ่อนชำระ")
                }
              }
              // console.log(this.jsnItem);
              // console.log(this.jsnOTPrq);
              // console.log(this.jsnONTOP);
              // console.log(this.jnsINST);
              let data = {
                EMAIL:this.ListQuotationHeader.email,
                MOBILE:this.ListQuotationHeader.mobile,
                REF:this.cartRef,
                jsnOTPrq:this.jsnOTPrq,
                jsnItem:this.jsnItem,
                jsnONTOP:this.jsnONTOP,
                jsnINST:this.jnsINST,
              }
              // console.log(data);
              let getAPI = await serviceAPI.call_API('post', `otp/otpSaveQTsorder`, data, 'auth');
              this.listsavedata = getAPI.data.dbitems;
              // let dataref = {
              //     "MCID": this.$store.getters['customer/getQTMCID'],
              //     "EMAIL": this.getQTCUSTINFO.EMAIL,
              //     "MOBILE": this.getQTCUSTINFO.MOBILE ,
              //     "USERID": this.$store.getters['auth/getQTUSERID']
              // }
              // let getAPIref = await serviceAPI.call_API('post', 'user/getCustref', dataref, 'auth');
              // let getData = getAPIref.data.dbitems[0];
              // let getCUSTREF = getData.CUSTREF;
              // this.$store.dispatch('customer/setQTCUSTREF', getCUSTREF)
              // this.$store.dispatch("customer/setQTDLVTRTYPE", "");
              // this.qtShopItem = 0;
              await this.Init()
              await this.openModal()
            } catch (error) {
                serviceMain.showErrorAlert(this, error.message)
            }
            
            
            
                                        
          } catch (error) {
            this.WaitBooking = false;
            serviceMain.showErrorAlert(this,error)
          }
        },

        async conOTPQT(){
          try {
            let data={
              REF:this.cartRef ,
              STCODE:this.stcode,
              USERID:this.getQTUSERID,
            }
            let getAPI = await serviceAPI.call_API('post',`otp/otpConfirmQuotation`,data,'auth');
            console.log(getAPI);
            let getOTPrqnum = getAPI.data.dbitems[0].OTPrqnum;
            console.log(getOTPrqnum);
            if(this.salesource == 'VP'){
              //ส่งmaill approve
              let ORDERITMS= []
              for (let i = 0; i < this.OTPDis.length; i++){
                ORDERITMS[i] = {"SEQ":this.listData[1][i].SKUSEQ,
                            "PRN":this.listData[1][i].PRN,
                            "QNT":this.listData[1][i].QNT,
                            "AMT":this.listData[1][i].AMT,
                            "UPC":this.listData[1][i].UPC,
                            "SKC":this.listData[1][i].SKC,
                            "AmountPrice":this.AmountPrice[i],
                            "PRCDISC":this.OTPDisType[i] == 'P' ? this.OTPDis[i] : 0,
                            "BHTDISC":this.OTPDisType[i] == 'B' ? this.OTPDis[i] : 0,
                            "PRCCOMP":this.priceCom[i] ? this.priceCom[i] : 0,
                            "PRCCHARGE":this.listData[1][i].PRCCHARGE,
                            "AMTCHARGE":this.AmountCharge[i],
                            "perMerch":this.perMerch ? parseInt(this.perMerch[i]) || 0 : 0,
                            "perSupplier":this.perSupplier ? parseInt(this.perSupplier[i]) || 0 : 0
                            }
              }
              console.log(ORDERITMS);
              let datamail = {
                  QT: this.cartRef,
                  TOTAL: this.getFinalPrice,
                  STC: this.stcode,
                  USERNAME: this.$store.getters['auth/getUser'].QTUSERNAME,
                  REMARK: '',
                  HOSTNAME:`${location.protocol}//${location.host}`,
                  APINAME:constant.API_URL,
                  ORDERITMS:ORDERITMS,
                  RQNUM: getOTPrqnum,
              };
              console.log(datamail);
              await serviceAPI.call_API('post', `otp/mailNotifyOTP`, datamail, 'auth');
            }
            serviceMain.showSuccessAlertWithTime(this,`สร้างOTPใบเสนอราคาหมายเลข(${getOTPrqnum})เรียบร้อยแล้ว`,3000)
            // this.clearDataCon()
            this.backtoHis()
            
                                        
          } catch (error) {
            this.WaitBooking = false;
            serviceMain.showErrorAlert(this,error)
          }
        },

        async test(){
          if(this.salesource == 'VP'){
              //ส่งmaill approve
              let ORDERITMS= []
              for (let i = 0; i < this.OTPDis.length; i++){
                ORDERITMS[i] = {"SEQ":this.listData[1][i].SKUSEQ,
                            "PRN":this.listData[1][i].PRN,
                            "QNT":this.listData[1][i].QNT,
                            "AMT":this.listData[1][i].AMT,
                            "UPC":this.listData[1][i].UPC,
                            "SKC":this.listData[1][i].SKC,
                            "AmountPrice":this.AmountPrice[i],
                            "PRCDISC":this.OTPDisType[i] == 'P' ? this.OTPDis[i] : 0,
                            "BHTDISC":this.OTPDisType[i] == 'B' ? this.OTPDis[i] : 0,
                            "PRCCOMP":this.priceCom[i] ? this.priceCom[i] : 0,
                            "PRCCHARGE":this.listData[1][i].PRCCHARGE,
                            "AMTCHARGE":this.AmountCharge[i],
                            "perMerch":this.perMerch ? parseInt(this.perMerch[i]) || 0 : 0,
                            "perSupplier":this.perSupplier ? parseInt(this.perSupplier[i]) || 0 : 0
                            }
              }
              console.log(ORDERITMS);
              let datamail = {
                  QT: this.cartRef,
                  TOTAL: this.getFinalPrice,
                  STC: this.stcode,
                  USERNAME: this.$store.getters['auth/getUser'].QTUSERNAME,
                  REMARK: '',
                  HOSTNAME:`${location.protocol}//${location.host}`,
                  APINAME:constant.API_URL,
                  ORDERITMS:ORDERITMS,
                  RQNUM: getOTPrqnum,
              };
              console.log(datamail);
              await serviceAPI.call_API('post', `otp/mailNotifyOTP`, datamail, 'auth');
            }
        },

        async clearDataCon(){
          this.$router.push({ name: 'FindUser' , query: { confirm: 'success' }})
        },

        async backtoHis(){
          this.$router.push({ name: 'History', params: { whereBy: this.whereBy}, query: { QT: this.cartRef },})
        },

        clearData(){
          let _this = this
          this.$swal.fire({
              html: '<h2>ต้องการล้างข้อมูลหรือไม่?</h2>',
              showCancelButton: true,
              confirmButtonColor: this.getBuColor,
              confirmButtonText: 'ตกลง'
          }).then(async (result) => {
              if (result.value) {
                for (let i = 0; i < _this.listData[1].length; i++) {
                  _this.OTPDis[i] = ''
                  _this.AmountPrice[i] = _this.listData[1][i].AMT
                  _this.OTPDisType[i] = ''
                  _this.priceCom[i] = ''
                  _this.maxLDis[i] = '12'
                  _this.DisEn[i]=false
                  _this.AmountCharge[i] = _this.AmountPrice[i]*_this.listData[1][i].PRCCHARGE/100
                }
                for (let i = 0; i < _this.ListDisc.length; i++) {
                  _this.OTPONTOP[i] = ''
                }
                _this.INST = false;
                _this.OnTOP = false;
                _this.BMGN = false;
                _this.payType = '';
                _this.discType = '';
                _this.bankType = '';
                _this.bankPeriod = '';
                _this.jsnOTPrq = [];
                _this.jsnItem = [];
                _this.jsnONTOP = [];
                _this.OTPDisc = 0
                _this.PLPPDisc = 0
                _this.ONTOPDisc = 0
                _this.CommentData = ''
              }
          }).catch((error) => {
              serviceMain.showErrorAlert(this, error)
          });
        },

        async validateDiseCount() {
            try {
              for (let i = 0; i < this.OTPDis.length; i++){
                if(this.OTPDisType[i] != '' && this.OTPDis[i] == ''){
                  throw new Error("กรุณากรอกส่วนลดOTPให้ครบ")
                } else if(this.OTPDisType[i] == 'P'){
                  
                } else {
                  
                }
                if(this.salesource == 'VP'){
                  console.log(this.listData[1][i].SKC,this.perSupplier[i],this.perMerch[i]);
                  console.log(this.OTPDisType[i] != '' && (this.perSupplier[i] === '' || this.perSupplier[i] === null || this.perSupplier[i] === undefined || this.perMerch[i] === '' || this.perMerch[i] === null || this.perMerch[i] === undefined));
                  if(this.OTPDisType[i] != '' && (this.perSupplier[i] === '' || this.perSupplier[i] === null || this.perSupplier[i] === undefined || this.perMerch[i] === '' || this.perMerch[i] === null || this.perMerch[i] === undefined)){
                    throw new Error("กรุณากรอก%Supplier / %Merch")
                  }
                  console.log(this.OTPDisType[i] != '' && (parseFloat(this.perSupplier[i]) + parseFloat(this.perMerch[i])  != 100));
                  if(this.OTPDisType[i] != '' && (parseFloat(this.perSupplier[i]) + parseFloat(this.perMerch[i])  != 100)){
                    console.log(1);
                    throw new Error("Merchandise Share + Supplier Share  ต้องเท่ากับ 100 !!")
                  }
                }

                // let data = [{"SEQ":ลำกดับรายการ,
                //               "QNT":จำนวน,
                //               "AMT":มูลค่าสินค้า,
                //               "UPC":ราคาต่อหน่วย,
                //               "SKC":รหัสSKU,
                //               "PRCDISC":%ส่วนลด,
                //               "BHTDISC":ส่วนลดบาท,
                //               "PRCOMP":ราคาคู่แข่ง,
                //               "PRCCHARGE":%ชาร์จ,
                //               "AMTCHARGE":ยอดชาร์จ}]

              }
              this.jsnItem = []
              this.jsnItemShare = []
              for (let i = 0; i < this.OTPDis.length; i++){
                this.jsnItem[i] = {"SEQ":this.listData[1][i].SKUSEQ,
                            "QNT":this.listData[1][i].QNT,
                            "AMT":this.listData[1][i].AMT,
                            "UPC":this.listData[1][i].UPC,
                            "SKC":this.listData[1][i].SKC,
                            "PRCDISC":this.OTPDisType[i] == 'P' ? this.OTPDis[i] : 0,
                            "BHTDISC":this.OTPDisType[i] == 'B' ? this.OTPDis[i] : 0,
                            "PRCCOMP":this.priceCom[i] ? this.priceCom[i] : 0,
                            "PRCCHARGE":this.listData[1][i].PRCCHARGE,
                            "AMTCHARGE":this.AmountCharge[i],
                            "perMerch":this.perMerch ? parseInt(this.perMerch[i]) || 0 : 0,
                            "perSupplier":this.perSupplier ? parseInt(this.perSupplier[i]) || 0 : 0
                            }
                this.jsnItemShare[i] = {"SEQ":this.listData[1][i].SKUSEQ,
                            "SKC":this.listData[1][i].SKC,
                            "perMerch":this.perMerch[i] || 0,
                            "perSupplier":this.perSupplier[i] || 0
                            }
              }
            } catch (error) {
                serviceMain.showErrorAlert(this, error.message)
            }
        },

        changeMaxLDis(event, index){
          if(event.target.value == 'B'){
            this.DisEn[index]=true
            // this.maxLDis[index] = '7'
          } else if(event.target.value == 'P'){
            if(this.OTPDis[index] > 100){
              this.OTPDis[index] = 100.00
            }
            this.DisEn[index]=true
            // this.maxLDis[index] = '3'
          } else {
            this.DisEn[index]=false
            this.OTPDis[index]=''
          }
          // this.OTPDis[index]=''
          this.sumDis(index)
          
        },

        sumDis(i){
          try {
          // if(event.charCode == 13){
            if(this.OTPDisType[i] == 'B' && this.OTPDis[i] != ''){
              this.AmountPrice[i] = parseFloat(this.listData[1][i].AMT) - parseFloat(this.OTPDis[i])
            } else if(this.OTPDisType[i] == 'P' && this.OTPDis[i] != ''){
              this.AmountPrice[i] = parseFloat(this.listData[1][i].AMT) - (parseFloat(this.listData[1][i].AMT)*parseFloat(this.OTPDis[i])/100)
            } else {
              this.AmountPrice[i] = parseFloat(this.listData[1][i].AMT)
            }
            if(this.AmountPrice[i] < 0){
              this.OTPDis[i] = 0.00
              this.AmountPrice[i] = parseFloat(this.listData[1][i].AMT)
              throw new Error("ส่วนลดมากกว่าราคาสินค้า กรุณากรอกส่วนลดอีกครั้ง")
            }
            this.AmountCharge[i] = this.AmountPrice[i]*this.listData[1][i].PRCCHARGE/100
          // }
          } catch (error) {
            serviceMain.showErrorAlert(this, error.message)
          }
        },

        updateValue(event,index) {
          const value = event.target.value
          if(this.OTPDisType[index] == 'B'){
            if (value < 0) {
              this.OTPDis[index] = 0.00
            }
            if (value > parseFloat(this.listData[1][index].AMT)) {
              this.OTPDis[index] = this.financial(parseFloat(this.listData[1][index].AMT))
            }
          } else if(this.OTPDisType[index] == 'P'){
            if (value > 100) {
              this.OTPDis[index] = 100.00
            }
            if (value < 0) {
              this.OTPDis[index] = 0.00
            }
          }
          this.$forceUpdate()
        },

        updateValueB(event,index) {
          const value = event.target.value
          if (value < 0) {
            this.OTPDisB[index] = 0
          }
          this.$forceUpdate()
        },

        updateValueP(event,index) {
          const value = event.target.value
          if (value > 100) {
            this.OTPDisP[index] = 100
          }
          if (value < 0) {
            this.OTPDisP[index] = 0
          }
          this.$forceUpdate()
        },

        updatePerSupplier(event,index) {
          const value = event.target.value
          if (value > 100) {
            this.perSupplier[index] = 100.00
          }
          if (value < 0) {
            this.perSupplier[index] = 0.00
          }
          // this.perSupplier[index] = parseInt(this.perSupplier[index])
          this.perMerch[index] = 100.00 - this.perSupplier[index]
          this.$forceUpdate()
        },

        updatePerMerch(event,index) {
          const value = event.target.value
          if (value > 100) {
            this.perMerch[index] = 100.00
          }
          if (value < 0) {
            this.perMerch[index] = 0.00
          }
          // this.perMerch[index] = parseInt(this.perMerch[index])
          this.perSupplier[index] = 100.00 - this.perMerch[index]
          this.$forceUpdate()
        },

        focusOutDis(index) {
          this.OTPDis[index] = this.financial(+this.OTPDis[index] || 0)
          this.sumDis(index)
        },

        financialCom(index) {
          this.priceCom[index] = this.financial(+this.priceCom[index] || 0)
        },

        financial(x) {
          return Number.parseFloat(x).toFixed(2);
        },

        textToNumeric(txtNum) {
          let val = parseFloat(txtNum);
          val = val.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
          return val
        },
        async openModal() {
          try {
            this.$refs.OTPreqModal.showmodal()
          } catch (error) {
            serviceMain.showErrorAlert(this, error.message)
          }
        },
        async hideModal() {
          try {
            this.$refs.OTPreqModal.hidemodal()
          } catch (error) {
            serviceMain.showErrorAlert(this, error.message)
          }
        },
    },
    computed:{
        getBU:function(){
            return this.$store.getters['customer/getQTBU']
        },
        getQTUSERID: function () {
          return this.$store.getters['auth/getQTUSERID']
        },
        getSumAMT:function(){
            let sum = 0
            for (let i = 0; i < this.listData[1].length; i++) {
              sum = sum + this.listData[1][i].AMT;
            }
            return this.financial(sum)
        },
        getSumDis:function(){
            let sum = 0
            for (let i = 0; i < this.OTPDis.length; i++) {
              if(this.OTPDisType[i] == 'B' && this.OTPDis[i] != ''){
                sum = sum + parseFloat(this.OTPDis[i]);
              } else if(this.OTPDisType[i] == 'P' && this.OTPDis[i] != ''){
                sum = sum + (parseFloat(this.listData[1][i].AMT)*parseFloat(this.OTPDis[i])/100);
              } else {

              }
            }
            return this.financial(sum)
        },
        getFinalDisc:function(){
            let sum = 0
            // sum = parseFloat(this.getSumDis) + this.listData[0][0]["#dscamt"]
            sum = this.OTPDisc + this.PLPPDisc + this.ONTOPDisc
            return this.financial(sum)
        },
        getFinalPrice:function(){
            let sum = 0
            // sum = this.getSumAMT - this.getSumDis - this.listData[0][0]["#dscamt"]
            sum = this.getSumAMT - this.getFinalDisc
            return this.financial(sum)
        },
        canEditEtax: function() {
          let profile = this.$store.getters['auth/getQTPROFILE'];
          return profile.includes('0116');
        },
        canDownloadEtax: function() {
          let profile = this.$store.getters['auth/getQTPROFILE'];
          return profile.includes('0117');
        },
        getOTPDIS: function() {
          return this.OTPDis;
        },
        isSaveOTP: function() {
          if( this.ListQuotationHeader.JsnOTPRQ_Disctype && this.ListQuotationHeader.JsnOTPRQ_Paytype ){
            return true
          }
          return false
        },

        isConfirm: function() {
          let ConQT = (['B', 'C', 'N', 'P', 'F', 'Q', 'R', 'V', 'U', 'O', 'D', 'X'].includes(this.listData[0][0]['#sorderstatus']))
          let ReqOTP = this.OTPreq == 'true' || this.listData[0][0]['#sorderstatus'] != 'C' || this.OTPreq == '1'
          return ReqOTP
          return !ConQT && ReqOTP
        },
        qtShopItem: {
          get() {
            return this.$store.getters["customer/getQTSHOPITEM"];
          },
          set(value) {
            this.$store.dispatch("customer/setQTSHOPITEM", value);
          },
        },
        getQTCUSTINFO:function(){
          return this.$store.getters['customer/getQTCUSTINFO']
        },
        getQTOTP: function () {
          return this.$store.getters['auth/getQTOTP']
        },
    },
    watch: {
      getOTPDIS: function () {
        for (let i = 0; i < this.OTPDis.length; i++){
          if(this.OTPDisType[i] == 'B'){
            this.AmountPrice[i] = this.listData[1].AMT - this.OTPDis[i]
          } else if(this.OTPDisType[i] == 'P'){
            this.AmountPrice[i] = this.listData[1].AMT - (this.listData[1].AMT*this.OTPDis[i]/100)
          } else {
            this.AmountPrice[i] = this.listData[1][i].AMT
          }
        }
      },
    }
}
</script>

<style scoped lang="scss">
$color-PWB: #55246c;
$color-bnb: #006e63;
$color-at1: #3866af;
$listBu: 'PWB' $color-PWB, 'bnb' $color-bnb, 'at1' $color-at1;

@each $buItems in $listBu {
    button.btn-visible-#{nth($buItems, 1)}:disabled,
    button[disabled].btn-visible-#{nth($buItems, 1)} {
        background-color: nth($buItems, 2);
        cursor: default;
    }
}

.input-History{
  border: 1px gray solid;
  padding: 0px 15px;
  border-radius:4px ;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.track {
     position: relative;
     background-color: none;
     height: 1px;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     margin-bottom: 50px;
     margin-top: 40px;
     //margin-left:-40px;
 }

 .line {
     position: relative;
     background-color: #CCC;
     height: 1px;
     width: 40%;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
 }

 .track .step {
     -webkit-box-flex: 1;
     -ms-flex-positive: 1;
     flex-grow: 1;
     width: 14%;
     margin-top: -18px;
     text-align: center;
     position: relative
 }

 .track .step.active .icon {
    background: #5cb85c;
    color: #fff;
    padding-top: 5px;
    font-size: 18px;
 }
 
 .track .step.cancel .icon {
     background: #55246c;
     color: #fff
 }

 .track .step.none .icon {
     background: none;
     color: #fff
 }

 .track .icon {
     display: inline-block;
     width: 30px;
     height: 30px;
     line-height: 30px;
     position: relative;
     border-radius: 100%;
     background: #ddd
 }

 .icon > .fa {
     font-size: 15px !important;
     margin-top: 8px;
 }

 .track .step.active .text {
     font-weight: 400;
     color: #000;
 }

 .lineca.none{
     background: none;
 }

 .track .text {
     font-size: 15px !important;
     display: block;
     margin: 7px -20px 0 -20px;
 }
 .track .textr {
     font-size: 15px !important;
     display: block;
     margin: 7px -20px 0 -20px;
 }
.bt {
    background-color: #55246c;
    font-weight: bold;
    color: white;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
.print-Div{
  width: 1000px;
}
button:disabled,
button[disabled]{
  cursor: not-allowed;
  background-color: lightgray !important;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

.table td, .table th {
    padding: .25rem !important;
}
</style>