<template>
  <div class="container">
    <h2>อนุมัติใบเสนอราคาเลขที่ {{ ref }} แล้ว</h2>
  </div>
</template>

<script>
// @ is an alias to /src
import * as serviceAPI from '@/services/API.service';
import * as serviceMain from '@/services/main.service';
export default {
  name: 'B2BApvOrderMail',
  components: {
  },
  props: {
    REF: {
        type: String,
        required: true,
      },
  },
    data: function () {
    return {
      orderref: '',
      ref: ''
    }
  },
  mounted() {
    document.title = `${process.env.VUE_APP_TITLE}`
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-CRY3HG283C')
    document.head.appendChild(recaptchaScript)
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CRY3HG283C');
    this.isApvOrder()
  },
  methods: {
    isApvOrder(){
      if(this.REF){
        this.orderref = Buffer.from(this.REF, 'base64').toString()
        let jsnRef = JSON.parse(this.orderref)
        const { REFQT, STATUS, USER } = jsnRef;
        this.ref = REFQT
        let data = {
          REFQT: REFQT,
          USER: USER,
          STATUS: STATUS
        }
        // console.log(this.orderref)
        // console.log(`directsale/apvOrderMail/${this.orderref}`);
        serviceAPI.call_API('post', `directsale/apvOrderMail/${REFQT}`, data, 'auth');
        serviceMain.showSuccessAlertWithTime(this,`Approve ใบเสนอราคาเรียบร้อย`,5000)
        
      }
      // setTimeout(() => {
      //   window.location = ('/twdb2b/login') 
      // }, 1200)
    }
  },
}
</script>
