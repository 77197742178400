<template>
  <section :key="getQuery">
    <div class="row">

      <div v-for="(e,index)  in listData" :key="index" class="col-6 col-sm-6 col-md-4 col-lg-3 p-1">
        <ProductCard v-if="e.skcount==1" :cardData="e" />
        <SpuCard v-else :cardData="e" />
      </div>
      <div class="col-12 pt-3" v-if="notfound">
        <center>
          <h1>ขออภัย! การค้นหาไม่ตรงกับสิ่งใดเลย</h1>
          <h3>โปรดลองใช้คำศัพท์ทั่วไปหรือตรวจสอบการสะกดคำของคุณ</h3>
          <div class="row">
            <div class="col-3 col-md-5"></div>
            <div class="col-6 col-md-2"><router-link to='/'><div :class="`bg-${getBU} buttoncart cardfont`" style="color:white;">กลับสู่หน้าหลัก</div></router-link></div>
            <div class="col-3 col-md-5"></div>
          </div>
          <img src="../../public/img/notfound.gif" v-if="getBU != 'PWB'" class="w-30">
          <img src="../../public/img/notfound.webp" v-else class="w-100">
        </center>
      </div>

    </div>
    <SkeletonProductCard v-if="isLoading" />
    
    <div  v-else class="d-flex justify-content-center w-100 mb-3">
        <a v-if="!isZeroRow" :class="`btn-border-${getBU}`" @click="showCategory()">ดูเพิ่มเติม มีอีก {{MaxCount-startCount}} รายการ</a>
    </div>
  </section>
</template>


<script>
  import * as serviceAPI from '../services/API.service';
  import * as serviceMain from '../services/main.service';
  import ProductCard from '@/components/ProductCard.vue'
  import SpuCard from '@/components/SpuCard.vue'
  import SkeletonProductCard from '@/components/SkeletonProductCard.vue'
  export default {
    name: 'ProductSearchList',
    data: function () {
      return {
        listData: [],
        isLoading:true,
        startCount:0,
        notfound:false,
        wantShowCount:24,
        isZeroRow:false,
        MaxCount:null

      }
    },
    mounted() {
      this.showCategory();
      this.scroll();
    },
    components: {
      ProductCard,
      SpuCard,
      SkeletonProductCard
    },
    props: [
      "srctxt"
    ],
    methods: {
      async showCategory() {
        try {
          let getFilter = this.$route.query.fill;
          let getFilterCat = this.$route.query.cat;
          let getSort = this.$route.query.sort;
          let getminpri = this.$route.query.minpri;
          let getmaxpri = this.$route.query.maxpri;
          this.isLoading = true
          let getSrctxt = this.srctxt
          if(this.MaxCount==null){
            let data = {
                MODE:'0',
                STCODE: this.getStoreCode || '026',
                SEARCH: getSrctxt,
                CATCODE: "%",
                PRICE1:getminpri||0,
                PRICE2:getmaxpri||100000,
                ORDERBY:getSort||'QNT DESC',
                OFFSET: this.startCount||0,
                LIMIT: this.wantShowCount || 0,
                FILTERS: getFilter||'',
                CAT: getFilterCat || '',
                ISTB: this.$store.getters['auth/getISTB'] ? 1:0,
                STCODESTOCK: this.$store.getters["service/getStShowStock"]
            }
            let getAPI = await serviceAPI.call_API('post', `product/catprodcardsrc_paging`, data,'auth');
            if(getAPI.data.dbrows != 0){
              this.MaxCount = getAPI.data.dbitems[0].skcount;
            }
            
          }
          
          let endCount = this.startCount + this.wantShowCount
          if(endCount>this.MaxCount){
            endCount =this.MaxCount
            this.isZeroRow=true;
          }
          let data = {
            MODE: '0',
            STCODE: this.getStoreCode || '026',
            SEARCH: getSrctxt,
            CATCODE: "%",
            PRICE1: getminpri||0,
            PRICE2: getmaxpri||1000000,
            ORDERBY: getSort||'QNT DESC',
            OFFSET: this.startCount||0,
            LIMIT: this.wantShowCount || 0,
            FILTERS: getFilter||'',
            CAT: getFilterCat || '',
            ISTB: this.$store.getters['auth/getISTB'] ? 1:0,
            STCODESTOCK: this.$store.getters["service/getStShowStock"]
     
            // "STCODE": this.getStoreCode,
            // "FILTER": "",
            // "ORDERBY": "",
            // "START": this.startCount,
            // "END": endCount
          }
          let getAPI = await serviceAPI.call_API('post', `product/catprodcardsrc`, data,'auth');
          this.listData = [...this.listData,...getAPI.data.dbitems];
          
          this.listData.map((e) => { e.prop = e.prop? e.prop : []})
          let SKCODE = []
          getAPI.data.dbitems.forEach(e => {
            SKCODE.push(e.skcode)
          });
          let dataprop = { SKCODE: SKCODE }
          let getAPIprop = await serviceAPI.call_API('post', `product/productAttributeBySkus`, dataprop,'auth');
          console.log(getAPIprop);
          if(getAPIprop.data.dbrows != 0){
            this.listData.map((e, i) => {
              getAPIprop.data.dbitems.map(element => {
                if(element.skcode == e.skcode && !e.prop.includes(element.base64)){
                  e.prop.push(element.base64)
                }
              })
            })
          }

          this.isLoading = false
          this.startCount = endCount
          if(this.listData.length == 0){
            this.notfound = true;
            this.$emit("notFound", true)
          }
          else{
            this.$emit("notFound", false)
          }
          
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      scroll () {
        window.onscroll = () => {
          let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight

          if (bottomOfWindow) {
            if(!this.isZeroRow){
              this.showCategory()
            }
          }
        }
      }
      // async getMaxCountSPU(){

      // }
      


    },
    computed:{
      getBU:function(){
        return this.$store.getters['customer/getQTBU']
      },
      getStoreCode:function(){
        return this.$store.getters['service/getQTDFSTORE']
      },
      getQuery:function(){
        return this.$route.query;
      }
    },
    watch:{
      getStoreCode:function(){
        this.startCount = 0;
        this.listData = [];
        this.showCategory()
      },
      getQuery:function(){
        this.startCount = 0;
        this.listData = [];
        this.showCategory();
      }
    }
  }
</script>

<style scoped>

</style>