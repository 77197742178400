<template>
<section>
    <div v-show="!EMAIL" class="col-md-12 loginform">
        <h2 style="margin-top: 5px;">ล็อกอินเข้าสู่ระบบ</h2>
        <div class="row">
            <div class="col-12" style="margin: 0px;background-color: white;padding:20px 20px;border-radius: 7px;height: auto;">
                <!-- <button onclick="window.location='https://eordering.powerbuy.co.th/api/eor-chg/login';" type="button" class="btn-login"> -->
                <button onclick="window.location='https://eordering.powerbuy.co.th/api/eor-chg/login';" type="button" class="btn-login m-0">
                    <strong style="font-size: 20px !important; font-family: 'kittithadamedium65regular';">เข้าสู่ระบบ Employee</strong>
                </button>
            </div>
            <div class="col-12" style="margin: 0px;background-color: white;padding:20px 20px;border-radius: 7px;height: auto;">
                <!-- <button onclick="window.location='https://eordering.powerbuy.co.th/api/eor-b2c/login';" type="button" class="btn-login"> -->
                <button onclick="window.location='https://eordering.powerbuy.co.th/api/eor-b2c/login';" type="button" class="btn-login m-0">
                    <strong style="font-size: 20px !important; font-family: 'kittithadamedium65regular';">เข้าสู่ระบบ PC</strong>
                </button>
            </div>
        </div>

    </div>
    <div v-show="EMAIL" class="col-md-12 loginform">
        <div class="row mb-3 p-0" :key="listsalesource">
            <div class="col-12 card border-0 p-3">
                <div class="row">
                    <div class="col-12">
                    <h6 class="text-center m-0">กรุณาเลือก SaleSource : {{ selectSaleSource }}</h6>
                    </div>
                    <div class="col-12">
                    <hr class="m-1">
                    </div>
                </div>
                <div class="row mt-2">
                    
                    <div v-for="(e,index) in listsalesource" :key="index" class="col-6 col-md-3">
                    <button  @click="selectSaleSource=e" class="btn-login ">{{e}}</button>
                    </div>
                    
                </div>
            </div>
        </div>
        <h2 style="margin-top: 5px;">ล็อกอินเข้าสู่ระบบ</h2>
        <div class="row">
            <div class="col-12" style="margin: 0px;background-color: white;padding:20px 20px;border-radius: 7px;height: auto;">
                <div class="form-group">
                    <label for="xuserid"><i class="fas fa-envelope"></i> USERID</label>
                    <input type="email" class="form-control" name="userid" placeholder="USERID" @blur="getUserSalesource" v-model="dataToSend.USERNAME">
                </div>
                <div class="form-group">
                    <label for="passw"><i class="fas fa-key"></i> รหัสผ่าน</label>
                    <input type="password" class="form-control" name="password" placeholder="Password" v-model="dataToSend.PASSWORD">
                </div>
                <button v-show="selectSaleSource" type="button" class="btn-login" @click="loginFN">
                    <strong style="font-size: 20px !important; font-family: 'kittithadamedium65regular';">เข้าสู่ระบบ</strong>
                </button>
                <!-- <strong class="text-right pointer" style="font-size: 20px !important; font-family: 'kittithadamedium65regular';"><a href="https://uat-eordering.powerbuy.co.th/api/eor-chg/login">เข้าสู่ระบบ</a></strong> -->
                <div id="message" class="info info-default">

                </div>
            </div>
        </div>

    </div>
</section>
</template>

<script>
import * as serviceAPI from '../services/API.service';
import * as serviceMain from '../services/main.service';
import * as constant from '@/services/constant';

export default {
    name: 'Loginform',
    props: [
        "EMAIL"
    ],
    data: function () {
        return {
            dataToSend: {
                USERNAME: "",
                PASSWORD: ""
            },
            listsalesource:[],
            selectSaleSource:''
        }
    },
    mounted() {
        this.cklogIn()
        console.log(this.EMAIL);

    },
    methods: {
        async loginFN() {
            try {
                let data = {SALESOURCE:this.selectSaleSource,EMAIL:this.EMAIL,...this.dataToSend};
                let getAPI = await serviceAPI.call_API('post', 'user/login', data);
                let getToken = getAPI.data.access_token
                this.$store.dispatch('auth/login', getToken)
                await this.setDataStoreByUser();
                if(this.$store.getters['auth/getISTB']){
                    this.getRefB2B()
                } else if(this.$store.getters['auth/getWSFLAG']){
                    this.$router.push({ name: 'OrderWholeSale' })
                }else{
                    this.$router.push({ name: 'FindUser'})
                }
            } catch (error) {
                serviceMain.showErrorAlert(this, error.message)
            }
        },
        cklogIn: async function () {
            if (this.$store.getters['auth/getloggedIn']) {
                this.$router.push('/');
            }
        },
        async setDataStoreByUser() {
            let data = {
              USERID: this.qtUserId,
              SALESOURCE: this.qtSalesource
            }
            let getAPI = await serviceAPI.call_API('post', `service/store`, data, 'auth');
            let getData = getAPI.data.dbitems[0].listdata;
            this.qtSaveStore = getData
        },
        async getRefB2B() {
            try {
                let data = {
                    "MCID": this.$store.getters['customer/getQTMCID'],
                    "USERID": this.$store.getters['auth/getQTUSERID']
                }
                // console.log(data)
                let getAPI = await serviceAPI.call_API('post', 'user/getCustrefB2B', data, 'auth');
                
                let getData = getAPI.data.dbitems[0];
                let getAPIcusInfo = await serviceAPI.call_API('post', `user/getCustinfoB2B`, {"USERID": this.$store.getters['auth/getQTUSERID']}, 'auth');
                console.log(getAPIcusInfo);
                let datainfo = getAPIcusInfo.data.dbitems[0]
                let getCUSTREF = getData.CUSTREF;
                // console.log(getCUSTREF)
                let getNITEM = getData.SUMQNT;
                let getCURSTC = getData.CURSTC.trim();
                this.$store.dispatch('customer/setQTCUSTINFO', {
                    CNAME: datainfo.CNAME,
                    MOBILE: datainfo.MOBILE,
                    EMAIL: datainfo.EMAIL,
                    T1C: datainfo.T1C,
                    VIPID: datainfo.VIPID,
                    SALESOURCE: this.$store.getters['auth/getUser'].QTSALESOURCE,
                    EMPLOYEEBUSHORTCODE: '',
                    EMPLOYEEID: '',
                    ISSTAFF: '',
                })
                this.$store.dispatch('customer/setQTCUSTREF', getCUSTREF)
                this.$store.dispatch('customer/setQTSHOPITEM', getNITEM)
                if (getCURSTC) {
                    this.$store.dispatch('service/setQTDFSTORE', getCURSTC)
                    let getAPI = await serviceAPI.call_API('post', 'service/findBU', {
                        "STCODE": getCURSTC
                    }, 'auth');
                    let getBU = getAPI.data.dbitems[0].BU;
                    this.$store.dispatch('customer/setQTBU', getBU)

                    let getQTSAVESTORE = this.$store.getters['service/getQTSAVESTORE']
                    let locationsTEMP = getQTSAVESTORE.split('|');
                    let locations = locationsTEMP.map(e => e.split(','));
                    let getQTStoreNow = getCURSTC
                    let QTStoreName = locations.filter(e => e[0] == getQTStoreNow);
                    let getStoreName = QTStoreName[0][1];
                    this.$store.dispatch('service/setQTSTORENAME', getStoreName)
                } else {
                    if(!this.qtDfStore) {
                        $('#mapModal').modal('show')
                    }
                }
                this.$router.push('/');
            } catch (error) {
                serviceMain.showErrorAlert(this, error.message)
            }

        },
        async getUserSalesource() {
            try {
                if(this.EMAIL != '') {
                    this.listsalesource = []
                    this.selectSaleSource =''
                    let data = {USERID : this.dataToSend.USERNAME};
                    let getAPI = await serviceAPI.call_API('post', 'user/getUserSalesource', data);
                    console.log(getAPI.data.dbrows);
                    if(getAPI.data.dbrows <= 0){
                        // throw new Error('ไม่พบ Salesource')
                        return
                    }
                    this.listsalesource = getAPI.data.dbitems[0].SALESOURCE.split(',')
                }
            } catch (error) {
                serviceMain.showErrorAlert(this, error.message)
            }
        },
    },
    computed: {
      qtSaveStore: {
        get() {
          return this.$store.getters['service/getQTSAVESTORE']
        },
        set(value) {
          this.$store.dispatch('service/setQTSAVESTORE', value)
        }
      },
      qtUserId: function () {
        return this.$store.getters['auth/getQTUSERID']
      },
      qtSalesource: function () {
        return this.$store.getters['auth/getQTSALESOURCE']
      }
    }
}
</script>

<style scoped>
.btn-login {
    background-color: #55246c;
    width: 100%;
    border: none;
    color: white;
    padding: 2px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 0px;
    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 4px;
}

.loginform {
    max-width: 400px;
    margin: 50px auto;
}
</style>
