<template>
<section>
  <div class="container">
    <CategoryCard :catcode="getcatcode"  />
  </div>
  <Comparefuc />
</section>
</template>

<script>
// @ is an alias to /src
import CategoryCard from '@/components/CategoryCard.vue'
import Comparefuc from '@/components/comparefuc.vue'
export default {
  name: 'Category',
  components: {
    CategoryCard,
    Comparefuc
  },
  props: {
    getcatcode: {
      type: String,
      required: true
    }
  },
  data: function () {
  return {

    }
  },
  mounted() {
    document.title = `${process.env.VUE_APP_TITLE} - Category`
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-CRY3HG283C')
    document.head.appendChild(recaptchaScript)
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CRY3HG283C');
  },
  methods: {

  },
}
</script>
