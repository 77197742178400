<template>
  <section class="row">
      <!-- desk -->
      <div class="col-12 prodbundleCard d-none d-lg-block">
          <div class="row d-flex justify-content-center">
            <div class="col">
              <div class="row h-100 d-flex justify-content-center">
                <div :class="`${cardData.length < 4 ? (cardData.length == 1 ? 'col-6' : 'col') : 'col-4'} p-0 m-0`" v-for="(e, index) in cardData" :key="index">
                  <div class="row h-100">
                    <div class="col-9" style='overflow:hidden; height: 100%' >
                      <div id='dvImg' style='overflow:hidden;'>
                          <router-link :to="`/ProductDetail/${e.slugname}`" >
                          <p class='text-center'>
                              <img :id="'IMG'+e.SKC"  :src="`${e.Base64?'data:image/jpeg;base64,'+e.Base64:(`${getBU}` == `bnb` ? '/img/no-imgbnb.png' : '/img/no-img.jpg')}`"
                              style='width:70%;overflow:hidden;max-width:248px;' alt="${e.SKC}" class='skcimage  img-responsive'
                              onError="IMG_ERR(this)">
                          </p>
                          </router-link>
                      </div>

                      <div class="card-body px-1 py-0">
                          <router-link :to="`/ProductDetail/${e.slugname}`"  style="color:black;">
                          <p class="brandcard" style="font-family: 'kittithadabold75regular'; margin: 0; ">{{e.PR_BRAND}}</p>
                          <!-- <div class="row show-attb-product">
                              <span class="box-status atb-color">{{e.atbcode}}</span> 
                              <span class="box-status dism-color">{{e.dismcode}}</span>
                              <span class="box-status hxb-color">{{e.hb}}</span>
                              <span class="box-status leadtme-color">{{e.lt}}</span>
                              <span class="box-status stckavl-color">{{e.stockavail}}</span>
                              <span class="box-status status-color">{{e.status}}</span>
                              </div> -->
                          <p class="namecard" style=" margin: 0; ">{{e.PRNAME}}</p>
                          </router-link>
                      </div>
                      <div class="card-body px-1 m-0 py-0 " style="">
                          <span class="skucard font-weight-bold" style="margin: 0; margin-bottom: 5px;  color: gray; font-family: 'kittithadathin35regular';">
                              รหัสสินค้า: {{e.SKC}}
                          </span><br>
                          <p class="h5">
                          <span :class="`prizecard font-price font-color-${getBU}`" style="font-size:1rem;">฿ </span>
                          <span :class="`prizecard font-price font-color-${getBU}`" >{{e.PRICE}}</span>
                          <span class="unitcard" style="">/{{e.PR_UNAME}}</span>
                          </p>
                          <span class="discard" style="text-decoration: line-through; margin:0px; ">{{e.DISC}}</span>
                          <span class="disccir">{{e.PER}}</span>
                          <span class="qntcircle">x{{e.QNT}}</span>
                          <!-- <div class="row">
                              <div class="col-12">
                              <span class="box-status atb-color h5" title='ATBCODE'>{{e.atbcode}}</span> 
                              <span class="box-status dism-color h5" title='DISMCODE'>{{e.dismcode}}</span>
                              <span class="box-status hxb-color h5" title='HxB'>{{e.hb}}</span>
                              <span class="box-status leadtme-color h5" title='Leadtime'>{{e.lt}}</span>
                              <span class="box-status stckavl-color h5" title='Stock Avail'>{{e.STCK}}</span>
                              <span class="box-status status-color h5 " title='Status'>{{e.status}}</span>
                              <span class="box-status stclt-color h5" title='Stock Longtail'>{{e.STCKLNGTAIL}}</span>
                              <span class="box-status stcdc-color h5" title='Stock DC'>{{e.STOCKDC}}</span>
                              </div>
                          </div> -->
                      </div>
                    </div>
                    <div class="col-3 align-self-center text-center p-0 m-0" v-if="index < cardData.length-1">
                        <div class="">
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M20 8.33331V31.6666" stroke="#111111" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                              <path d="M8.33334 20H31.6667" stroke="#111111" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </div>
                    </div>
                    <div class="col-3 align-self-center text-center p-0 m-0" v-else>
                        <div class="">
                            <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1 21L11 11L1 1" stroke="#111111" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-3 align-self-center text-center bundlediv">
                <!-- <div class="row">
                    <div class="col-12">
                      <p class="m-0 p-0" style="font-size: 1.35rem;">ราคาปกติ {{ cardData[0].ALLORGPRICE }} รวม</p>
                      <p class="m-0 p-0" style="font-size: 1.35rem; color: #d4232b;">ราคาพิเศษ {{ cardData[0].ALLPRICE }} รวม</p>
                    </div>
                    <div class="col-12" style="font-size: 1.35rem;color: #d4232b;">
                        
                    </div>
                    <div class="col-12">
                        ทั้งชุดถูกกว่า {{ cardData[0].ALLPERCENT }}% เหลือเพียง ฿{{ cardData[0].ALLPLPPPRICE }}
                    </div>
                    <div class="col-12">
                        ราคานี้ลดถึง {{ cardData[0].PRO_ENDDATE }} เท่านั้น
                    </div>
                    <div class="col-12">
                        <addMoreToCart :class="`bg-${getBU} buttoncart cardfont col-12`" :sku="skuMain" :setid="cardData[0].SETID" @validateSelectStore="validateSelectStore">ใส่รถเข็น</addMoreToCart>
                    </div>
                </div> -->
                <div class="bundletotal">
                  <div class="discinfo px-0 text-center" style="padding: .5em;">
                      <p class="f-kitti75 p-0 m-0" style="color:#666666;font-size: 1.35rem;">ราคาปกติ รวม {{ cardData[0].ALLORGPRICE }}</p>
                      <p class="f-kitti75 p-0 m-0" style="color: #d4232b;font-size: 1.35rem;">ราคาพิเศษ รวม {{ cardData[0].ALLPRICE }}</p>
                  </div>
                  <div class="discimage text-center">
                      <div class="discribbonbundle mx-auto">
                          <div class="f-kitti75 morecheap">
                              ทั้งชุดถูกกว่า
                          </div>
                          <div class="f-kitti75 mx-auto discpercent">{{ cardData[0].ALLPERCENT }}<span style="font-size: 36px;">%</span></div>
                      </div>
                      <div class="mx-auto" style="position: absolute;bottom: 0;text-align: center;width: 100%;line-height: 1.2em;">
                          <span class="f-kitti75" style="color: #d4232b;font-size: 24px;">เหลือเพียง </span><br>
                          <!-- <span class="f-kitti75" style="color: #d4232b;">฿ <span class="finalprice" style="font-family: pricesign;"> </span></span> -->
                          <span :class="`prizecard font-price font-color-${getBU}`" style="font-size: 1rem;">฿</span>
                          <span :class="`prizecard font-price font-color-${getBU}`" >{{ cardData[0].ALLPLPPPRICE }}</span>
                      </div>
                  </div>
                  <div class="datebundle text-center">
                  <span class="f-kitti75">ราคานี้ถึงวันที่ {{ cardData[0].PRO_ENDDATE }} เท่านั้น</span>
                  </div>
                  <div>
                      <addMoreToCart :class="`bg-${getBU} buttoncart cardfont col-12`" :sku="skuMain" :setid="cardData[0].SETID" @validateSelectStore="validateSelectStore">ใส่รถเข็น</addMoreToCart>
                  </div>
                </div>
            </div>
          </div>
      </div>
    <!-- mobile -->
      <div class="col-12 prodbundleCard d-lg-none">
          <div class="row">
              <div class="col-12 p-0">
                  <div class="row d-flex justify-content-center" >
                    <div :class="`${cardData.length < 4 ? (cardData.length == 1 ? 'col-6' : 'col') : 'col-4'} col p-0 m-0`" v-for="(e, index) in cardData" :key="index">
                      <div class="row">
                        <div class="col-9 p-0" style='overflow:hidden; height: 100%'>
                            <div id='dvImg' style='overflow:hidden;'>
                                <router-link :to="`/ProductDetail/${e.slugname}`" >
                                <p class='text-center'>
                                    <img :id="'IMG'+e.SKC"  :src="`${e.Base64?'data:image/jpeg;base64,'+e.Base64:(`${getBU}` == `bnb` ? '/img/no-imgbnb.png' : '/img/no-img.jpg')}`"
                                    style='width:50%;overflow:hidden;max-width:248px;' alt="${e.SKC}" class='skcimage  img-responsive'
                                    onError="IMG_ERR(this)">
                                </p>
                                </router-link>
                            </div>
                            <span class="qntcirclelg">x{{e.QNT}}</span>
                        </div>
                        <div class="col-3 p-0 m-0 align-self-center text-center" v-if="index < cardData.length-1">
                            <div class="">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 8.33331V31.6666" stroke="#111111" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M8.33334 20H31.6667" stroke="#111111" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="col-12" v-if="!showComp">
                  <addMoreToCart :class="`bg-${getBU} buttoncart cardfont col-12`" :sku="skuMain" :setid="cardData[0].SETID" @validateSelectStore="validateSelectStore">ใส่รถเข็น</addMoreToCart>
              </div>
              <div class="col-12 p-0">
                  <div class="row mx-3 py-2 border-top border-bottom d-flex justify-content-between pointer" @click="showComp = !showComp">
                      <div class="col-1 text-center d-flex align-items-center">
                          <span class="disccirclebundle">{{ cardData[0].ALLPERCENT }}<span style="font-size:10px">%</span><br><span style="font-size:15px">OFF</span></span>
                      </div>
                      <div class="col-8 ">
                          <div class="row d-flex justify-content-center">
                              <span class="px-1">ทั้งชุดถูกกว่า</span>
                              <span class="px-1" style="color: #d4232b;">เหลือเพียง</span>
                              <span class="px-1" style="font-size: 24px !important;" :class="`prizecard font-price font-color-${getBU}`">{{ cardData[0].ALLPLPPPRICE }}.-</span>
                          </div>
                      </div>
                      <div class="col-1">
                          <div class="d-flex align-items-center" v-if="!showComp">
                              <i class='fa fa-chevron-down' aria-hidden='true'></i>
                          </div>
                          <div class="d-flex align-items-center" v-else>
                              <i class='fa fa-chevron-up' aria-hidden='true'></i>
                          </div>
                      </div>
                      
                  </div>
              </div>
              <div class="col-12" v-if="showComp">
                <div class="card-body p-2" >
                        <div class="infodiv py-2 mb-1" style="font-size: 18px;border-bottom: 1px solid #DDDDDD"  v-for="(e, index) in cardData" :key="index">
                            <div class="row">
                                <div class="col-1">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.75 8.25L9 10.5L16.5 3" stroke="#111111" stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M15.75 9V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V3.75C2.25 3.35218 2.40804 2.97064 2.68934 2.68934C2.97064 2.40804 3.35218 2.25 3.75 2.25H12" stroke="#111111" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>     
                                </div>
                                <div class="col-10">
                                    <p class="mb-0">{{e.PRNAME}}</p>
                                    <span class="font-weight-bold">฿ {{e.PRICE}}</span> 
                                </div>
                            </div>
                        </div>
                </div>
                <div class="bundletotal">
                    <div class="discinfo text-center">
                        <span class="" style="color:#666666;font-size: 24px;">ราคารวม {{ cardData[0].ALLPRICE }}.- </span><br>
                        <span class="font-weight-bold" style="font-size: 24px;color:black;">ทั้งชุดถูกกว่า {{ cardData[0].ALLPRICESAVE }}.- </span>
                    </div>
                    <div class="text-center">
                        <span class="font-weight-bold" style="color: #d4232b;font-size: 24px;">เหลือเพียง <span style="font-size: 24px !important;" :class="`prizecard font-price font-color-${getBU}`"> {{ cardData[0].ALLPLPPPRICE }}.-</span></span><br>
                        <addMoreToCart :class="`bg-${getBU} buttoncart cardfont col-12`" :sku="skuMain" :setid="cardData[0].SETID" @validateSelectStore="validateSelectStore">ใส่รถเข็น</addMoreToCart>
                    </div>
                </div>
            </div>
          </div>
      </div>
      
    <SelectStore />
    </section>
</template>


<script>
import addMoreToCart from '@/components/AddMoreToCart_Slot.vue'
import SelectStore from '@/components/SelectStore.vue'
  export default {
    name: 'ProductCard',
    components: {
      addMoreToCart,
      SelectStore
    },
    data: function () {
      return {
        showComp: false,
      }
    },
    mounted() {
      
    },
    props: [
      "cardData",
      "skuMain"
    ],
    methods: {
      validateSelectStore (){
        $('#mapModal').modal('show')
      }
    },
    computed:{
      getBU:function(){
        return this.$store.getters['customer/getQTBU']
      }
    }
  }
       
</script>

<style scoped>
.bundlediv .discimage {
    border: 1px solid #ccc;
    height: 245px;
    background-color: #eee;
    border-radius: 5px;
    position: relative;
    /* width: 80%; */
}
.discribbonbundle {
    width: 150px;
    height: 166px;
    font-size: .8em;
    color: #fff;
    font-weight: 700;
    text-align: center;
    letter-spacing: .025em;
    background-color: #ffcb00;
    position: relative;
    transition: all .2s ease-out;
    top: -8px;
    left: 0;
    padding-top: .5em;
    z-index: 2;
}
.bundlediv .morecheap {
  font-size: 20rem;
    position: absolute;
    font-size: 48px;
    transform: rotate(-15deg);
    color: #55246c;
    white-space: nowrap;
    /* left: 0.7em; */
    top: .35em;
    letter-spacing: -.1px;
    text-shadow: -1px -1px 0 #fff, 0 -1px 0 #fff, 1px -1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff, -1px 1px 0 #fff, 0 1px 0 #fff, 1px 1px 0 #fff, -4px -4px 0 #000, -1px -4px 0 #000, 0 -4px 0 #000, 1px -4px 0 #000, 4px -4px 0 #000, 4px -1px 0 #000, 4px 0 0 #000, 4px 1px 0 #000, 4px 4px 0 #000, 1px 4px 0 #000, 0 4px 0 #000, -1px 4px 0 #000, -4px 4px 0 #000, -4px 1px 0 #000, -4px 0 0 #000, -4px -1px 0 #000;
}
.bundlediv .discpercent {
    font-size: 64px;
    color: #d4232b;
    font-family: pricesign;
    position: absolute;
    bottom: -15px;
    width: 100%;
    text-shadow: -2px 0 #fff, 0 2px #fff, 2px 0 #fff, 0 -2px #fff;
}
.discribbonbundle:before {
    content: '';
    position: absolute;
    border: 4px solid #eaad21;
    top: 0;
    right: -8px;
    border-top-color: transparent;
    border-right-color: transparent;
}
.discribbonbundle:after {
    content: "";
    position: absolute;
    bottom: -.97em;
    left: 0;
    width: 0;
    height: 0;
    border-top: 1em solid #ffcb00;
    border-right: 4.3em solid transparent;
    border-left: 4.3em solid transparent;
}

.disccir:empty {
  display: none;
}

.disccir {
  font-size: 15px !important;
  color: rgb(255, 255, 255);
  font-weight: 700;
  text-align: center;
  letter-spacing: .025em;
  border-radius: 50%;
  background-color:#55246c;
  width: 40px;
  height: 40px;
  line-height: 10px;
  position: absolute;
  transition: all .2s ease-out;
  top: 10px;
  right: 10px;
  padding-top: 10px;
  font-family: 'kittithadabold75regular' !important;
}
.qntcircle {
    font-size: 2em !important;
    color: rgb(255, 255, 255);
    font-weight: 700;
    text-align: center;
    letter-spacing: .025em;
    border-radius: 50%;
    background-color: #55246c;
    width: 60px;
    height: 60px;
    line-height: 50px;
    position: absolute;
    transition: all .2s ease-out;
    top: 150px;
    right: 10px;
    padding-top: 7px;
    font-family: 'kittithadabold75regular' !important;
}
.disccirclebundle {
    width: 2em;
    height: 2em;
    font-size: 20px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    letter-spacing: .025em;
    border-radius: 50%;
    background-color: #d4232b;
    position: absolute;
    transition: all .2s ease-out;
    line-height: .7em;
    padding: .3em;
    left: 2px;
    z-index: 2;
}
.qntcirclelg {
    width: 2em;
    height: 2em;
    font-size: 20px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    letter-spacing: .025em;
    border-radius: 50%;
    background-color: #d4232b;
    position: absolute;
    transition: all .2s ease-out;
    padding: .3em;
    margin-bottom: 1em;
    right: 1em;
    z-index: 2;
    top: 4em;
}
.prodbundleCard {
    background-color: white;
    padding: 20px;
    border-radius: 15px;
}
.box-status{
  
  padding: 0px 5px;
  margin-right: 2px;

}
.card-text {
    font-size: calc(18px + 0.5vw);
    padding: 0px auto;
    text-align: center;
    color: #000;
    text-decoration: none;
    background-color: #fff;
    font-weight: 700;
  }
  
  .buttoncart {

  border: none;
  color: white;
  /* padding: 2px 1vw; */
  text-align: center;
  text-decoration: none;
  display: inline-block;

  margin: 0px 0px;
  cursor: pointer;
  border-radius: 4px;
  font-family: 'kittithadabold75regular';
}

.pcard {
  background-color: #4CAF50;
  /* Green */
  border: none;
  color: white;
  padding: 0px 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0px;
  cursor: pointer;
  background-color: white;
  color: #55246c;
  border: 1px solid #55246c;
  border-radius: 4px;
}

.shadowDiv {
  margin: 0px;
  background-color: white;
  padding: 10px;
  border-radius: 7px;
  height: auto;
}

.paddingMCard {
  padding: 10px;
  margin: 0px;
}

.marginPM {

  margin-top: 10px;
}


.brandcard {
  font-size: 34px;
}

.namecard {
  font-size: 1.25rem;
  overflow: hidden;
  height: 78px;
  line-height: normal;
}

.skucard {
  font-size: 24px;
}

.prizecard {
  font-size: calc(180% - 0.1rem);
}

.unitcard {
  font-size: 28px;
}

.cardfont {
  font-size: 26px;
}

.discard {
  height: 28px;
  font-size: 26px;
}

.difcard {
  font-size: 24px;
}

.miniimgcard {
  height: 28px;
}

.nameimgmini {
  font-size: 26px;
}


.shadowDiv:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.hidxs{
  display: none;
}
@media screen and (max-width: 1700px) {
  .shadowDiv {
    height: auto;
  }

  .brandcard {
    font-size: 30px;
  }

  .namecard {
    font-size: 28px;
    overflow: hidden;
    height: 85px;
  }

  .skucard {
    font-size: 22px;
  }

  /* .prizecard {
    font-size: 28px;
  } */

  .unitcard {
    font-size: 24px;
  }

  .cardfont {
    font-size: 22px;
  }

  .discard {
    height: 26px;
    font-size: 24px;
  }

  .difcard {
    font-size: 22px;
  }

  .miniimgcard {
    height: 26px;
  }

  .nameimgmini {
    font-size: 24px;
  }
}

@media screen and (max-width: 1400px) {
  .shadowDiv {
    height: auto;
  }

  .pr-5 {
    padding-right: 3em;
    padding-left: 0;
  }

  .brandcard {
    font-size: 28px;
  }

  .namecard {
    font-size: 24px;
    overflow: hidden;
    height: 70px;
  }

  .skucard {
    font-size: 20px;
  }

  /* .prizecard {
    font-size: 22px;
  } */

  .unitcard {
    font-size: 20px;
  }

  .cardfont {
    font-size: 18px;
  }

  .discard {
    height: 24px;
    font-size: 22px;
  }

  .difcard {
    font-size: 20px;
  }

  .miniimgcard {
    height: 24px;
  }

  .nameimgmini {
    font-size: 22px;
  }
}

@media screen and (max-width: 1100px) {
  .shadowDiv {
    height: auto;

  }

  .pr-5 {
    padding-right: 2em;
    padding-left: 0;
  }

  .brandcard {
    font-size: 26px;
  }

  .namecard {
    font-size: 22px;
    overflow: hidden;
    height: 70px;
  }

  .skucard {
    font-size: 18px;
  }

  /* .prizecard {
    font-size: 18px;
  } */

  .unitcard {
    font-size: 18px;
  }

  .cardfont {
    font-size: 14px;
  }

  .discard {
    height: 22px;
    font-size: 20px;
  }

  .difcard {
    font-size: 18px;
  }

  .miniimgcard {
    height: 20px;
  }

  .nameimgmini {
    font-size: 18px;
  }

  .BGcolor {
    padding: 124px 0 0px 0;
  }

  .BGcart {
    margin-top: 85px;
  }

  .qntcircle {
    font-size: 2em !important;
    color: rgb(255, 255, 255);
    font-weight: 700;
    text-align: center;
    letter-spacing: .025em;
    border-radius: 50%;
    background-color: #55246c;
    width: 60px;
    height: 60px;
    line-height: 50px;
    position: absolute;
    transition: all .2s ease-out;
    top: 110px;
    right: 10px;
    padding-top: 7px;
    font-family: 'kittithadabold75regular' !important;
}

}

@media screen and (max-width: 800px) {
  .shadowDiv {
    height: auto;
  }

  .brandcard {
    font-size: calc(26px + 0.5vw);
  }

  .namecard {
    font-size: calc(22px+ 0.5vw);
    overflow: hidden;
    height: 72px;
  }

  .skucard {
    font-size: calc(18px + 0.5vw);
  }

  /* .prizecard {
    font-size: calc(22px + 0.5vw);
  } */

  .unitcard {
    font-size: calc(18px + 0.5vw);
  }

  .cardfont {
    font-size: calc(22px + 0.5vw);
  }

  .discard {
    font-size: calc(18px + 0.5vw);
  }

  .difcard {
    font-size: calc(18px + 0.5vw);
  }

  .miniimgcard {
    height: calc(20px + 0.3vw)
  }

  .nameimgmini {
    font-size: calc(18px + 0.5vw);
  }



}
@media screen and (max-width: 766px) {
  .hidxs{
    display: inline-block;
  }
}
@media screen and (max-width: 600px) {
  .skcimagesrc {
    width: 80%;
  }

  .tabsearch {
    top: 2.24em;
    left: -30%;
    width: 150%;
  }

  .marginPM {

    margin-top: 0px;
  }

  .disccir {
    font-size: 0.8em !important;
    color: rgb(255, 255, 255);
    font-weight: 700;
    text-align: center;
    letter-spacing: .025em;
    border-radius: 50%;
    background-color:#55246c;
    width: 40px;
    height: 40px;
    line-height: 15px;
    position: absolute;
    transition: all .2s ease-out;
    top: 15px;
    right: 15px;
    padding-top: 7px;
    font-family: 'kittithadabold75regular' !important;
  }
  .qntcircle {
    font-size: 2em !important;
    color: rgb(255, 255, 255);
    font-weight: 700;
    text-align: center;
    letter-spacing: .025em;
    border-radius: 50%;
    background-color: #55246c;
    width: 60px;
    height: 60px;
    line-height: 50px;
    position: absolute;
    transition: all .2s ease-out;
    top: 110px;
    right: 10px;
    padding-top: 7px;
    font-family: 'kittithadabold75regular' !important;
}

  .shadowDiv {
    height: auto;
  }

  .paddingMCard {
    padding: 5px !important;
  }

  .nopaddingMDetail {
    padding: 0 !important;
  }
  .nopaddingM {
    padding: 0 !important;
  }
  .nomarginM {
    margin: 0 !important;
  }



  .brandcard {
    font-size: 1em;
    height: 30px;
    overflow: hidden;

  }

  .namecard {
    height: 3em;
    font-size: 0.9em;
    overflow: hidden;
    line-height: 1;
  }

  .skucard {
    font-size: 0.7em;
  }

  /* .prizecard {
    font-size: 0.8em;
  } */

  .unitcard {
    font-size: 0.7em;
  }

  .cardfont {
    font-size: 0.7em;
  }

  .discard {
    font-size: 0.8em;
    height: 20px;
  }

  .difcard {
    font-size: 0.7em;
  }

  .miniimgcard {
    font-size: 0.7em;
  }

  .nameimgmini {
    font-size: 0.7em;
  }

  .qntcirclelg {
    width: 2em;
    height: 2em;
    font-size: 20px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    letter-spacing: .025em;
    border-radius: 50%;
    background-color: #d4232b;
    position: absolute;
    transition: all .2s ease-out;
    padding: .3em;
    margin-bottom: 0.2em;
    right: 0em;
    z-index: 2;
    top: 1em;
  }

}

.btn-view-more-PWB{
  border: 2px #55246c solid;
  padding: 0px 15px;
  color: #55246c;
}
.btn-view-more-PWB:hover{
  background-color:#55246c ;
  color: #FFF;
}
.btn-view-more-bnb{
  border: 2px #006e63 solid;
  padding: 0px 15px;
  color: #006e63;
}
.btn-view-more-bnb:hover{
  background-color:#006e63 ;
  color: #FFF;
}
.btn-view-more-at1{
  border: 2px #3866af solid;
  padding: 0px 15px;
  color: #3866af;
}
.btn-view-more-at1:hover{
  background-color:#3866af ;
  color: #FFF;
}

.hide {
  display: none;
}
</style>