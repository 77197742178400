<template>
  <section ::key="getQuery">
    <div class="row pt-2">
      <div class="col-6 p-0 pl-1">
        <a :class="` btn-invisible-${getBU}`" @click="openNav()"> 
          <span>
            <svg class="icon-filter" width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path :fill="`${buColor}`" fill-rule="evenodd" clip-rule="evenodd" d="M7.7599 6.21376C7.7599 5.12681 6.86462 4.24192 5.76229 4.24192C4.66113 4.24192 3.76467 5.12681 3.76467 6.21376C3.76467 7.30072 4.66113 8.18561 5.76229 8.18561C6.86462 8.18561 7.7599 7.30072 7.7599 6.21376ZM9.52457 6.21376C9.52457 8.26109 7.83754 9.92753 5.76229 9.92753C3.68821 9.92753 2 8.26109 2 6.21376C2 4.16643 3.68821 2.5 5.76229 2.5C7.83754 2.5 9.52457 4.16643 9.52457 6.21376ZM22 6.214C22 5.73323 21.6047 5.34304 21.1177 5.34304H13.8825C13.3954 5.34304 13.0002 5.73323 13.0002 6.214C13.0002 6.69476 13.3954 7.08495 13.8825 7.08495H21.1177C21.6047 7.08495 22 6.69476 22 6.214ZM16.2397 17.7862C16.2397 18.8732 17.135 19.7581 18.2374 19.7581C19.3397 19.7581 20.235 18.8732 20.235 17.7862C20.235 16.6981 19.3397 15.8144 18.2374 15.8144C17.135 15.8144 16.2397 16.6981 16.2397 17.7862ZM14.4751 17.7862C14.4751 15.7377 16.1621 14.0725 18.2374 14.0725C20.3126 14.0725 21.9996 15.7377 21.9996 17.7862C21.9996 19.8336 20.3126 21.5 18.2374 21.5C16.1621 21.5 14.4751 19.8336 14.4751 17.7862ZM2.88316 16.9149H10.1172C10.6042 16.9149 10.9995 17.3051 10.9995 17.7859C10.9995 18.2667 10.6042 18.6568 10.1172 18.6568H2.88316C2.39611 18.6568 2.00082 18.2667 2.00082 17.7859C2.00082 17.3051 2.39611 16.9149 2.88316 16.9149Z"></path>
            </svg>
          </span> 
                                ตัวกรอง</a>
      </div>
      <div class="col-6 p-0 pl-1">
        <div class="dropdown show">
          <a :class="` btn-invisible-${getBU}`" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            
            <span v-if="sortData=='Pricesort ASC'">
              <span class="px-1">
                <i class="fas fa-sort-amount-down-alt"></i>
              </span>
              Price
            </span>
            <span v-else-if="sortData=='Pricesort DESC'">
              <span class="px-1">
                <i class="fas fa-sort-amount-down"></i>
              </span>
              Price
            </span>
            <span v-else-if="sortData=='Name ASC'">
              <span class="px-1">
                <i class="fas fa-sort-alpha-down"></i>
              </span>
              Name
            </span>
            <span v-else-if="sortData=='Name DESC'">
              <span class="px-1">
                <i class="fas fa-sort-alpha-down-alt"></i>
              </span>
              Name
            </span>
            <span v-else>
              <span>
                <svg class="icon-filter" width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path :fill="`${buColor}`" fill-rule="evenodd" clip-rule="evenodd" d="M7.3586 3.23021C7.06449 3.01114 6.64624 3.03548 6.37972 3.30323L2.30194 7.3999L2.22952 7.48419C2.01234 7.7783 2.03751 8.19491 2.30439 8.46056L2.38868 8.53298C2.68279 8.75016 3.0994 8.72499 3.36505 8.45811L6.16116 5.64851V17.4507L6.16801 17.5524C6.21767 17.9185 6.53147 18.2007 6.91116 18.2007C7.32538 18.2007 7.66116 17.8649 7.66116 17.4507V5.64864L10.4575 8.45811L10.5414 8.53092C10.8346 8.74945 11.2513 8.72621 11.5182 8.46056C11.8117 8.16834 11.8128 7.69347 11.5206 7.3999L7.44283 3.30323L7.43763 3.29873C7.41474 3.27617 7.39041 3.25507 7.36479 3.23558L7.3586 3.23021ZM17.5828 6.44468C17.5331 6.0786 17.2193 5.79645 16.8396 5.79645C16.4254 5.79645 16.0896 6.13223 16.0896 6.54645V18.3481L13.2933 15.539L13.2093 15.4662C12.9162 15.2477 12.4995 15.2709 12.2326 15.5366C11.939 15.8288 11.9379 16.3036 12.2302 16.5972L16.3079 20.6939L16.3131 20.6984C16.336 20.721 16.3604 20.7421 16.386 20.7615L16.3922 20.7669C16.6863 20.986 17.1045 20.9616 17.3711 20.6939L21.4488 16.5972L21.5213 16.5129C21.7384 16.2188 21.7133 15.8022 21.4464 15.5366C21.1528 15.2443 20.6779 15.2454 20.3857 15.539L17.5896 18.3472V6.54645L17.5828 6.44468Z"></path></svg>
              </span>
              เรียงลำดับ
            </span>
          </a>

          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <a class="dropdown-item d-flex justify-content-between pointer" @click="onSort('Pricesort ASC')"> <span>Price</span> <i class="fas fa-sort-amount-down-alt"></i></a>
            <a class="dropdown-item d-flex justify-content-between pointer" @click="onSort('Pricesort DESC')"><span>Price</span> <i class="fas fa-sort-amount-down"></i></a>
            <a class="dropdown-item d-flex justify-content-between pointer" @click="onSort('Name ASC')"><span>Name</span> <i class="fas fa-sort-alpha-down"></i></a>
            <a class="dropdown-item d-flex justify-content-between pointer" @click="onSort('Name DESC')"><span>Name</span> <i class="fas fa-sort-alpha-down-alt"></i></a>
          </div>
        </div>
      </div>
    </div>
    

    <div id="mySidenavSearch" class="sidenav p-0 m-0">
      <div class="row">
        <div :class="`col-12  asid-nav px-3 bg-${getBU}`">
          <span  class="nav-titel">เลือกตัวกรอง</span>
          <a href="javascript:void(0)" class="closebtn float-right " @click="closeNav()">&times;</a>
        </div>
      </div>
      <!-- <a href="javascript:void(0)" class="closebtn" @click="closeNav()">&times;</a> -->
      <!-- <div v-for="(e,index) in getProperty" :key="index" class="row px-2">
        <div class="col-12 property-titel" v-if="e.prop.length > 0">
          <div @click="toggle_property(e)" class="d-flex justify-content-between"> <span>{{e.name}}</span> <i class="fa fa-chevron-down" aria-hidden="true" style="color: gray; padding-top:10px;"></i></div>
        </div>
        <div v-if="e.click" class="col-12 property-card ">
          <div v-for="(prop) in e.prop" :key="prop" class="row" >
            <div v-if="prop.name!=='ALL'" class="col-12">
              <label  class="container-checkbox">
                {{prop.name}}
                <input type="checkbox" v-model="prop.selected" >
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          
        </div>
      </div> -->
      <div class="row px-2" :key="srcTxt">
        <!-- <div class="col-12 property-titel">
          <div @click="toggle_property('BND')" class="d-flex justify-content-between pointer"> <span>แบรนด์</span> <i class="fa fa-chevron-down" aria-hidden="true" style="color: gray; padding-top:10px;"></i></div>
        </div>
        <div v-if="propClick.BND" class="col-12 property-card ">
          <div class="row my-2">
            <div class="col-12">
              <input v-model="search" class="input-store-src text-center " type="text" placeholder="ค้นหา" >
            </div>
          </div>
          <div v-for="(e, index) in filteredList" :key="index" class="row" >
            <div class="col-12">
              <label  class="container-checkbox">
                {{e.bnd}}
                <input type="checkbox" v-model="e.selected" >
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="col-12 property-titel">
          <div @click="toggle_property('CAT')" class="d-flex justify-content-between pointer"> <span>Catagory</span> <i class="fa fa-chevron-down" aria-hidden="true" style="color: gray; padding-top:10px;"></i></div>
        </div>
        <div v-if="propClick.CAT" class="col-12 property-card ">
          <div class="row my-2">
            <div class="col-12">
              <input v-model="searchcat" class="input-store-src text-center " type="text" placeholder="ค้นหา" >
            </div>
          </div>
          <div v-for="(e, index) in filteredListcat" :key="index" class="row" >
            <div class="col-12">
              <label  class="container-checkbox">
                {{e.catname}}
                <input type="checkbox" v-model="e.selected" >
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div> -->
        <div class="col-12 property-titel">
          <div @click="toggle_property('PRI')" class="d-flex justify-content-between pointer"> <span>ราคา</span> <i class="fa fa-chevron-down" aria-hidden="true" style="color: gray; padding-top:10px;"></i></div>
        </div>
        <div v-if="propClick.PRI" class="col-12 property-card p-0">
          <div class="row d-flex justify-content-between" style="padding: 10px;margin: 0;">
            <div class="col-xs-4 text-left" style="padding: 0;">
              <b>ต่ำสุด </b>
            </div>
            <div class="col-xs-8 text-left" style="padding: 0;">
              <input style="text-align: center;font-weight: bold;background-color: #fff;" class="Rprice" id="mypricemin" v-model="PRI.MIN">
            </div>
          </div>
          <div class="row d-flex justify-content-between" style="padding: 10px;margin: 0;">
            <div class="col-xs-4 text-left" style="padding: 0;">
              <b> สูงสุด</b> 
            </div>
            <div class="col-xs-8 text-left" style="padding: 0;">
              <input style="text-align: center;font-weight: bold;background-color: #fff;" class="Rprice" id="myprice" v-model="PRI.MAX">
            </div>
          </div>
        </div>
      </div>
      <div class="row w-100 m-0 p-0 bg-white">
        <div class="col-6 px-1 py-0">
          <a @click="find()" :class="`p-1 btn-visible-${getBU}`">ค้นหา</a>
        </div>
        <div class="col-6 px-1 py-0">
          <a @click="clear()" :class="` btn-invisible-${getBU}`">ล้างข้อมูล</a>
        </div>
      </div>
      
      

    </div>

  </section>
</template>


<script>
  import * as serviceAPI from '../services/API.service';
  import * as serviceMain from '../services/main.service';
  export default {
    name: 'PropertySearch',
    data: function () {
      return {
        listData: [],
        sortData:'',
        filterData:'',
        filterCatData:'',
        clrdata: false,
        propClick:{
          'BND':false,
          'CAT':false,
          'PRI':false
        },
        PRI:{
          'MIN':0,
          'MAX':100000
        },
        listbnd:[],
        search:'',
        listDatacat:[],
        searchcat:'',
      }
    },
    mounted() {
      this.startProperty();
      this.setSort()
      this.setPri()
      // this.listbnd = this.getBnd;
    },
    components: {
    
    },
    props: [
      "srcTxt"
    ],
    methods: {
      async setSort(){
        this.sortData= this.$route.query.sort;
        // let getFilter = this.$route.query.fill;
        // let getSort = this.$route.query.sort;
      },
      async setPri(){
        this.PRI.MIN = this.$route.query.minpri || 0;
        this.PRI.MAX = this.$route.query.maxpri || 100000;
      },
      async startProperty() {
        try {
          let getSrcTxt = this.srcTxt
          let data = {
            "SEARCH": getSrcTxt,
            "STCODE": this.getStoreCode,
            "ISTB": this.$store.getters['auth/getISTB'] ? 1:0
          }
          // let getAPI = await serviceAPI.call_API('post', `product/brandlist`, data,'auth');
          // this.listData = getAPI.data.dbitems;

          // let getAPIcat = await serviceAPI.call_API('post', `product/catlist`, data,'auth');
          // this.listDatacat = getAPIcat.data.dbitems;
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      onSort(e){
        this.sortData=e;
        this.$router.push({ path: '/Product', query: { src: this.srcTxt, fill: this.filterData, cat: this.filterCatData, minpri: this.PRI.MIN, maxpri: this.PRI.MAX ,sort:this.sortData}});
        $("#mySidenavSearch").hide()
        // this.$router.push({ name: 'Product', params: { getcatcode: this.catcode }, query: { src: this.srcTxt, fill: this.filterData ,sort:this.sortData}})
      },
      async find(){
        // |4-5|5-2%6|6-2
        let getfilterArray=[]
        let getCatArray=[]
        let firsted =true;
        let firstedCat =true;
        for(let i of this.filteredList){
          if(i.selected==true){
            if(firsted){
              getfilterArray.push(`|1-${i.index}`)
              firsted=false
            }else{
              getfilterArray.push(`%${i.index}`)
            }
          }
        }
        for(let i of this.filteredListcat){
          if(i.selected==true){
            if(firstedCat){
              getCatArray.push(`${i.catcode}`)
              firstedCat=false
            }else{
              getCatArray.push(`,${i.catcode}`)
            }
          }
        }
        this.filterData = getfilterArray.join("")
        this.filterCatData = getCatArray.join("")
        // console.log(this.filterData);
        this.$router.push({ path: '/Product', query: { src: this.srcTxt, fill: this.filterData, cat: this.filterCatData, minpri: this.PRI.MIN, maxpri: this.PRI.MAX ,sort:this.sortData}});
        $("#mySidenavSearch").hide()
        // this.$router.push({ name: 'Product', params: { getcatcode: this.catcode } , query: { src: this.srcTxt, fill: this.filterData ,sort:this.sortData}})
      },
      async clear(){
        this.clrdata = true;
        this.search = '';
        this.searchcat = '';
        // this.listbnd = this.getBnd.map(e => e.selected=false);
      },
      openNav() {
        $("#mySidenavSearch").show()
        // document.getElementById("mySidenavSearch").show();
      },
      closeNav() {
        $("#mySidenavSearch").hide()
        // document.getElementById("mySidenavSearch").hide();
      },
      toggle_property(e){
        this.propClick[e] = !this.propClick[e];
      }

    },
    destroyed() {
      $("#mySidenavSearch").hide()
    },
    computed:{
      getBU:function(){
        return this.$store.getters['customer/getQTBU']
      },
      buColor:function(){
        let getBU = this.getBU
        let color = '';
        switch(getBU) {
          case 'bnb':
            color = '#006e63';
          break;
          case 'at1':
            color = '#3866af';
          break;
          default:
            color = '#55246c';
        }
        return color;
      },
      getStoreCode:function(){
        return this.$store.getters['service/getQTDFSTORE']
      },
      getSrcText:function(){
        return this.srcTxt
      },
      getBnd:function(){
        let newbnd=[];
        let checkbnd = this.$route.query.fill ? this.$route.query.fill.substring(3,this.$route.query.fill.length).split('%') : '';
        for (let i = 0; i < this.listData.length; i++) {
          newbnd.push({
            bnd: this.listData[i].bnd,
            Np: this.listData[i].Np,
            index: i,
            selected: false
          });
        }
        if(checkbnd){
          for (let i = 0; i < checkbnd.length; i++) {
            newbnd[checkbnd[i]].selected = true;
          }
        }
        if(this.clrdata){
          newbnd = newbnd.map(e => ({...e, selected:false}));
          this.clrdata = false;
        }
        newbnd.sort((a, b) => {
          let fa = a.bnd.toLowerCase(),
              fb = b.bnd.toLowerCase();

          if (fa < fb) {
              return -1;
          }
          if (fa > fb) {
              return 1;
          }
          return 0;
        });
        return newbnd
        // return this.listData.map((itm) => ({
        //                                     ...itm,
        //                                     index: 1,
        //                                     selected: false
        //                                   }))
      },
      filteredList() {
        return this.getBnd.filter(e => {
          if(e.bnd.toLowerCase().includes(this.search.toLowerCase())){
            return true
          }
          return false
          
        })
      },
      getCat:function(){
        let newbnd=[];
        let checkbnd = this.$route.query.cat ? this.$route.query.cat.split(',') : '';
        for (let i = 0; i < this.listDatacat.length; i++) {
          newbnd.push({
            catname: this.listDatacat[i].CATNAME,
            catcode: this.listDatacat[i].CLASS,
            Np: this.listDatacat[i].Np,
            index: i,
            selected: false
          });
        }
        if(checkbnd){
          for (let i = 0; i < newbnd.length; i++) {
            for (const e of checkbnd) {
              if(newbnd[i].catcode == e){
                newbnd[i].selected = true;
              }
            }
          }
        }
        if(this.clrdata){
          newbnd = newbnd.map(e => ({...e, selected:false}));
          this.clrdata = false;
        }
        newbnd.sort((a, b) => {
          let fa = a.catname.toLowerCase(),
              fb = b.catname.toLowerCase();

          if (fa < fb) {
              return -1;
          }
          if (fa > fb) {
              return 1;
          }
          return 0;
        });
        return newbnd
        // return this.listData.map((itm) => ({
        //                                     ...itm,
        //                                     index: 1,
        //                                     selected: false
        //                                   }))
      },
      filteredListcat() {
        return this.getCat.filter(e => {
          if(e.catname.toLowerCase().includes(this.searchcat.toLowerCase())){
            return true
          }
          return false
          
        })
      },
      getQuery:function(){
        let getFilter = this.$route.query.fill;
        return {
                STCODE: this.getStoreCode,
                SEARCH: this.srcTxt,
                PRICE1: this.PRI.MIN,
                PRICE2: this.PRI.MAX,
                ORDERBY: this.sortData,
                FILTERS: getFilter
            }
      },
      // getProperty:function(){
      //   let PropertyData =[]
      //   let listData = this.listData
      //   for(let i =0;i<listData.length;i++){
      //     let e = listData[i];
      //     let firstStr = e.name.charAt(0);
      //     if(['S','M','X'].includes(firstStr)){
      //       if(e.name=='Series'){
      //         let getProp = e.VAL.split("|");
      //         e.prop=[]
      //         for(let j =0;j<getProp.length;j++){
      //           let p = getProp[j];
      //           let ObjProp ={
      //             name:p,
      //             index:j,
      //             selected:false
      //           }
      //           e.prop.push(ObjProp);
      //         }
      //         e.click = false;
      //         e.index = i+1
      //         PropertyData.push(e);
      //       }
      //     }else{
      //       let getProp = e.VAL.split("|");
      //       e.prop=[]
      //       for(let j =0;j<getProp.length;j++){
      //         let p = getProp[j];
      //         if(p != 'ALL' && p != '') {
      //           let ObjProp ={
      //             name:p,
      //             index:j,
      //             selected:false
      //           }
      //           e.prop.push(ObjProp);
      //         }
      //       }
      //       e.click = false;
      //       e.index = i+1
      //       PropertyData.push(e);
      //     }
      //   }
      //   console.log(PropertyData)
      //   return PropertyData
        
      // }
    },
    watch:{
      getSrcText:function(){
        this.startProperty()
      },
    }
  }

</script>

<style scoped lang="scss">
  .sidenav-bottom{
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
  }
  .property-titel{
    border-bottom: 1px lightgray solid;
  }
  .asid-nav{
    height: 45px;
  }
  .nav-titel{
    color: #fff;
    font-size: 1.5em;

  }
  .property-card{
    background-color: rgb(235, 235, 235);
    overflow: auto;
    max-height: 520px;
  }

  .input-store-src{
    width: 100%;
    border: 1px #55246c solid;
    border-radius: 8px;
  }
  .input-store-src:focus{
    border: 1px #55246c solid;
  }


  /* The side navigation menu */
  .sidenav {
    height: 100%; /* 100% Full-height */
    width: 250px; /* 0 width - change this with JavaScript */
    display: none;
    position: fixed; /* Stay in place */
    z-index: 999; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    background-color: white; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    padding: 0px 5px;
    -webkit-box-shadow: 7px 0px 29px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 7px 0px 29px -6px rgba(0,0,0,0.75);
    box-shadow: 7px 0px 29px -6px rgba(0,0,0,0.75);
  }

  /* The navigation menu links */
  // .sidenav a {
    
  //   text-decoration: none;
  //   font-size: 25px;
  //   color: black;
  //   display: block;
  //   transition: 0.3s;
  // }

  .sidenav .closebtn {
    font-size: 36px;
  }
  .closebtn {
    text-decoration: none;
    font-size: 25px;
    color: #f1f1f1 !important;
    display: block;
    transition: 0.3s;
  }
  .closebtn {
    color: white;
  }
  .closebtn:hover {
    color: #818181;
  }

  #main {
    transition: margin-left .5s;
    padding: 20px;
  }

  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }
  @media only screen and (max-width: 768px) {
    .sidenav {
      width: 100%;
      
    }
   }
  

   /* Check Box start */

      /* The container */
    .container-checkbox {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 7px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

      /* Hide the browser's default checkbox */
    .container-checkbox input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

      /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #fff;
      border: 1px black solid;
      border-radius: 4px;
    }

    /* On mouse-over, add a grey background color */
    .container-checkbox:hover input ~ .checkmark {
      background-color: #eee;
    }

    /* When the checkbox is checked, add a blue background */
    .container-checkbox input:checked ~ .checkmark {
      // background-color: #2196F3;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .container-checkbox input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .container-checkbox .checkmark:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid #000;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
   /* Check Box end */
</style>