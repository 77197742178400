<template>
  <section>
    <div class="row m-3" v-show="!disPrint">
      <div class="col-12 col-md-4">
        <button type="button" :disabled="disPrint" :class="`btn bt w-100 bg-${buCode ? buCode : getBU}`" data-dismiss="modal"
          @click="goPrint(0)">พิมพ์ใบเสนอราคา</button>
      </div>
      <div class="col-12 col-md-4">
        <button type="button" :disabled="disPrint" :class="`btn bt w-100 bg-${buCode ? buCode : getBU}`" data-dismiss="modal"
          @click="goPrint(1)">พิมพ์ใบเสนอราคาแบบกำหนดเอง</button>
      </div>
      <div class="col-12 col-md-4">
        <button type="button" :disabled="disPrint" :class="`btn bt w-100 bg-${buCode ? buCode : getBU}`" data-dismiss="modal"
          @click="goPrint(2)">พิมพ์ใบเสนอราคาแบบบริษัท</button>
      </div>
    </div>
    <PrintQuotationDetailSkeleton v-show="disPrint"/>

    <!-- //////////////////////////// Print Page ////////////////////////////// -->
    <div v-show="false">
      <div  class="container print-Div" :id="`printQuo-${cartref}`" v-if="listData.userid">
        <div class="row mb-1">
          <div class="col-2 p-2 align-self-center">
            <img :src="`/img/${buCode == 'bnb'?'bnbqtlogo1.jpg': (buCode == 'at1'? 'at1qtlogo.jpg' : 'twdqtlogo.jpeg')}`" style="max-height:200px; float:left; margin:0px 10px;width: 150px;" alt="">
          </div>
          <div class="col-10 p-2">
            <p class="line-low m-0"> <b class="bhead">{{listData.sttname}}</b></p>
            <p class="line-low title-little m-0">{{listData.st_thaddr}} โทร. {{listData.sttel}}</p>
            <p class="line-low title-little m-0">เลขที่ประจำตัวผู้เสียภาษี 0105555021215</p>
            <p class="line-low m-0"> <b class="bhead">{{listData.stename}}</b></p>
            <p class="line-low title-little m-0">{{listData.st_enaddr}} Tel. {{listData.sttel}}</p>
            <p class="line-low title-little m-0">TAX ID 0105555021215</p>
            <p class="line-low title-little m-0">E-Mail : LineOA@chg.co.th Web Site : https://thaiwatsadu.com / https://www.bnbhome.com/</p>
          </div>
        </div>
        <div class="row border">
          <div class="col-12 p-0 ">
            <p class="text-center title-name my-1" >ใบเสนอราคา</p>
          </div>
        </div>
        <div class="row border">
          <div class="col-6 px-3 p-0" >
            <p class="m-0 line-low"><b>สาขา: </b>{{listData.stname}} </p>
            <p class="m-0 line-low"><b>วันที่เสนอราคา: </b>{{listData.qtdate}} </p>
            <p class="m-0 line-low"><b>รูปแบบการรับสินค้า: </b>{{dlvType.D ? 'จัดส่งสินค้า' : ''}} {{dlvType.D && dlvType.P ? '/' : ''}} {{dlvType.P ? 'รับที่สาขา' : ''}}</p>
            <!-- <p v-if="!dlvStatus && dlvStoreName.trim() != ''" class="m-0 line-low"><b>รับสินค้าที่สาขา: </b>{{dlvStoreName}}</p> -->
          </div>
          <div class="col-6 px-3 p-0">
            <p class="m-0 line-low"><b>อ้างอิงเลขที่ OTP: </b>{{listData.otp}}</p>
            <p class="m-0 line-low"><b>วันหมดอายุ: </b>{{listData.expireddate}}</p>
            <!-- <p class="m-0 line-low"><b>{{dlvDateShow}}</b>{{listData.dlvdate}}</p> -->
          </div>
        </div>
        <div class="row border">
          <div v-if="m2" class="col-6 px-3 p-0 border-right ">
            <p class="m-0 line-low"><b><u>ข้อมูลลูกค้า</u></b></p>
            <p class="m-0 line-low"><b>ผู้ติดต่อ:</b> {{customQT.nameC ? customQT.nameC : ''}}</p>
            <p class="m-0 line-low"><b>ชื่อบริษัท:</b> {{customQT.comnameC ? customQT.comnameC : ''}}</p>
            <p class="m-0 line-low"><b>ที่อยู่:</b> {{customQT.addressC ? customQT.addressC : ''}}</p>
            <p class="m-0 line-low"><b>เลขที่ประจำตัวผู้เสียภาษีอากร:</b> {{customQT.idnumC ? customQT.idnumC : ''}}</p>
            <p class="m-0 line-low"><b>โทรศัพท์:</b> {{customQT.phoneC ? customQT.phoneC : ''}}</p>
            <p class="m-0 line-low"><b>แฟกซ์:</b> {{customQT.faxC ? customQT.faxC : ''}}</p>
            <p v-if="dlvType.D" class="m-0 line-low"><b>ที่อยู่ที่จัดส่ง:</b> {{dlvAddrShow}}</p>
            <p v-if="dlvType.P" class="m-0 line-low"><b>ที่อยู่สาขา:</b> {{listData.st_thaddr}}</p>
          </div>
          <div v-else-if="m1" class="col-6 px-3 p-0 border-right ">
            <p class="m-0 line-low"><b><u>ข้อมูลลูกค้า</u></b></p>
            <p class="m-0 line-low"><b>ชื่อ-นามสกุล:</b> {{customQT.nameU ? customQT.nameU : ''}}</p>
            <p class="m-0 line-low"><b>เบอร์มือถือ:</b> {{customQT.phoneU ? customQT.phoneU : ''}}</p>
            <p class="m-0 line-low"><b>E-mail:</b> {{customQT.emailU ? customQT.emailU : ''}}</p>
            <p class="m-0 line-low"><b>The1:</b> {{customQT.the1U ? customQT.the1U : ''}}</p>
            <p v-if="dlvType.D" class="m-0 line-low"><b>ที่อยู่ที่จัดส่ง:</b> {{dlvAddrShow}}</p>
            <p v-if="dlvType.P" class="m-0 line-low"><b>ที่อยู่สาขา:</b> {{listData.st_thaddr}}</p>
          </div>
          <div v-else class="col-6 px-3 p-0 border-right ">
            <p class="m-0 line-low"><b><u>ข้อมูลลูกค้า</u></b></p>
            <p class="m-0 line-low"><b>ชื่อ-นามสกุล:</b> {{listData.custname}}</p>
            <p class="m-0 line-low"><b>เบอร์มือถือ:</b> {{listData.mobile}}</p>
            <p class="m-0 line-low"><b>E-mail:</b> {{listData.email}}</p>
            <p class="m-0 line-low"><b>The1:</b> {{listData.The1}}</p>
            <p v-if="dlvType.D" class="m-0 line-low"><b>ที่อยู่ที่จัดส่ง:</b> {{dlvAddrShow}}</p>
            <p v-if="dlvType.P" class="m-0 line-low"><b>ที่อยู่สาขา:</b> {{listData.st_thaddr}}</p>
          </div>
          <div class="col-6 px-3 p-0 ">
            <p class="m-0 line-low" ><b><u>ข้อมูลพนักงาน</u></b></p>
            <p class="m-0 line-low"><b>รหัสพนักงาน:</b> {{listData.saleperson}}</p>
            <p class="m-0 line-low"><b>ชื่อพนักงาน:</b> {{listData.empname}}</p>
            <p class="m-0 line-low"><b>Sale Source:</b> {{listData.salesource}}</p>
          </div>
        </div>
        <div class="row border px-3" v-if="!grouping">
          <div class="col-12 px-3 pt-1 p-0 d-flex justify-content-between">
            <span class="title-name mt-3">{{cartref}} ( {{sorderref}} )</span>
            <VueBarcode :value="cartref" :key="cartref" style="width:250px;  height:120px; margin-right:15px;">
              ไม่สามารถสร้าง Barcode ได้
            </VueBarcode>
          </div>
          <div class="col-12 p-0 d-flex flex-column bd-highlight" >
            <table>
              <tbody>
                <tr v-for="(e, index) in listDataOrder" :key="index" >
                  <td>
                    <VueBarcode v-if="e.SKC" :value="e.SKC" :key="e.SKC" style="width:200px; height:120px;">
                      ไม่สามารถสร้าง Barcode ได้
                    </VueBarcode>
                  </td>
                  <td class="p-2 pb-3">
                    <ImageBySKU class="py-1" style="max-height: 100px; margin-right:15px;" :sku="e.SKC" :key="e.SKC" :buCode="buCode" v-if="!(e.SKC == '')" />
                  </td>
                  <td class="">
                    <p class="title-name">{{ e.PRN }}</p>  
                    <p class="line-low m-0">รหัสสินค้า : {{ e.SKC }} ราคา {{priceBaht(e.UPC)}} บาท/{{e.UNIT}} จำนวน {{e.QNT}} {{e.UNIT}}
                      <br>
                      <span class="line-low">OFflag: {{e.OFflag}}</span>
                      <span class="line-low" v-if="e.PORQ == 'P'"> PO: {{e.VENST}} </span>
                      <span class="line-low" v-if="e.PORQ == 'R'"> RQ: {{e.VENST}} </span>
                      <!-- <br> -->
                      <span class="line-low pl-1" v-if="e.SKC != '154338'">{{ e.DLVTEXT }} วันที่:{{ e.DLVDATE }}</span>
                    </p>
                    <div class="line-low" v-if="e.SLPRSN" style="width:600px;word-wrap: break-word;"> รหัสพนักงาน: {{e.SLPRSN}}</div>
                    <div class="line-low" v-if="e.REMARK" style="width:600px;word-wrap: break-word;"> REMARK: {{e.REMARK}}</div>
                    <p class="line-low float-right text-right font-weight-bold">ยอดรวม {{ priceBaht(e.AMT) }} บาท</p>
                  </td>
                
                  <!-- <td class="text-right font-weight-bold align-bottom pb-3"> </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="listDataDIS.length>0 && !grouping" class="row ">
          <div class="col-12 m-0 p-0">
            <table class="table m-0 p-0 table-bordered w-100">
              <thead >
                <tr>
                  <th class="title-little m-0 py-0 text-center">ลำดับ</th>
                  <th class="title-little m-0 py-0">ชื่อสินค้า</th>
                  <th class="title-little m-0 py-0 text-center">จำนวน</th>
                  <th class="title-little m-0 py-0 text-center" >ส่วนลด</th>
                  <th class="title-little m-0 py-0">Remark</th>
                </tr>
              </thead>
              <tbody >
                <tr v-for="(e, index) in listDataDIS" :key="index">
                  <td class="title-little m-0 py-0 text-center">{{ e.SEQ }}</td>
                  <td class="title-little m-0 py-0">{{ e.SKNAME }}</td>
                  <td class="title-little m-0 py-0 text-center">{{ e.QNT }}</td>
                  <td class="title-little m-0 py-0 text-center">{{ e.DSC }}</td>
                  <td class="title-little m-0 py-0">{{ e.REMARK }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row border" v-if="grouping == '1'">
          <div class="col-6 px-3 p-0" >
            <p class="title-little m-0"><b>{{ groupName }}</b></p>
          </div>
          <div class="col-6 px-3 p-0" style="flex-end">
            <p class="title-little m-0 float-right text-right"><b>ราคารวม  {{ sendTotPrice }} บาท</b></p>
          </div>
        </div>
        <div class="row mb-0 pt-0  border" style="border-right:none;" >
          <div class="col-7">
            <p class="title-name m-0"><b><u>หมายเหตุ</u></b></p>
            <p class="line-low">{{ listDataAMT.REMARK }}</p>
          </div>
          <div class="col-5 m-0 p-0">
            <table class="table m-0 p-0 table-bordered w-100" >
              <tbody>
                <tr>
                  <td colspan="2" class="m-0 py-0 title-name text-left">รายละเอียดการชำระเงิน</td>
              
                </tr>
                <tr>
                  <td class="line-low m-0 py-0 text-left">ราคารวม</td>
                  <td class="line-low m-0 py-0 text-right">{{ priceBaht(priceBeforeVat) }} บาท</td>
                </tr>
                <tr>
                  <td class="line-low m-0 py-0 text-left">ส่วนลดตามรายการ</td>
                  <td class="line-low m-0 py-0 text-right">{{ discount }} บาท</td>
                </tr>
                <tr> 
                  <td class="line-low m-0 py-0 text-left">ส่วนลด MKT {{listDataAMT.DISCCODE ? listDataAMT.DISCCODE:''}}</td>
                  <td class="line-low m-0 py-0 text-right">{{ listDataAMT.discmkt }} บาท</td>
                </tr>
                <tr> 
                  <td class="line-low m-0 py-0 text-left">ส่วนลดพนักงาน</td>
                  <td class="line-low m-0 py-0 text-right">{{ showDisStaff }} บาท</td>
                </tr>
                <tr>
                  <td class="line-low m-0 py-0 text-left">VAT</td>
                  <td class="line-low m-0 py-0 text-right">{{ priceBaht(priceVat) }} บาท</td>
                </tr>
                <tr>
                  <td class="line-low m-0 py-0 title-name text-left">ราคารวมสุทธิ</td>
                  <td class="line-low m-0 py-0 title-name text-right">{{ sumNET }} บาท</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row  pt-0  border">
          <div class="col-12 mt-1" >
            <p class="title-little m-0 text-center">ค่าขนส่งเป็นราคาประเมิน หากมีการเปลี่ยนแปลงจะมีการติดต่อจากเจ้าหน้าที่ไปอีกครั้ง</p>
          </div>
        </div>
      </div>
      <!-- //////////////////////////// Print Page AT1 ////////////////////////////// -->
      <div class="container print-Div" :id="`printQuoAT1-${cartref}`" v-if="listData.userid">
        <div class="row mb-1">
          <div class="col-12 p-2"><p class="text-center title-name my-1" ><b class="bhead">ใบเสนอราคา (E-Ordering)</b></p></div>
          <div class="col-8"></div>
          <div class="col-4">
            <VueBarcode :value="cartref" :key="cartref" style="width:250px;  height:120px; margin-right:15px;">
              ไม่สามารถสร้าง Barcode ได้
            </VueBarcode>
          </div>
          <div class="col-2 p-2">
            <img :src="`/img/${buCode == 'bnb'?'bnbqtlogo1.jpg': (buCode == 'at1'? 'at1qtlogo.jpg' : 'twdqtlogo.jpeg')}`" style="max-height:150px; float:left; margin:0px 10px;width: 150px;" alt="">
          </div>
          <div class="col-6 p-2">
            <p class="line-low m-0"> <b class="bhead">{{listData.sttname}}<b>สาขา:</b>{{listData.stname}}</b></p>
            <p class="line-low title-little m-0">{{listData.st_thaddr}}</p>
            <p class="line-low title-little m-0">เลขที่ประจำตัวผู้เสียภาษี 0105555021215</p>
            <p class="line-low title-little m-0"> โทร. {{listData.sttel}}</p>
          </div>
          <div class="col-4 p-2">
            <p class="line-low m-0"> <b class="bhead">{{cartref}} ( {{sorderref}} )</b></p>
            <p class="line-low title-little m-0"><b>วันที่เสนอราคา: </b>{{listData.qtdate}}</p>
            <p class="line-low title-little m-0"><b>วันหมดอายุ: </b>{{listData.expireddate}}</p>
            <p class="line-low title-little m-0"><b>อ้างอิงเลขที่ OTP: </b>{{listData.otp || '-'}}</p>
          </div>
        </div>
        <div class="row border">
          <div v-if="m2" class="col-6 p-3 border-right ">
            <p class="m-0 line-low"><b><u>รายละเอียดลูกค้า</u></b></p>
            <p class="m-0 line-low"><b>ผู้ติดต่อ:</b> {{customQT.nameC ? customQT.nameC : ''}}</p>
            <p class="m-0 line-low"><b>ชื่อบริษัท:</b> {{customQT.comnameC ? customQT.comnameC : ''}}</p>
            <p class="m-0 line-low"><b>ที่อยู่:</b> {{customQT.addressC ? customQT.addressC : ''}}</p>
            <p class="m-0 line-low"><b>เลขที่ประจำตัวผู้เสียภาษีอากร:</b> {{customQT.idnumC ? customQT.idnumC : ''}}</p>
            <p class="m-0 line-low"><b>โทรศัพท์:</b> {{customQT.phoneC ? customQT.phoneC : ''}}</p>
            <p class="m-0 line-low"><b>แฟกซ์:</b> {{customQT.faxC ? customQT.faxC : ''}}</p>
            <p v-if="dlvType.D" class="m-0 line-low"><b>ที่อยู่ที่จัดส่ง:</b> {{dlvAddrShow}}</p>
            <p v-if="dlvType.P" class="m-0 line-low"><b>ที่อยู่สาขา:</b> {{listData.st_thaddr}}</p>
          </div>
          <div v-else-if="m1" class="col-6 p-3 border-right ">
            <p class="m-0 line-low"><b><u>รายละเอียดลูกค้า</u></b></p>
            <p class="m-0 line-low"><b>ชื่อ-นามสกุล:</b> {{customQT.nameU ? customQT.nameU : ''}}</p>
            <p class="m-0 line-low"><b>เบอร์มือถือ:</b> {{customQT.phoneU ? customQT.phoneU : ''}}</p>
            <p class="m-0 line-low"><b>E-mail:</b> {{customQT.emailU ? customQT.emailU : ''}}</p>
            <p class="m-0 line-low"><b>The1:</b> {{customQT.the1U ? customQT.the1U : ''}}</p>
            <p v-if="dlvType.D" class="m-0 line-low"><b>ที่อยู่ที่จัดส่ง:</b> {{dlvAddrShow}}</p>
            <p v-if="dlvType.P" class="m-0 line-low"><b>ที่อยู่สาขา:</b> {{listData.st_thaddr}}</p>
          </div>
          <div v-else class="col-6 p-0 border-right ">
            <div class="col-12 border-bottom px-1"><p class="m-0 line-low"><b><u>รายละเอียดลูกค้า</u></b></p></div>
            <div class="col-12 border-bottom px-1"><p class="m-0 line-low"><b>ชื่อ-นามสกุล:</b> {{listData.custname}}</p></div>
            <div class="col-12 border-bottom px-1"><p class="m-0 line-low"><b>เบอร์มือถือ:</b> {{listData.mobile}}</p></div>
            <div class="col-12 border-bottom px-1"><p class="m-0 line-low"><b>E-mail:</b> {{listData.email}}</p></div>
            <div class="col-12 border-bottom px-1"><p class="m-0 line-low"><b>The1:</b> {{listData.The1 || '-'}}</p></div>
            <div class="col-12 px-1"><p v-if="dlvType.D" class="m-0 line-low"><b>ที่อยู่ที่จัดส่ง:</b> {{dlvAddrShow}}</p></div>
            <div class="col-12 px-1"><p v-if="dlvType.P" class="m-0 line-low"><b>ที่อยู่สาขา:</b> {{listData.st_thaddr}}</p></div>
          </div>
          <div class="col-6 p-0">
            <div class="col-12 px-1 border-bottom"><p class="m-0 line-low" ><b><u>รายละเอียดรถ</u></b></p></div>
            <div class="row m-0 p-0">
              <div class="col-6 px-1 border-bottom border-right">
                <p class="m-0 line-low"><b>วันที่นัดหมาย:</b> 1</p>
              </div>
              <div class="col-6 px-1 border-bottom">
                <p class="m-0 line-low"><b>เวลานัดหมาย:</b> 1</p>
              </div>
              <div class="col-6 px-1 border-bottom border-right">
                <p class="m-0 line-low"><b>ทะเบียนรถ:</b> 1</p>
              </div>
              <div class="col-6 px-1 border-bottom">
                <p class="m-0 line-low"><b>จังหวัด:</b> 1</p>
              </div>
              <div class="col-6 px-1 border-bottom border-right">
                <p class="m-0 line-low"><b>ยี่ห้อรถ:</b> 1</p>
              </div>
              <div class="col-6 px-1 border-bottom">
                <p class="m-0 line-low"><b>รุ่นรถ:</b> 1</p>
              </div>
              <div class="col-12 px-1 border-bottom">
                <p class="m-0 line-low"><b>เลขตัวถัง:</b> 1</p>
              </div>
              <div class="col-6 px-1 border-bottom border-right">
                <p class="m-0 line-low"><b>ขนาดเครื่องยนต์:</b> 1 CC.</p>
              </div>
              <div class="col-3 px-1 border-bottom border-right">
                <p class="m-0 line-low"><b>สีรถ:</b> 1</p>
              </div>
              <div class="col-3 px-1 border-bottom">
                <p class="m-0 line-low"><b>ปีรถ:</b> 1</p>
              </div>
              <div class="col-6 px-1 border-right">
                <p class="m-0 line-low"><b>เกียร์:</b> 1</p>
              </div>
              <div class="col-6">
                <p class="m-0 line-low"><b>เลขไมล์:</b> 1</p>
              </div>
            </div>
            
          </div>
        </div>
        <div class="row" v-if="!grouping">
          <div class="col-12 p-0 d-flex flex-column bd-highlight" >
            <table>
              <thead>
                <tr>
                  <th class="title-little border m-0 py-0 text-center">รายละเอียดสินค้า</th>
                  <th class="title-little border m-0 py-0 text-center">รหัสสินค้า</th>
                  <th class="title-little border m-0 py-0 text-center">รายการสินค้า</th>
                  <th class="title-little border m-0 py-0 text-center" >จำนวน</th>
                  <th class="title-little border m-0 py-0 text-center">ราคา/หน่วย</th>
                  <th class="title-little border m-0 py-0 text-center">ราคารวม</th>
                  <th class="title-little border m-0 py-0 text-center">ส่วนลด</th>
                  <th class="title-little border m-0 py-0 text-center">ราคาสุทธิ</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(e, index) in listDataOrder" :key="index" >
                  <td class="border text-center">
                    <VueBarcode v-if="e.SKC" :value="e.SKC" :key="e.SKC" style="width:200px; height:120px;">
                      ไม่สามารถสร้าง Barcode ได้
                    </VueBarcode>
                  </td>
                  <td class="border px-2 text-center">
                    {{ e.SKC }}
                  </td>
                  <td class="border px-2">
                    <p class="m-0">{{ e.PRN }}</p>  
                    <ImageBySKU style="max-height: 100px; margin-right:15px;" :sku="e.SKC" :key="e.SKC" :buCode="buCode" v-if="!(e.SKC == '')" />
                    <!-- <p class="line-low m-0">รหัสสินค้า :  ราคา {{priceBaht(e.UPC)}} บาท/{{e.UNIT}} จำนวน {{e.QNT}} {{e.UNIT}}
                      <br>
                      <span class="line-low">OFflag: {{e.OFflag}}</span>
                      <span class="line-low" v-if="e.PORQ == 'P'"> PO: {{e.VENST}} </span>
                      <span class="line-low" v-if="e.PORQ == 'R'"> RQ: {{e.VENST}} </span>
                      <span class="line-low pl-1" v-if="e.SKC != '154338'">{{ e.DLVTEXT }} วันที่:{{ e.DLVDATE }}</span>
                    </p>
                    <div class="line-low" v-if="e.REMARK" style="width:600px;word-wrap: break-word;"> REMARK: {{e.REMARK}}</div>
                    <p class="line-low float-right text-right font-weight-bold">ยอดรวม {{ priceBaht(e.AMT) }} บาท</p> -->
                  </td>
                  <td class="border px-2 text-center">{{e.QNT}}
                  </td>
                  <td class="border px-2 text-right">{{priceBaht(e.UPC)}}
                  </td>
                  <td class="border px-2 text-right">{{ priceBaht(e.AMT) }}
                  </td>
                  <td class="border px-2 text-right">
                  </td>
                  <td class="border px-2 text-right">
                  </td>
                
                  <!-- <td class="text-right font-weight-bold align-bottom pb-3"> </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="listDataDIS.length>0 && !grouping" class="row ">
          <div class="col-12 m-0 p-0">
            <table class="table m-0 p-0 table-bordered w-100">
              <thead >
                <tr>
                  <th class="title-little m-0 py-0 text-center">ลำดับ</th>
                  <th class="title-little m-0 py-0">ชื่อสินค้า</th>
                  <th class="title-little m-0 py-0 text-center">จำนวน</th>
                  <th class="title-little m-0 py-0 text-center" >ส่วนลด</th>
                  <th class="title-little m-0 py-0">Remark</th>
                </tr>
              </thead>
              <tbody >
                <tr v-for="(e, index) in listDataDIS" :key="index">
                  <td class="title-little m-0 py-0 text-center">{{ e.SEQ }}</td>
                  <td class="title-little m-0 py-0">{{ e.SKNAME }}</td>
                  <td class="title-little m-0 py-0 text-center">{{ e.QNT }}</td>
                  <td class="title-little m-0 py-0 text-center">{{ e.DSC }}</td>
                  <td class="title-little m-0 py-0">{{ e.REMARK }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row border" v-if="grouping == '1'">
          <div class="col-6 px-3 p-0" >
            <p class="title-little m-0"><b>{{ groupName }}</b></p>
          </div>
          <div class="col-6 px-3 p-0" style="flex-end">
            <p class="title-little m-0 float-right text-right"><b>ราคารวม  {{ sendTotPrice }} บาท</b></p>
          </div>
        </div>
        <div class="row mb-0 pt-0">
          <div class="col-7">
            <p class="title-name m-0"><b><u>หมายเหตุ</u></b></p>
            <p class="line-low">{{ listDataAMT.REMARK }}</p>
          </div>
          <div class="col-5 m-0 p-0">
            <div class="row p-0 m-0">
              <div class="col-12 border-right border-left border-bottom m-0 p-0">
                <div class="row p-0 m-0">
                  <div class="col-6 line-low text-left border-right">ราคารวม</div>
                  <div class="col-6 line-low text-right">{{ priceBaht(priceBeforeVat) }} บาท</div>
                </div>
              </div>
              <div class="col-12 border-right border-left border-bottom m-0 p-0">
                <div class="row p-0 m-0">
                  <div class="col-6 line-low text-left border-right">ส่วนลดตามรายการ</div>
                  <div class="col-6 line-low text-right">{{ listDataAMT.discplpp }} บาท</div>
                </div>
              </div>
              <div class="col-12 border-right border-left border-bottom m-0 p-0">
                <div class="row p-0 m-0">
                  <div class="col-6 line-low text-left border-right">ส่วนลด MKT {{listDataAMT.DISCCODE ? listDataAMT.DISCCODE:''}}</div>
                  <div class="col-6 line-low text-right">{{ listDataAMT.discmkt }} บาท</div>
                </div>
              </div>
              <div class="col-12 border-right border-left border-bottom m-0 p-0">
                <div class="row p-0 m-0">
                  <div class="col-6 line-low text-left border-right">ส่วนลดพนักงาน</div>
                  <div class="col-6 line-low text-right">{{ showDisStaff }} บาท</div>
                </div>
              </div>
              <div class="col-12 border-right border-left border-bottom m-0 p-0">
                <div class="row p-0 m-0">
                  <div class="col-6 line-low text-left border-right">VAT</div>
                  <div class="col-6 line-low text-right">{{ priceBaht(priceVat) }} บาท</div>
                </div>
              </div>
              <div class="col-12 border-right border-left border-bottom m-0 p-0">
                <div class="row p-0 m-0">
                  <div class="col-6 line-low text-left border-right">ราคารวมสุทธิ</div>
                  <div class="col-6 line-low text-right">{{ sumNET }} บาท</div>
                </div>
              </div>
            </div>
            <!-- <table class="table m-0 p-0 table-bordered w-100" >
              <tbody> 
                <tr>
                  <td class="line-low m-0 py-0 text-left">ราคารวม</td>
                  <td class="line-low m-0 py-0 text-right">{{ priceBaht(priceBeforeVat) }} บาท</td>
                </tr>
                <tr>
                  <td class="line-low m-0 py-0 text-left">ส่วนลดตามรายการ</td>
                  <td class="line-low m-0 py-0 text-right">{{ listDataAMT.discplpp }} บาท</td>
                </tr>
                <tr> 
                  <td class="line-low m-0 py-0 text-left">ส่วนลด MKT {{listDataAMT.DISCCODE ? listDataAMT.DISCCODE:''}}</td>
                  <td class="line-low m-0 py-0 text-right">{{ listDataAMT.discmkt }} บาท</td>
                </tr>
                <tr> 
                  <td class="line-low m-0 py-0 text-left">ส่วนลดพนักงาน</td>
                  <td class="line-low m-0 py-0 text-right">{{ showDisStaff }} บาท</td>
                </tr>
                <tr>
                  <td class="line-low m-0 py-0 text-left">VAT</td>
                  <td class="line-low m-0 py-0 text-right">{{ priceBaht(priceVat) }} บาท</td>
                </tr>
                <tr>
                  <td class="line-low m-0 py-0 title-name text-left">ราคารวมสุทธิ</td>
                  <td class="line-low m-0 py-0 title-name text-right">{{ sumNET }} บาท</td>
                </tr>
              </tbody>
            </table> -->
          </div>
        </div>
        <br>
        <div class="row pt-0  border">
          <div class="col-6 mt-1 border-right text-center" >
            <p class="m-0 mt-5">............................................................................</p>
            <p class="m-0">ลงชื่อผู้เสนอราคา</p>
          </div>
          <div class="col-6 mt-1 text-center" >
            <p class="m-0 mt-5">............................................................................</p>
            <p class="m-0">ลงชื่อผู้อนุมัติสั่งซื้อ</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import * as serviceAPI from '@/services/API.service';
  import * as serviceMain from '@/services/main.service';
  import ImageBySKU from '@/components/ImageBySKU.vue';
  import PrintQuotationDetailSkeleton from '@/components/Menu/PrintQuotationDetailSkeleton.vue';
  import printJS from 'print-js'

  export default {
    name: "PrintQuotationDetail",
    data: function () {
      return {
        listData: {
          The1: '',
          custname: '',
          email: '',
          empname: '',
          expired: '',
          expireddate: '',
          mobile: '',
          otp: '',
          qtdate: '',
          salesource: '',
          saleperson: '',
          stname: '',
          userid: '',
          sttname: '',
          stename: '',
          st_thaddr: '',
          st_enaddr: '',
          sttel: '',
        },
        divToPrinttime: '',
        divToPrinthome: '',
        customQT: {
          nameC: '',
          comnameC: '',
          addressC: '',
          idnumC: '',
          phoneC: '',
          faxC: '',
          nameU: '',
          phoneU: '',
          emailU: '',
          the1U: ''
        },
        m0: false,
        m1: false,
        m2: false,
        getStyle:'',
        pickUpText:'',
        priceVat:'',
        priceBeforeVat:'',
        dlvDateShow:'',
        dlvAddrShow:'',
        dlvStatus: false,
        dlvStoreName:'',
        disPrint:false,

      };
    },
    components: {
      ImageBySKU,
      PrintQuotationDetailSkeleton
    },
    props: [
      "cartref",
      "stcode",
      "listDataAMT",
      "listDataOrder",
      "listDataDIS",
      "grouping",
      "groupName",
      "sendTotPrice",
      "buCode",
      "showDisStaff",
      "sumNET",
      "sorderref",
      "dlvType",
      "discount"
    ],
    methods: {

      async goPrint(mode) {
        try {
          this.disPrint = true
          const NUMBER_OF_SPACES = 4;
          // this.checkPickUp()
          this.calVat()
          let data = {
            "REF": this.cartref,
            "STCODE": this.stcode
          }
          let getAPI = await serviceAPI.call_API('post', `history/getQuotationHeader`, data, 'auth');
          this.listData = getAPI.data.dbitems[0];
          this.$emit('getSalesource', this.listData.salesource)
          let dlvCode = this.listData.dlvcode
          let dlvAddr = this.listData.dlvaddr
          let address = JSON.parse(dlvAddr.toString().replace(/\t/g, " ".repeat(NUMBER_OF_SPACES)))
          this.dlvAddrShow = `${address?.company}${address.company?(address.ishq=='Y'?'(สำนักงานใหญ่)':'(สาขา '+address.idbranch+')'):''} ${address?.adrnum} ตึก ${address?.build} ซอย ${address?.soi} แขวง ${address?.subdistrict} เขต ${address?.district} ${address?.province} ${address?.zipcode}`
          this.dlvStoreName = `${address?.SnameTH || ''} ${address?.THADDRESS || ''}`

          if(dlvCode == 'D'){
            this.dlvDateShow = 'วันที่จัดส่ง :';
            this.dlvStatus = true
          }else if (dlvCode == 'P'){
            this.dlvDateShow = 'วันที่รับสินค้า :';
            this.dlvStatus = false
          }
          
          let data1 = {
            "ref": this.cartref
          }
          let getAPI1 = await serviceAPI.call_API('post', `history/ShowEditQuotation`, data1, 'auth');
          let getDataAPI1 = getAPI1.data.dbitems;
          this.customQT = (getAPI1.data.dbrows != 0 && getDataAPI1[0].EDITADDR) && (JSON.parse(getDataAPI1[0].EDITADDR))

          this.m0 = mode == 0
          this.m1 = mode == 1
          this.m2 = mode == 2
          this.getStyle = `
            .title-name{
              font-weight: 900 !important;
              margin-bottom: 0 !important;
              font-size: 1.5rem;
            }
            @font-face {
              font-family: 'kittithadalight45regular';
              src: url('/fonts/kittithadalight45-webfont.woff2') format('woff2'),
                url('/fonts/kittithadalight45-webfont.woff') format('woff');
              font-weight: normal;
              font-style: normal;
            }

            * {
              font-family: 'kittithadalight45regular';
              font-size: 20px;
            }
            .title-little{
              font-size: 1rem !important;
              font-weight: 900 !important;
            }
            .line-low{
              font-size: 1.3rem;
              color: black;
              font-weight: 900 !important;
            }
        
          `;
    
          setTimeout(() => {
            printJS({
              printable: this.buCode == 'at1' ? 'printQuoAT1-' + this.cartref : 'printQuo-' + this.cartref,
              type: 'html',
              maxWidth:1000,
              css: '/css/bt4forprint.css',
              style: this.getStyle,
              font_size:null
            });
            this.disPrint = false
          }, 3000);
          
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      priceBaht(para_price) {
        return para_price.toLocaleString(undefined, {maximumFractionDigits: 2})
      },
      async checkPickUp(){
        let status = this.pickUpStatus
        if(status == 'PickupNow'){
          this.pickUpText = 'รับสินค้าที่สาขาทันที';
        } else if(status == 'Pickup'){
          this.pickUpText = 'รับสินค้าที่สาขาตามเวลา'
        } else if(status == 'Delivery'){
          this.pickUpText = 'จัดส่งสินค้า'
        }
      },
      async calVat(){
        let amt = parseFloat(this.listDataAMT.total.toString().replace(/,/g, ""));
        let beforeVat = amt/1.07
        let vat = amt-beforeVat
        this.priceVat = vat
        this.priceBeforeVat = beforeVat
      }
    },
    computed: {
      getBU: function () {
        return this.$store.getters['customer/getQTBU']
      }
    }
  };
</script>

<style scoped lang="scss">
  .bt {
    background-color: #55246c;
    font-weight: bold;
    color: white;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .print-Div{
    width: 1000px;
  }
</style>