<template>
      <div>
        <slot>
          <div class="p-0 m-0">
            <input id="chkcompare" type="checkbox" class="pointer" @click="addtocompare($event)" v-model="checked" :key="checked"> 
            <label class="fontslotcom px-1 mb-0">เปรียบเทียบ</label>
          </div>
        </slot>
      </div>
</template>


<script>
  import * as serviceAPI from '../services/API.service';
  import * as serviceMain from '../services/main.service';

  export default {
    name: 'AddCompare_Slot',
    data: function () {
      return {
        checked: false,
      }
    },
    mounted() {
      this.checked = this.getCHECKED;
    },
    props: [
      "sku"
    ],
    methods: {

      async addtocompare(e){
        try {
          e.preventDefault();
          let slistsku = this.getCOMPARE.split(',')
          if(slistsku.find(element => element == this.sku)){
            this.removecompare()
            this.checked = false;
          }
          else if(slistsku.length > 3){
            throw new Error('ไม่สามาตรเพิ่มสิ้นค้าเปรียบเทียบได้ กรุณาลบสินค้าก่อนครับ')
          }
          else{
            if(this.getCOMPARE == ''){
              this.$store.dispatch('customer/setQTCOMPARE',this.sku);
            }
            else{
              this.$store.dispatch('customer/setQTCOMPARE',this.getCOMPARE + ',' +this.sku);
            }
            this.checked = true;
          }
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      async removecompare(){
        let slistsku = this.getCOMPARE.split(',')
        let index = slistsku.indexOf(`${this.sku}`);
        if (index > -1) {
          slistsku.splice(index, 1);
        }
        this.$store.dispatch('customer/setQTCOMPARE',slistsku.toString());
      },
      

    },
    computed:{
      getCOMPARE:function(){
        return this.$store.getters['customer/getQTCOMPARE'].replace(/\\/g,'').replace(/"/g,'')
      },
      getCHECKED:function(){
        let slistsku = this.getCOMPARE.split(',')
        if(slistsku.find(element => element == this.sku)){
          return true;
        }
        else{
          return false;
        }
      },
    },
    watch:{
      getCHECKED:function(){
        this.checked = this.getCHECKED;
      }
    }
  }
       
</script>

<style scoped>
  .fontslotcom {
    font-size: 1.2em;
  }
</style>