

//PROD
// export const TOKEN_KEY = "user";
// export const company = "SR_2d79608bda5c48cb97bbebc0ca358c1f";
// export const instance = "RELEASE";
// export const original = "https://eordering.thaiwatsadu.com/Prodboard/";
// export const API_URL = "https://eordering.thaiwatsadu.com/api/";
// export const imageUrl = "https://eordering.thaiwatsadu.com/api/";
// export const paylaterUrl = "https://www.thaiwatsadu.com/th/paylater";

//UAT
// export const TOKEN_KEY = "user";
// export const company = "SR_2d79608bda5c48cb97bbebc0ca358c1f";
// export const instance = "TN0E";
// export const original = "http://10.77.3.11:9999/Prodboard/";
// export const API_URL = "http://10.77.3.11:8888/api/";
// export const imageUrl = "http://10.77.3.11:8888/";
// export const paylaterUrl = "https://uat-www.thaiwatsadu.com/th/paylater";

//LOCALHOST
// export const TOKEN_KEY = "user";
// export const company = "SR_2d79608bda5c48cb97bbebc0ca358c1f";
// export const instance = "1NWD";
// export const original = "http://localhost:8080/Prodboard/";
// export const API_URL = "http://localhost:5000/api/";
// export const imageUrl = "http://localhost:5000/";
// export const paylaterUrl = "https://uat-www.thaiwatsadu.com/th/paylater";
//ENV
export const TOKEN_KEY = process.env.VUE_APP_TOKEN_KEY;
export const company = process.env.VUE_APP_company;
export const instance = process.env.VUE_APP_instance;
export const original = process.env.VUE_APP_original;
export const API_URL = process.env.VUE_APP_API_URL;
export const imageUrl = process.env.VUE_APP_imageUrl;
export const paylaterUrl = process.env.VUE_APP_paylaterUrl;
export const loginUrl = process.env.VUE_APP_Login;

