<template>
  <section>
    <nav class="nav-all sticky-nav">
      <!-- window-mode -->
      <div class="d-none d-sm-block">
        <div :class="`container-fluid p-o m-0 bg-${getBU}`">
          <div class="container-fluid">
            <div class="d-flex justify-content-between">
              <div class="font-PWB-white">
                <span
                  ></span
                >
              </div>
              <div
                v-if="!$store.getters['auth/getISB2B']"
                class="font-PWB-white login"
              >
                <a v-if="!$store.getters['auth/getISTB']" class="nav-top-right top-nav-a pointer" @click="openStore"
                  ><i class="fas fa-map-marker-alt"></i>
                  {{
                    qtStoreName
                      ? `สาขา ${qtStoreName}(${qtDfStore})`
                      : `เลือกสาขา`
                  }}</a
                >
                <!-- login -->
                <span v-if="$store.getters['auth/getloggedIn']">
                  <span v-if="!$store.getters['auth/getISTB']">
                    <span
                      class="nav-top-right pointer"
                      @click="
                        $router.push({
                          name: 'History',
                          params: { whereBy: 'B2B' },
                        })
                      "
                      >{{ $store.getters["auth/getQTUSERID"] }} (
                      {{ getQTSUBSALESOURCE }} )
                      {{ 
                        qtCustInfo.CNAME ? (!$store.getters['auth/getISTB'] ?
                          ` ดูแลคุณ ${qtCustInfo.CNAME}${isStaff} เลขตะกร้า ${qtCustRef}`
                          : `เลขตะกร้า ${qtCustRef}`) : ""
                      }}</span
                    >
                    <span v-if="!$store.getters['auth/getISTB'] && !getWSFLAG"
                      class="nav-top-right pointer"
                      @click="$router.push({ name: 'FindUser' })"
                      >ค้นหาลูกค้า</span
                    >
                    <span @click="logout" class="nav-top-right pointer"
                      >ออกจากระบบ</span
                    >
                  </span>
                  <span v-else>
                    <span class="nav-top-right pointer" @click="$router.push({ name: 'History', params: { whereBy: 'ByUser' },})" >
                      <!-- {{ $store.getters["auth/getQTUSERID"] }} ( {{ getQTSUBSALESOURCE }} ) -->
                      {{ qtCustInfo.CNAME ? `คุณ ${qtCustInfo.CNAME} เลขตะกร้า ${qtCustRef}` : ""}}
                    </span>
                    <span v-if="!$store.getters['auth/getISTB'] && !getWSFLAG"
                      class="nav-top-right pointer"
                      @click="$router.push({ name: 'FindUser' })"
                      >ค้นหาลูกค้า</span
                    >
                    <span @click="logout" class="nav-top-right pointer"
                      >ออกจากระบบ</span
                    >
                  </span>
                </span>
                <!-- logout -->
                <span v-else>
                  <span v-if="!$store.getters['auth/getISTB']"
                    @click="$router.push('/Login')"
                    class="nav-top-right pointer"
                    >เข้าสู่ระบบ</span
                  >
                  <span v-else
                    @click="$router.push('/twdb2b/Login')"
                    class="nav-top-right pointer"
                    >เข้าสู่ระบบ</span
                  >
                </span>
              </div>
              <div v-else class="font-PWB-white login">
                <a class="nav-top-right top-nav-a"
                  ><i class="fas fa-map-marker-alt"></i>
                  {{
                    qtStoreName
                      ? `สาขา ${qtStoreName} (${qtDfStore})`
                      : `เลือกสาขา`
                  }}</a
                >
                <span v-if="$store.getters['auth/getloggedIn']">
                  <span class="nav-top-right"
                    >{{ $store.getters["auth/getB2BFIRSTNAME"] }}
                    {{ $store.getters["auth/getB2BLASTNAME"] }} ({{
                      $store.getters["auth/getB2BSHIPTO"]
                    }}-{{ $store.getters["auth/getB2BCUSTREF"] }})
                  </span>
                </span>
                <!-- <span @click="logout" class="nav-top-right pointer"
                  >ออกจากระบบ</span
                > -->
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid p-o m-0 bg-PWB">
          <div class="container-fluid nav-mid">
            <div v-if="$route.name !== 'B2BFinished' " class="row p-0 pt-2">
              <div v-if="!$store.getters['auth/getISB2B']" class="col-3">
                <router-link to="/">
                  <img
                    :src="`/img/logo.svg`"
                    class="iconBuSize"
                    alt=""
                    height="50"
                  />
                </router-link>
              </div>
              <div v-else class="col-3">
                <router-link to="/ProductB2B">
                <img
                    :src="`'/img/logo.svg'`"
                    class="iconBuSize"
                    alt=""
                    height="50"
                  />
                   </router-link>
              </div>
              <div class="col-7">
                <SearchProduct />
              </div>
              <div class="col-2" v-if="$store.getters['auth/getISTB']">
                <div class="row m-0">
                  <div class="col-6 m-0">
                    <router-link to="/Menu/History/B2B">
                      <img
                        id="orderhistory"
                        src="@/assets/img/order-history.png"
                        class="nav-cart-logo"
                        alt=""
                      />
                    </router-link>
                  </div>
                  <div class="col-6 m-0">
                    <router-link to="/cart">
                      <img
                        id="shopcart"
                        src="@/assets/img/shopping-cart.png"
                        class="nav-cart-logo"
                        alt=""
                      />
                      <span id="shopitem" :class="`countcart bg-${getBU}`">{{
                        qtShopItemLimit || "0"
                      }}</span>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="col-2" v-else>
                <router-link to="/cart">
                  <img
                    id="shopcart"
                    src="@/assets/img/shopping-cart.png"
                    class="nav-cart-logo"
                    alt=""
                  />
                  <span id="shopitem" :class="`countcart bg-${getBU}`">{{
                    qtShopItemLimit || "0"
                  }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!$store.getters['auth/getISB2B'] && !$store.getters['auth/getISTB']" class="container-fluid p-o m-0 bg-deeppurple">
          <div class="container-fluid nav-bottom">
            <div class="row">
              <div
                class="col-3 munu-nav-bottom"
                @click="openCatmenuClick()"
                @mouseover="openCatmenuOver()"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="30"
                  height="30"
                  style="cursor: pointer"
                >
                  <path
                    fill-rule="evenodd"
                    fill="white"
                    d="M1 2.75A.75.75 0 011.75 2h12.5a.75.75 0 110 1.5H1.75A.75.75 0 011 2.75zm0 5A.75.75 0 011.75 7h12.5a.75.75 0 110 1.5H1.75A.75.75 0 011 7.75zM1.75 12a.75.75 0 100 1.5h12.5a.75.75 0 100-1.5H1.75z"
                  ></path>
                </svg>
                <span class="munu-nav-bottom-text" style="cursor: pointer;color: white;"
                  >หมวดหมู่สินค้า</span
                >
              </div>
              <div class="col-9 munu-nav-bottom-navigator p-0" style="white-space: nowrap; overflow-x: auto; scrollbar-width: thin;">
                <span v-if="!$store.getters['auth/getISTB'] && !getWSFLAG"
                  :class="`munu-nav-bottom-navigator-text-${getBU} pointer`" style="color: white;"
                  @click="$router.push({ name: 'FindUser' })"
                  >ค้นหาลูกค้า</span
                >
                <span v-if="qtCustInfo.VIPID != ''"
                  :class="`munu-nav-bottom-navigator-text-${getBU} pointer`" style="color: white;"
                  @click="$router.push({ name: 'Address' })"
                  >จัดการที่อยู่</span
                >
                <!-- <span v-if="getBU == 'at1'"
                  :class="`munu-nav-bottom-navigator-text-${getBU} pointer`" style="color: white;"
                  @click="$router.push({ name: 'OrderAuto1' })"
                  >Order Auto1</span
                >
                <span v-if="getWSFLAG"
                  :class="`munu-nav-bottom-navigator-text-${getBU} pointer`" style="color: white;"
                  @click="$router.push({ name: 'OrderWholeSale' })"
                  >Order WholeSale</span
                >
                <span
                  :class="`munu-nav-bottom-navigator-text-${getBU} pointer`" style="color: white;"
                  @click="openStore"
                  >เลือกสาขา</span
                >
                <span
                  :class="`munu-nav-bottom-navigator-text-${getBU} pointer`" style="color: white;"
                  @click="$router.push({ name: 'Wardrobe' })"
                  >Wardrobe</span
                >
                <span
                  :class="`munu-nav-bottom-navigator-text-${getBU} pointer`" style="color: white;"
                  @click="goVfix()"
                  >ค้นหาสินค้า vFIX</span
                > -->
                <span
                  :class="`munu-nav-bottom-navigator-text-${getBU} pointer`" style="color: white;"
                  @click="$router.push({ name: 'Template' })"
                  >Template</span
                >
                <span
                  :class="`munu-nav-bottom-navigator-text-${getBU} pointer`" style="color: white;"
                  @click="
                    $router.push({
                      name: 'History',
                      params: { whereBy: 'ByUser' },
                    })
                  "
                  >รายการคำสั่งซื้อ</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- mobile-mode -->
      <div class="d-block d-sm-none">
        <div :class="`container-fluid p-0 m-0 bg-${getBU}`">
          <div class="container">
            <div class="d-flex justify-content-center">
              <div class="font-PWB-white">
                <p class="m-0" style="font-size: 5vw"
                  >ศูนย์รวมสินค้าบ้านของคนไทย คลังวัสดุก่อสร้าง
                  ของตกแต่งบ้าน</p>
                  <p class="m-0" style="font-size: 5vw" v-if="$store.getters['auth/getloggedIn']"
                  >{{
                      qtCustInfo.CNAME
                        ? ` ดูแลคุณ ${qtCustInfo.CNAME} เลขตะกร้า ${qtCustRef}`
                        : ""
                    }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid p-0 m-0 bg-PWB">
          <div class="container nav-mid pt-2">
            <div class="row d-flex justify-content-around">
              <div class="col-xs-3 pl-2">
                <div class="row d-flex justify-content-around">
                  <div v-if="!$store.getters['auth/getISB2B'] && !$store.getters['auth/getISTB']" class="col-xs-4 text-center p-0 align-self-center">
                    <a
                      class="btn dropdown p-1"
                      type="button"
                      @click.prevent="toggle"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        width="30"
                        height="30"
                        style="fill: currentColor;"
                      >
                        <path
                          fill-rule="evenodd"
                          fill="white"
                          d="M1 2.75A.75.75 0 011.75 2h12.5a.75.75 0 110 1.5H1.75A.75.75 0 011 2.75zm0 5A.75.75 0 011.75 7h12.5a.75.75 0 110 1.5H1.75A.75.75 0 011 7.75zM1.75 12a.75.75 0 100 1.5h12.5a.75.75 0 100-1.5H1.75z"
                        ></path>
                      </svg>
                    </a>
                  </div>
                  <div class="col-xs-5">
                    <router-link to="/">
                      <img
                        class="mt-1"
                        :src="`${getBU == 'bnb' ? '/img/mini-logo-bnb.svg' : (getBU == 'at1' ? '/img/mini-logo-at1.png' : '/img/mini-logo-twd.png')}`"
                        height="50"
                        alt=""
                      />
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="col-xs-6">
                <SearchProduct class="pr-0" style="max-width: 50vw" />
              </div>
              <div class="col-xs-1">
                <a v-if="!$store.getters['auth/getISTB']"
                  class="btn btn-default btn-bar hidxs mt-1"
                  style="
                    padding-left: 10px;
                    padding-right: 5px;
                  "
                  @click="openStore"
                >
                  <i class="fas fa-map-marker-alt" style="color: #ffffff;"></i>
                </a>
                <router-link v-else to="/Menu/History/B2B">
                  <img
                    id="orderhistory"
                    src="@/assets/img/order-history.png"
                    class="nav-cart-logo"
                    alt=""
                  />
                </router-link>
              </div>
              <div class="col-xs-2 pr-2">
                <router-link to="/cart">
                  <img
                    id="shopcart"
                    src="@/assets/img/shopping-cart.png"
                    class="nav-cart-logo"
                    alt=""
                  />
                  <span id="shopitem" :class="`countcartmobile bg-${getBU}`">{{
                    qtShopItemLimit || "0"
                  }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <Sidebar
      :isPanelOpenS="isPanelOpen"
      @closeSidebarPanelS="closeSidebarPanel()"
      @openStore="openStore()"
    />

    <Catmenu
      @mouseover="openCatmenuOver()"
      @ClickCatmenu="closeCatmenuOver()"
      @mouseleave="closeCatmenuOver()"
      v-if="(isOpenCatmenuOver && isOpenCatmenuClick) || isHome"
    />

    <SelectStore v-if="getloggedIn" />
  </section>
</template>

<script>
  import SelectStore from "@/components/SelectStore.vue";
  import SearchProduct from "@/components/SearchProduct.vue";
  import * as serviceAPI from "../services/API.service";
  import * as serviceMain from "../services/main.service";
  import Sidebar from "@/components/Sidebar.vue";
  import Catmenu from "@/components/categoryMenu.vue";
  export default {
    name: "Navbar",
    data: function() {
      return {
        isLoaded: false,
        isPanelOpen: false,
        isOpenCatmenuOver: false,
        isOpenCatmenuClick: false,
        isHome: false,
      };
    },
    props: ["path"],
    mounted() {
      this.dataStore();
      this.getHome();
    },
    components: {
      SelectStore,
      SearchProduct,
      Sidebar,
      Catmenu,
    },
    methods: {
      toggle() {
        this.isPanelOpen = !this.isPanelOpen;
      },
      closeSidebarPanel() {
        this.isPanelOpen = false;
      },
      async openStore() {
        $("#mapModal").modal("show");
      },
      async logout() {
        this.$store.dispatch("auth/logout");
        this.$store.dispatch("customer/clearAfterLogout");
        this.$store.dispatch("service/clearAfterLogout");
        this.$router.push("/login");
        // console.log('http://localhost:5000/api/eor-chg/logout');
        // this.$router.push("http://localhost:5000/api/eor-chg/logout");
        // window.location.href = `https://eordering.powerbuy.co.th/api/eor-chg/logout`;
      },
      async dataStore() {
        let getData = null;
        if(this.qtUserId){
          if (!this.qtSaveStore) {
            let data = {
              USERID: this.qtUserId,
                SALESOURCE: this.qtSalesource
            };
            let getAPI = await serviceAPI.call_API("post", `service/store`, data, 'auth');
            getData = getAPI.data.dbitems[0].listdata;
            this.qtSaveStore = getData;
          } else {
            getData = this.qtSaveStore;
          }

          let locationsTEMP = getData.split("|");
          let locations = locationsTEMP.map((e) => e.split(","));
          let getQTStoreNow = this.qtDfStore;
          if (getQTStoreNow) {
            let QTStoreName = locations.filter((e) => e[0] == getQTStoreNow);
            let getStoreName = QTStoreName[0][1];
            this.qtStoreName = getStoreName;
          }
        }

        // 920,บางนา,13.6524,100.6767,02-101-5200,07.00,21.00,54 Moo 13   Bangkaew Bang Phli,09.00,19.00,twd
        // let locationsTEMP=getData.split('|');
        // let locations = locationsTEMP.map(e => e.split(','));
        // locations = locations.toString()
        // locationsTEMP.forEach(function(e) {
        //     locations.push(e.split(','));
        //   });
        // console.log(locations)
        // console.log('%c%s', 'color: #00e600', getData)
        // console.log(getData)
      },
      openCatmenuClick() {
        this.isOpenCatmenuClick = !this.isOpenCatmenuClick;
      },
      openCatmenuOver() {
        this.isOpenCatmenuOver = true;
      },
      closeCatmenuOver() {
        this.isOpenCatmenuOver = false;
        this.isOpenCatmenuClick = false;
      },
      getHome() {
        if (this.getPath == "/") {
          this.isHome = true;
        } else {
          this.isHome = false;
        }
      },
      async goVfix(){
        try {
          let getAPI = await serviceAPI.call_API('get',`product/category/96`,{},'auth');
          let getdata = getAPI.data.dbitems;
          if(getdata.length == 0){
            this.$router.push({ name: 'Product', params: { getcatcode: 990000 } })
          }else{
            this.$router.push({ name: 'Category', params: { getcatcode: 96 } })
          }
        } catch (error) {
          serviceMain.showErrorAlert(this,error.message)
        }
      },
    },
    computed: {
      getBU: function() {
        return this.$store.getters["customer/getQTBU"];
      },
      qtCustRef: function() {
        return this.$store.getters["customer/getQTCUSTREF"];
      },
      qtSaveStore: {
        get() {
          return this.$store.getters["service/getQTSAVESTORE"];
        },
        set(value) {
          this.$store.dispatch("service/setQTSAVESTORE", value);
        },
      },
      qtStoreName: {
        get() {
          return this.$store.getters["service/getQTSTORENAME"];
        },
        set(value) {
          this.$store.dispatch("service/setQTSTORENAME", value);
        },
      },
      qtDfStore: function() {
        return this.$store.getters["service/getQTDFSTORE"];
      },
      qtCustInfo: function() {
        return this.$store.getters["customer/getQTCUSTINFO"];
      },
      qtShopItemLimit: function() {
        return this.$store.getters["customer/getQTSHOPITEM_LIMIT"];
      },
      qtUserId: function() {
        return this.$store.getters["auth/getQTUSERID"];
      },
      getQTSUBSALESOURCE: function() {
        return this.$store.getters["auth/getQTSUBSALESOURCE"];
      },
      getPath: function() {
        return this.path;
      },
      getloggedIn: function() {
        return this.$store.getters["auth/getloggedIn"];
      },
      getISB2B: function() {
        return this.$store.getters["auth/getISB2B"];
      },
      qtSalesource: function () {
        return this.$store.getters['auth/getQTSALESOURCE']
      },
      isPro:function(){
          return process.env.NODE_ENV == 'production'
      },
      isStaff:function(){
        if(this.qtCustInfo.ISSTAFF == 'Y') return '(Staff)'
        return ''
      },
      getWSFLAG: function () {
        return this.$store.getters['auth/getWSFLAG']
      },
    },
    watch: {
      getPath: function() {
        this.getHome();
      },
      getloggedIn: function() {
        if(this.getloggedIn){
          this.dataStore();
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  $color-PWB: #55246c;
  $color-bnb: #006e63;
  $color-at1: #3866af;
  .top-nav-a {
    color: white;
  }

  .sticky-nav {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 5;
  }

  .nav-img-logo {
    width: 100%;
  }

  .nav-cart-logo {
    width: 40px;
    margin-top: 8px;
    float: right;
    position: relative;
  }

  .countcart {
    font-size: 15px;
    color: rgb(255, 255, 255);
    background-color: #ed7203 !important;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.025em;
    border-radius: 50%;

    width: 26px;
    height: 26px;
    line-height: 26px;
    position: absolute;
    transition: all 0.2s ease-out;
    top: 1px;
    right: 5px;
  }

  .countcartmobile {
    font-size: calc(2vw+2px);
    color: rgb(255, 255, 255);
    background-color: #ed7203 !important;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.025em;
    border-radius: 50%;

    width: 26px;
    height: 26px;
    line-height: 26px;
    position: absolute;
    transition: all 0.2s ease-out;
    // top: calc(10vw+2px);
    right: 2vw;
  }

  .nav-all {
    border: none;
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 20%);
  }

  .munu-nav-bottom-text {
    padding-left: 10px;
    font-weight: 900;
  }

  .nav-top-right {
    margin: 0px 15px;
  }

  .munu-nav-bottom-navigator {
    padding: 0px 0px 0px 0px;
    max-height: 40px;
    overflow: auto;
  }

  ::-webkit-scrollbar {
    height: 6px;
    border: 2px solid #d5d5d5;
  }

  @mixin munu-nav-bottom-navigator-text($color) {
    font-weight: 900;
    padding: 0px 20px;
    &:hover {
      border-bottom: 5px $color solid;
    }
  }

  .munu-nav-bottom-navigator-text-PWB {
    @include munu-nav-bottom-navigator-text($color-PWB);
  }

  .munu-nav-bottom-navigator-text-bnb {
    @include munu-nav-bottom-navigator-text($color-bnb);
  }

  .munu-nav-bottom-navigator-text-at1 {
    @include munu-nav-bottom-navigator-text($color-at1);
  }

  .loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #55246c;
    border-bottom: 16px solid #55246c;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
  .iconBuSize {
    height: auto;
    width: 80%;
  }
  @media screen and (max-width: 1024px) {
    .iconBuSize {
      height: auto;
      width: 80%;
    }
  }
  @media screen and (max-width: 768px) {
    .iconBuSize {
      height: auto;
      width: 100%;
    }
  }

  @media screen and (max-width: 425px) {
    .iconBuSize {
      height: auto;
      width: 50%;
    }
  }
</style>
