// import AuthService from '../services/auth.service';
import * as main from '../services/main.service';
// import * as constant from '../services/constant';



export const prodboard = {
  namespaced: true,
  state: {
    QTSAVESTORE:main.readCookieEncrypt("QTSAVESTORE"),
    QTtest:''

  },
  actions: {
    setQTSAVESTORE({commit},QTSAVESTORE) {
      main.saveCookieEncrypt("QTSAVESTORE", QTSAVESTORE)
      commit('setQTSAVESTORE',QTSAVESTORE)
    },

    // setChangeAddress({commit}) {
    //   commit('setChangeAddress',Date.now())
    // },
   
  },
  getters: {
    getQTSAVESTORE(state) {
      return state.QTSAVESTORE?state.QTSAVESTORE:'';
    },

    // getQTDFSTORE(state) {
    //   return state.QTDFSTORE?state.QTDFSTORE:'920';
    // },
  },
  mutations: {
    setQTSAVESTORE(state, QTSAVESTORE) {
      state.QTSAVESTORE = QTSAVESTORE;
    },
    // setQTDFSTORE(state, QTDFSTORE) {
    //   state.QTDFSTORE = QTDFSTORE;
    // },
 
  }
};