<template>
    <section>
        <button @click="openModal" type="button" class="btn-PWB-nocolor bg-black font-kitti-medium my-1`">
            <slot>เช็คสต็อก</slot>
        </button>
        <Modal ref="CheckStockRef" size="90">

            <template v-slot:header>
                <p>เช็คสต๊อกสินค้า</p>
            </template>
            <template v-slot:body>
                <div class="row px-3 m-0 mt-3">
                    <div class="col-12 mb-2">
                        <!-- <Multiselect v-model="selected" :options="options" mode="tags" :searchable="true"
                            :createTag="true" placeholder="เลือกสาขา" /> -->
                        <Multiselect v-model="selected"
                            mode="tags"
                            :closeOnSelect="false"
                            :searchable="true"
                            :groups="true"
                            :groupSelect="true"
                            :options="options"
                            placeholder="เลือกสาขา" >
                        <!-- <template v-slot:option="{ option }">
                            <input type="checkbox" id="checkbox" v-model="option.checked"> {{ option.name }}
                        </template> -->
                        </Multiselect>

                        <div class="row m-3" style="min-height:200px">
                            <div class="col-12 card-white" style="text-align:center; overflow:auto;">
                                <table id="StockTable" class="table table-striped table-bordered m-0 p-0"
                                    style="width:100%;">
                                    <thead>
                                        <tr>
                                            <th v-for="(keyItem, index) in keysTemp" :key="index">{{keyItem}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td v-for="(value, index) in valuesTemp" :key="index">{{value}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row m-1" style="justify-content: flex-end;">
                            <button type="button" class="btn btn-primary m-1" style="border: 1px solid blue"
                                @click="selectStore(selected)">ค้นหา</button>
                            <button type="button" class="btn btn-secondary m-1" @click="hideModal"
                                style="border: 1px solid black">ปิด</button>
                        </div>

                    </div>


                </div>

            </template>
        </Modal>

    </section>
</template>


<script>
    import * as serviceAPI from '../services/API.service';
    import * as serviceMain from '../services/main.service';
    import Multiselect from '@vueform/multiselect'
    import Modal from '@/components/Modal.vue'

    import 'jquery/dist/jquery.min.js';
    import "datatables.net-dt/js/dataTables.dataTables"
    import "datatables.net-dt/css/jquery.dataTables.min.css"
    import $ from 'jquery';

    export default {
        name: 'CheckStock',
        data: function () {
            return {
                selected: [],
                options: [],
                listStore: [],
                storeStock: [],
                listData: [],
                customTable: {
                    "order": [
                        [5, "desc"]
                    ],
                    scrollX: true,
                },
                keysTemp: [],
                valuesTemp: []
            }
        },
        components: {
            Multiselect,
            Modal
        },
        props: [
            "sku"
        ],
        methods: {

            async openModal() {
                try {
                    this.$refs.CheckStockRef.showmodal()
                    this.onModal()
                } catch (error) {
                    serviceMain.showErrorAlert(this, error.message)
                }
            },
            async hideModal() {
                try {
                    this.$refs.CheckStockRef.hidemodal()
                } catch (error) {
                    serviceMain.showErrorAlert(this, error.message)
                }
            },
            async onModal() {
                try {
                    //ฟังชั่นทำงานเมื่อเปิด modal ให้โหลดข้อมูล จาก api
                    this.startStore();
                } catch (error) {
                    serviceMain.showErrorAlert(this, error.message)
                }
            },
            async startStore() {
                let getQTSAVESTORE = this.$store.getters['service/getQTSAVESTORE']
                let locationsTEMP = getQTSAVESTORE.split('|');
                let listTemp = locationsTEMP.map(e => e.split(','));

                listTemp.forEach((itm, idx, arr) => {
                    this.listStore = itm.slice(0, 2)
                    console.log(this.listStore);
                    let Temp = `${this.listStore[1]} - ${this.listStore[0]}`;
                    this.options.push(Temp)
                })
            },

            async selectStore(para_selected) {
                para_selected.forEach((itm, idx, arr) => {
                    let dataProc = itm.slice(itm.length - 3, itm.length);
                    this.storeStock.push(dataProc)
                });
                try {
                    let data = {
                        "skcode": this.sku,
                        "stcode": '026',
                        "storelist": this.storeStock
                    }
                    console.log(data);
                    let getAPI = await serviceAPI.call_API('post', `detail/SKUStockList`, data, 'auth');
                    let getData = getAPI.data.dbitems[0]

                    let delArr = Object.keys(getData).filter(e => e.startsWith("POS"));
                    delArr.every(e => delete getData[e]);
                    
                    this.listData = getData;
                    let header = Object.keys(this.listData)
                    let valuetable = Object.values(this.listData)
                    this.keysTemp = header;
                    this.valuesTemp = valuetable;

                } catch (error) {
                    serviceMain.showErrorAlert(this, error.message)
                }

            },
            async setDataTable() {
                $('#stockTable').DataTable(this.customTable);
            }
        },
        computed: {
            getQTSAVESTORE: function () {
                return this.$store.getters['service/getQTSAVESTORE']
            },
        },
        watch: {
            getQTSAVESTORE: function () {
                this.startStore()
            },
            valuesTemp: function () {
                this.$nextTick(() => {
                    this.setDataTable();
                })
            }
        }
    }
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
    table.dataTable tbody th,
    table.dataTable tbody td {
        padding: 8px 10px;
        min-width: 150px;
    }
</style>
