// import AuthService from '../services/auth.service';
import * as main from '../services/main.service';
export const b2b = {
  namespaced: true,
  state: {
    B2BCartOrder:main.readCookieEncrypt("B2BCartOrder"),
  },
  actions: {
    setB2BCartOrder({commit},B2BCartOrder) {
      let B2BCookie = JSON.stringify(B2BCartOrder)
      main.saveCookieEncrypt("B2BCartOrder",B2BCookie )
      commit('setB2BCartOrder',B2BCookie)
    },
  },
  getters: {
    getB2BCartOrder(state) {
      return state.B2BCartOrder?JSON.parse(state.B2BCartOrder):"";
    },
  },
  mutations: {
    setB2BCartOrder(state, B2BCartOrder) {
      state.B2BCartOrder = B2BCartOrder;
    },
  }
};