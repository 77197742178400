<template>
    <section class="row" v-if="haveBundel" :key="sku">
        <div class="col-12" >
            <ProdBundleCard :cardData="firstBundel" :skuMain="sku"/>
        </div>
        <div class="col-12" v-if="haveMoreBundel">
            <div class="row">
                <div :class="`col-12 p-2 text-center bg-${qtBu} prodbundle my-2`" v-if="!showMore">
                    <span>
                        <a style="color: white;text-decoration: none;font-weight: bolder;cursor: pointer;" @click="this.showMore = true">
                            <div id="clicktoshowprop-t" class="">
                                <i class='fa fa-chevron-down' aria-hidden='true'></i> แสดงชุดสุดคุ้มอื่นๆ <i class='fa fa-chevron-down' aria-hidden='true'></i>
                            </div>
                        </a>
                    </span>
                </div>
                <div class="w-100" v-if="showMore">
                    <div class="col-12 my-2" v-for="(e, index) in moreBundel" :key="index">
                        <ProdBundleCard :cardData="e" :skuMain="sku" />
                    </div>
                    <div :class="`col-12 p-2 text-center bg-${qtBu} prodbundle my-2`">
                        <span>
                            <a style="color: white;text-decoration: none;font-weight: bolder;cursor: pointer;" @click="this.showMore = false">
                                <div id="clicktoshowprop-t" class="">
                                    <i class='fa fa-chevron-up' aria-hidden='true'></i> ปิด <i class='fa fa-chevron-up' aria-hidden='true'></i>
                                </div>
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import * as serviceAPI from '../services/API.service';
import * as serviceMain from '../services/main.service';
import AddToCart from '@/components/AddToCart_Slot.vue';
import ProdBundleCard from '@/components/ProdBundleCard.vue';

export default {
    name:'ProdBundle',
    components:{
        AddToCart,
        ProdBundleCard
    },
    data() {
        return {
            listData: [],
            showMore: false,
            skc:'',
        }
    },
    props:[
        'sku'
    ],
    mounted() {
        if(this.sku)
        {
            this.skc = this.sku
            this.getProdBundle();
        }
    },
    methods: {
        async getProdBundle() {
        try {
          let data = {
            SKCODE: this.sku,
            STCODE: this.qtStcode
          }
          let getAPI = await serviceAPI.call_API('post', `product/getprodbundle`, data, 'auth');
          this.listData = getAPI.data.dbitems;
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      clicktoshow(){
          this.showMore = true;
      }
    },
    computed:{
        qtBu: function () {
            return this.$store.getters['customer/getQTBU'];
        },
        qtStcode: function () {
            return this.$store.getters['service/getQTDFSTORE']  || '026';
        },
        bundelList: function () {
            let bundelLists = [];
            let tempList = [];
            let getSetId = [];
            for (let i = 0; i < this.listData.length; i++) {
                tempList[this.listData[i].SETID] = 'have';
            }
            for (let j = 0; j < tempList.length; j++) {
                if(tempList[j]=='have')
                    getSetId.push(j)
            }
            for (let p = 0; p < getSetId.length; p++) {
                let gropBundel = []
                for (let q = 0; q < this.listData.length; q++) {
                    if(getSetId[p] == this.listData[q].SETID){
                        gropBundel.push(this.listData[q]);
                    }
                }
                bundelLists.push(gropBundel);
            }

            return bundelLists;
        },
        firstBundel: function () {
            return this.bundelList[0];
        },
        moreBundel: function () {
            let mList = [];
            if(this.bundelList.length > 1){
                for (let i = 1; i < this.bundelList.length; i++) {
                    mList.push(this.bundelList[i]);
                }
            }
            return mList;
        },
        haveBundel: function () {
            return this.bundelList.length > 0 ? true : false
        },
        haveMoreBundel: function () {
            return this.bundelList.length > 1 ? true : false
        }
    },
    watch:{
        qtStcode: function () {
            this.getProdBundle();
        }
    }


}
</script>

<style scope>
.prodbundle {
    padding: 20px;
    border-radius: 15px;
}
</style>