<template>
  <div>
    <div class="row" v-if="shape == 1">
      <!-- whit,oak,walnut -->
      <!-- i1 -->
      <ProdboardShapeListComp
        :sizeShape="`สูง 200 ซม. กว้าง 120 ซม.`"
        :whiteNameShape="`I-Shape SET 1WH`"
        :WhitePrice="`฿ 11,327.00`"
        :whiteShapeId="`333`"
        :whiteImageShape="`/img/prodboard/i_shape/i1/white.JPG`"
        :oakNameShape="`I-Shape SET 1OA`"
        :oakPrice="`฿ 11,327.00`"
        :oakShapeId="`335`"
        :oakImageShape="`/img/prodboard/i_shape/i1/oak.JPG`"
        :walnutNameShape="`I-Shape SET 1WL`"
        :walnutPrice="`฿ 11,327.00`"
        :walnutShapeId="`334`"
        :walnutImageShape="`/img/prodboard/i_shape/i1/walnut.JPG`"
      />
      <!-- i2 -->
      <ProdboardShapeListComp
        :sizeShape="`สูง 200 ซม. กว้าง 150 ซม.`"
        :whiteNameShape="`I-Shape SET 2WH`"
        :WhitePrice="`฿ 13,456.35`"
        :whiteShapeId="`194`"
        :whiteImageShape="`/img/prodboard/i_shape/i2/white.JPG`"
        :oakNameShape="`I-Shape SET 2OA`"
        :oakPrice="`฿ 13,456.35`"
        :oakShapeId="`325`"
        :oakImageShape="`/img/prodboard/i_shape/i2/oak.JPG`"
        :walnutNameShape="`I-Shape SET 2WL`"
        :walnutPrice="`฿ 13,456.35`"
        :walnutShapeId="`326`"
        :walnutImageShape="`/img/prodboard/i_shape/i2/walnut.JPG`"
      />
      <!-- i3 -->
      <ProdboardShapeListComp
        :sizeShape="`สูง 200 ซม. กว้าง 120 ซม.`"
        :whiteNameShape="`I-Shape SET 3WH`"
        :WhitePrice="`฿ 13,868.60`"
        :whiteShapeId="`322`"
        :whiteImageShape="`/img/prodboard/i_shape/i3/white.JPG`"
        :oakNameShape="`I-Shape SET 3OA`"
        :oakPrice="`฿ 13,868.60`"
        :oakShapeId="`321`"
        :oakImageShape="`/img/prodboard/i_shape/i3/oak.JPG`"
        :walnutNameShape="`I-Shape SET 3WL`"
        :walnutPrice="`฿ 13,868.60`"
        :walnutShapeId="`246`"
        :walnutImageShape="`/img/prodboard/i_shape/i3/walnut.JPG`"
      />
      <!-- i4 -->
      <ProdboardShapeListComp
        :sizeShape="`สูง 200 ซม. กว้าง 200 ซม.`"
        :whiteNameShape="`I-Shape SET 4WH`"
        :WhitePrice="`฿ 15,972.00`"
        :whiteShapeId="`302`"
        :whiteImageShape="`/img/prodboard/i_shape/i4/white.JPG`"
        :oakNameShape="`I-Shape SET 4OA`"
        :oakPrice="`฿ 15,972.00`"
        :oakShapeId="`301`"
        :oakImageShape="`/img/prodboard/i_shape/i4/oak.JPG`"
        :walnutNameShape="`I-Shape SET 4WL`"
        :walnutPrice="`฿ 15,972.00`"
        :walnutShapeId="`304`"
        :walnutImageShape="`/img/prodboard/i_shape/i4/walnut.JPG`"
      />
      <!-- i5 -->
      <ProdboardShapeListComp
        :sizeShape="`สูง 210 ซม. กว้าง 225 ซม.`"
        :whiteNameShape="`I-Shape SET 5WH`"
        :WhitePrice="`฿ 19,944.00`"
        :whiteShapeId="`105`"
        :whiteImageShape="`/img/prodboard/i_shape/i5/white.JPG`"
        :oakNameShape="`I-Shape SET 5OA`"
        :oakPrice="`฿ 19,944.00`"
        :oakShapeId="`375`"
        :oakImageShape="`/img/prodboard/i_shape/i5/oak.JPG`"
        :walnutNameShape="`I-Shape SET 5WL`"
        :walnutPrice="`฿ 19,944.00`"
        :walnutShapeId="`376`"
        :walnutImageShape="`/img/prodboard/i_shape/i5/walnut.JPG`"
      />
      <!-- i6 -->
      <ProdboardShapeListComp
        :sizeShape="`สูง 225 ซม. กว้าง 180 ซม.`"
        :whiteNameShape="`I-Shape SET 6WH`"
        :WhitePrice="`฿ 20,810.00`"
        :whiteShapeId="`404`"
        :whiteImageShape="`/img/prodboard/i_shape/i6/white.JPG`"
        :oakNameShape="`I-Shape SET 6OA`"
        :oakPrice="`฿ 20,810.00`"
        :oakShapeId="`384`"
        :oakImageShape="`/img/prodboard/i_shape/i6/oak.JPG`"
        :walnutNameShape="`I-Shape SET 6WL`"
        :walnutPrice="`฿ 20,810.00`"
        :walnutShapeId="`383`"
        :walnutImageShape="`/img/prodboard/i_shape/i6/walnut.JPG`"
      />
      <!-- i7 -->
      <ProdboardShapeListComp
        :sizeShape="`สูง 200 ซม. กว้าง 180 ซม.`"
        :whiteNameShape="`I-Shape SET 7WH`"
        :WhitePrice="`฿ 26,784.00`"
        :whiteShapeId="`306`"
        :whiteImageShape="`/img/prodboard/i_shape/i7/white.JPG`"
        :oakNameShape="`I-Shape SET 7OA`"
        :oakPrice="`฿ 26,784.00`"
        :oakShapeId="`305`"
        :oakImageShape="`/img/prodboard/i_shape/i7/oak.JPG`"
        :walnutNameShape="`I-Shape SET 7WL`"
        :walnutPrice="`฿ 26,784.00`"
        :walnutShapeId="`179`"
        :walnutImageShape="`/img/prodboard/i_shape/i7/walnut.JPG`"
      />
      <!-- i8 -->
      <ProdboardShapeListComp
        :sizeShape="`สูง 240 ซม. กว้าง 225 ซม.`"
        :whiteNameShape="`I-Shape SET 8WH`"
        :WhitePrice="`฿ 28,554.00`"
        :whiteShapeId="`186`"
        :whiteImageShape="`/img/prodboard/i_shape/i8/white.JPG`"
        :oakNameShape="`I-Shape SET 8OA`"
        :oakPrice="`฿ 28,554.00`"
        :oakShapeId="`401`"
        :oakImageShape="`/img/prodboard/i_shape/i8/oak.JPG`"
        :walnutNameShape="`I-Shape SET 8WL`"
        :walnutPrice="`฿ 28,554.00`"
        :walnutShapeId="`402`"
        :walnutImageShape="`/img/prodboard/i_shape/i8/walnut.JPG`"
      />
      <!-- i9 -->
      <ProdboardShapeListComp
        :sizeShape="`สูง 300 ซม. กว้าง 225 ซม.`"
        :whiteNameShape="`I-Shape SET 9WH`"
        :WhitePrice="`฿ 33,564.00`"
        :whiteShapeId="`363`"
        :whiteImageShape="`/img/prodboard/i_shape/i9/white.JPG`"
        :oakNameShape="`I-Shape SET 9OA`"
        :oakPrice="`฿ 33,564.00`"
        :oakShapeId="`329`"
        :oakImageShape="`/img/prodboard/i_shape/i9/oak.JPG`"
        :walnutNameShape="`I-Shape SET 9WL`"
        :walnutPrice="`฿ 33,564.00`"
        :walnutShapeId="`364`"
        :walnutImageShape="`/img/prodboard/i_shape/i9/walnut.JPG`"
      />
      <!-- i10 -->
      <ProdboardShapeListComp
        :sizeShape="`สูง 300 ซม. กว้าง 225 ซม.`"
        :whiteNameShape="`I-Shape SET 10WH`"
        :WhitePrice="`฿ 33,564.00`"
        :whiteShapeId="`134`"
        :whiteImageShape="`/img/prodboard/i_shape/i10/white.JPG`"
        :oakNameShape="`I-Shape SET 10OA`"
        :oakPrice="`฿ 33,564.00`"
        :oakShapeId="`400`"
        :oakImageShape="`/img/prodboard/i_shape/i10/oak.JPG`"
        :walnutNameShape="`I-Shape SET 10WL`"
        :walnutPrice="`฿ 33,564.00`"
        :walnutShapeId="`379`"
        :walnutImageShape="`/img/prodboard/i_shape/i10/walnut.JPG`"
      />
    </div>
    <div class="row" v-if="shape == 2">
      <!-- l1 -->
      <ProdboardShapeListComp
        :sizeShape="`สูง 366 ซม. กว้าง 225 ซม.`"
        :whiteNameShape="`L-Shape SET 1WH`"
        :WhitePrice="`฿ 23,673.00`"
        :whiteShapeId="`372`"
        :whiteImageShape="`/img/prodboard/l_shape/l1/white.JPG`"
        :oakNameShape="`L-Shape SET 1OA`"
        :oakPrice="`฿ 23,673.00`"
        :oakShapeId="`374`"
        :oakImageShape="`/img/prodboard/l_shape/l1/oak.JPG`"
        :walnutNameShape="`L-Shape SET 1WL`"
        :walnutPrice="`฿ 23,673.00`"
        :walnutShapeId="`373`"
        :walnutImageShape="`/img/prodboard/l_shape/l1/walnut.JPG`"
      />
      <!-- l2 -->
      <ProdboardShapeListComp
        :sizeShape="`สูง 213 ซม. กว้าง 200 ซม.`"
        :whiteNameShape="`L-Shape SET 2WH`"
        :WhitePrice="`฿ 23,747.00`"
        :whiteShapeId="`195`"
        :whiteImageShape="`/img/prodboard/l_shape/l2/white.JPG`"
        :oakNameShape="`L-Shape SET 2OA`"
        :oakPrice="`฿ 23,747.00`"
        :oakShapeId="`327`"
        :oakImageShape="`/img/prodboard/l_shape/l2/oak.JPG`"
        :walnutNameShape="`L-Shape SET 2WL`"
        :walnutPrice="`฿ 23,747.00`"
        :walnutShapeId="`328`"
        :walnutImageShape="`/img/prodboard/l_shape/l2/walnut.JPG`"
      />
      <!-- l3 -->
      <ProdboardShapeListComp
        :sizeShape="`สูง 213 ซม. กว้าง 200 ซม.`"
        :whiteNameShape="`L-Shape SET 3WH`"
        :WhitePrice="`฿ 24,982.00`"
        :whiteShapeId="`355`"
        :whiteImageShape="`/img/prodboard/l_shape/l3/white.JPG`"
        :oakNameShape="`L-Shape SET 3OA`"
        :oakPrice="`฿ 24,982.00`"
        :oakShapeId="`357`"
        :oakImageShape="`/img/prodboard/l_shape/l3/oak.JPG`"
        :walnutNameShape="`L-Shape SET 3WL`"
        :walnutPrice="`฿ 24,982.00`"
        :walnutShapeId="`356`"
        :walnutImageShape="`/img/prodboard/l_shape/l3/walnut.JPG`"
      />
      <!-- l4 -->
      <ProdboardShapeListComp
        :sizeShape="`สูง 396 ซม. กว้าง 225 ซม.`"
        :whiteNameShape="`L-Shape SET 4WH`"
        :WhitePrice="`฿ 28,276.80`"
        :whiteShapeId="`405`"
        :whiteImageShape="`/img/prodboard/l_shape/l4/white.JPG`"

        :oakNameShape="`L-Shape SET 4OA`"
        :oakPrice="`฿ 28,276.80`"
        :oakShapeId="`407`"
        :oakImageShape="`/img/prodboard/l_shape/l4/oak.JPG`"

        :walnutNameShape="`L-Shape SET 4WL`"
        :walnutPrice="`฿ 28,276.80`"
        :walnutShapeId="`406`"
        :walnutImageShape="`/img/prodboard/l_shape/l4/walnut.JPG`"
      />
      <!-- l5 -->
      <ProdboardShapeListComp
        :sizeShape="`สูง 213 ซม. กว้าง 200 ซม.`"
        :whiteNameShape="`L-Shape SET 5WH`"
        :WhitePrice="`฿ 31,686.00`"
        :whiteShapeId="`357`"
        :whiteImageShape="`/img/prodboard/l_shape/l5/white.JPG`"

        :oakNameShape="`L-Shape SET 5OA`"
        :oakPrice="`฿ 31,686.00`"
        :oakShapeId="`386`"
        :oakImageShape="`/img/prodboard/l_shape/l5/oak.JPG`"

        :walnutNameShape="`L-Shape SET 5WL`"
        :walnutPrice="`฿ 31,686.00`"
        :walnutShapeId="`387`"
        :walnutImageShape="`/img/prodboard/l_shape/l5/walnut.JPG`"
      />
      <!-- l6 -->
      <ProdboardShapeListComp
        :sizeShape="`สูง 243 ซม. กว้าง 225 ซม.`"
        :whiteNameShape="`L-Shape SET 6WH`"
        :WhitePrice="`฿ 32,970.00`"
        :whiteShapeId="`271`"
        :whiteImageShape="`/img/prodboard/l_shape/l6/white.JPG`"

        :oakNameShape="`L-Shape SET 6OA`"
        :oakPrice="`฿ 32,970.00`"
        :oakShapeId="`388`"
        :oakImageShape="`/img/prodboard/l_shape/l6/oak.JPG`"

        :walnutNameShape="`L-Shape SET 6WL`"
        :walnutPrice="`฿ 32,970.00`"
        :walnutShapeId="`389`"
        :walnutImageShape="`/img/prodboard/l_shape/l6/walnut.JPG`"
      />
      <!-- l7 -->
      <ProdboardShapeListComp
        :sizeShape="`สูง 273 ซม. กว้าง 200 ซม.`"
        :whiteNameShape="`L-Shape SET 7WH`"
        :WhitePrice="`฿ 36,777.60`"
        :whiteShapeId="`202`"
        :whiteImageShape="`/img/prodboard/l_shape/l7/white.JPG`"

        :oakNameShape="`L-Shape SET 7OA`"
        :oakPrice="`฿ 36,074.40`"
        :oakShapeId="`393`"
        :oakImageShape="`/img/prodboard/l_shape/l7/oak.JPG`"

        :walnutNameShape="`L-Shape SET 7WL`"
        :walnutPrice="`฿ 36,777.60`"
        :walnutShapeId="`391`"
        :walnutImageShape="`/img/prodboard/l_shape/l7/walnut.JPG`"
      />
      <!-- l8 -->
      <ProdboardShapeListComp
        :sizeShape="`สูง 243 ซม. กว้าง 200 ซม.`"
        :whiteNameShape="`L-Shape SET 8WH`"
        :WhitePrice="`฿ 38,242.00`"
        :whiteShapeId="`365`"
        :whiteImageShape="`/img/prodboard/l_shape/l8/white.JPG`"

        :oakNameShape="`L-Shape SET 8OA`"
        :oakPrice="`฿ 38,242.00`"
        :oakShapeId="`394`"
        :oakImageShape="`/img/prodboard/l_shape/l8/oak.JPG`"

        :walnutNameShape="`L-Shape SET 8WL`"
        :walnutPrice="`฿ 31,202.00`"
        :walnutShapeId="`395`"
        :walnutImageShape="`/img/prodboard/l_shape/l8/walnut.JPG`"
      />
      <!-- l9 -->
      <ProdboardShapeListComp
        :sizeShape="`สูง 273 ซม. กว้าง 200 ซม.`"
        :whiteNameShape="`L-Shape SET 9WH`"
        :WhitePrice="`฿ 42,198.00`"
        :whiteShapeId="`307`"
        :whiteImageShape="`/img/prodboard/l_shape/l9/white.JPG`"

        :oakNameShape="`L-Shape SET 9OA`"
        :oakPrice="`฿ 42,198.00`"
        :oakShapeId="`309`"
        :oakImageShape="`/img/prodboard/l_shape/l9/oak.JPG`"

        :walnutNameShape="`L-Shape SET 9WL`"
        :walnutPrice="`฿ 42,198.00`"
        :walnutShapeId="`190`"
        :walnutImageShape="`/img/prodboard/l_shape/l9/walnut.JPG`"
      />
      
    </div>
    <div class="row" v-if="shape == 3">
      <!-- u1 -->
      <ProdboardShapeListComp
        :sizeShape="`สูง 486 ซม. กว้าง 200 ซม.`"
        :whiteNameShape="`U-Shape SET 1WH`"
        :WhitePrice="`฿ 36,944.00`"
        :whiteShapeId="`330`"
        :whiteImageShape="`/img/prodboard/u_shape/u3/white.JPG`"

        :oakNameShape="`U-Shape SET 1OA`"
        :oakPrice="`฿ 36,944.00`"
        :oakShapeId="`331`"
        :oakImageShape="`/img/prodboard/u_shape/u3/oak.JPG`"

        :walnutNameShape="`U-Shape SET 1WL`"
        :walnutPrice="`฿ 36,944.00`"
        :walnutShapeId="`332`"
        :walnutImageShape="`/img/prodboard/u_shape/u3/walnut.JPG`"
      />
      <!-- u2 -->
      <ProdboardShapeListComp
        :sizeShape="`สูง 287 ซม. กว้าง 200 ซม.`"
        :whiteNameShape="`U-Shape SET 2WH`"
        :WhitePrice="`฿ 38,206.00`"
        :whiteShapeId="`312`"
        :whiteImageShape="`/img/prodboard/u_shape/u1/white.JPG`"

        :oakNameShape="`U-Shape SET 2OA`"
        :oakPrice="`฿ 38,206.00`"
        :oakShapeId="`310`"
        :oakImageShape="`/img/prodboard/u_shape/u1/oak.JPG`"

        :walnutNameShape="`U-Shape SET 2WL`"
        :walnutPrice="`฿ 38,206.00`"
        :walnutShapeId="`304`"
        :walnutImageShape="`/img/prodboard/u_shape/u1/walnut.JPG`"
      />
      <!-- u3 -->
      <ProdboardShapeListComp
        :sizeShape="`สูง 546 ซม. กว้าง 200 ซม.`"
        :whiteNameShape="`U-Shape SET 3WH`"
        :WhitePrice="`฿ 45,458.00`"
        :whiteShapeId="`336`"
        :whiteImageShape="`/img/prodboard/u_shape/u2/white.JPG`"

        :oakNameShape="`U-Shape SET 3OA`"
        :oakPrice="`฿ 45,458.00`"
        :oakShapeId="`338`"
        :oakImageShape="`/img/prodboard/u_shape/u2/oak.JPG`"

        :walnutNameShape="`U-Shape SET 3WL`"
        :walnutPrice="`฿ 45,458.00`"
        :walnutShapeId="`337`"
        :walnutImageShape="`/img/prodboard/u_shape/u2/walnut.JPG`"
      />
      <!-- u4 -->
      <ProdboardShapeListComp
        :sizeShape="`สูง 606 ซม. กว้าง 200 ซม.`"
        :whiteNameShape="`U-Shape SET 4WH`"
        :WhitePrice="`฿ 49,362.00`"
        :whiteShapeId="`408`"
        :whiteImageShape="`/img/prodboard/u_shape/u4/white.JPG`"

        :oakNameShape="`U-Shape SET 4OA`"
        :oakPrice="`฿ 49,362.00`"
        :oakShapeId="`412`"
        :oakImageShape="`/img/prodboard/u_shape/u4/oak.JPG`"

        :walnutNameShape="`U-Shape SET 4WL`"
        :walnutPrice="`฿ 49,362.00`"
        :walnutShapeId="`411`"
        :walnutImageShape="`/img/prodboard/u_shape/u4/walnut.JPG`"
      />
      <!-- u5 -->
      <ProdboardShapeListComp
        :sizeShape="`สูง 546 ซม. กว้าง 225 ซม.`"
        :whiteNameShape="`U-Shape SET 5WH`"
        :WhitePrice="`฿ 54,379.00`"
        :whiteShapeId="`357`"
        :whiteImageShape="`/img/prodboard/u_shape/u5/white.JPG`"

        :oakNameShape="`U-Shape SET 5OA`"
        :oakPrice="`฿ 54,379.00`"
        :oakShapeId="`397`"
        :oakImageShape="`/img/prodboard/u_shape/u5/oak.JPG`"

        :walnutNameShape="`U-Shape SET 5WL`"
        :walnutPrice="`฿ 54,379.00`"
        :walnutShapeId="`396`"
        :walnutImageShape="`/img/prodboard/u_shape/u5/walnut.JPG`"
      />
      <!-- u6 -->
      <ProdboardShapeListComp
        :sizeShape="`สูง 546 ซม. กว้าง 225 ซม.`"
        :whiteNameShape="`U-Shape SET 6WH`"
        :WhitePrice="`฿ 62,199.00`"
        :whiteShapeId="`351`"
        :whiteImageShape="`/img/prodboard/u_shape/u6/white.JPG`"

        :oakNameShape="`U-Shape SET 6OA`"
        :oakPrice="`฿ 62,199.00`"
        :oakShapeId="`398`"
        :oakImageShape="`/img/prodboard/u_shape/u6/oak.JPG`"

        :walnutNameShape="`U-Shape SET 6WL`"
        :walnutPrice="`฿ 62,199.00`"
        :walnutShapeId="`399`"
        :walnutImageShape="`/img/prodboard/u_shape/u6/walnut.JPG`"
      />
    </div>
  </div>
</template>

<script>
  import ProdboardShapeListComp from "@/components/Prodboard/ProdboardShapeListComp.vue";
  export default {
    name: "ProdboardShapeListPageComp",
    data: function() {
      return {
        shape: "",
      };
    },
    components: {
      ProdboardShapeListComp,
    },
    mounted() {
      this.checkShape();
    },
    props: ["prodShape"],
    methods: {
      async checkShape() {
        try {
          let prodShape = this.prodShape;
          if (prodShape == "i-shape") {
            this.shape = 1;
          } else if (prodShape == "l-shape") {
            this.shape = 2;
          } else if (prodShape == "u-shape") {
            this.shape = 3;
          } else {
            this.$router.push({ name: "Wardrobe" });
          }
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
    },
    computed: {
      getStore: function() {
        return this.$store.getters["service/getQTDFSTORE"];
      },
    },
    watch: {},
  };
</script>

<style scope></style>
