<template>
    <section>
        <div class="shadowDiv" style='overflow:hidden;'>
            <div class="row m-3">
                <div class="col-12 col-md-4">
                    <p><span class="skeleton-box w-100" style="height: 30px"></span></p>
                </div>
                <div class="col-12 col-md-4">
                    <p><span class="skeleton-box w-100" style="height: 30px"></span></p>
                </div>
                <div class="col-12 col-md-4">
                    <p><span class="skeleton-box w-100" style="height: 30px"></span></p>
                </div>
            </div>
        </div>
        
    </section>
</template>

<script>
export default {
    name: 'PrintQuotationDetailSkeleton'
}
</script>

<style scoped>
    .shadowDiv {
        margin: 0px;
        background-color: white;
        padding: 10px;
        border-radius: 7px;
        height: auto;
    }
    .shadowDiv:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }
</style>