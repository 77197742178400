<template>
  <div class="row px-2 mt-md-ne">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <p style="font-size:20; margin:0px;">
            ราคา:
            <label style="font-size:20; margin:0px;" id="lbprice">
              <b
                id="getprice"
                style="margin:0px; font-size:26;font-family: 'markerfeltwide-plainregular'; color:#55246c;"
                :class="`color-${getBu}`"
              >
                {{ listDataP.PRICE }}</b
              >
              Baht
            </label>
          </p>
        </div>
        <div class="col-12">
          <p style="margin:0px;">
            <del>{{ getDisc }}</del>
          </p>
        </div>
      </div>
      <hr style="margin:.5em 0 .5em 0" />

      <div class="row p-0 m-0">
        <div class="col-6 col-md-6 col-lg-4 text-right">
          <span style="font-weight:bolder;">จำนวน </span>
        </div>
        <div class="col-6 col-md-6 col-lg-8 text-left">
          <span class="number">
            <button
              class="pdpminus text-center"
              @click="qnt--"
              :disabled="qnt == 1"
            >
              -
            </button>
            <input id="qtypdp" class="qtypdpinput" type="text" v-model="qnt" />
            <button class="pdpplus text-center" @click="qnt++">+</button>
          </span>
        </div>
      </div>
      <hr style="margin:.5em 0 .5em 0" />
      <div class="row p-0 m-0">
        <div class="col-12 p-0">
          <AddToCart
            :class="`bg-${getBu} buttoncart w-100  text-center`"
            :sku="sku"
            :qnt="qnt"
            >ใส่รถเข็น</AddToCart
          >
        </div>
      </div>
      <div>
        <hr style="margin:.5em 0 .5em 0" />
      </div>
      <div v-if="!$store.getters['auth/getISB2B']">
        <div class="row d-flex justify-content-center">
          <div class="col-12">
            <CheckStock class="mt-1 " :sku="sku" />
          </div>
          <div class="col-12 d-flex justify-content-center">
            <AddToCompare :sku="sku"></AddToCompare>
          </div>
        </div>
        <hr style="margin:0em 0 .5em 0" />
      </div>
      <!-- <span class="typeplpp"><img style="height:25px;" src="../images/logo/promotion.png"><b style="font-family: 'kittithadamedium65regular';"> แนะนำโปรโมชั่น</b></span> -->
      <p v-if="!$store.getters['auth/getISTB']"
        class="typeplpp"
        style=" overflow-y: auto; padding:3px; margin-top:5px;margin-bottom:0; background-color: rgb(235,235, 235); border-radius: 0px; font-size:20px; "
      >
        <!---*#plpp
        *---><span id="noplpp">{{ listDataPlpp.PM }}</span>
        <!-- <span>{{ sku }}</span> -->
      </p>
      <p v-if="!$store.getters['auth/getISTB']"
        class="typeplpp"
        style=" overflow: none; padding:3px; background-color: rgb(235,235, 235); font-size:20px; font-weight:bolder; "
      >
        กรณีมีของแถมหรือซื้อเป็นเซ็ตแล้วลดเพิ่ม
        ระบบจะปรับยอดรวมโดยอัตโนมัติเมื่อมีการเพิ่มของแถมหรือสินค้าที่ร่วมรายการเข้าไปในตะกร้า
      </p>
    </div>
  </div>
</template>

<script>
  import * as serviceAPI from "../services/API.service";
  import * as serviceMain from "../services/main.service";
  import AddToCart from "@/components/AddToCart_Slot.vue";
  import AddToCompare from "@/components/AddCompare_Slot.vue";
  import CheckStock from "@/components/CheckStock.vue";
  export default {
    name: "SideProdDetail",
    components: {
      AddToCart,
      CheckStock,
      AddToCompare,
    },
    props: ["sku", "price"],
    data() {
      return {
        qnt: "1",
        listData: [],
        listDataP: [],
        listDataPlpp: [],
      };
    },
    mounted() {
      if (this.sku) {
        this.getPrice();
        this.checkSkcPlpp();
      }
    },
    methods: {
      async getPrice() {
        try {
          let getAPI = "";
          if (!this.$store.getters["auth/getISB2B"]) {
            let data = {
              SKCODE: this.sku,
              STCODE: this.getStore,
            };
            getAPI = await serviceAPI.call_API(
              "post",
              `product/getskprice`,
              data,
              "auth"
            );
          } else {
            let data = {
              SKCODE: this.sku,
              STCODE: this.getStore,
              UUID: this.getUUID,
            };
            getAPI = await serviceAPI.call_API(
              "post",
              `b2b/product/getskprice`,
              data,
              "auth"
            );
          }

          // console.log(getAPI)
          this.listDataP = getAPI.data.dbitems[0];
          // console.log(this.listDataP);
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async checkSkcPlpp() {
        try {
          let data = {
            SKCODE: this.sku,
            STCODE: this.getStore,
          };
          let getAPI = await serviceAPI.call_API(
            "post",
            `detail/checkskcplpp`,
            data,
            "auth"
          );
          // console.log(getAPI)
          this.listDataPlpp = getAPI.data.dbitems[0];
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
    },
    computed: {
      getBu: function() {
        return this.$store.getters["customer/getQTBU"];
      },
      getDisc: function() {
        if (this.listDataP != "") {
          return this.listDataP.disc.substring(
            4,
            this.listDataP.disc.length - 5
          );
        }
      },
      getStore: function() {
        return this.$store.getters["service/getQTDFSTORE"] || "026";
      },
      getUUID: function() {
        return this.$store.getters["auth/getB2BUUID"];
      },
    },
    watch: {
      getStore: function() {
        this.getPrice();
        this.checkSkcPlpp();
      },
    },
  };
</script>

<style scoped lang="scss">
  $color-PWB: #55246c;
  $color-bnb: #006e63;
  $color-at1: #3866af;
  $color-white: white;
  $color-black: black;
  @mixin color-($color) {
    color: $color !important;
  }
  .color-PWB {
    @include color-($color-PWB);
  }
  .color-bnb {
    @include color-($color-bnb);
  }
  .color-at1 {
    @include color-($color-at1);
  }
  .boxlocation {
    padding: 5px 30px;
    background-color: lightgray;
    border-radius: 10px;
  }
  .pdpminus {
    width: 20px;
    height: 24px;
    border: none;
    display: inline;
    vertical-align: middle;
    text-align: right;
    font-size: 18px;
    font-weight: bold;
    z-index: 998;
    background-color: transparent;
    padding-bottom: 1.5em;
  }
  .pdpminus:active {
    outline: none;
  }
  .pdpminus:focus {
    outline: none;
  }
  .pdpplus {
    width: 20px;
    height: 24px;
    border: none;
    display: inline;
    vertical-align: middle;
    text-align: left;
    margin-left: -6px;
    font-size: 18px;
    font-weight: bold;
    background-color: transparent;
    padding-bottom: 1.5em;
  }
  .pdpplus:active {
    outline: none;
  }
  .pdpplus:focus {
    outline: none;
  }
  .qtypdpinput {
    margin-left: -6px;
    padding: 0;
  }
  .number {
    border-radius: 4px;
    border: 1px solid #ddd;
    text-align: center;
    height: 24px;
    padding: 0;
    margin: 0;
  }
  input {
    height: 24px;
    font-weight: bold;
    width: 40px;
    text-align: center;
    font-size: 20px;
    border: 1px solid #ddd;
    border-top: none;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
  }
  .containercb .checkmarkd:after {
    border: solid green;
    border-width: 0 3px 3px 0;
  }
  .btn-block {
    display: block;
    width: 100%;
  }
  .btnheaderdetail {
    background-color: rgb(35, 31, 32);
    border: none;
    border-radius: 4px;
    margin-bottom: 5px;
    font-size: 18px !important;
    padding: 3px 10px;
    font-family: "kittithadabold75regular" !important;
  }
  .buttoncart {
    border: none;
    color: white;
    // padding: 2px 1vw;
    text-align: center;
    text-decoration: none;
    display: inline-block;

    margin: 0px 0px;
    cursor: pointer;
    border-radius: 4px;
    font-family: "kittithadabold75regular";
  }
</style>
