<template>
  <div class="container">
    <Loginform :EMAIL="EMAIL" :key="EMAIL"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Loginform from '@/components/Loginform.vue'

export default {
  name: 'Product',
  components: {
    Loginform
  },
  props: {

  },
    data: function () {
    return {
      EMAIL:''
    }
  },
  mounted() {
    document.title = `${process.env.VUE_APP_TITLE} - Login`
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-CRY3HG283C')
    document.head.appendChild(recaptchaScript)
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CRY3HG283C');
    this.$store.dispatch("auth/setISTB", 'false');
    // console.log(this.$route.query.EMAIL);
    this.isToken()
  },
  methods: {
    isToken(){
      if(this.$route.query.info){
        
        this.EMAIL = Buffer.from(this.$route.query.info, 'base64').toString()
        // console.log(this.EMAIL);
      }
    }
  },
}
</script>
