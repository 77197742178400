<template>
      <div  :pagco='`${cardData.slugname}`' class="shadowDiv" style='overflow:hidden; height: 100%'>
          <div id='dvImg' style='overflow:hidden;'>
            <router-link :to="`/ProductDetail/${cardData.slugname}`">
              <p class='text-center'>
                  <img :id="'IMG'+cardData.skcode" :src="`${cardData.base64?'data:image/jpeg;base64,'+cardData.base64:(`${getBU}` == `bnb` ? '/img/no-imgbnb.png' : '/img/no-img.jpg')}`"
                  style='width:70%;overflow:hidden;max-width:248px;' :alt="`${cardData.skcode}`" class='skcimage  img-responsive'
                  onError="IMG_ERR(this)">
              </p>
            </router-link>
          </div>

          <div class="card-body px-1 py-0">
            <router-link :to="`/ProductDetail/${cardData.slugname}`" style="color:black;">
              <p class="brandcard" style="font-family: 'kittithadabold75regular'; margin: 0; ">{{cardData.brand}}</p>
              <p class="namecard" style=" margin: 0; ">{{cardData.name}}</p>
              <!-- <div class="row">
                <div class="col-12">
                  <span class="box-status"></span> 
                </div>
              </div> -->

            </router-link>

          </div>
          <div class="card-body px-1 py-0 m-0" style="height: 100%">
            <span :class="`skucard font-color-${getBU} font-weight-bold`" style="margin: 0; margin-bottom: 5px; font-family: 'kittithadathin35regular';">
                เริ่มต้น
            </span><br>
            <p class='text-center h5'>
              <span :class="`prizecard font-price font-color-${getBU}`">฿
              {{(cardData.min_price==cardData.max_price?cardData.price:`${cardData.min_price} - ${cardData.max_price}`)}}</span>
            </p>
            <div class="row">
              <div class="col-12">
                  <p class="difcard font-weight-bold" style="margin: 0; float:right; color: gray; font-family: 'kittithadathin35regular';">
                    ดูสินค้าทั้งหมด {{cardData.skcount}} รายการ</p>
              </div>
            </div>
            <router-link :to="`/ProductDetail/${cardData.slugname}`" :class="`buttoncart cardfont col-12`" >ดูเพิ่มเติม</router-link>
            <!-- <div class="row">
              <div class="col-12">
                <center>
                    <h5 v-if='!getStore' :class='`font-color-${getBU}`'>ราคา: บางนา</h5>
                </center>
              </div>
            </div> -->
          </div>


        </div>
</template>


<script>

  export default {
    name: 'ProductCard',
    components: {
    
    },
    data: function () {
      return {
        
      }
    },
    mounted() {
      
    },
    props: [
      "cardData"
    ],
    methods: {
      
    },
    computed:{
      getBU:function(){
        return this.$store.getters['customer/getQTBU']
      },
      getStore: function () {
        return this.$store.getters["service/getQTDFSTORE"];
      }
    }
  }
       
</script>

<style scoped>
.card-text {
    font-size: calc(18px + 0.5vw);
    padding: 0px auto;
    text-align: center;
    color: #000;
    text-decoration: none;
    background-color: #fff;
    font-weight: 700;
  }
  
  .buttoncart {

  border: none;
  color: white;
  background-color: #000;
  /* padding: 2px 1vw; */
  text-align: center;
  text-decoration: none;
  display: inline-block;

  margin: 0px 0px;
  cursor: pointer;
  border-radius: 4px;
  font-family: 'kittithadabold75regular';
}

.pcard {
  background-color: #4CAF50;
  /* Green */
  border: none;
  color: white;
  padding: 0px 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 0px;
  cursor: pointer;
  background-color: white;
  color: #55246c;
  border: 1px solid #55246c;
  border-radius: 4px;
}

.shadowDiv {
  margin: 0px;
  background-color: white;
  padding: 10px;
  border-radius: 7px;
  height: auto;
}

.paddingMCard {
  padding: 10px;
  margin: 0px;
}

.marginPM {

  margin-top: 10px;
}


.brandcard {
  font-size: 34px;
}

.namecard {
  font-size: 1.25rem;
  overflow: hidden;
  height: 78px;
  line-height: normal;
}

.skucard {
  font-size: 24px;
}

.prizecard {
  font-size: calc(100% - 0.1rem);
}

.unitcard {
  font-size: 28px;
}

.cardfont {
  font-size: 26px;
}

.discard {
  height: 28px;
  font-size: 26px;
}

.difcard {
  font-size: 24px;
}

.miniimgcard {
  height: 28px;
}

.nameimgmini {
  font-size: 26px;
}


.shadowDiv:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.hidxs{
  display: none;
}
@media screen and (max-width: 1700px) {
  .shadowDiv {
    height: auto;
  }

  .brandcard {
    font-size: 30px;
  }

  .namecard {
    font-size: 28px;
    overflow: hidden;
    height: 85px;
  }

  .skucard {
    font-size: 22px;
  }

  /* .prizecard {
    font-size: 28px;
  } */

  .unitcard {
    font-size: 24px;
  }

  .cardfont {
    font-size: 22px;
  }

  .discard {
    height: 26px;
    font-size: 24px;
  }

  .difcard {
    font-size: 22px;
  }

  .miniimgcard {
    height: 26px;
  }

  .nameimgmini {
    font-size: 24px;
  }
}

@media screen and (max-width: 1400px) {
  .shadowDiv {
    height: auto;
  }

  .pr-5 {
    padding-right: 3em;
    padding-left: 0;
  }

  .brandcard {
    font-size: 28px;
  }

  .namecard {
    font-size: 24px;
    overflow: hidden;
    height: 70px;
  }

  .skucard {
    font-size: 20px;
  }

  /* .prizecard {
    font-size: 22px;
  } */

  .unitcard {
    font-size: 20px;
  }

  .cardfont {
    font-size: 18px;
  }

  .discard {
    height: 24px;
    font-size: 22px;
  }

  .difcard {
    font-size: 20px;
  }

  .miniimgcard {
    height: 24px;
  }

  .nameimgmini {
    font-size: 22px;
  }
}

@media screen and (max-width: 1100px) {
  .shadowDiv {
    height: auto;

  }

  .pr-5 {
    padding-right: 2em;
    padding-left: 0;
  }

  .brandcard {
    font-size: 26px;
  }

  .namecard {
    font-size: 22px;
    overflow: hidden;
    height: 70px;
  }

  .skucard {
    font-size: 18px;
  }

  /* .prizecard {
    font-size: 18px;
  } */

  .unitcard {
    font-size: 18px;
  }

  .cardfont {
    font-size: 14px;
  }

  .discard {
    height: 22px;
    font-size: 20px;
  }

  .difcard {
    font-size: 18px;
  }

  .miniimgcard {
    height: 20px;
  }

  .nameimgmini {
    font-size: 18px;
  }

  .BGcolor {
    padding: 124px 0 0px 0;
  }

  .BGcart {
    margin-top: 85px;
  }


}

@media screen and (max-width: 800px) {
  .shadowDiv {
    height: auto;
  }

  .brandcard {
    font-size: calc(26px + 0.5vw);
  }

  .namecard {
    font-size: calc(22px+ 0.5vw);
    overflow: hidden;
    height: 72px;
  }

  .skucard {
    font-size: calc(18px + 0.5vw);
  }

  /* .prizecard {
    font-size: calc(22px + 0.5vw);
  } */

  .unitcard {
    font-size: calc(18px + 0.5vw);
  }

  .cardfont {
    font-size: calc(22px + 0.5vw);
  }

  .discard {
    font-size: calc(18px + 0.5vw);
  }

  .difcard {
    font-size: calc(18px + 0.5vw);
  }

  .miniimgcard {
    height: calc(20px + 0.3vw)
  }

  .nameimgmini {
    font-size: calc(18px + 0.5vw);
  }



}
@media screen and (max-width: 766px) {
  .hidxs{
    display: inline-block;
  }
}
@media screen and (max-width: 600px) {
  .skcimagesrc {
    width: 80%;
  }

  .tabsearch {
    top: 2.24em;
    left: -30%;
    width: 150%;
  }

  .marginPM {

    margin-top: 0px;
  }

  .disccir {
    font-size: 0.8em !important;
    color: rgb(255, 255, 255);
    font-weight: 700;
    text-align: center;
    letter-spacing: .025em;
    border-radius: 50%;
    background-color:#55246c;
    width: 40px;
    height: 40px;
    line-height: 15px;
    position: absolute;
    transition: all .2s ease-out;
    top: 15px;
    right: 15px;
    padding-top: 7px;
    font-family: 'kittithadabold75regular' !important;
  }

  .shadowDiv {
    height: auto;
  }

  .paddingMCard {
    padding: 5px !important;
  }

  .nopaddingMDetail {
    padding: 0 !important;
  }
  .nopaddingM {
    padding: 0 !important;
  }
  .nomarginM {
    margin: 0 !important;
  }



  .brandcard {
    font-size: 1em;
    height: 30px;
    overflow: hidden;

  }

  .namecard {
    height: 3em;
    font-size: 0.9em;
    overflow: hidden;
    line-height: 1;
  }

  .skucard {
    font-size: 0.7em;
  }

  /* .prizecard {
    font-size: 0.8em;
  } */

  .unitcard {
    font-size: 0.7em;
  }

  .cardfont {
    font-size: 0.7em;
  }

  .discard {
    font-size: 0.8em;
    height: 20px;
  }

  .difcard {
    font-size: 0.7em;
  }

  .miniimgcard {
    font-size: 0.7em;
  }

  .nameimgmini {
    font-size: 0.7em;
  }

}
.disccir:empty {
  display: none;
}

.disccir {
  font-size: 24px !important;
  color: rgb(255, 255, 255);
  font-weight: 700;
  text-align: center;
  letter-spacing: .025em;
  border-radius: 50%;
  background-color:#55246c;
  width: 60px;
  height: 60px;
  line-height: 20px;
  position: absolute;
  transition: all .2s ease-out;
  top: 20px;
  right: 20px;
  padding-top: 10px;
  font-family: 'kittithadabold75regular' !important;
}

</style>