import axios from 'axios';
import * as constant from './constant';
const API_URL = constant.API_URL;

export async function call_API(para_method,para_url,para_data,para_auth) {
      return new Promise((resolve,reject) => {
          var gethead 
          let getToken = null
          if(para_auth){
            let getHaveLocal = localStorage.getItem(constant.TOKEN_KEY);
            if(!getHaveLocal){
              if(store.getters['auth/getISTB']){
                window.location = ('/twdb2b/login') 
              } else {
                window.location = ('/login') 
              }
              // window.location = ('/login') 
              return
            } 
            try {
              getToken = atob(atob(getHaveLocal))
            } catch (error) {
              localStorage.removeItem(constant.TOKEN_KEY)
              if(store.getters['auth/getISTB']){
                window.location = ('/twdb2b/login') 
              } else {
                window.location = ('/login') 
              }
              return
            }
            gethead = { 
              'Authorization': 'Bearer '+getToken, 
              'Content-Type': 'application/json'
            }
          }else{
            gethead = { 
              'Content-Type': 'application/json'
            }
          }
          var data = JSON.stringify(para_data);

          var config = {}
          if(para_method=='get'){
            let queryString = new URLSearchParams(para_data).toString();
            config = {
              method: para_method,
              url: API_URL+para_url+"?"+queryString,
              headers: gethead
            };
          }else{
            var data = JSON.stringify(para_data);
            config = {
              method: para_method,
              url: API_URL+para_url,
              headers: gethead,
              data : data
            };
          }
 
          
          axios(config)
          .then(function (response) {
            // console.log(response.data)
            let getData = JSON.parse(JSON.stringify(response.data))
       
            if(getData.data.dbcode && getData.data.dbcode !==0) {
              reject(getData.data.dbmessage)
            }

            resolve(getData);
     
          })
          .catch(function (error) {
            console.log(error)
            reject(error.response.data);
          });
          
      })
  }
export async function call_API_Verify_Token(para_method,para_url,para_data,para_token) {
    return new Promise((resolve,reject) => {
        var gethead 
        if(para_token){
          gethead = { 
            'Authorization': 'Bearer '+para_token, 
            'Content-Type': 'application/json'
          }
        }
        var data = JSON.stringify(para_data);
        var config = {}
        if(para_method=='get'){
          let queryString = new URLSearchParams(para_data).toString();
          config = {
            method: para_method,
            url: API_URL+para_url+"?"+queryString,
            headers: gethead
          };
        }else{
          var data = JSON.stringify(para_data);
          config = {
            method: para_method,
            url: API_URL+para_url,
            headers: gethead,
            data : data
          };
        }
        axios(config)
        .then(function (response) {
          // console.log(response.data)
          let getData = JSON.parse(JSON.stringify(response.data))
     
          if(getData.data.dbcode && getData.data.dbcode !==0) {
            reject(getData.data.dbmessage)
          }

          resolve(getData);
   
        })
        .catch(function (error) {
          console.log(error)
          reject(error.response.data);
        });
        
    })
}
