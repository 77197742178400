<template>
<section v-if="!$store.getters['auth/getISB2B']" >
    <!-- <nav aria-label="breadcrumb"> -->
    <router-link v-if="headerdefault" to="/">{{ headerdefault }}</router-link>
    <router-link v-if="header1" :to="`${havenext || header2?`/Category/${catcodeLV1}`:`/Product/${(catcodeLV1 + '0000').slice(0,6)}`}`" :key="catcode"> <span class="text-center journeynav-cev"> <i class="fa fa-chevron-right"></i> </span> {{ header1 }} </router-link>
    <router-link v-if="header2" :to="`${havenext || header3?`/Category/${catcodeLV2}`:`/Product/${(catcodeLV2 + '0000').slice(0,6)}`}`" :key="catcode"> <span class="text-center journeynav-cev"> <i class="fa fa-chevron-right"></i> </span> {{ header2 }} </router-link>
    <router-link v-if="header3" :to="`${havenext?`/Category/${catcodeLV3}`:`/Product/${(catcodeLV3 + '0000').slice(0,6)}`}`" :key="catcode"> <span class="text-center journeynav-cev"> <i class="fa fa-chevron-right"></i> </span> {{ header3 }} </router-link>
</section>
<section v-else class="mt-2 mb-2">
</section>
<!-- </nav> -->
</template>

<script>
import * as serviceAPI from '../services/API.service';
import * as serviceMain from '../services/main.service';

export default {
    name: 'HeaderSPN',
    data: function () {
        return {
            headerdefault: '',
            header1: '',
            header2: '',
            header3: '',
            catcodeLV1: '',
            catcodeLV2: '',
            catcodeLV3: '',
            listData: [],
            newCatCode: ''
        }
    },
    mounted() {
        this.startHeader()
    },
    props: [
        "catcode",
        "havenext"
    ],
    methods: {
        async startHeader() {
            this.newCatCode = this.catcode || '';
            while(this.newCatCode.endsWith('00')) {
                this.newCatCode = this.newCatCode.substring(0, this.newCatCode.length - 2)
            }

            if (this.newCatCode == '' || this.newCatCode === 'undefined') {
                this.headerdefault = "All Category"
            }

            if (this.newCatCode.length == 2) {
                this.headerdefault = "All Category"
                let tempData = this.newCatCode + '000000';
                this.catcodeLV1 = this.newCatCode
                let data = {
                    catcode: tempData
                };
                let geta = await this.getData(data);
                this.header1 = geta;

            }

            if (this.newCatCode.length == 4) {
                this.headerdefault = "All Category"
                let tempData1 = this.newCatCode.substring(0, 2) + '000000'
                let tempData2 = this.newCatCode + '0000';
                this.catcodeLV1 = this.newCatCode.substring(0, 2)
                this.catcodeLV2 = this.newCatCode
                let data = {
                    catcode: tempData1
                };
                let gettempData1 = await this.getData(data);
                this.header1 = gettempData1;
                data = {
                    catcode: tempData2
                };
                let gettempData2 = await this.getData(data);
                this.header2 = gettempData2;
            }

            if (this.newCatCode.length == 6) {
                this.headerdefault = "All Category"
                let tempData1 = this.newCatCode.substring(0, 2) + '000000'
                let tempData2 = this.newCatCode.substring(0, 4) + '0000';
                let tempData3 = this.newCatCode + '00'
                this.catcodeLV1 = this.newCatCode.substring(0, 2)
                this.catcodeLV2 = this.newCatCode.substring(0, 4)
                this.catcodeLV3 = this.newCatCode
                let data = {
                    catcode: tempData1
                };
                let gettempData1 = await this.getData(data);
                this.header1 = gettempData1;
                data = {
                    catcode: tempData2
                };
                let gettempData2 = await this.getData(data);
                this.header2 = gettempData2;
                
                data = {
                    catcode: tempData3
                };
                let gettempData3 = await this.getData(data);
                this.header3 = gettempData3;
            }
        },

        async getData(data) {
            try {
                let getAPI = await serviceAPI.call_API('post', `headerSPN/getNameFromCode`, data, 'auth');
                if(getAPI.data.dbrows != 0){
                    this.listData = getAPI.data.dbitems[0];
                    let TempHeader = Object.values(this.listData)
                    return TempHeader[0]
                }
                return null

            } catch (error) {
                serviceMain.showErrorAlert(this, error.message)
            }
        }
    }
}
</script>

<style scoped>
a {
    font-weight: bolder;
    color: gray;
    white-space: nowrap;
    width: auto;
    max-width: 33%;
    padding: 0;

}

a:hover {
    color: black;
}

.journeynav-cev {
    width: auto;
    max-width: 5%;
    padding: 0 5px;
    font-weight: bolder;
    color: gray;
}

svg {
    height: 0.7rem !important;
    vertical-align: -2px !important;
}
</style>
