import { createStore } from 'vuex'
import { display } from './display.module';
import { auth } from './auth.module';
import { customer } from './customer.module';
import { service } from './service.module';
import { prodboard } from './prodboard.module';
import { b2b } from './b2b.module';
export default createStore({
  modules: {
    service:service,
    auth:auth,
    display:display,
    customer:customer,
    prodboard:prodboard,
    b2b:b2b
  }
})
