<template>
<section class="container">
    <div v-if="!$store.getters['auth/getISTB']" class="row">
        <div class="col-12 col-md-3 p-0">
            <div class="row d-none d-md-block" style="background-color: white; padding: 20px 0 20px 0;border-radius: 7px; margin: 20px;">
                <ul :class="`m-0 pl-3 sidebar-pro sidebar-pro-${qtBu}`">
                    <div :class="`font-color-${qtBu}`" style="font-size: 26px;font-weight: bolder;">รายการสั่งซื้อ</div>
                    <li @click="toggleActiveClass('hist-find')" id="hist-find">
                        <router-link :to="{ name: 'History', params: { whereBy: 'ByFind' }}">ค้นหารายการสั่งซื้อ</router-link>
                    </li>
                    <li v-if="isAdmin && checkUV" @click="toggleActiveClass('hist-wait-apv-admin')" id='hist-wait-apv-admin'>
                        <router-link :class="`font-hover-${qtBu}`" :to="{ name: 'History', params: { whereBy: 'WaitApproveByAdmin' }}">รายการที่ขออนุมัติ (Admin) </router-link>
                    </li>
                    <li v-else-if="checkUV" @click="toggleActiveClass('hist-wait-apv-user')" id="hist-wait-apv-user">
                        <router-link :class="`font-hover-${qtBu}`" :to="{ name: 'History', params: { whereBy: 'WaitApproveByUser' }}">รายการที่รออนุมัติ</router-link>
                    </li>

                    <li v-if="isAdmin && checkUV" @click="toggleActiveClass('hist-apv-admin')" id="hist-apv-admin">
                        <router-link :class="`font-hover-${qtBu}`" :to="{ name: 'History', params: { whereBy: 'ApproveByAdmin' }}">รายการที่อนุมัติแล้ว (Admin)</router-link>
                    </li>
                    <li v-else-if="checkUV" @click="toggleActiveClass('hist-apv-user')" id="hist-apv-user">
                        <router-link :class="`font-hover-${qtBu}`" :to="{ name: 'History', params: { whereBy: 'ApproveByUser' }}">รายการที่อนุมัติแล้ว</router-link>
                    </li>
                    <li @click="toggleActiveClass('hist-user')" id="hist-user">
                        <router-link :class="`font-hover-${qtBu}`" :to="{ name: 'History', params: { whereBy: 'ByUser' }}">รายการสั่งซื้อของ User</router-link>
                    </li>
                    <li @click="toggleActiveClass('hist-customer')" id="hist-customer">
                        <router-link :class="`font-hover-${qtBu}`" :to="{ name: 'History', params: { whereBy: 'ByCustomer' }}">รายการสั่งซื้อของลูกค้า</router-link>
                    </li>
                    <li v-if="$store.getters['auth/getWSFLAG']" @click="toggleActiveClass('hist-polist')" id="hist-polist">
                        <router-link :class="`font-hover-${qtBu}`" :to="{ name: 'POList' }">PO List</router-link>
                    </li>
                    <div :class="`font-color-${qtBu}`" style="font-size: 26px;font-weight: bolder;">Template</div>
                    <li @click="toggleActiveClass('hist-template')" id="hist-template">
                        <router-link :class="`font-hover-${qtBu}`" :to="{ name: 'Template'}">ค้นหา Template</router-link>
                    </li>
                </ul>
            </div>
            <!-- Menu Mobile -->
            <div class="dropdown d-block d-md-none" style="padding:10px 40px;width:100%; color:white; cursor:pointer;background-color: white;">
                <a class="btn dropdown" type="button" data-toggle="dropdown" style="width:100%;color:  black; ">
                    <div class="row col-12 d-flex align-items-center" id="current-menum">
                        <div class="col-xs-10" style="font-size: 30px;font-weight: bold;">
                            Menu
                        </div>
                        <div class="col-xs-2 ml-auto">
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </div>
                    </div>
                </a>
                <ul class="dropdown-menu px-2" style="width: 100%;">
                    <div :class="`font-color-${qtBu}`" style="font-size: 26px;font-weight: bolder;">รายการสั่งซื้อ</div>
                    <li>
                        <router-link :class="`font-hover-${qtBu}`" :to="{ name: 'History', params: { whereBy: 'ByFind' }}">ค้นหารายการสั่งซื้อ</router-link><br>
                    </li>
                    <li v-if="isAdmin && checkUV">
                        <router-link :class="`font-hover-${qtBu}`" :to="{ name: 'History', params: { whereBy: 'WaitApproveByAdmin' }}">รายการที่ขออนุมัติ (Admin)</router-link>
                    </li>
                    <li v-else-if="checkUV">
                        <router-link :class="`font-hover-${qtBu}`" :to="{ name: 'History', params: { whereBy: 'WaitApproveByUser' }}">รายการที่รออนุมัติ</router-link>
                    </li>
                    <li v-if="isAdmin && checkUV">
                        <router-link :class="`font-hover-${qtBu}`" :to="{ name: 'History', params: { whereBy: 'ApproveByAdmin' }}">รายการที่อนุมัติแล้ว (Admin)</router-link>
                    </li>
                    <li v-else-if="checkUV">
                        <router-link :class="`font-hover-${qtBu}`" :to="{ name: 'History', params: { whereBy: 'ApproveByUser' }}">รายการที่อนุมัติแล้ว</router-link>
                    </li>
                    <router-link :class="`font-hover-${qtBu}`" :to="{ name: 'History', params: { whereBy: 'ByUser' }}">รายการสั่งซื้อของ User</router-link><br>
                    <router-link :class="`font-hover-${qtBu}`" :to="{ name: 'History', params: { whereBy: 'ByCustomer' }}">รายการสั่งซื้อของลูกค้า</router-link><br>
                    <router-link :class="`font-hover-${qtBu}`" :to="{ name: 'POList' }">PO List</router-link><br>
                    <div :class="`font-color-${qtBu}`" style="font-size: 26px;font-weight: bolder;">Template</div>
                    <li>
                        <router-link :class="`font-hover-${qtBu}`" to="/">ค้นหา Template</router-link>
                    </li>
                </ul>

            </div>
        </div>
        <div class="col-12 col-md-9 p-0">
            <router-view :key="$route.path" />
        </div>
    </div>
    <div v-else class="row">
        <div class="col-12 col-md-12 p-0">
            <router-view :key="$route.path" />
        </div>
    </div>
</section>
</template>

<script>
export default {
    name: 'Menu',

    components: {

    },
    methods: {
      toggleActiveClass: function (elId) {
        $(`#${elId}`).addClass('actt')
      }
    },
    computed: {

        isAdmin: function () {
            let profile = this.$store.getters['auth/getQTPROFILE'];
            if(profile){
                let admin = profile.split("X")
                for (let i = 1; i < admin.length; i++) {
                    if (admin[i] == '0076' || admin[i] == '0090') {
                        return true;
                    }
                }
            }
        },
        qtBu: function () {
            return this.$store.getters['customer/getQTBU']
        },
        checkUV: function () {
            let getSS = this.$store.getters['auth/getQTSALESOURCE']
            if (getSS == 'UV') {
                return true
            }

        }

    }

}
</script>

<style lang="scss" scoped>
$color-PWB: #55246c;
$color-bnb: #006e63;
$color-at1: #3866af;
$listBu: 'PWB'$color-PWB,
'bnb'$color-bnb,
'at1'$color-at1;

a {
    color: black;
    display: inline-block;
}

.sidebar-pro {
    position: relative;
    top: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: white;
}

.sidebar-pro li {
    position: relative;
    line-height: 20px;
    display: inline-block;
    width: 100%;
    margin-top: 2px;
    background-color: white;
}

.sidebar-pro li:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100%;
    width: 3px;
    background-color: #1c1c1c;
    display: none;
}

.sidebar-pro li:first-child {
    margin-top: 0 !important;
}

.sidebar-pro li:hover:before,
.sidebar-pro li.open:hover:before {
    display: block;
}

.sidebar-pro li a {
    display: block;
    color: rgb(0, 0, 0);
    font-size: 22px;
    font-weight: 500;
    text-decoration: none;
    padding: 8px 15px 8px 25px;
}

.sidebar-pro li a:hover,
.sidebar-pro li a:active,
.sidebar-pro li a:focus {
    font-weight: bold;
    text-decoration: none;
    background-color: transparent;
}

.sidebar-pro li.actt:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100%;
    width: 3px;
    background-color: #1c1c1c;
    display: block;
}

.sidebar-pro li.actt a {
    font-weight: bold !important;
}

@each $buItems in $listBu {

    .sidebar-pro-#{nth($buItems, 1)} li:before {
        background-color: nth($buItems, 2) !important;
        z-index: 49;
    }

    .sidebar-pro-#{nth($buItems, 1)} li.actt:before {
        background-color: nth($buItems, 2) !important;
        z-index: 49;
    }
}
</style>
