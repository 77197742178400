<template>
  <section :key="getQuery">
    <div class="row">
      <div
        v-for="(e, index) in listData"
        :key="index"
        class="col-6 col-sm-6 col-md-4 col-lg-3 p-1"
      >
        <ProductCard v-if="e.skcount == 1" :cardData="e" />
        <SpuCard v-else :cardData="e" />
      </div>
      <div class="col-12 pt-3" v-if="notfound">
        <center>
          <h1>ขออภัย! การค้นหาไม่ตรงกับสิ่งใดเลย</h1>
          <h3>โปรดลองใช้คำศัพท์ทั่วไปหรือตรวจสอบการสะกดคำของคุณ</h3>
          <div class="row">
            <div class="col-3 col-md-5"></div>
            <div class="col-6 col-md-2">
              <router-link to="/"
                ><div
                  :class="`bg-${getBU} buttoncart cardfont`"
                  style="color:white;"
                >
                  กลับสู่หน้าหลัก
                </div></router-link
              >
            </div>
            <div class="col-3 col-md-5"></div>
          </div>
          <img
            src="../../public/img/notfound.gif"
            v-if="getBU != 'PWB'"
            class="w-30"
          />
          <img src="../../public/img/notfound.webp" v-else class="w-100" />
        </center>
      </div>
    </div>
    <SkeletonProductCard v-if="isLoading" />

    <div v-else class="d-flex justify-content-center w-100 mb-3">
      <div v-if="MaxCount - startCount != 0">
        <a
          v-if="!isZeroRow"
          style="cursor:pointer"
          :class="`btn-border-${getBU}`"
          @click="showCategory()"
          >ดูเพิ่มเติม มีอีก {{ MaxCount - startCount }} รายการ</a
        >
      </div>
    </div>
  </section>
</template>

<script>
  import * as serviceAPI from "../services/API.service";
  import * as serviceMain from "../services/main.service";
  import ProductCard from "@/components/ProductCard.vue";
  import SpuCard from "@/components/SpuCard.vue";
  import SkeletonProductCard from "@/components/SkeletonProductCard.vue";
  export default {
    name: "ProductSearchListB2B",
    data: function() {
      return {
        listData: [],
        isLoading: true,
        startCount: 0,
        notfound: false,
        wantShowCount: 24,
        isZeroRow: false,
        MaxCount: null,
      };
    },
    mounted() {
      this.showCategory();
    },
    components: {
      ProductCard,
      SpuCard,
      SkeletonProductCard,
    },
    props: ["srctxt"],
    methods: {
      async showCategory() {
        try {
          this.isLoading = true;
          let getSrctxt = this.srctxt;
          if (this.MaxCount == null) {
            let data = {
              UUID: this.getB2BUUID,
              STCODE: this.getStoreCode,
              SEARCH: getSrctxt,
            };
            let getAPI = await serviceAPI.call_API(
              "post",
              `b2b/product/catprodcardsrc_paging`,
              data,
              "auth"
            );
            if (getAPI.data.dbrows != 0) {
              this.MaxCount = getAPI.data.dbitems[0].skcount;
            }
          }
          let endCount = this.startCount + this.wantShowCount;
          if (endCount > this.MaxCount) {
            endCount = this.MaxCount;
            this.isZeroRow = true;
          }

          let data = {
            STCODE: this.getStoreCode,
            SEARCH: getSrctxt,
            UUID: this.getB2BUUID,
            START: this.startCount || 0,
            END: this.wantShowCount || 0,
          };
          let getAPI = await serviceAPI.call_API(
            "post",
            `b2b/product/getsearchlist`,
            data,
            "auth"
          );
          this.listData = [...this.listData, ...getAPI.data.dbitems];
          this.isLoading = false;
          this.startCount = endCount;
          if (this.listData.length == 0) {
            this.notfound = true;
            this.$emit("notFound", true);
          } else {
            this.$emit("notFound", false);
          }
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      // async getMaxCountSPU(){

      // }
    },
    computed: {
      getBU: function() {
        return this.$store.getters["customer/getQTBU"];
      },
      getStoreCode: function() {
        return this.$store.getters["service/getQTDFSTORE"];
      },
      getQuery: function() {
        return this.$route.query;
      },
      getB2BUUID: function() {
        return this.$store.getters["auth/getB2BUUID"];
      },
    },
    watch: {
      getStoreCode: function() {
        this.startCount = 0;
        this.listData = [];
        this.showCategory();
      },
      getQuery: function() {
        this.startCount = 0;
        this.listData = [];
        this.showCategory();
      },
    },
  };
</script>

<style scoped></style>
