<template>
  <footer v-if="!$store.getters['auth/getISB2B'] && !$store.getters['auth/getISTB']" >
    <div id="foot" class="container-fluid"
      style="background-color: #ffffff; padding-top: 30px;padding-bottom: 1em; font-size: 20;position: relative;   ">
      <div class="row" style="padding: 0; margin: 0;">
        <div class="col-12 col-sm-6 col-md-4beautiful" style="padding-left: 3%;">
          <div class="row">
            <div class="col-6">
              <p></p>
              <strong style="font-family: kittithadabold75regular ; ">ไทวัสดุ</strong>
              <a href="https://www.thaiwatsadu.com/page/about" target="_blank" style="color: black;">
                <p style="margin-top: 10px;">เกี่ยวกับเรา</p>
              </a>
              <a href="http://www.thaiwatsadu.com/promotion.html" target="_blank" style="color: black;">
                <p>ดาวน์โหลดแคตตาล็อก</p>
              </a>
              <router-link to="/BuyingPolicy" target="_blank" style="color: black;">
                <p style="margin-top: 10px;">วิธีการสั่งซื้อสินค้า</p>
              </router-link>
              <router-link to="/PaymentPolicy" target="_blank" style="color: black;">
                <p style="margin-top: 10px;">วิธีการชำระเงินออนไลน์ </p>
              </router-link>
              <router-link to="/DeliverPolicy" target="_blank" style="color: black;">
                <p style="margin-top: 10px;">วิธีการจัดส่งสินค้า </p>
              </router-link>
            </div>
            <div class="col-6">
              <p></p>
              <strong style="font-family: kittithadabold75regular ; ">นโยบาย</strong>
              <a style="color: black;" data-target="#consentFooter" data-toggle="modal" href="#consentFooter">
                <p>นโยบายความเป็นส่วนตัว</p>
              </a>
              <router-link to="/RefundPolicy" target="_blank" style="color: black;">
                <p>นโยบายการเปลี่ยนคืนสินค้า</p>
              </router-link>
              <!-- <a href="../pricecomparison" target="_blank" style="color: black;"><p>เงื่อนไขเปรียบเทียบราคา</p></a> -->
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <strong style="font-family: kittithadabold75regular ; ">บริการลูกค้า</strong>
              <a href="http://www.thaiwatsadu.com/contact.html" target="_blank" style="color: black;">
                <p>ติดต่อเรา</p>
              </a>
            </div>
            <div class="col-6" style="margin-top: -1.9em;">
              <strong style="font-family: kittithadabold75regular ;">The 1</strong>
              <a href="https://www.the1.co.th/" target="_blank" style="color: black;">
                <p>สมัครสมาชิก</p>
              </a>
              <a href="https://www.the1.co.th/articles/earn-burn-the1-points" target="_blank" style="color: black;">
                <p>รับ/แลก ของรางวัล</p>
              </a>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-4" style="padding-left: 3%;">
          <div class="row">
            <div class="col-12">
              <p></p>
              <strong style="font-family: kittithadabold75regular ; ">ธุรกิจที่เกี่ยวข้อง</strong>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <a href="https://www.central.co.th/" target="_blank"><img src="@/assets/img/central.jpg" alt=""
                  style="height: 50px; max-width: 100%;"></a>
              <br>
              <a href="https://www.robinson.co.th/" target="_blank"><img src="@/assets/img/robinson.png" alt=""
                  style="height: 50px;  max-width: 100%;"></a>
              <br>
              <a href="https://www.officemate.co.th/" target="_blank"><img src="@/assets/img/office.jpg" alt=""
                  style="height: 25px;  max-width: 100%; margin-top: 10px;"></a>
              <br>
              <a href="https://www.b2s.co.th/" target="_blank"><img src="@/assets/img/b2s.jpg" alt=""
                  style="height: 40px;  max-width: 100%;  margin-top: 5px;"></a>
            </div>
            <div class="col-6">
              <a href="https://www.powerbuy.co.th/" target="_blank"><img src="@/assets/img/powerbuy.png" alt=""
                  style="height: 50px; max-width: 100%;"></a>
              <br>
              <a href="https://www.supersports.co.th/" target="_blank"><img src="@/assets/img/supersports.jpg" alt=""
                  style="height: 28px; max-width: 100%;"></a>
              <br>
              <a href="https://www.tops.co.th/" target="_blank"><img src="@/assets/img/tops.jpg" alt=""
                  style="height: 50px; margin-top: 5; max-width: 100%;"></a>
              <br>
              <a href="https://www.mebmarket.com/" target="_blank"><img src="@/assets/img/meb.jpg" alt=""
                  style="height: 40px; max-width: 100%;"></a>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-4 pt-sm-2">

          <strong style="color: #55246c; font-family: kittithadabold75regular ; ">ติดตามเรา</strong>
          <br>
          <a href="https://bit.ly/2X7bAuK" target="_blank"><img style="margin: 5px; height: 30px;"
              src="@/assets/img/line.png" alt="Line"></a>
          <a href="https://web.facebook.com/thaiwatsadu.thai/" target="_blank"><img style="margin: 5px; height: 30px;"
              src="@/assets/img/facebook.png" alt="Facebook"></a>

          <a href="https://www.youtube.com/channel/UCqkeU8psATW3SP_xhgeeN8A" target="_blank"><img
              style="margin: 5px; height: 30px;" src="@/assets/img/youtube.png" alt="Youtube"></a>
        </div>
      </div>
      <img class="hidden-sm hidden-xs"
        style="margin: 0px; padding-bottom: 0px;  height: 170px;  position: relative; bottom: 0px; position: absolute; right: 4%; bottom: 0px; "
        src="@/assets/img/peple2.png" alt="">
    </div>

    <div class="modal fade" id="consentFooter" role="dialog" tabindex="-1">
      <div class="modal-dialog modal-md" style="width:95%">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
          </div>
          <div id="model-policy-footer" style="padding:10px 20px;">

          </div>
          <div class="modal-footer">
            <button type="button" class="btn" data-dismiss="modal">ตกลง</button>
          </div>
        </div>
      </div>
    </div>
    <div :class="`container-fluid twdorbnb bg-${getBU}`">
      <p
        style="text-align: center; padding-top: 3px; padding-bottom:0px; margin-bottom: 0px; color: white; font-size: 23;">
        Copyright 2019 Thaiwatsadu, Thaiwatsadu is the trading name of thaiwatsadu, a registered company of Thailand.
      </p>
    </div>
  </footer>


</template>

<script>
  export default {
    name: 'Footer',
    methods: {},
    computed:{
      getBU:function(){
        return this.$store.getters['customer/getQTBU']
      }
    }
  }
</script>

<style scoped>
  #foot a:hover {
    color: #55246c !important;
    font-weight: bold;
  }
</style>