<template>
  <section>
    <!-- {{ data }} -->
    <div class="card" v-if="Object.keys(this.orders).length !== 0">
      <div class="card-body">
        <div class="row">
          <div class="col-2">
            <h5 class="font-weight-bold">Request ID</h5>
          </div>
          <div class="col-1">
            <h5 class="font-weight-bold">:</h5>
          </div>
          <div class="col-9">
            <h5 class="">{{ orders.requestID }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-2">
            <h5 class="font-weight-bold">Order ID</h5>
          </div>
          <div class="col-1">
            <h5 class="font-weight-bold">:</h5>
          </div>
          <div class="col-9">
            <h5 class="">{{ orders.orderID }}</h5>
          </div>
        </div>
      </div>
      <ul class="list-group list-group-flush">
        <li
          class="list-group-item"
          v-for="cart in this.carts"
          :key="cart.vendorSKU"
        >
          <div class="d-flex justify-content-between">
            <span style="width:10%"><img  :src="`data:image/jpeg;base64,${cart.itemImageURL}`" 
                class='catimage  img-responsive' style='width:100%;overflow:hidden;'></span>
            <span>{{ cart.vendorSKU }} : {{ cart.itemName }}</span>
            <span
              >{{ convert_currency(cart.unitPrice) }} x {{ cart.quantity }} =
              {{ convert_currency(cart.lineTotal) }}</span
            >
          </div>
        </li>
      </ul>
      <div class="card-body">
        <div class="d-flex justify-content-end">
          <span>Sub Total : {{ convert_currency(orders.subTotal) }}</span>
        </div>
        <div class="d-flex justify-content-end">
          <span>Discount : {{ convert_currency(orders.discount) }}</span>
        </div>
        <div class="d-flex justify-content-end">
          <span>Total : {{convert_currency(orders.total) }}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  // @ is an alias to /src
  
  export default {
    name: "B2BCartOrder",
    components: {},
    data: function() {
      return {
        orders: {},
        carts: {},
      };
    },
    props: ["data"],
    mounted() {
      if (Object.keys(this.data).length !== 0) {
        this.orders = this.data;
        
        if (Object.keys(this.orders.cart).length !== 0) {
          this.carts = JSON.parse(this.orders.cart);
        }
      };
      if(window.parent) window.parent.postMessage(
        {
            event_id: 'already_checked_out',
            data: {
              orders : JSON.stringify(this.orders),
              carts : JSON.stringify(this.carts),
            }
        }, 
        "*"
      );
    },
    methods: {
      convert_currency(number) {
        var formatter = new Intl.NumberFormat("th-TH", {
          style: "currency",
          currency: "THB",
        });
        return formatter.format(number);
      },
    },
    computed: {},
    watch: {},
  };
</script>

<style scoped></style>
