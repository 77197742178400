<template>
      <div class="row">

        <div v-for="index in 12" :key="index" class="col-6 col-sm-6 col-md-4 col-lg-3 p-1">
          <div class="shadowDiv" style='overflow:hidden;'>
            <span class="skeleton-box w-100" style="height:200px; "></span>
            <h3 class="blog-post__headline">
              <span class="skeleton-box" style="width:55%;"></span>
            </h3>
            <p class="m-0">
              <span class="skeleton-box" style="width:80%;"></span>
              <span class="skeleton-box" style="width:90%;"></span>
              <span class="skeleton-box" style="width:83%;"></span>
            </p>
            <h3 class="blog-post__headline m-0">
                <span :class="`skeleton-box bg-${getBU}`" style="width:100%; border-radius: 4px; "></span>
            </h3>
          </div>
          

        </div>

    </div>
</template>


<script>

  export default {
    name: 'SkeletonProductCard',
    components: {
    
    },
    data: function () {
      return {
        
      }
    },
    mounted() {
      
    },
   
    methods: {
      
    },
    computed:{
      getBU:function(){
        return this.$store.getters['customer/getQTBU']
      }
    }
  }
       
</script>

<style scoped>
  .shadowDiv {
    margin: 0px;
    background-color: white;
    padding: 10px;
    border-radius: 7px;
    height: auto;
  }
  .shadowDiv:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
</style>