// import AuthService from '../services/auth.service';
import * as main from '../services/main.service';
// import * as constant from '../services/constant';



export const service = {
  namespaced: true,
  state: {
    QTSAVESTORE:main.readCookieEncrypt("QTSAVESTORE"),
    QTDFSTORE:'026',
    QTSTORENAME:main.readCookieEncrypt("QTSTORENAME"),
    DSCUSTID:main.readCookieEncrypt("DSCUSTID"),
    DSMMSSTORE:main.readCookieEncrypt("DSMMSSTORE"),
    ReloadCart:'',
    QTCHKP:main.readCookieEncrypt("QTCHKP") || 'Pickup',
    ChangeAddress:'',
    ReloadOrderInfo:'',
    ReloadOrderInfoF101:'',
    CallOrderInfo:'',
    ReloadFlee:'',
    StoreOnline:'',
    ReloadPlpp:'',
    DisMKT:0,
    NameMKT:'',
    ReloadMKT:'',
    Is10101:false,
    ReloadIs10101:'',
    DisMemberCode:0,
    DisMemberName:'',
    ReloadMem:'',
    TimePick:'',
    DatePick:'',
    AddressDelivery:'',
    AddressTax:'',
    BookingItms: {
      code: '',
      date: '',
      name: ''
    },
    IsCheckOrderForm: '',
    DeliveryFee: 0,
    QTPDLV:main.readCookieEncrypt("QTPDLV"),
    WaitBooking: false,
    AddNewAddress: '',
    ReloadStore:'',
    ReloadStaff:'',
    StcShowStock:main.readCookieEncrypt("EPDSSTORE"),
    StShowStock:main.readCookieEncrypt("EPDSSTCODE"),
    DSBillAddress: '',
    WSOTP: false,
    WSdiscountBill: false,
    WSplpp: false,
    WSt1c: false,
  },
  actions: {
    setQTSAVESTORE({commit},QTSAVESTORE) {
      main.saveCookieEncrypt("QTSAVESTORE", QTSAVESTORE)
      commit('setQTSAVESTORE',QTSAVESTORE)
    },
    setQTDFSTORE({commit},QTDFSTORE) {
      main.saveCookieEncrypt("QTDFSTORE", QTDFSTORE)
      commit('setQTDFSTORE','026')
    },
    setQTSTORENAME({commit},QTSTORENAME) {
      main.saveCookieEncrypt("QTSTORENAME", QTSTORENAME)
      commit('setQTSTORENAME',QTSTORENAME)
    },
    setDSCUSTID({commit},DSCUSTID) {
      main.saveCookieEncrypt("DSCUSTID", DSCUSTID)
      commit('setDSCUSTID',DSCUSTID)
    },
    setDSMMSSTORE({commit},DSMMSSTORE) {
      main.saveCookieEncrypt("DSMMSSTORE", DSMMSSTORE)
      commit('setDSMMSSTORE',DSMMSSTORE)
    },
    setReloadCart({commit}) {
      commit('setReloadCart',Date.now())
    },
    setQTCHKP({commit},value) {
      main.saveCookieEncrypt("QTCHKP", value)
      commit('setQTCHKP',value)
    },
    setQTPDLV({commit},value) {
      main.saveCookieEncrypt("QTPDLV", value)
      commit('setQTPDLV',value)
    },
    setChangeAddress({commit}) {
      commit('setChangeAddress',Date.now())
    },
    setReloadOrderInfo({commit}) {
      commit('setReloadOrderInfo',Date.now())
    },
    setReloadOrderInfoF101({commit}) {
      commit('setReloadOrderInfoF101',Date.now())
    },
    setCallOrderInfo({commit}) {
      commit('setCallOrderInfo',Date.now())
    },
    setReloadPlpp({commit}) {
      commit('setReloadPlpp',Date.now())
    },
    setDisMKT({commit},value) {
      commit('setDisMKT',value)
    },
    setNameMKT({commit},value) {
      commit('setNameMKT',value)
    },
    setReloadMKT({commit}) {
      commit('setReloadMKT',Date.now())
    },
    setIs10101({commit},value) {
      commit('setIs10101',value)
    },
    setReloadIs10101({commit}) {
      commit('setReloadIs10101',Date.now())
    },
    setDisMemberCode({commit},value) {
      commit('setDisMemberCode',value)
    },
    setDisMemberName({commit},value) {
      commit('setDisMemberName',value)
    },
    setReloadMem({commit}) {
      commit('setReloadMem',Date.now())
    },
    setTimePick({commit},value) {
      commit('setTimePick',value)
    },
    setDatePick({commit},value) {
      commit('setDatePick',value)
    },
    setAddressDelivery({commit},value) {
      commit('setAddressDelivery',value)
    },
    setAddressTax({commit},value) {
      commit('setAddressTax',value)
    },
    setBookingItms({commit},value) {
      commit('setBookingItms',value)
    },
    setCheckOrderForm({commit}) {
      commit('setCheckOrderForm',Date.now())
    },
    setReloadFlee({commit}) {
      commit('setReloadFlee',Date.now())
    },
    setStoreOnline({commit}) {
      commit('setStoreOnline',Date.now())
    },
    setDeliveryFee({commit}, value) {
      commit('setDeliveryFee', value)
    },
    setWaitBooking({commit}, value) {
      commit('setWaitBooking', value)
    },
    setAddNewAddress({commit}, value) {
      commit('setAddNewAddress', value)
    },
    setDSBillAddress({commit}, value) {
      commit('setDSBillAddress', value)
    },
    setWSOTP({commit}, value) {
      commit('setWSOTP', value)
    },
    setWSdiscountBill({commit}, value) {
      commit('setWSdiscountBill', value)
    },
    setWSplpp({commit}, value) {
      commit('setWSplpp', value)
    },
    setWSt1c({commit}, value) {
      commit('setWSt1c', value)
    },
    clearStore({commit}){
      main.removeCookie('QTDFSTORE')
      main.removeCookie('QTSTORENAME')
      main.removeCookie('DSCUSTID')
      main.removeCookie('DSMMSSTORE')
      commit('clearStore')
    },
    clearAfterConfirmCheckout({commit, dispatch}){
      main.removeCookie('QTCHKP')
      main.removeCookie('QTPDLV')
      dispatch('clearStore')
      commit('clearAfterConfirmCheckout')
    },
    clearAfterLogout({commit, dispatch}){
      main.removeCookie('QTCHKP')
      main.removeCookie('QTPDLV')
      dispatch('clearStore')
      commit('clearAfterLogout')
    },
    setReloadStore({commit}) {
      commit('setReloadStore',Date.now())
    },
    setReloadStaff({commit}) {
      commit('setReloadStaff',Date.now())
    },
    setStcShowStock({commit}, EPDSSTORE) {
      main.saveCookieEncrypt("EPDSSTORE", EPDSSTORE)
      commit('setStcShowStock', EPDSSTORE)
    },
    setStShowStock({commit}, EPDSSTCODE) {
      main.saveCookieEncrypt("EPDSSTCODE", EPDSSTCODE)
      commit('setStShowStock', EPDSSTCODE)
    },
  },
  getters: {
    getQTSAVESTORE(state) {
      return state.QTSAVESTORE?state.QTSAVESTORE:'';
    },
    // getQTSAVESTORE_Array(state) {
    //   let locationsTEMP=state.QTSAVESTORE.split('|');
    //   let locations = locationsTEMP.map(e => e.split(','));
    //   return locations?locations:'';
    // },
    getQTDFSTORE(state) {
      return state.QTDFSTORE;
    },
    getQTSTORENAME(state) {
      return state.QTSTORENAME;
    },
    getDSCUSTID(state) {
      return state.DSCUSTID;
    },
    getDSMMSSTORE(state) {
      return state.DSMMSSTORE;
    },
    getReloadCart(state) {
      return state.ReloadCart;
    },
    getQTCHKP(state) {
      return state.QTCHKP?state.QTCHKP:'Pickup';
    },
    getQTPDLV(state) {
      return state.QTPDLV;
    },
    getChangeAddress(state) {
      return state.ChangeAddress;
    },
    getReloadOrderInfo(state) {
      return state.ReloadOrderInfo;
    },
    getReloadOrderInfoF101(state) {
      return state.ReloadOrderInfoF101;
    },
    getCallOrderInfo(state) {
      return state.CallOrderInfo;
    },
    getReloadPlpp(state) {
      return state.ReloadPlpp;
    },
    getDisMKT(state) {
      return state.DisMKT;
    },
    getNameMKT(state) {
      return state.NameMKT;
    },
    getReloadMKT(state) {
      return state.ReloadMKT;
    },
    getIs10101(state) {
      return state.Is10101;
    },
    getReloadIs10101(state) {
      return state.ReloadIs10101;
    },
    getDisMemberCode(state) {
      return state.DisMemberCode;
    },
    getDisMemberName(state) {
      return state.DisMemberName;
    },
    getReloadMem(state) {
      return state.ReloadMem;
    },
    getTimePick(state) {
      return state.TimePick;
    },
    getDatePick(state) {
      return state.DatePick;
    },
    getAddressDelivery(state) {
      return state.AddressDelivery;
    },
    getAddressTax(state) {
      return state.AddressTax;
    },
    getBookingItms(state) {
      return state.BookingItms;
    },
    getCheckOrderForm(state) {
      return state.CheckOrderForm;
    },
    getReloadFlee(state) {
      return state.ReloadFlee;
    },
    getStoreOnline(state) {
      return state.StoreOnline;
    },
    getDeliveryFee(state) {
      return state.DeliveryFee;
    },
    getWaitBooking(state) {
      return state.WaitBooking;
    },
    getAddNewAddress(state) {
      return state.AddNewAddress;
    },
    getDSBillAddress(state) {
      return state.DSBillAddress;
    },
    getReloadStore(state) {
      return state.ReloadStore;
    },
    getReloadStaff(state) {
      return state.ReloadStaff;
    },
    getWSOTP(state) {
      return state.WSOTP;
    },
    getWSdiscountBill(state) {
      return state.WSdiscountBill;
    },
    getWSplpp(state) {
      return state.WSplpp;
    },
    getWSWSt1c(state) {
      return state.WSt1c;
    },
    getStcShowStock(state) {
      return state.StcShowStock;
    },
    getStShowStock(state) {
      return state.StShowStock;
    },
  },
  mutations: {
    setQTSAVESTORE(state, QTSAVESTORE) {
      state.QTSAVESTORE = QTSAVESTORE;
    },
    setQTDFSTORE(state, QTDFSTORE) {
      state.QTDFSTORE = '026';
    },
    setQTSTORENAME(state, QTSTORENAME) {
      state.QTSTORENAME = QTSTORENAME;
    },
    setDSCUSTID(state, DSCUSTID) {
      state.DSCUSTID = DSCUSTID;
    },
    setDSMMSSTORE(state, DSMMSSTORE) {
      state.DSMMSSTORE = DSMMSSTORE;
    },
    setReloadCart(state, ReloadCart) {
      state.ReloadCart = ReloadCart;
    },
    setQTCHKP(state, value) {
      state.QTCHKP = value;
    },
    setQTPDLV(state, value) {
      state.QTPDLV = value;
    },
    setChangeAddress(state, value) {
      state.ChangeAddress = value;
    },
    setReloadOrderInfo(state, value) {
      state.ReloadOrderInfo = value;
    },
    setReloadOrderInfoF101(state, value) {
      state.ReloadOrderInfoF101 = value;
    },
    setCallOrderInfo(state, value) {
      state.CallOrderInfo = value;
    },
    setReloadPlpp(state, value) {
      state.ReloadPlpp = value;
    },
    setDisMKT(state, value) {
      state.DisMKT = value;
    },
    setNameMKT(state, value) {
      state.NameMKT = value;
    },
    setReloadMKT(state, value) {
      state.ReloadMKT = value;
    },
    setIs10101(state, value) {
      state.Is10101 = value;
    },
    setReloadIs10101(state, value) {
      state.ReloadIs10101 = value;
    },
    setDisMemberCode(state, value) {
      state.DisMemberCode = value;
    },
    setDisMemberName(state, value) {
      state.DisMemberName = value;
    },
    setReloadMem(state, value) {
      state.ReloadMem = value;
    },
    setTimePick(state, value) {
      state.TimePick = value;
    },
    setDatePick(state, value) {
      state.DatePick = value;
    },
    setAddressDelivery(state, value) {
      state.TimePick = value;
    },
    setAddressTax(state, value) {
      state.AddressTax = value;
    },
    setBookingItms(state, value) {
      state.BookingItms = value;
    },
    setCheckOrderForm(state, value) {
      state.CheckOrderForm = value
    },
    setReloadFlee(state, value) {
      state.ReloadFlee = value;
    },
    setStoreOnline(state, value) {
      state.StoreOnline = value;
    },
    setDeliveryFee(state, value) {
      state.DeliveryFee = value
    },
    setWaitBooking(state, value) {
      state.WaitBooking = value
    },
    setAddNewAddress(state, value) {
      state.AddNewAddress = value
    },
    setDSBillAddress(state, value) {
      state.DSBillAddress = value
    },
    setWSOTP(state, value) {
      state.WSOTP = value
    },
    setWSdiscountBill(state, value) {
      state.WSdiscountBill = value
    },
    setWSplpp(state, value) {
      state.WSplpp = value
    },
    setWSt1c(state, value) {
      state.WSt1c = value
    },
    clearStore(state) {
      state.QTDFSTORE = '026';
      state.QTSTORENAME = null;
      state.DSCUSTID = null
      state.StcShowStock = null;
      state.StShowStock = null;
      state.DSMMSSTORE = null;
    },
    clearAfterConfirmCheckout(state) {
      state.QTCHKP = null;
      state.QTPDLV = null;
    },
    clearAfterLogout(state) {
      state.QTCHKP = null;
      state.QTPDLV = null;
    },
    setReloadStore(state, value) {
      state.ReloadStore = value;
    },
    setReloadStaff(state, value) {
      state.ReloadStaff = value;
    },
    setStcShowStock(state, value) {
      state.StcShowStock = value;
    },
    setStShowStock(state, value) {
      state.StShowStock = value;
    },
  }
};