<template>
  <section>
    <div class="row">

      <div v-for="(e,index)  in listData" :key="index" class="col-6 col-sm-6 col-md-4 row-height col-lg-3 p-1">
        <ProductCard v-if="e.skcount==1" :cardData="e" :key="$route.path" />
        <SpuCard v-else :cardData="e" />
      </div>

    </div>
    <SkeletonProductCard v-if="isLoading" />
    
    <div  v-else class="d-flex justify-content-center w-100 mb-3">
        <a v-if="!isZeroRow" :class="`btn-border-${getBU}`" @click="showCategory()">ดูเพิ่มเติม มีอีก {{MaxCount-startCount}} รายการ</a>
    </div>
    
  </section>
</template>


<script>
  import * as serviceAPI from '../services/API.service';
  import * as serviceMain from '../services/main.service';
  import ProductCard from '@/components/ProductCard.vue'
  import SpuCard from '@/components/SpuCard.vue'
  // import PropertyProduct from '@/components/PropertyProduct.vue'
  import SkeletonProductCard from '@/components/SkeletonProductCard.vue'
  export default {
    name: 'Category',
    data: function () {
      return {
        listData: [],
        isLoading:true,
        startCount:0,
        wantShowCount:24,
        isZeroRow:false,
        MaxCount:null

      }
    },
    mounted() {
      this.showCategory();
      this.scroll();
    },
    components: {
      ProductCard,
      SpuCard,
      SkeletonProductCard,
      // PropertyProduct
    },
    props: [
      "catcode"
    ],
    methods: {
      async showCategory() {
        try {
          // Pricesort ASC
          // Pricesort DESC
          // Name ASC
          // Name DESC
          let getFilter = this.$route.query.fill;
          let getSort = this.$route.query.sort;
          this.isLoading = true
          let getCat = this.catcode
          let getLength = getCat.length;
          // if(getLength==4){
          //   getCat = getCat+"00"
          // }
          // if(getLength==2){
          //   getCat = getCat+"0000"
          // }
          if(this.MaxCount==null){
            let data = {
              "CATCODE": getCat,
              "STCODE": this.getStoreCode,
              "FILTER": getFilter||'',
              "ISTB": this.$store.getters['auth/getISTB'] ? 1:0
              // MINPRICE, MAXPRICE
            }
            let getAPI = await serviceAPI.call_API('post', `product/spupaging`, data,'auth');
            this.MaxCount = getAPI.data.dbitems[0].skcount;
            
          }
          
          let endCount = this.startCount + this.wantShowCount
          if(endCount>this.MaxCount){
            endCount =this.MaxCount
            this.isZeroRow=true;
          }
          let data = {
            "CATCODE": getCat,
            "STCODE": this.getStoreCode,
            "FILTER": getFilter||'',
            "ORDERBY": getSort||'',
            "START": this.startCount,
            "END": this.wantShowCount,
            "STCODESTOCK": this.$store.getters["service/getStShowStock"],
            "ISTB": this.$store.getters['auth/getISTB'] ? 1:0
          }
          let getAPI = await serviceAPI.call_API('post', `product/spu`, data,'auth');
          this.listData = [...this.listData,...getAPI.data.dbitems];
          
          this.listData.map((e) => { e.prop = e.prop? e.prop : []})
          let SKCODE = []
          getAPI.data.dbitems.forEach(e => {
            SKCODE.push(e.skcode)
          });
          let dataprop = { SKCODE: SKCODE }
          let getAPIprop = await serviceAPI.call_API('post', `product/productAttributeBySkus`, dataprop,'auth');
          console.log(getAPIprop);
          if(getAPIprop.data.dbrows != 0){
            this.listData.map((e, i) => {
              getAPIprop.data.dbitems.map(element => {
                if(element.skcode == e.skcode && !e.prop.includes(element.base64)){
                  e.prop.push(element.base64)
                }
              })
            })
          }

          this.isLoading = false
          this.startCount = endCount
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },

      openNav() {
        $("#mySidenav").show()
        // document.getElementById("mySidenav").show();
      },
      closeNav() {
        $("#mySidenav").hide()
        // document.getElementById("mySidenav").hide();
      },
      scroll () {
        window.onscroll = () => {
          let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight

          if (bottomOfWindow) {
            if(!this.isZeroRow){
              this.showCategory()
            }
          }
        }
      }


    },
    computed:{
      getBU:function(){
        return this.$store.getters['customer/getQTBU']
      },
      getStoreCode:function(){
        return this.$store.getters['service/getQTDFSTORE']
      }
    },
    watch:{
      getStoreCode:function(){
        this.startCount = 0;
        this.listData = [];
        this.showCategory()
      }
    }
  }

</script>

<style scoped>
 .row-height{
   min-height: 100%;
 }
</style>