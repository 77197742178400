<template>
      <img :src="dataIMG" alt="">
</template>


<script>
  import * as serviceAPI from '../services/API.service';


  export default {
    name: 'ImageBySKU',
    data: function () {
      return {
        dataIMG:''
      }
    },
    mounted() {
      this.showIMG()
    },
    props: [
      "sku",
      "buCode"
    ],
    methods: {
      async showIMG(){
        try {
          let data={}
          let getAPI = await serviceAPI.call_API('get',`service/image/${this.sku}`,data,'auth');
          let getData =  getAPI.data.dbitems[0].base64
          this.dataIMG = "data:image/jpeg;base64,"+ getData;
      
        } catch (error) {
          let bu = this.buCode || this.getBU
          if(bu == 'bnb'){
            this.dataIMG = '/img/no-imgbnb.png';
          } else if (bu == 'at1') {
            this.dataIMG = '/img/no-imgat1.png';
          } else{
            this.dataIMG = '/img/no-img.jpg';
          }
        }
      }
    },
    computed: {
      getBU: function () {
          return this.$store.getters['customer/getQTBU']
      },
    }
  }
       
</script>

<style scoped>

</style>