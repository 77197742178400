
import * as serviceAPI from './API.service';

export const Base64 = {
	encode: function(s) {
		return btoa(unescape(encodeURIComponent(s)));
	},
	decode: function(s) {
		return decodeURIComponent(escape(atob(s)));
	}
  };
export function saveCookie(name, value, days) {
	var date = new Date();
	if (days)
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000)); //n days
	else
		date.setTime(date.getTime() + (10 * 60 * 60 * 1000)); //106 hour 

	var expires = "; expires=" + date.toUTCString();
	//	var expires = "";
	document.cookie = name + "=" + value + expires + "; path=/";
}

export function readCookie(name) {
	try {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		}
		return null;
	} catch (error) {
		return null	
	}
}
export function readCookieEncrypt(name) {
	try {
		let read = Base64.decode(readCookie(name));
		return read
	} catch (error) {
		return null	
	}
}
export function saveCookieEncrypt(name, value, days) {
	try {
		saveCookie(name, Base64.encode(value), days)
		return 
	} catch (error) {
		return null	
	}
}
import jwt_decode from 'jwt-decode';
export const parseJwtByLocalStorage = (token) => {
	try {
		// let getDataToken = JSON.parse(atob(atob(atob(localStorage.getItem(token))).split('.')[1]));
		let getDataToken = jwt_decode(atob(atob(localStorage.getItem(token))))
		let getDate = Date.now();
		let getexp = getDataToken.exp * 1000;
		if (getDate >= getexp) {
			return null;
		}
		return getDataToken
	} catch (e) {
		return null;
	}
};

export const parseJwtByCookie = (token) => {
	try {
		return JSON.parse(atob(readCookie(token).split('.')[1]));
	} catch (e) {
		return null;
	}
};

export function removeCookie(name) {
	saveCookie(name, "", -1);
}

export function showSuccessAlert(_this, para_message, para_title='Success', haveAction=false) {
	_this.$swal.fire({
		icon: "success",
		title: para_title,
		text: para_message,
	})
	.then(async (result) => {
		if (result.value) {
			if(haveAction) _this.actionMethod();
		}
	  });;
}
export function showSuccessAlertWithTime(_this, para_message,para_time) {
	_this.$swal.fire({
		icon: "success",
		title: "Success",
		html: `<h2>${para_message}</h2>`,
		showConfirmButton: false,
		timer: para_time
	});
}
export function showWarningAlert(_this, para_message) {
	_this.$swal.fire({
		icon: "warning",
		title: "Warning",
		text: para_message,
	});
}
export function showErrorAlert(_this,error) {
	let getUL = '';
	try {
		if(error.validation){
			getUL = error.validation.map(e => `<p class="text-center">${e.msg}</p>` ).join("");
		}
	} catch (error) {
		console.log(error)
	}
	if(error ==	'store is not defined'){

	}
	else if(error.message){
		_this.$swal.fire({
			icon: "error",
			title: "Oops...",
			html:`
			<h3>${error.message.replace("ER_SIGNAL_EXCEPTION:","").replace("ER_DATA_TOO_LONG:","")}</h3>
			${getUL}
			
			
			`,
		  });
	}else{
		_this.$swal.fire({
			icon: "error",
			title: "Oops...",
			html:`
			<h3>${error}</h3>
			<ul>
			${getUL}
			</ul>
			
			`,
		  });
	}
}
// export function showErrorAlert(_this, para_message) {
// 	_this.$swal.fire({
// 		icon: "error",
// 		title: "Oops...",
// 		text: para_message,
// 	});
// }
export function generateUID()
{
    var seed = Date.now();
    if (window.performance && typeof window.performance.now === "function") {
        seed += performance.now();
    }

    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (seed + Math.random() * 16) % 16 | 0;
        seed = Math.floor(seed/16);

        return (c === 'x' ? r : r & (0x3|0x8)).toString(16);
    });

    return uuid;
} 

export function getMCID(para_MCID)
{
    let getUUID = localStorage.getItem(para_MCID)
	if(!getUUID){
		getUUID = generateUID()
		localStorage.setItem(para_MCID, getUUID);
	}
	return getUUID
} 
export function getStoreData(para_KEYSTORE)
{
    let locationsTEMP=readCookieEncrypt(para_KEYSTORE).split('|');
    return locationsTEMP.map(e => e.split(','));
} 
export function  Delay(time){
 return new Promise((resolve) => {
	setTimeout(() => {
		resolve('OK');
	}, time);
	});
}

// export async function checkConditionOrderform(_this, salesource) {
// 	try {
// 		if(['UO', 'US'].includes(salesource)) {
// 			let usedOrderForm = false;
// 			$('.order-form-flag').each((e, obj) => {
// 				let ofFlag = $(obj).attr('offlag');
// 				usedOrderForm = ofFlag == '1';
	
// 				if(usedOrderForm) return false;
// 			})
	
// 			if(!usedOrderForm) {
// 				$('.order-form-project-flag').each((e, obj) => {
// 					let ofFlag = $(obj).attr('offlag');
// 					usedOrderForm = ofFlag == '1';
		
// 					if(usedOrderForm) return false;
// 				})
// 			}
			
// 			let data = {
// 				REF: store.getters['customer/getQTCUSTREF'],
// 				SALESOURCE: usedOrderForm ? 'UO' : 'US'
// 			}
				
// 			await serviceAPI.call_API('post',`user/updateSalesource`,data,'auth');
// 			store.dispatch('auth/setQTSALESOURCE', usedOrderForm ? 'UO' : 'US')
// 		}
// 	} catch(error) {
// 		this.showErrorAlert(_this, error);
// 	}
	
// }