<template>
    <div class="sidebar" id="Sidebar">
        <div class="sidebar-backdrop" @click="closeSidebarPanelS" v-if="isPanelOpenS"></div>
        <transition name="slide">
            <div v-if="isPanelOpenS"
                 class="sidebar-panel">
                 <span style="font-size:30px;">สวัสดี {{$store.getters['auth/getQTUSERID']}} ( {{ getQTSUBSALESOURCE }} ) </span>
                 <span class="pointer"><a @click="closeSidebarPanelS()"><i class="fas fa-times float-right" style="font-size:50px;"></i></a></span>
                 <br>
                 <!-- <button @click="closeSidebarPanelS">{{isPanelOpenS}}</button> -->
                 <ul id="navmobile" class="nav sidebar-nav sidebar-panel-nav" v-if="navmobileOpen">
                    <li id="slctstore" class="dropdown" @click="closeSidebarPanelS()">
                        <a v-if="!getWSFLAG" @click="$router.push({ name: 'FindUser'})" style="font-size:30px;cursor: pointer;">ค้นหาลูกค้า<span id="Mstnamestore"></span></a>
                    </li>
                    <!-- <li  class="dropdown" @click="closeSidebarPanelS">
                        <a @click="$router.push({ name: 'Product', params: { getcatcode: 990000 }})" style="font-size:30px;cursor: pointer;">ค้นหาสินค้า vFIX<span ></span></a>
                    </li> -->
                    <li v-if="qtCustInfo.VIPID != ''" class="dropdown" @click="closeSidebarPanelS">
                        <a @click="$router.push({ name: 'Address' })" style="font-size:30px;cursor: pointer;">จัดการที่อยู่<span ></span></a>
                    </li>
                    <!-- <li v-if="getBU == 'at1'" class="dropdown" @click="closeSidebarPanelS">
                        <a @click="$router.push({ name: 'OrderAuto1' })" style="font-size:30px;cursor: pointer;">Order Auto1<span ></span></a>
                    </li>
                    <li v-if="getWSFLAG" class="dropdown" @click="closeSidebarPanelS">
                        <a @click="$router.push({ name: 'OrderWholeSale' })" style="font-size:30px;cursor: pointer;">Order WholeSale<span ></span></a>
                    </li> -->
                    <li  class="dropdown" @click="closeSidebarPanelS">
                        <a @click="$router.push({ name: 'History', params: { whereBy: 'ByUser' }})" style="font-size:30px;cursor: pointer;">รายการคำสั่งซื้อ<span ></span></a>
                    </li>
                    <li  class="dropdown" @click="closeSidebarPanelS">
                        <a @click="$router.push({ name: 'Template'})" style="font-size:30px;cursor: pointer;">Template<span ></span></a>
                    </li>
                    <!-- <li id="slctstore" class="dropdown" @click="closeSidebarPanelS">
                        <a @click="openStoreS" style="font-size:30px;cursor: pointer;">สาขา<span id="Mstnamestore"></span></a>
                    </li> -->
                    <li style="display:block;">
                        <a @click="loadmmenu()" style="cursor: pointer;">
                        <div class="row d-flex align-items-center">
                            <div class="col-xs-2">
                                
                            </div>
                            <div class="col-xs-8" style="margin-left: 15px;font-size: 30px">
                                หมวดหมู่สินค้า
                            </div>
                            <div class="col-xs-2 ml-auto">
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </div>
                        </div>
                        </a>
                    </li>
                    <li v-if="$store.getters['auth/getloggedIn']" id="setlogout2" @click="closeSidebarPanelS">
                        <a @click="logout" style="font-size:30px;cursor: pointer;">ออกจากระบบ</a>
                    </li>
                    <li v-else id="setlogin" @click="closeSidebarPanelS">
                        <a @click="$router.push({ name: 'Login'})" style="font-size:30px;cursor: pointer;">เข้าสู่ระบบ</a>
                    </li>
                </ul>
                <ul id="navmenu" class="nav sidebar-nav sidebar-panel-nav" v-if="navmenuOpen">
                    <li>
                        <div class="row d-flex align-items-center" @click="closemenu()" style="cursor: pointer;">
                            <div class="col-xs-2">
                                <i class="fa fa-chevron-left" aria-hidden="true"></i>
                            </div>
                            <div class="col-xs-8">
                                <a  style="font-size:30px;">กลับสู่เมนูหลัก</a>
                            </div>
                            <div class="col-xs-2">

                            </div>
                        </div>
                    </li>
                    <br>
                    <ul class="pl-3" >
                        <li v-for="(e,index) in listData" :key="index" >
                            <div class="row d-flex align-items-center justify-content-between" @click="loadnlevel(e.catname, e.node)" style="cursor: pointer;">
                                <div class="col-xs-2">
                                    
                                </div>
                                <div class="col-xs-9" style="max-width: 80vw;">
                                     <a style="font-size:25px;"><img :src="`/img/lo/${e.node}.webp`" class="pr-1" style="height: 42px;" onerror="this.style.display='none'">{{ e.catname }}<span></span></a>
                                </div>
                                <div class="col-xs-1 ml-auto">
                                    <div v-if="e.HAVENEXT">
                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </ul>
                <ul id="navlevel" class="nav sidebar-nav sidebar-panel-nav" v-if="navlevelOpen">
                    <li>
                        <div class="row d-flex align-items-center" @click="backmenu()" style="cursor: pointer;">
                            <div class="col-xs-2">
                                <i class="fa fa-chevron-left" aria-hidden="true"></i>
                            </div>
                            <div class="col-xs-8">
                                <a  style="font-size:30px;">กลับเมนูก่อนหน้า</a>
                            </div>
                            <div class="col-xs-2">

                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="row d-flex align-items-center">
                            <div class="col-xs-2">
                                
                            </div>
                            <div class="col-xs-8">
                                <a  style="font-size:30px;max-width: 350px;"><span id="nlhead">{{ navlevelHead }}</span></a>
                            </div>
                            <div class="col-xs-2">

                            </div>
                        </div>
                    </li>
                    <br>
                    <ul class="pl-3" >
                        <li v-for="(e,index) in listData" :key="index" >
                            <div class="row d-flex align-items-center" @click="(e.HAVENEXT ? loadnlevel(e.catname, e.node) : loadSPage(e.node))" style="cursor: pointer;">
                                <div class="col-xs-2">
                                    
                                </div>
                                <div class="col-xs-8" style="max-width: 60vw;" >
                                    <a style="font-size:25px;">{{ e.catname }}<span></span></a>
                                </div>
                                <div class="col-xs-2 ml-auto">
                                    <div v-if="e.HAVENEXT">
                                        <i class="fa fa-chevron-right" aria-hidden="true" ></i>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </ul>
            </div>
        </transition>
    </div>
</template>
<script>
import * as serviceAPI from '../services/API.service';
import * as serviceMain from '../services/main.service';
    export default {
        name: 'Sidebar',
        data: () => ({
            // isPanelOpen: false,
            navmenuOpen: false,
            navlevelOpen: false,
            navmobileOpen: true,
            listData: [],
            listHead: [],
            navlevelHead: "",
            catcode: "",
        }),
        props: ["isPanelOpenS"],
        methods: {
            closeSidebarPanelS() {
                this.listData = [];
                this.navmenuOpen = false;
                this.navmobileOpen= true;
                this.navlevelOpen= false;
                this.$emit("closeSidebarPanelS")
            },
            openStoreS() {
                this.$emit("openStore")
            },
            async loadmmenu(){
                this.navmenuOpen = true;
                this.navmobileOpen = false;
                this.navlevelOpen = false;
                try {
                    let data={}
                    let getAPI = await serviceAPI.call_API('get','product/category/',data,'auth');
                    this.listData = getAPI.data.dbitems;

                } catch (error) {
                    serviceMain.showErrorAlert(this, error.message)
                }
            },
            async loadnlevel(navlevelHead, getCat){
                this.navlevelHead = navlevelHead;
                this.catcode = getCat;
                this.navmenuOpen = false;
                this.navlevelOpen = true;
                this.listHead.push(navlevelHead);
                try {
                    let data={}
                    let getAPI = await serviceAPI.call_API('get',`product/category${(getCat)?'/'+getCat:''}`,data,'auth');
                    // let getAPI = await serviceAPI.call_API('get','product/category/',data,'auth');
                    this.listData = getAPI.data.dbitems;
                    if(this.listData.length == 0){
                        this.$router.push("/Product/"+ getCat +'0000');
                        this.closeSidebarPanelS();
                    }

                } catch (error) {
                    serviceMain.showErrorAlert(this, error.message)
                }
            },
            loadSPage(url){
                // this.$router.push(url);
                this.$router.push('/Product/'+ (url.length == 6 ? url : (url.length == 4 ? url+'00' : url+'0000')))
                this.closemenu();
                this.closeSidebarPanelS();
            },
            closemenu(){
                this.navmenuOpen = false;
                this.navlevelOpen = false;
                this.listData=[];
                this.navmobileOpen = true;
                this.listHead=[];
                this.catcode='';
                catcode: "";
            },
            async backmenu(){
                this.listData=[];
                this.listHead.pop();
                this.navlevelHead = this.listHead[this.listHead.length-1];
                if(this.catcode>100){
                    try {
                        let data={}
                        let getAPI = await serviceAPI.call_API('get',`product/category${(this.catcode)?'/'+Math.floor(this.catcode/100):''}`,data,'auth');
                        // let getAPI = await serviceAPI.call_API('get','product/category/',data,'auth');
                        this.listData = getAPI.data.dbitems;
                        this.catcode = Math.floor(this.catcode/100);

                    } catch (error) {
                        serviceMain.showErrorAlert(this, error.message)
                    }
                }
                else{
                    this.loadmmenu()
                }
            },
            async logout() {
                if(this.$store.getters['auth/getISTB']){
                    this.$router.push("/twdb2b/login");
                } else {
                    this.$router.push("/login");
                }
                this.$store.dispatch("auth/logout");
                this.$store.dispatch("customer/clearAfterLogout");
                this.$store.dispatch("service/clearAfterLogout");
            },
        },
        computed:{
            isPro:function(){
                return process.env.NODE_ENV == 'production'
            },
            getQTSUBSALESOURCE: function() {
                return this.$store.getters["auth/getQTSUBSALESOURCE"];
            },
            qtCustInfo: function() {
                return this.$store.getters["customer/getQTCUSTINFO"];
            },
            getBU: function() {
                return this.$store.getters["customer/getQTBU"];
            },
            getWSFLAG: function () {
                return this.$store.getters['auth/getWSFLAG']
            },
        }
    }
</script>
<style>
    .slide-enter-active,
    .slide-leave-active
    {
        transition: transform 0.2s ease;
    }

    .slide-enter,
    .slide-leave-to {
        transform: translateX(-100%);
        transition: all 150ms ease-in 0s
    }

    .sidebar-backdrop {
        background-color: rgba(0,0,0,.5);
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        cursor: pointer;
    }

    .sidebar-panel {
        overflow-y: auto;
        background-color: #ffffff;
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        z-index: 999;
        padding: 3rem 20px 2rem 20px;
        width: 100%;
    }

    /*-------------------------------*/
    /*     Sidebar nav styles        */
    /*-------------------------------*/

    .sidebar-nav {
    position: relative;
    top: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    /* background-color: white; */
    }

    .sidebar-nav li {
    position: relative;
    line-height: 20px;
    display: inline-block;
    width: 100%;
    margin-bottom: 2px;
    /* background-color: rgb(241, 241, 241); */
    }

    .dropdown-menu>li {
    /* background-color: rgb(255, 255, 255); */
    }

    /* .dropdown-menu > li:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100%;
    width: 3px;
    background-color: #1c1c1c;
    } */

    .sidebar-nav li:before {
    /* content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100%;
    width: 3px;
    background-color: #1c1c1c; */

    }

    .sidebar-nav li:first-child {
    margin-top: 0 !important;
    }

    .sidebar-nav li:hover:before,
    .sidebar-nav li.open:hover:before {
    /* width: 100%; */

    }


    .sidebar-nav li a {
    display: block;
    color: rgb(0, 0, 0);
    font-weight: bold;
    text-decoration: none;
    padding: 10px 15px 10px 15px;
    }

    .sidebar-nav li a:hover,
    .sidebar-nav li a:active,
    .sidebar-nav li a:focus,
    .sidebar-nav li.open a:hover,
    .sidebar-nav li.open a:active,
    .sidebar-nav li.open a:focus {
    /* color: #fff; */
    text-decoration: none;
    background-color: transparent;
    }

    .sidebar-nav>.sidebar-brand {
    height: 65px;
    font-size: 20px;
    line-height: 44px;
    }

    .sidebar-nav .dropdown-menu {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
    background-color: rgb(255, 255, 255);
    box-shadow: none;
    }
</style>