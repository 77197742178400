<template>
  <section>
    <div class="container">
      <ProdDetail
        v-if="!$store.getters['auth/getISB2B']"
        :slugName="slugName"
      />
      <ProdDetailB2B v-else :slugName="slugName" />
    </div>
    <Comparefuc />
  </section>
</template>

<script>
  import ProdDetail from "@/components/ProdDetail.vue";
  import ProdDetailB2B from "@/components/ProdDetailB2B.vue";
  import Comparefuc from "@/components/comparefuc.vue";
  export default {
    name: "ProductDetail",
    components: {
      ProdDetail,
      ProdDetailB2B,
      Comparefuc,
    },
    data: function() {
      return {};
    },
    props: {
      slugName: {
        type: String,
        required: true,
      },
    },
    mounted() {
      document.title = `${process.env.VUE_APP_TITLE} - Product Detail`;
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-CRY3HG283C')
      document.head.appendChild(recaptchaScript)
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-CRY3HG283C');
    },
    methods: {},
  };
</script>
