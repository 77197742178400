<template>
  <section>
    <div v-if="!isLoading" class="row">
      <div class="col-md-3 col-sm-12 mt-4">
        <div class="text-center">
          <img height="200" width="200" v-bind:src="project.preview" alt="" />
        </div>
        <p
          class="text-center"
          style="margin-bottom:0px;font-family: 'kittithadabold75regular'"
        >
          {{ project.reference ? project.reference : "-" }}
        </p>
        <p class="text-center" style="margin-bottom:0px;margin-top: -10px;">
          {{ project.date }}
        </p>
        <p
          class="text-center"
          style="margin-bottom:0px;margin-top: -10px;color: #999999;"
        >
          (สินค้า {{ totalOrders(prices) }} รายการ)
        </p>
        <p
          class="text-center"
          style="font-family: 'kittithadabold75regular';font-size:1.2em;margin-bottom:0px;margin-top: -10px;color: #55246c;"
        >
          <span>{{ project.total_price }}</span>
        </p>
        <div
          class="text-center"
          style="margin-bottom:0px;margin-top: -10px;width:100%"
        >
          <button
            @click="sentToCart(project.id)"
            style="width: 60%;background-color: #EAAD21;font-family: 'kittithadathin35regular';font-size:24px; color: black;margin-top: 12px;border-radius: 4px;border: none;padding: 0px 20px;"
          >
            <b>ใส่ตะกร้า</b>
          </button>
        </div>
        <div
          class="text-center"
          style="margin-bottom:0px;margin-top: -10px;width:100%"
        >
          <button
            @click="redirectProdboard(project.id)"
            style="width: 60%;background-color: #EAAD21;font-family: 'kittithadathin35regular';font-size:24px; color: black;margin-top: 12px;border-radius: 4px;border: none;padding: 0px 20px;"
          >
            <b>แก้ไขห้องครัวนี้</b>
          </button>
        </div>
        <div
          class="text-center"
          style="margin-bottom:0px;margin-top: -10px;width:100%"
        >
          <button
            @click="copyProdboard(project.id)"
            style="width: 60%;background-color: #EAAD21;font-family: 'kittithadathin35regular';font-size:24px; color: black;margin-top: 12px;border-radius: 4px;border: none;padding: 0px 20px;"
          >
            <b>Copy URL</b>
          </button>
        </div>
        <div class="text-center" style="margin-bottom:0px;margin-top: -10px;">
          <button
            @click="saveImage(project.preview)"
            style="width: 60%;background-color: #EAAD21;font-family: 'kittithadathin35regular';font-size:24px; color: black;margin-top: 12px;border-radius: 4px;border: none;padding: 0px 20px;"
          >
            <b>บันทึกรูป 1</b>
          </button>
        </div>
        <div
          v-for="(image, i) in project.images"
          :key="i"
          class="text-center"
          style="margin-bottom:0px;margin-top: -10px;"
        >
          <button
            @click="saveImage(image)"
            style="width: 60%;background-color: #EAAD21;font-family: 'kittithadathin35regular';font-size:24px; color: black;margin-top: 12px;border-radius: 4px;border: none;padding: 0px 20px;"
          >
            <b>บันทึกรูป {{ i + 2 }}</b>
          </button>
        </div>
      </div>
      <div class="col-md-9 col-sm-12 mt-4">
        <div class="mr-2 dot_all" style="background-color: #DDDDDD;">
          <div class="row">
            <div class="col-1 dot_right text-center pl-4">
              no.
            </div>
            <div class="col-2 dot_right text-center">
              ภาพ
            </div>
            <div class="col-3 dot_right text-center">
              รายการสินค้า
            </div>
            <div class="col-2 dot_right text-center">
              จำนวน
            </div>
            <div class="col-2 dot_right text-right">
              <span class="pr-1">ราคา/หน่วย</span>
            </div>
            <div class="col-2 text-right">
              <span class="pr-1">ราคารวม</span>
            </div>
          </div>
        </div>
        <div id="prices" class="mb-4">
          <div class="mr-2 dot_all">
            <div class="row" v-for="(price, index) in prices" :key="price.code">
              <div class="col-1 dot_right text-center pl-4">
                {{ index + 1 }}
              </div>
              <div class="col-2 dot_right text-center">
                <img
                  class="mx-auto d-block p-2"
                  style="width:100%;height:100px"
                  v-bind:src="price.image ? price.image : (`${getBU}` == `bnb` ? '/img/no-imgbnb.png' : '/img/no-img.jpg')"
                />
              </div>
              <div class="col-3 dot_right text-left">
                <span>{{ price.pr_name }}</span>
              </div>
              <div class="col-2 dot_right text-center">
                {{ price.quantity }} ชิ้น
              </div>
              <div class="col-2 dot_right text-right">
                <span class="pr-1">{{ price.db_price_convert }}</span>
              </div>
              <div class="col-2 text-right">
                <span class="pr-1">{{ price.db_price_qty }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading"></div>
  </section>
</template>

<script>
  import * as serviceAPI from "@/services/API.service";
  import * as serviceMain from "@/services/main.service";
  export default {
    name: "ProdboardDetailComp",
    data: function() {
      return {
        project: {},
        prices: {},
        isLoading: true,
      };
    },
    components: {},
    mounted() {
      $(".loading").show();
      this.showProjectDetail();
    },
    props: ["prodId"],
    methods: {
      totalOrders: function(values) {
        return values.reduce((acc, val) => {
          return acc + parseInt(val.quantity);
        }, 0);
      },
      async convert_currency(number) {
        var formatter = new Intl.NumberFormat("th-TH", {
          style: "currency",
          currency: "THB",
        });
        return formatter.format(number);
      },
      async saveImage(url) {
        window.open(url);
      },
      async copyProdboard(id) {
        let domain = `${window.location.origin}/Prodboard/${id}`;
        navigator.clipboard.writeText(domain);
        this.$swal.fire({
          icon: "success",
          title: "Copied",
          text: domain,
        });
      },

      async showProjectDetail() {
        try {
          let prodId = this.prodId;
          let getStoreId = this.getStore;
          const data = {
            id: prodId,
            store_id: getStoreId,
          };
          let getAPI = await serviceAPI.call_API(
            "post",
            `prodboard/getProjectByID`,
            data,
            "auth"
          );

          let res = getAPI.data.data;
          res.total_price = await this.convert_currency(res.total_price);
          this.project = res;
          let prices_format = res.prices;
          if (prices_format.length > 0) {
            for (var key in prices_format) {
              if (prices_format.hasOwnProperty(key)) {
                let db_price_float = parseFloat(
                  prices_format[key].db_price.replace(/,/g, "")
                );
                let price_qty = db_price_float * prices_format[key].quantity;
                const final_price_qty = await this.convert_currency(price_qty);
                const final_price = await this.convert_currency(db_price_float);
                prices_format[key]["db_price_convert"] = final_price;
                prices_format[key]["db_price_qty"] = final_price_qty;
              }
            }
          }
          this.prices = prices_format;
          // console.log(prices_format);
          $(".loading").hide();
          this.isLoading = false;
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async redirectProdboard(id) {
        try {
          // console.log(id);
          this.$router.push({
            name: "Prodboard",
            params: { getProdID: id },
          });
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async sentToCart(id) {
        try {
          let dataInfo = {
            REF: this.CartRef,
          };
          let getAPIProjectInfo = await serviceAPI.call_API(
            "post",
            `prodboard/getProjectInfo`,
            dataInfo,
            "auth"
          );
          const data_items = getAPIProjectInfo.data.dbitems;
          let exist = false;
          let project_array = [];
          if (data_items.length > 0) {
            for (var key in data_items) {
              if (data_items.hasOwnProperty(key)) {
                project_array.push(parseInt(data_items[key].PROJECT_ID));
              }
            }
          }

          let cntItems = 0;
          exist = project_array.includes(parseInt(id));
          if (!exist) {
            let items = this.project;
            let getStoreId = this.getStore;
            let prices = this.prices;
            let ref = this.getRef;
            let prices_format = [];
            var _this = this;
            if (prices.length > 0) {
              for (var key in prices) {
                if (prices.hasOwnProperty(key)) {
                  var innerObj = {};
                  innerObj["sku"] = prices[key].code;
                  innerObj["qty"] = prices[key].quantity;
                  cntItems += prices[key].quantity;
                  prices_format.push(innerObj);
                }
              }
              this.qtShopItem = cntItems;
            }
            const data = {
              REF: ref,
              project_id: id,
              store: getStoreId,
              image_preview: items.preview,
              title: items.reference,
              sub_title: "ตู้เสื้อผ้า DIY", //fix
              items: prices_format,
            };
            let getAPI = await serviceAPI.call_API(
              "post",
              `prodboard/sentToCart`,
              data,
              "auth"
            );
            if (getAPI.status_code == 200) {
              //
              // console.log(getAPI);
              $(".loading").hide();
              _this.$router.push({
                name: "Cart",
              });
            }
          } else {
            for(let item of data_items) {
              let jsnitems = JSON.parse(item.JSNITEMS);
              for(let jsnitem of jsnitems) {
                cntItems += jsnitem.qty;
              }
            }
            this.qtShopItem = cntItems;
            this.$router.push({ name: "Cart" });
          }
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
    },
    computed: {
      getStore: function() {
        return this.$store.getters["service/getQTDFSTORE"];
      },
      CartRef: function() {
        return this.$store.getters["customer/getQTCUSTREF"];
      },
      getRef: function() {
        return this.$store.getters["customer/getQTCUSTREF"];
      },
      getBU:function(){
        return this.$store.getters['customer/getQTBU']
      },
      qtShopItem: {
          get(){
              return this.$store.getters['customer/getQTSHOPITEM']
          }, 
          set(value) {
              this.$store.dispatch('customer/setQTSHOPITEM', value)
          }
      },
    },
    watch: {},
  };
</script>

<style scoped>
  .dot_all {
    border-color: #afafaf;
    border-width: 1px;
    border-style: dotted;
  }

  .dot_right {
    border-right-color: #afafaf;
    border-right-width: 1px;
    border-right-style: dotted;
  }
  .promoTag {
    width: 60px;
    height: 60px;
    background: #55246c;
    -moz-border-radius: 70px;
    -webkit-border-radius: 70px;
    border-radius: 70rgb (228, 207, 208);
    position: absolute;
    right: 0px;
    top: 0px;
    color: white;
    font-family: "kittithadabold75regular";
  }
  .group_promotion {
    position: absolute;
    top: -12%;
    margin-left: 40px;
    left: -50%;
  }
  .num_promotion {
    font-size: 44px;
  }
  .percent_promotion {
    top: 0;
    font-size: 20px;
  }
  .off_promotion {
    position: absolute;
    top: 40%;
    font-size: 30px;
    margin-left: 40px;
    left: -50%;
  }
  .loading {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background-color: #000000;
    background-image: url("/img/loadsmall.gif");
    background-size: 50px;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10000000;
    opacity: 0.7;
    filter: alpha(opacity=70);
  }
</style>
