import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index";
import Home from "../views/Home.vue";
import Product from "../views/Product.vue";
import ProductB2B from "../views/ProductB2B.vue";
import ProductSearchB2B from "../views/ProductSearchB2B.vue";
import B2BFinished from "../views/B2BFinished.vue";
import ProductSearch from "../views/ProductSearch.vue";
import ProductDetail from "../views/ProductDetail.vue";
import Category from "../views/Category.vue";
import Login from "../views/Login.vue";
import Notfound from "../views/Notfound.vue";
import Menu from "../views/Menu/Menu.vue";
import OTPRequest from "../views/OTPRequest.vue";
import ProdboardDetail from "../views/ProdboardDetail.vue";
import ProdboardShapeListPage from "../views/ProdboardShapeListPage.vue";
import OTPApvOrderMail from "../views/OTPApvOrderMail.vue";
import B2BApvOrderMail from "../views/B2BApvOrderMail.vue";
import * as serviceAPI from "../services/API.service";
const routes = [
  {
    path: "/",
    name: "Home",
    meta: { requiresAuth: true ,requiresNotB2B: true, requiresSOStatus: true },
    component: Home,
  },
  {
    path: "/Login",
    name: "Login",
    component: Login,
    // beforeEnter: (to, from, next) => {
    //   if (to.query.EMAIL) {
    //     next();
    //   } else {
    //     next({ name: "NotFound" });
    //   }
    // },
  },
  {
    path: "/FindUser",
    name: "FindUser",
    meta: { requiresAuth: true,requiresNotB2B: true,requiresNotTB: true },
    component: () =>
      import(/* webpackChunkName: "FindUser.vue" */ "../views/FindUser.vue"),
  },
  {
    path: "/Address",
    name: "Address",
    meta: { requiresAuth: true,requiresNotB2B: true,requiresNotTB: true },
    component: () =>
      import(/* webpackChunkName: "AddressManagement.vue" */ "../views/AddressManagement.vue"),

  },
  {
    path: "/OrderAuto1",
    name: "OrderAuto1",
    meta: { requiresAuth: true,requiresNotB2B: true,requiresNotTB: true },
    component: () =>
      import(/* webpackChunkName: "AddressManagement.vue" */ "../views/OrderAuto1.vue"),

  },
  {
    path: "/OrderWholeSale",
    name: "OrderWholeSale",
    meta: { requiresAuth: true,requiresNotB2B: true },
    component: () =>
      import(/* webpackChunkName: "AddressManagement.vue" */ "../views/OrderWholeSale.vue"),

  },
  {
    path: "/Cart",
    name: "Cart",
    meta: { requiresAuth: true, requiresSOStatus: true },
    component: () =>
      import(/* webpackChunkName: "Cart.vue" */ "../views/Cart.vue"),
  },
  {
    path: "/CheckOut",
    name: "CheckOut",
    meta: { requiresAuth: true, requiresSOStatus: true },
    component: () =>
      import(/* webpackChunkName: "CheckOut.vue" */ "../views/CheckOut.vue"),
  },
  {
    path: "/CompareProduct",
    name: "CompareProduct",
    meta: { requiresAuth: true,requiresNotB2B: true },
    component: () =>
      import(
        /* webpackChunkName: "CheckOut.vue" */ "../views/CompareProduct.vue"
      ),
  },
  {
    path: "/Category/:getcatcode",
    name: "Category",
    props: true,
    meta: { requiresAuth: true,requiresNotB2B: true },
    component: Category,
    beforeEnter: (to, from, next) => {
      if (to.params.getcatcode) {
        next();
      } else {
        next({ name: "NotFound" });
      }
    },
  },
  {
    path: "/Product/:getcatcode",
    name: "Product",
    props: true,
    meta: { requiresAuth: true,requiresNotB2B: true },
    component: Product,
    beforeEnter: (to, from, next) => {
      if (to.params.getcatcode) {
        next();
      } else {
        next({ name: "NotFound" });
      }
    },
  },
  {
    path: "/Product",
    name: "ProductSearch",
    props: true,
    meta: { requiresAuth: true,requiresNotB2B: true },
    component: ProductSearch,
    beforeEnter: (to, from, next) => {
      if (to.query.src) {
        next();
      } else {
        next({ name: "NotFound" });
      }
    },
  },
  {
    path: "/ProductDetail/:slugName",
    name: "ProductDetail",
    props: true,
    meta: { requiresAuth: true },
    component: ProductDetail,
    beforeEnter: (to, from, next) => {
      if (to.params.slugName) {
        next();
      } else {
        next({ name: "NotFound" });
      }
    },
  },
  {
    path: "/Menu",
    name: "Menu",
    component: Menu,
    children: [
      {
        path: "History/:whereBy/Detail/:cartRef",
        name: "HistoryDetail",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "MenuHistoryDetail.vue" */ "../components/Menu/MenuHistoryDetail.vue"
          ),
        meta: { requiresAuth: true,requiresNotB2B: true },
      },
      {
        path: "History/:whereBy",
        name: "History",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "History.vue" */ "../views/Menu/History.vue"
          ),
        meta: { requiresAuth: true,requiresNotB2B: true },
      },
      {
        path: "Template",
        name: "Template",
        component: () =>
          import(
            /* webpackChunkName: "Template.vue" */ "../components/Menu/Template.vue"
          ),
        meta: { requiresAuth: true,requiresNotB2B: true,requiresNotTB: true },
      },
      {
        path: "POList",
        name: "POList",
        component: () =>
          import(
            /* webpackChunkName: "POList.vue" */ "../components/Menu/POList/POList.vue"
          ),
        meta: { requiresAuth: true,requiresNotB2B: true },
      },
    ],
  },
  {
    path: "/OTPRequest/:cartRef",
    name: "OTPRequest",
    props: true,
    meta: { requiresAuth: true,requiresNotB2B: true,requiresNotTB: true },
    component: OTPRequest,
    beforeEnter: (to, from, next) => {
      if (to.params.cartRef) {
        next();
      } else {
        next({ name: "NotFound" });
      }
    },
  },
  {
    path: "/OTPApvOrderMail",
    name: "OTPApvOrderMail",
    props: true,
    component: OTPApvOrderMail
  },
  {
    path: "/Wardrobe",
    name: "Wardrobe",
    meta: { requiresAuth: true,requiresNotB2B: true,requiresNotTB: true },
    component: () =>
      import(/* webpackChunkName: "Wardrobe.vue" */ "../views/Wardrobe.vue"),
  },
  {
    path: "/Prodboard/:getProdID",
    name: "Prodboard",
    props: true,
    meta: { requiresAuth: true,requiresNotB2B: true,requiresNotTB: true },
    component: () =>
      import(/* webpackChunkName: "Prodboard.vue" */ "../views/Prodboard.vue"),
    beforeEnter: (to, from, next) => {
      if (to.params.getProdID) {
        next();
      } else {
        next({ name: "NotFound" });
      }
    },
  },
  {
    path: "/ProdboardDetail/:getProdID",
    name: "ProdboardDetail",
    props: true,
    meta: { requiresAuth: true,requiresNotB2B: true,requiresNotTB: true },
    component: ProdboardDetail,
    beforeEnter: (to, from, next) => {
      if (to.params.getProdID) {
        next();
      } else {
        next({ name: "NotFound" });
      }
    },
  },
  {
    path: "/ProdboardShapeListPage/:getProdShape",
    name: "ProdboardShapeListPage",
    props: true,
    meta: { requiresAuth: true,requiresNotB2B: true,requiresNotTB: true },
    component: ProdboardShapeListPage,
    beforeEnter: (to, from, next) => {
      if (to.params.getProdShape) {
        next();
      } else {
        next({ name: "NotFound" });
      }
    },
  },
  {
    path: "/BuyingPolicy",
    name: "BuyingPolicy",
    meta: { requiresAuth: true,requiresNotB2B: true,requiresNotTB: true },
    component: () =>
      import(
        /* webpackChunkName: "BuyingPolicy.vue" */ "../views/BuyingPolicy.vue"
      ),
  },
  {
    path: "/PaymentPolicy",
    name: "PaymentPolicy",
    meta: { requiresAuth: true,requiresNotB2B: true,requiresNotTB: true },
    component: () =>
      import(
        /* webpackChunkName: "PaymentPolicy.vue" */ "../views/PaymentPolicy.vue"
      ),
  },
  {
    path: "/DeliverPolicy",
    name: "DeliverPolicy",
    meta: { requiresAuth: true,requiresNotB2B: true,requiresNotTB: true },
    component: () =>
      import(
        /* webpackChunkName: "DeliverPolicy.vue" */ "../views/DeliverPolicy.vue"
      ),
  },
  {
    path: "/RefundPolicy",
    name: "RefundPolicy",
    meta: { requiresAuth: true,requiresNotB2B: true,requiresNotTB: true },
    component: () =>
      import(
        /* webpackChunkName: "RefundPolicy.vue" */ "../views/RefundPolicy.vue"
      ),
  },
  {
    path: "/CartGrouping",
    name: "CartGrouping",
    meta: { requiresAuth: true,requiresNotB2B: true,requiresNotTB: true, requiresSOStatus: true },
    component: () =>
      import(
        /* webpackChunkName: "CartGrouping.vue" */ "../views/CartGrouping.vue"
      ),
  },
  // {
  //   path: '/Confirm',
  //   name: 'Confirm',
  //   props: true,
  //   meta: { requiresAuth: true },
  //   component: () => import(/* webpackChunkName: "Confirm.vue" */ '../views/Confirm.vue'),
  // },
  {
    path: "/NotFound",
    name: "NotFound",
    component: Notfound,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/NotFound",
  },
  //B2B TB
  {
    path: "/twdb2b",
    name: "twdb2b",
    meta: {},
    component: () =>
      import(
        /* webpackChunkName: "BuyingPolicy.vue" */ "../views/LoginB2B.vue"
      ),
  },
  {
    path: "/twdb2b/login",
    name: "twdb2blogin",
    meta: {},
    component: () =>
      import(
        /* webpackChunkName: "BuyingPolicy.vue" */ "../views/LoginB2B.vue"
      ),
  },
  {
    path: "/b2b/loginb2b",
    name: "loginb2b",
    meta: {},
    component: () =>
      import(
        /* webpackChunkName: "BuyingPolicy.vue" */ "../views/LoginB2B.vue"
      ),
  },
  {
    path: "/tb/getcart",
    name: "b2bgetcart",
    meta: {},
    component: () =>
      import(
        /* webpackChunkName: "BuyingPolicy.vue" */ "../views/FindUserB2B.vue"
      ),
  },
  {
    path: "/B2BApvOrderMail/:REF",
    name: "B2BApvOrderMail",
    props: true,
    component: B2BApvOrderMail,
    beforeEnter: (to, from, next) => {
      if (to.params.REF) {
        next();
      } else {
        next({ name: "NotFound" });
      }
    },
  },
  //B2B
  {
    path: "/b2b/login",
    name: "b2blogin",
    meta: {},
    component: ProductSearch,
    beforeEnter: async (to, from, next) => {
      try {
        const query = to.query.token;
        let data = {};
        //check token ยิง api ไป เพื่อ verify token
        if (query) {
          await serviceAPI.call_API_Verify_Token(
            "get",
            `b2b/customer/verify-token`,
            data,
            query
          );
          store.dispatch("auth/login", query);
          next({ name: "ProductB2B" });
        } else {
          next({ name: "NotFound" });
        }
      } catch (error) {
        next({ name: "NotFound" });
      }
    },
  },
  {
    path: "/ProductB2B",
    name: "ProductB2B",
    props: true,
    meta: { requiresB2B: true },
    component: ProductB2B,
  },
  
  {
    path: "/ProductSearchB2B",
    name: "ProductSearchB2B",
    props: true,
    meta: {requiresB2B: true },
    component: ProductSearchB2B,
    beforeEnter: (to, from, next) => {
      if (to.query.src) {
        next();
      } else {
        next({ name: "NotFound" });
      }
    },
  },
  {
    path: "/B2BFinished",
    name: "B2BFinished",
    props: true,
    meta: {requiresB2B: true },
    component: B2BFinished,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: "history",
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let getUser = store.getters["auth/getUser"];
    if (!getUser) {
      store.dispatch("auth/logout");
      next({
        path: "/login",
      });
      
      return;
    }
  }

  // if (to.matched.some(record => record.meta.requiresAdmin)) {
  //   let getUser = store.getters.getUser
  //   console.log(getUser)
  //   if(getUser.ROLE !=='Admin'){
  //     next({
  //       path: '/welcome'
  //     })
  //     return
  //   }else{
  //   }
  // }

  next(); // make sure to always call next()!
});
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresSOStatus)) {
    let CUSTREF = store.getters["customer/getQTCUSTREF"]
    if(CUSTREF){
      let data={
        REF:CUSTREF,
      }
      let getAPI = await serviceAPI.call_API('post',`service/getQTSOStatus`,data,'auth');
      let soStatus = getAPI.data.dbitems[0].STATUS.trim();
      if (soStatus != '') {
        if(store.getters['auth/getWSFLAG']){
          next({
            path: 'OrderWholeSale',
            query: { confirm: 'success' }
          });
        } else {
          next({
            path: 'FindUser',
            query: { confirm: 'success' }
          });
        }
        return;
      }

    }
  }
  // if (to.matched.some(record => record.meta.requiresAdmin)) {
  //   let getUser = store.getters.getUser
  //   console.log(getUser)
  //   if(getUser.ROLE !=='Admin'){
  //     next({
  //       path: '/welcome'
  //     })
  //     return
  //   }else{
  //   }
  // }

  next(); // make sure to always call next()!
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresB2B)) {
    let getISB2B = false
    if (!getISB2B) {
      store.dispatch("auth/logout");
      next({
        path: "/welcome",
      });
      return;
    }
  }
  next(); // make sure to always call next()!
});
// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresNotTB)) {
//     let getISTB = store.getters["auth/getISTB"];
//     if (getISTB) {
//       // console.log(getISTB);
//       // store.dispatch("auth/logout");
//       next({
//         path: "/",
//       });
//       return;
//     }
//   }
//   next(); // make sure to always call next()!
// });
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresNotB2B)) {
    let getISB2B = false
    if (getISB2B) {
      next({
        path: "/ProductB2B",
      });
      return;
    }
  }
  next(); // make sure to always call next()!
});
export default router;
