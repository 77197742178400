<template>
  <section>
    <div class="container">
      <!-- <HeaderSPN :catcode="getcatcode" :havenext="false" /> -->
      <!-- <HeaderSPN :havenext="false" /> -->
      <div class="row">
        <div class="col-12 col-md-6"></div>
        <div class="col-12 col-md-6">
          <!-- <PropertyProduct :catcode="52001" /> -->
          <!-- <PropertyProduct /> -->

        </div>
      </div>
      <!-- <ProductListB2B :catcode="getcatcode" :key="$route.query" /> -->
      <ProductListB2B :key="$route.query" />
    </div>
    <Comparefuc />
  </section>
</template>

<script>
  import ProductListB2B from "@/components/ProductListB2B.vue";
  import PropertyProduct from "@/components/PropertyProduct.vue";
  import HeaderSPN from "@/components/HeaderSPN.vue";
  import Comparefuc from "@/components/comparefuc.vue";
  import * as serviceAPI from "../services/API.service";
  import store from "../store/index";
  export default {
    name: "Product",
    components: {
      ProductListB2B,
      PropertyProduct,
      HeaderSPN,
      Comparefuc,
    },
    props: {
    },

    data: function() {
      return {
        listStore:[],
      }
    },
    mounted() {
      document.title = `${process.env.VUE_APP_TITLE} - Product Lists`;
      let getQTSTCODE = store.getters["auth/getQTSTCODE"];
      let getQTSTORENAME = store.getters["auth/getQTSTORENAME"];
      this.$store.dispatch('service/setQTDFSTORE',getQTSTCODE)
      this.$store.dispatch('service/setQTSTORENAME',getQTSTORENAME)
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-CRY3HG283C')
      document.head.appendChild(recaptchaScript)
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-CRY3HG283C');
    },
    methods: {
    },
    computed: {
      
    },
  };
</script>
