<template>
  <section>
    <HeaderSPN  :catcode="listDataSkname.CATCODE" :havenext="false" :key="listDataSkname.CATCODE" />
  <!-- winddow -->
    <div class="prod-detail row d-none d-lg-block" style="margin-bottom: 50px">
      <div class ="col-12" style='padding:0px;margin:0px;'>
        <div class="row">
          <div class='col-3' style='padding:0px;margin:0px;'>
            <router-link v-if="listDataSkname.CATCODE" class="text-left" :to="`${listDataSkname.CATCODE ? '/Product/' + listDataSkname.CATCODE : '/'}`"><span class="back-detail"><i style='font-size:15px;transform: translateY(10%);' class='fas fa-chevron-left'></i> ย้อนกลับ </span></router-link>
            <div class ='text-center' v-if="pimage">
              <div id='imgsrc' hidden>{{ pimage }}</div>
              <div id='@skcode' class="slider-for text-center">
                <div  class='img-nav' v-for="(e,idx) in imgSlickFor" :key="idx">
                  <img :id="`img-${idx}`" class='img-detail img-zoom' v-if='e != ""' :src='`https://pim.powerbuy.com/PWBPIM/web/${e.toString().replace(",","")}`' >
                </div>
              </div>
              <br>
              <div class="slider-nav hidden-xs hidden-sm" style="margin:0 1.5vw;">
                <div class='img-nav'  v-for="(e,idx) in imgSlickNav" :key="idx">
                  <img class='img-detail pointer' v-if='e != ""' :src='`https://pim.powerbuy.com/PWBPIM/web/${e.toString().replace(",","")}`'>
                </div>
              </div>
            </div>
            <div v-else>
              <img :src="`/img/no-img${qtBu=='bnb' ? 'bnb' : ''}.svg`">
              <!-- <img id='img_${i}' class='img-detail img-zoom' v-if='e != ""' :src='' > -->
            </div>


            <span class='disccir' id="putper" v-if="listDataPrice.per">{{listDataPrice.per}}</span>
            <div class="row d-flex justify-content-center">
              <div class="col-xs-12 tisidiv" style="display:none;">
                <div class="tisi m-0 ">
                </div>
              </div>
				    </div>
          </div>
          <div class="col-6">
            <div id='skcDiv' class='px-2 mt-md-ne'>
              <p style="font-family: 'kittithadabold75regular'; margin: 0; font-size:35px;">
                {{listDataDetail.prname}}</p>
              
              <hr style="margin-top:10px;">
              <p v-html="listDataDetail.sdesp" style=" font-size:30px"></p>
              <br>
              <div>
                <p style="font-family: 'kittithadabold75regular'; margin: 0; " >
                  <span class="box-status atb-color h5" title='ATBCODE'>{{listDataPrice.atbcode}}</span> 
                  <span class="box-status dism-color h5" title='DISMCODE'>{{listDataPrice.dismcode}}</span>
                  <span class="box-status hxb-color h5" title='HxB'>{{listDataPrice.hb}}</span>
                  <span class="box-status leadtme-color h5" title='Leadtime'>{{listDataPrice.lt}}</span>
                  <span class="box-status status-color h5" title='Status'>{{listDataPrice.status}}</span>
                  <span class="box-status stckavl-color h5" title='Stock Avail'>{{listDataPrice.stockavail}}</span>
                  <span class="box-status stclt-color h5" title='Stock Longtail'>{{listDataPrice.STCKLNGTAIL}}</span>
                  <span class="box-status stcdc-color h5" title='Stock DC'>{{listDataPrice.STCKDC}}</span>
                </p>
              </div>
              <b style=" font-size:25px;">รหัสสินค้า : {{listDataDetail.skc}}</b>
              <div id='spuprop' hidden></div>
              <!-- <div v-html="spuPropHtml"></div> -->
              <div :key="listDataDetail.skc" v-if="listDataSkname.SPU != 0">
                <div style="display: block;" v-for="(e, index) in listProp" :key="index" >
                  <div id="fillprod0" class="fillprod" style="display:block;" v-if="e.data.length > 1">
                    <div class="row m-0">
                      <div class="col-12 p-0 propname">
                        <span>{{ e.head }}</span>
                        <span> : </span>
                        </div>
                      <div class="col-12 p-0">
                        <div class="prodDivfill row m-0">
                          <div class="pl-0 pr-1 pb-1 btnGroupSpu prodDivfill" v-for="(item, indexD) in e.data" :key="indexD">
                            <button :class="`btn-mono ${item.isActive}-${qtBu}`" @click="selectoption(item.prop,index)" >{{ item.prop }}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="fillprod1" class="fillprod" style="display:block;"></div>
                </div>
              </div>
              <br>
              <b style=" font-size:25px;">สาขา : <span class="boxlocation">{{getStName}}</span></b>
              <br>
              <b style=" font-size:25px;">แถวที่ : <span class="boxlocation m-1" id="LROW">{{ listDataPrice.LOCATIONROW }}</span></b>
              <b style=" font-size:25px;">เชลฟ์ที่ : <span class="boxlocation m-1" id="LSHELF">{{ listDataPrice.LOCATIONSHELF }}</span></b>
            </div>
          </div>
          <div class="col-3">
            <SideProdDetail :sku="listDataDetail.skc" :price="listDataPrice.PRICE" :key="listDataDetail.skc" />
          </div>
        </div>
      </div>
    </div>
  <!-- mobile -->
    <div class="prod-detail row d-lg-none" style="margin-bottom: 50px">
      <div class ="col-12" style='padding:0px;margin:0px;'>
        <div class="row">
          <div class="col-12">
            <router-link v-if="listDataSkname.CATCODE" class="text-left" :to="`/Product/${listDataSkname.CATCODE}`"><span class="back-detail"><i style='font-size:15px;transform: translateY(10%);' class='fas fa-chevron-left'></i> ย้อนกลับ </span></router-link>

            <!-- <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
              <ol class="carousel-indicators">
                <li data-target="#carouselExampleIndicators"  v-for="(banner,idx) in imgSlickNav" :key="idx" :class="{ active: idx==0 }" :data-slide-to="idx"></li>
              </ol>
              <div class="carousel-inner">
                <div class="carousel-item" v-for="(banner,idx) in imgSlickNav" :key="idx" :class="{ active: idx==0 }">
                      <img :src='`https://pim.thaiwatsadu.com/TWDPIM/web/${banner.toString().replace(",","")}`' alt="" class="d-block w-100" v-if='banner != ""'>
                </div>
              </div>
              <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div> -->

            <div class ='text-center'>
              <div id='imgsrc' hidden>{{ pimage }}</div>
                
              <div id='@skcode' class="slider-for text-center">
                <div  class='img-nav' v-for="(e,idx) in imgSlickFor" :key="idx">
                  <img class='img-detail img-zoom' v-if='e != ""' :src='`https://pim.powerbuy.com/PWBPIM/web/${e.toString().replace(",","")}`' >
                </div>
              </div>
            </div>

            <span class='disccir' id="putper" v-if="listDataPrice.per">{{listDataPrice.per}}</span>
            <div class="row d-flex justify-content-center">
              <div class="col-xs-12 tisidiv" style="display:none;">
                <div class="tisi m-0 ">
                </div>
              </div>
				    </div>
            <div id='skcDiv' class='px-2 mt-md-ne'>
              <p style="font-family: 'kittithadabold75regular'; margin: 0; font-size:35px;">
                {{listDataDetail.prname}}</p>
              
              <hr style="margin-top:10px;">
              <p v-html="listDataDetail.sdesp" style=" font-size:30px"></p>
              <br>
              <div>
                <p style="font-family: 'kittithadabold75regular'; margin: 0; " >
                  <span class="box-status atb-color" title='ATBCODE'>{{listDataPrice.atbcode}}</span> 
                  <span class="box-status dism-color" title='DISMCODE'>{{listDataPrice.dismcode}}</span>
                  <span class="box-status hxb-color" title='HxB'>{{listDataPrice.hb}}</span>
                  <span class="box-status leadtme-color" title='Leadtime'>{{listDataPrice.lt}}</span>
                  <span class="box-status status-color" title='Status'>{{listDataPrice.status}}</span>
                  <span class="box-status stckavl-color" title='Stock Avail'>{{listDataPrice.stockavail}}</span>
                  <span class="box-status stclt-color" title='Stock Longtail'>{{listDataPrice.STCKLNGTAIL}}</span>
                  <span class="box-status stcdc-color" title='Stock DC'>{{listDataPrice.STCKDC}}</span>
                </p>
              </div>
              <b style=" font-size:25px;">รหัสสินค้า : {{listDataDetail.skc}}</b>
              <div id='spuprop' hidden></div>
              <!-- <div v-html="spuPropHtml"></div> -->
              <div :key="listDataDetail.skc" v-if="listDataSkname.SPU != 0">
                <div style="display: block;" v-for="(e, index) in listProp" :key="index" >
                  <div id="fillprod0" class="fillprod" style="display:block;" v-if="e.data.length > 1">
                    <div class="row m-0">
                      <div class="col-12 p-0 propname">
                        <span>{{ e.head }}</span>
                        <span> : </span>
                        </div>
                      <div class="col-12 p-0">
                        <div class="prodDivfill row m-0">
                          <div class="pl-0 pr-1 pb-1 btnGroupSpu prodDivfill" v-for="(item, indexD) in e.data" :key="indexD">
                            <button :class="`btn-mono ${item.isActive}-${qtBu}`" @click="selectoption(item.prop,index)" >{{ item.prop }}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="fillprod1" class="fillprod" style="display:block;"></div>
                </div>
              </div>
              <br>
              <b style=" font-size:25px;">สาขา : <span class="boxlocation">{{getStName}}</span></b>
              <br>
              <b style=" font-size:25px;">แถวที่ : <span class="boxlocation m-1" id="LROW">{{ listDataPrice.LOCATIONROW }}</span></b>
              <b style=" font-size:25px;">เชลฟ์ที่ : <span class="boxlocation m-1" id="LSHELF">{{ listDataPrice.LOCATIONSHELF }}</span></b>
            </div>
          </div>
          <br>
          <div class="col-12">
            <SideProdDetail :sku="listDataDetail.skc" :price="listDataPrice.PRICE" :key="listDataDetail.skc"  />
          </div>
        </div>
      </div>
    </div>
    <ProdBundle :sku="listDataDetail.skc" :key="listDataDetail.skc" v-if="!isPro"/>
    <br>
    <div id='mdetailDiv'>
      <ul class="nav nav-tabs nav-justified">
        <li class="nav-item active">
          <a data-toggle="tab" class="nav-link active" href="#prodDetail">รายละเอียดสินค้า</a>
        </li>
        <li class="nav-item">
          <a data-toggle="tab" class="nav-link" href="#prodSpec">ข้อมูลเฉพาะสินค้า</a>
        </li>
        <li class="nav-item">
          <a data-toggle="tab" class="nav-link" href="#prodTrans">การจัดส่งสินค้า</a>
        </li>
        <li class="nav-item">
          <a data-toggle="tab" class="nav-link" href="#prodReview">รีวิว</a>
        </li>
      </ul>

      <div class="tab-content">
        <div id="prodDetail" class="tab-pane fade show active">
          <p v-html="listDataDetail.ldesp" style="font-size:30px"></p>
        </div>
        <div id="prodSpec" class="tab-pane fade">
          <p>ข้อมูลเฉพาะสินค้า</p>
          <div :key="listDataDetail.skc" class="p-0 m-0">
            <div class="evenOdd pl-1" style="display: block;" v-for="(e, index) in listProp" :key="index" >
              <div  style="display:block;" v-if="e.data.length > 0">
                <div class="row m-0">
                  <div class="col-6 p-0">
                    <span>{{ e.head }}</span>
                  </div>
                  <div class="col-6 p-0">
                    <div class=" row m-0">
                      <div v-for="(item, indexD) in e.data" :key="indexD">
                        <span class="p-0 m-0" v-if="item.isActive == 'active'">{{ item.prop }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="fillprod1" class="fillprod" style="display:block;"></div>
            </div>
          </div>
        </div>
        <div id="prodTrans" class="tab-pane fade">
          <ProductSpec  />
        </div>
        <div id="prodReview" class="tab-pane fade">
          <p style="font-weight:bolder;">สินค้านี้ยังไม่มีรีวิว</p>
        </div>
      </div>
    </div>


    <div aria-hidden="true" aria-labelledby="myModalLabel" class="modal fade" id="modalIMG" role="dialog" tabindex="-1">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modalIMG-body mb-0 p-0">
          </div>
        </div>
      </div>
    </div>

    
  </section>
</template>


<script>
  import * as serviceAPI from '../services/API.service';
  import * as serviceMain from '../services/main.service';
  import ProductSpec from '@/components/ProductSpec.vue';
  import SideProdDetail from '@/components/SideProdDetail.vue';
  import HeaderSPN from '@/components/HeaderSPN.vue'
  import SimilarProd from '@/components/SimilarProd.vue';
  import ProdBundle from '@/components/ProdBundle.vue';
  export default {
    name: 'ProdDetailB2B',
    data: function () {
      return {
        sku: '',
        skuname: '',
        STCODE: '',
        listDataDetail: [],
        listDataSkname: [],
        listDataPrice: [],
        listDatabysku: [],
        listDatabycat: [],
        listD: [],
        listProp: [],
        pimage: '',
        spuPropHtml: '',
        divimg:'',
        ndivimg:'',
        settingcaro: {
          "centerMode": true,
          "centerPadding": "20px",
          "focusOnSelect": true,
          "infinite": true,
          "slidesToShow": 3,
          "speed": 500
        },
        tmpPropSelected: {},
        propSelected: {}
      }
    },
    components: {
      ProductSpec,
      SideProdDetail,
      HeaderSPN,
      SimilarProd,
      ProdBundle,
    },
    mounted() {
      this.STCODE = this.$store.getters['service/getQTDFSTORE']  || '026';
      this.init();
      // this.getPropkerty;
      if (this.sku) {
        this.getPrice();
      }
      // if(this.listData.skc)
      // this.loadProductDetail();
      
    },
    props: [
      "slugName"
    ],
    methods: {
      async init(){
        // console.log(this.slugName);
        let skuObjs = await this.slugName.split('-');
        this.sku = await skuObjs[skuObjs.length - 1]
        this.skuname = await this.slugName.replace(`-${this.sku}`, '').replace(/-/g,' ').replace(/'/g,"")
        await this.showProductDetail();
        await this.getSkname();
      },
      async getTisi(){
        let data1 = {
            "dskcode": this.sku
        }
        let getAPI1 = await serviceAPI.call_API('post', `detail/tisi1`, data1,'auth');
        let itm1 = getAPI1.data.dbitems[0]
        // console.log(itm1);
        let data2 = {
            "CLASS": itm1.CLASS
        }
        let getAPI2 = await serviceAPI.call_API('post', `detail/tisi2`, data2,'auth');
        let itm2 = getAPI2.data.dbitems[0]
        // console.log(itm2);
        var nameprop=JSON.parse(itm2.PROPERTY);
        var prop=JSON.parse(itm1.PROP);
        var cntprop = 0;
        var divcnt = 0;
        let qrcnt = 0;
        var divnameadd = "prodprop1";
        $('#prodprop').html("");
        $('#prodprop1, #prodprop2, #prodprop1m, #prodprop2m').html("");
        $(".tisi").html("");
        $(".tisidiv").hide();
        Object.keys(prop).forEach(function(key) {
          if(prop[key] != "" && prop[key] != '-'){
            divcnt++;
            var propnam = "";
            if(['S', 'Z'].includes(nameprop[cntprop].name.substring(0, 1)) && !['Style', 'Series'].includes(nameprop[cntprop].name)){
              propnam = nameprop[cntprop].name.substring(1);
            }
            else if (['X','M'].includes(nameprop[cntprop].name.substring(0, 1))) {
              propnam = '';
              qrcnt++;
              $(".tisi").append(`
                <div class="col-12 pl-0 pr-2 mt-2" style="max-width:300px;min-width:100px;">
                  <a href="${prop[key].split("|")[1]}" target="_blank">
                    <div style="border: 1px solid #CCCCCC;">
                      <div class="row mx-0 d-flex justify-content-start" style='padding: 8px 8px 0px 8px; display: flex; flex-wrap: wrap;'>
                        <div class="col-5 px-1"><img src="/img/tisi1.png" width="60" alt="มอก"></div>
                        <div class="col-7 px-1"><span id="qrtisi${qrcnt}" class="qrtisi m-0 w-100"></span></div>
                      </div>
                      <div class="text-center" style='color:black; font-family: kittithadabold75regular;'>
                        ${prop[key].split("|")[0]}
                      </div>
                    </div>
                  </a>
                </div>
              `)
              $(".tisidiv").show();
              new QRCode(document.getElementById("qrtisi"+qrcnt), {
                text: prop[key].split("|")[1],
                width: 100,
                height: 100,
                correctLevel : QRCode.CorrectLevel.H
              });
            }
            else{
              propnam = nameprop[cntprop].name;
            }
            if(divcnt >= 11){
              if(divnameadd == "prodprop1"){
                divnameadd = "prodprop2";
                divcnt = 1;
              }
              else{
                return;
              }
            }
            //font-weight:bolder;
            if (propnam != '') {
              if(divcnt%2 == 0){
                $('#'+divnameadd).append("<div style='padding:0 .5em;margin:0 0 .1em 0;background-color: rgb(228, 228, 228);'><div class='row' style='margin:0 .5em;'><div class='col-xs-6' style='padding:0;margin:0;font-weight:bolder;'>"+propnam+"</div><div class='col-xs-6' style='padding:0;margin:0;'>"+prop[key]+"</div></div></div>");
                $('#'+divnameadd+"m").append("<div style='padding:0 .5em;margin:0 0 .1em 0;background-color: rgb(228, 228, 228);'><div class='row' style='margin:0 .5em;'><div class='col-xs-6' style='padding:0;margin:0;font-weight:bolder;'>"+propnam+"</div><div class='col-xs-6' style='padding:0;margin:0;'>"+prop[key]+"</div></div></div>");
              }
              else{
                $('#'+divnameadd).append("<div style='padding:0 .5em;margin:0 0 .1em 0;'><div class='row' style='margin:0 .5em;'><div class='col-xs-6' style='padding:0;margin:0;font-weight:bolder;'>"+propnam+"</div><div class='col-xs-6' style='padding:0;margin:0;'>"+prop[key]+"</div></div></div>");
                $('#'+divnameadd+"m").append("<div style='padding:0 .5em;margin:0 0 .1em 0;'><div class='row' style='margin:0 .5em;'><div class='col-xs-6' style='padding:0;margin:0;font-weight:bolder;'>"+propnam+"</div><div class='col-xs-6' style='padding:0;margin:0;'>"+prop[key]+"</div></div></div>");
              }
            }
          }
          cntprop += 1;
        })
      },
      async showProductDetail() {
        try {
          let dataD = {
            skc: this.sku
          }
          let getAPI = await serviceAPI.call_API('post', `detail/getdetailproduct`, dataD, 'auth');
          this.listDataDetail = getAPI.data.dbitems[0];
          this.pimage = this.listDataDetail.pimg.substring(1,this.listDataDetail.pimg.length);
          if(this.listDataDetail.sdesp){
            this.listDataDetail.sdesp = this.listDataDetail.sdesp.replace(/th\/sku/g,'ProductDetail').replace(/href/g,'target="_blank" href')
          }
          // console.log(this.listDataDetail)
          // this.loadProductDetail();
          // this.getSkname();
          this.getPrice();
          this.getTisi();
          // this.insertImg();
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      async getSkname() {
        try {
          if(this.sku == this.skuname){
            this.skuname = this.listDataDetail.prname.replace(/\"/g, '').replace(/\`/g,'%27').replace(/ /g,'-').replace(/\//g,'|').replace(/#/g,'').replace(/\'/g,'').replace(/\?/g,'').replace(/\*/g,'').replace(/\+/g,'').replace(/\:/g,'').replace(/\./g,'').replace(/%/g,'').replace(/-/g,' ')
          }
          let dataS = {
            skc: this.sku,
            slugName: this.skuname
          }
          let getAPI = await serviceAPI.call_API('post', `detail/getdetailskname`, dataS, 'auth');
          this.listDataSkname = getAPI.data.dbitems[0];
          // console.log(this.listDataSkname);
          if(this.listDataSkname != null && this.listDataSkname.SPU != 0){
            this.loadProductDetail();
          }
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      async getPrice() {
        try {
          let data = {
            SKCODE: this.listDataDetail.skc,
            STCODE: this.getStore,
            UUID: this.getUUID,
          }
          console.log(data);
          let getAPI = await serviceAPI.call_API('post', `b2b/product/getskprice`, data, 'auth');
          this.listDataPrice = getAPI.data.dbitems[0];
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      async loadProductDetail() {
        try {
          this.listD = [];
          let datasku = {
            spucode: this.listDataSkname.SPU,
            dskcode: this.listDataDetail.skc,
            stcode: this.getStore,
            catcode: this.listDataSkname.CATCODE,
          }
          let datacat = {
            catcode: this.listDataSkname.CATCODE
          }
          let getAPIsku = await serviceAPI.call_API('post', `detail/spubysku`, datasku, 'auth');
          let getAPIcat = await serviceAPI.call_API('post', `detail/spubycat`, datacat, 'auth');
          this.listDatabysku = getAPIsku.data.dbitems;
          this.listDatabycat = getAPIcat.data.dbitems;
          // console.log(this.listDatabysku);
          var spubysku = (getAPIsku.data.dbcode == 0 && getAPIsku.data.dbrows > 0) ? JSON.stringify(getAPIsku.data.dbitems) : null;
          var spubycat = (getAPIcat.data.dbcode == 0 && getAPIcat.data.dbrows > 0) ? JSON.stringify(getAPIcat.data.dbitems[0]) : null;
          var prodlist = {};
          // console.log(spubysku);
          // console.log(spubycat);
          if(!spubysku || !spubycat)
          {
            return
          }
          var thisskuarr = JSON.parse(spubysku).filter(el => el.SKCODE == datasku.dskcode)[0];
          var thisskuprop = thisskuarr && thisskuarr != {} ? thisskuarr : null;
          prodlist = JSON.parse(thisskuprop.PROP);
          // console.log(thisskuprop);
          // console.log(prodlist);
          let spubycatprops = JSON.parse(JSON.parse(spubycat).PROPERTY);
          // console.log(spubycatprops);
          let key;
 
          for (key = 0; key < spubycatprops.length; key++) {
            let spubycatprop = spubycatprops[key];
            if (spubycatprop.name != '' && ((spubycatprop.name.substring(0, 1) != 'S' && spubycatprop.name.substring(
                    0, 1) != 'X' && spubycatprop.name.substring(0, 1) != 'M') || spubycatprop.name == 'Style' || spubycatprop.name == 'Series')) {
              // this.spuPropHtml += `<div id="fillprod${key}" class="fillprod" style="display:block;">
              // <div class="row m-0">
              // <div class="col-12 p-0 propname">
              // <span>${spubycatprop.name.substring(0, 1)!='Z'?spubycatprop.name:spubycatprop.name.substring(1)}</span>
              // <span> : </span>
              // </div>
              // <div class="col-12 p-0">
              // <div class="prodDivfill row m-0" propcount="${key}">`
              // console.log(key);
              // console.log(spubycatprop.name.substring(0, 1)!='Z'?spubycatprop.name:spubycatprop.name.substring(1));
              var counthas = 0;
              let dataS = [];
              let props = spubycatprop.VAL.split("|");
              for (let spubycatval of props) {
                if (spubycatval != '' && spubycatval != '-' && spubycatval != 'ALL') {
                  if (JSON.parse(spubysku).some(skprop => JSON.parse(skprop.PROP)["PROP" + (key + 1)] ? JSON.parse(
                      skprop.PROP)["PROP" + (key + 1)].trim().toLowerCase() == spubycatval.toLowerCase() : false)) {
                    // console.log("key "+key+" spubycatval" + spubycatval);
                    dataS.push(spubycatval);
                    // console.log(spubycatval);
                    // this.spuPropHtml += `<div class='pl-0 pr-1 pb-1 btnGroupSpu'><button :prop='PROP${(key+1)}' class="btn-mono" :propval='${spubycatval}' V-on:click="selectoption(1)">${spubycatval}</button></div>`
                    counthas++
                  }
                }
              }
              this.listD.push({
                head: spubycatprop.name.substring(0, 1) != 'Z' ? spubycatprop.name : spubycatprop.name.substring(
                  1),
                data: dataS,
                key: key
              })
              
            }
            
          }
          this.getProperty;
          this.getPrice();
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      async insertImg(){
        $(".slider").not('.slick-initialized').slick()
        var pimg=$('#imgsrc').text().split('|');
        if(pimg.length <= 1){
          $('.slider-nav').hide();
          $('.slider-for').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            draggable: false,
            dots: false,
            responsive: [
              {
              breakpoint: 991,
              settings: {
                fade: false,
                draggable: true,
                dots: false,
              }
              }
            ]
            // asNavFor: '.slider-nav'
          });
        }
        else{
          $('.slider-nav').show();
          $('.slider-for').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            draggable: false,
            dots: false,
            responsive: [
              {
              breakpoint: 991,
              settings: {
                fade: false,
                draggable: true,
                dots: true,
              }
              }
            ]
            // asNavFor: '.slider-nav'
          });
        }
        
        if(pimg.length <= 4){
          $('.slider-nav').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.slider-for',
            centerMode: false,
            infinite: false,
            dots: false,
            focusOnSelect: true,
            responsive: [
              {
                breakpoint: 991,
                settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                asNavFor: '.slider-for',
                centerMode: false,
                infinite: false,
                dots: false,
                focusOnSelect: true,
                }
              }
              ]
          });
        }
        else{
          $('.slider-nav').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: '.slider-for',
            arrows: true,
            centerMode: true,
            infinite: false,
            dots: false,
            focusOnSelect: true,
            draggable: false,
            responsive: [
              {
                breakpoint: 991,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                asNavFor: '.slider-for',
                arrows: true,
                centerMode: true,
                infinite: false,
                dots: false,
                focusOnSelect: true,
                draggable: false,
                }
              }
              ]
          });
        }
        $('.slick-prev').addClass('hidden');
        // $('.slick-next').removeClass('hidden');
        $('.slider-nav').on('afterChange', function (event, slick, currentSlide) {

          if(currentSlide === pimg.length+1) {
            $('.slick-next').addClass('hidden');
          }
          else {
            $('.slick-next').removeClass('hidden');
          }
      
          if(currentSlide === 0) {
            $('.slick-prev').addClass('hidden');
          }
          else {
            $('.slick-prev').removeClass('hidden');
          }  
        });

        $( ".img-zoom" ).mouseover(function() {
          if(window.innerWidth>995){
            $(this).ezPlus({
              zoomType: 'lens',
              lensShape: 'round',
              lensSize: 300
            });
          }
          else{
            if($(this).parent().attr("class") == 'zoomWrapper'){
              $(this).attr('style','');
              // var oldsave = $(this).parent().html();
              // $(this).parent().parent().html(oldsave);
            }
          }
        });
        // $('.img-zoom').ezPlus();
        $( ".img-zoom" ).bind('click', function (e) {
            // console.log(e);
            // var ez = $(`#${e.target.id}`);
            // console.log(ez);
            $( ".modalIMG-body" ).html(`<img class='w-100' src='${e.target.src}' >`);
            $("#modalIMG").modal();
            return false;
        });
      },
      selectoption(value, index) {
        // this.listProp = this.listProp.map(e => e.data.map(e1 => e1.isActive='disabled'));
        this.tmpPropSelected = {...this.propSelected}
        let keySelected, skcReserve;
        for (const e of this.listProp) {
          for (const e1 of e.data) {
            e1.isActive = 'disabled'
          }
        }
        let CSku = '';
        let skuLists = [];
        for (let i = 0; i < this.listProp[index].data.length; i++) {
          // console.log(this.listProp[index].data[i].prop);
          if(this.listProp[index].data[i].prop == value){
            skuLists = [...this.listProp[index].data[i].sku];
            keySelected = this.listProp[index].key;
            skcReserve = this.listProp[index].data[i].sku[0].SKCODE
            this.propSelected = {...this.propSelected, ['PROP'+keySelected]: value}
            this.listProp[index].data[i].isActive = "active";
            break;
          }
        }

        let correctSku = true;
        let propItem;
        for(let itm of skuLists) {
          CSku = itm.SKCODE
          propItem = JSON.parse(itm.PROP);
          for(let propKey of Object.keys(this.propSelected)) {
            if(this.propSelected[propKey] && propItem[propKey]) {
              correctSku = this.propSelected[propKey] == propItem[propKey];
              if(!correctSku) {
                CSku = '';
                break;
              }
            }
          }
          if(correctSku) {
            break;
          }
        }

        // alert(CSku)
        if (!CSku) {
          CSku = skcReserve;
        }
        
        for (let j = 0; j < this.listProp.length; j++) {
          for (let k = 0; k < this.listProp[j].data.length; k++) {
            for (let q = 0; q < this.listProp[j].data[k].sku.length; q++) {
              if(this.listProp[j].data[k].sku[q].SKCODE == CSku){
              this.propSelected['PROP'+this.listProp[j].key] = this.listProp[j].data[k].prop
              this.listProp[j].data[k].isActive = "active";
              break;
            }

            }
            
          }
          
        }
        this.sku = CSku;
        this.showProductDetail();
      },
      async goBack() {
        window.history.go(-1);
        return false;
      },  
    },
    computed: {
      qtBu: function () {
        return this.$store.getters['customer/getQTBU'];
      },
      getStName: function () {
        return this.$store.getters['service/getQTSTORENAME']
      },
      getProperty:function(){
        this.listProp = [];
        this.propSelected = {};
        let PropertyData =[]
        let listData = this.listD
        for(let i =0;i<listData.length;i++){
          let head = listData[i].head;
          let data = listData[i].data;
          let allprop = [];
          for (let j = 0; j < data.length; j++) {
            let Cdata = data[j];
            let allsku = [];
            for (let k = 0; k < this.listDatabysku.length; k++) {
              for (const key in JSON.parse(this.listDatabysku[k].PROP)) {
                if (Object.hasOwnProperty.call(JSON.parse(this.listDatabysku[k].PROP), key)) {
                  const element = JSON.parse(this.listDatabysku[k].PROP)[key];
                  if(element == Cdata){
                    allsku.push(this.listDatabysku[k]);
                  }
                }
              }
            }
            allprop.push({
                prop: Cdata,
                sku: allsku,
                isActive: 'disabled'
              })
          }
          this.listProp.push({
            head: head,
            data: allprop,
            key: listData[i].key+1
          })
        }
        for (let j = 0; j < this.listProp.length; j++) {
          for (let k = 0; k < this.listProp[j].data.length; k++) {
            for (let q = 0; q < this.listProp[j].data[k].sku.length; q++) {
              if(this.listProp[j].data[k].sku[q].SKCODE == this.sku){
                this.propSelected['PROP'+this.listProp[j].key] = this.listProp[j].data[k].prop
                this.listProp[j].data[k].isActive = "active";
                break;
              }
            }
          }
        }
      },
      NSku: function () {
        return this.listDataDetail.skc
      },
      imgSlickNav: function () {
        return this.pimage.split('|');
      },
      imgSlickFor: function () {
        return this.pimage.split('|');
      },
      getStore:function(){
        return this.$store.getters['service/getQTDFSTORE']  || '026';
      },
      getUUID:function(){
        return this.$store.getters['auth/getB2BUUID'];
      },
      isPro:function(){
          return process.env.NODE_ENV == 'production'
      },
    },
    watch:{
      NSku:function(){
        this.getPrice()
      },
      imgSlickNav:function(){
        this.$nextTick(() => {
          // setTimeout(async () => {
          // this.insertImg();
          // }, 500)
          this.insertImg();
        })
      },
      getStore:function(){
        this.loadProductDetail()
        this.getPrice();
      }
    }
  }
</script>

<style scope lang="scss">
$color-PWB: #55246c;
$color-bnb: #006e63;
$color-at1: #3866af;
$color-white: white;
$color-black: black;
  .box-status{
    
    padding: 0px 5px;
    margin-right: 2px;
    line-height: 1px;

  }
  .boxlocation {
  padding: 5px 30px;
  background-color: lightgray;
  border-radius: 10px;
  }
  .pdpminus {
  width: 20px;
  height: 24px;
  border: none;
  display: inline;
  vertical-align: middle;
  text-align: right;
  font-size: 18px;
  font-weight: bold;
  z-index: 998;
  background-color: transparent;
  padding-bottom: 1.5em;
  }
  .pdpminus:active {
  outline: none;
  }
  .pdpminus:focus {
  outline: none;
  }
  .pdpplus {
  width: 20px;
  height: 24px;
  border: none;
  display: inline;
  vertical-align: middle;
  text-align: left;
  margin-left: -6px;
  font-size: 18px;
  font-weight: bold;
  background-color: transparent;
  padding-bottom: 1.5em;
  }
  .pdpplus:active {
  outline: none;
  }
  .pdpplus:focus {
  outline: none;
  }
  .qtypdpinput {
  margin-left: -6px;
  }
  .number {
  border-radius: 4px;
  border: 1px solid #ddd;
  text-align: center;
  height: 24px;
  padding: 0;
  margin: 0;
  }
  .prod-detail {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  }
  table,
  th,
  td {
  border: solid white;
  vertical-align: middle !important;
  }
  a {
  color: black;
  font-weight: bold;
  }
  .tab-content {
  background-color: white;
  padding: 20px;
  margin-bottom: 30px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  }
  .nav-link:hover {
  background-color: rgb(207, 207, 207);
  }
  
  @mixin active-($color) {
    border: 1px solid $color !important;
    background-color: $color !important;
    color: white !important;
  }
  .active-PWB{
    @include active-($color-PWB)
  }
  .active-bnb{
    @include active-($color-bnb)
  }
  .active-at1{
    @include active-($color-at1)
  }

  .prodDivfill button {
  background-color: white;
  border: 1px solid rgba(61, 61, 61, 0.5);
  min-width: 34.8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline;
  }
  .prodDivfill button:disabled {
  border: 1px solid #F5F5F5;
  background-color: #F5F5F5;
  color: black;
  }
  .btn-mono {
  border-radius: 5px;
  }
  .evenOdd:nth-child(2n-1){background-color: rgb(228, 228, 228);} 

  .disccir:empty {
  display: none;
}
@media screen and (max-width: 762) {
  .disccir {
  font-size: 24px !important;
  color: rgb(255, 255, 255);
  font-weight: 700;
  text-align: center;
  letter-spacing: .025em;
  border-radius: 50%;
  background-color:#55246c;
  width: 60px;
  height: 60px;
  line-height: 20px;
  position: absolute;
  transition: all .2s ease-out;
  top: 40px;
  right: 10px;
  padding-top: 10px;
  font-family: 'kittithadabold75regular' !important;
}
.img-detail{
  width:100%;
}
}
.disccir {
  font-size: 24px !important;
  color: rgb(255, 255, 255);
  font-weight: 700;
  text-align: center;
  letter-spacing: .025em;
  border-radius: 50%;
  background-color:#55246c;
  width: 60px;
  height: 60px;
  line-height: 20px;
  position: absolute;
  transition: all .2s ease-out;
  top: 40px;
  right: 20px;
  padding-top: 10px;
  font-family: 'kittithadabold75regular' !important;
}

.img-detail{
    width:80%;
    display: inline !important;
}
.slick-dots li button:before{
	font-size: 20px !important;
}

.slider-nav .img-nav{
  border:0.1em solid #ddd;
  border-radius: 5px;
  margin:0 0.3em;
}

.slider-nav .slick-list{
  width:80% !important;
  margin:auto;
}

.slider-nav .slick-slide:focus{
  outline: none;
}

.slider-for .slick-current{
  outline: none;
}

.slider-nav .slick-current{
  border:0.1em solid #55246c; 
  border-color: #dc3545;
  box-shadow:0 0 0 .2rem rgba(220,53,69,.5);
  outline: none;
}

.slick-prev {
	left: -1.9vw;
}

.slick-prev:before, .slick-next:before {
    color:black !important;
    font-size: 1.5vw;
}
</style>