<template>
  <section >
    <HeaderSPN :catcode="catcode" :havenext="true" />
    <div class="row">
      <div v-for="(e,index) in listData" :key="index" class ='col-md-3 col-sm-4 col-6  p-1'   >
        <div class="bg-white" style='overflow:hidden; height: 100%;' > 
          <router-link :to="`${e.HAVENEXT?`/Category/${e.node}`:`/Product/${(e.node+'0000').slice(0,6)}`}`">
            <!-- <a href="javascript:nextcat('^node^','^Lv^',^HAVENEXT^)"> -->
                <img  :src="`${e.base64img ? `data:image/jpeg;base64,${e.base64img}` : '/img/no-img.jpg'}`" style='width:100%;overflow:hidden;' alt="^node^" 
                class='catimage  img-responsive' ucat="28LV">  
                <p class="card-text text-center">{{e.catname}}</p>  
            </router-link>
        </div> 
      </div>
    </div>
    <div  v-if="isLoading" class="row">

      <div v-for="index in 12" :key="index" class="col-md-3 col-sm-4 col-6 fillpag p-1">
        <div class="shadowDiv" style='overflow:hidden;'>
          <span class="skeleton-box w-100" style="height:150px;"></span>
          <span class="skeleton-box" style="width:55%;"></span>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import * as serviceAPI  from '../services/API.service';
import * as serviceMain  from '../services/main.service';

import HeaderSPN from '@/components/HeaderSPN.vue'

export default {
  name: 'CategoryCard',
  data: function () {
    return {
        listData:[],   
        isLoading: true
      }
  },
  components: {
    HeaderSPN
  },
  mounted() {
    this.showCategory();
  },
  props: [
    "catcode"
  ],
  methods: {
    async showCategory(){
      try {
        this.isLoading = true
        let getCat = this.catcode
        let data={}
        let getAPI = await serviceAPI.call_API('get',`product/category${(getCat)?'/'+getCat:''}`,data,'auth');
        this.listData = getAPI.data.dbitems;
        this.isLoading = false
      } catch (error) {
        serviceMain.showErrorAlert(this,error.message)
      }
    },
    // async sendCreateData(){
    //     try {
    //         let data = this.createData;
    //         let getAPI = await serviceAPI.call_API('post','crudplant',data,'auth');
    //         console.log("🚀 ~ file: User.vue ~ line 342 ~ sendCreateData ~ getAPI", getAPI)
    //         let getCode = getAPI.status_code;
    //         $('#new-member').modal('hide');
    //         let getData = getAPI.data.data[0].message;
    //         serviceMain.showSuccessAlert(this,getData)
    //         this.startPermision();

    //     } catch (error) {
    //         $('#new-member').modal('hide');
    //         serviceMain.showErrorAlert(this,error.message)
    //     }
    // },
  },
}

</script>

<style scoped>

 .card-text{
  font-size:calc(18px + 0.5vw);
  padding: 0px auto;
  text-align: center;
  color: #000;
  text-decoration: none;
  background-color: #fff;
  font-weight: 700;
}
.shadowDiv {
  margin: 0px;
  background-color: white;
  padding: 10px;
  border-radius: 7px;
  height: auto;
}
</style>
