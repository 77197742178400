<template>
  <section>
    <div class="container">
      <h2>{{ status == 'A' ? 'Approve' : 'Reject' }} ใบเสนอราคา {{ref}} เลขที่RQ {{rqnum}} เรียบร้อยแล้ว</h2>
    </div>
  
  </section>
</template>


<script>
  import * as serviceAPI from '@/services/API.service';
  import * as serviceMain from '@/services/main.service';
  export default {
    name: 'OTPApvOrderMail',
    components: {
      
    },
    props: {
      REF: {
        type: String,
        required: true
      }
    },
      data: function () {
      return {
        status: '',
        ref: '',
        rqnum: ''
      }
    },
    mounted() {
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-CRY3HG283C')
      document.head.appendChild(recaptchaScript)
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-CRY3HG283C');
      this.init()
    },
    methods: {
      init(){
        let { status, ref, rqnum} = this.$route.query
        this.status = status
        this.ref = ref
        this.rqnum = rqnum
        if(status =='A'){
          serviceMain.showSuccessAlertWithTime(this,`Approve ใบเสนอราคา ${ref} เลขที่RQ ${rqnum} เรียบร้อยแล้ว`,3000)
        }
        else if(status =='E'){
          serviceMain.showSuccessAlertWithTime(this,`Reject ใบเสนอราคา ${ref} เลขที่RQ ${rqnum} เรียบร้อยแล้ว`,3000)
        }
        else{
          serviceMain.showSuccessAlertWithTime(this,`การทำงานไม่ถูกต้อง`,3000)
        }
      }
    },
    computed: {
    },
  }

</script>

<style scoped lang="scss">

</style>