// import AuthService from '../services/auth.service';
import * as main from '../services/main.service';
import * as constant from '../services/constant';
const user = main.parseJwtByLocalStorage("user");
// const initialState = user ?
//   {
//     status: {
//       loggedIn: true
//     },
//     user
//   } :
//   {
//     status: {
//       loggedIn: false
//     },
//     user: null
//   };

export const auth = {
  namespaced: true,
  state: {
    status: {
      loggedIn: user?true:false
    },
    user:user?user:null,
    QTSUBSALESOURCE:main.readCookieEncrypt("QTSUBSALESOURCE"),
    USERMAILB2B:'',
    ISTB:'false'
  },
  actions: {
    login({commit},para_token) {
      let getEncodeAccess_token = btoa(btoa(para_token))
      localStorage.setItem(constant.TOKEN_KEY, getEncodeAccess_token);
      let getUser = main.parseJwtByLocalStorage(constant.TOKEN_KEY)
      commit('loginSuccess', getUser);
    },
    logout({commit}) {
      localStorage.removeItem(constant.TOKEN_KEY)
      main.saveCookieEncrypt("QTSUBSALESOURCE", "")
      commit('setQTSUBSALESOURCE',"")
      commit('logout')
    },
    setQTSUBSALESOURCE({commit},val) {
      main.saveCookieEncrypt("QTSUBSALESOURCE", val)
      commit('setQTSUBSALESOURCE',val)
    },
    setUSERMAILB2B({commit},val) {
      main.saveCookieEncrypt("USERMAILB2B", val)
      commit('setUSERMAILB2B',val)
    },
    setQTETAX({commit},val) {
      commit('setQTETAX',val)
    },
    setISTB({commit},val) {
      main.saveCookieEncrypt("ISTB", val, 365)
      commit('setISTB',val)
    },
  },
  getters: {
    getloggedIn(state) {
      return state.status.loggedIn;
    },
    getUser(state) {
      return state.user;
    },
    getQTUSERID(state) {
      return state.user?.QTUSERID;
    },
    getQTUSERGROUP(state) {
      return state.user?.QTUSERGROUP;
    },
    getQTUSERNAME(state) {
      return state.user?.QTUSERNAME;
    },
    getQTSTCODE(state) {
      return state.user?.QTSTCODE;
    },
    getQTSTORENAME(state) {
      return state.user?.QTSTORENAME;
    },
    getQTPROFILE(state) {
      return state.user?.QTPROFILE;
    },
    getQTSALESOURCE(state) {
      return state.user?.QTSALESOURCE;
    },
    getQTSUBSALESOURCE(state) {
      return state.QTSUBSALESOURCE?state.QTSUBSALESOURCE:state.user?.QTSALESOURCE;
    },
    getISTB(state) {
      return false;
    },
    // getISTB(state) {
    //   //return false;
    //   if(state.status.loggedIn){
    //     return state.user?state.user.QTSALESOURCE == 'TB':false;
    //   }else{
    //     return false;
    //   }
    // },
    getUSERMAILB2B(state) {
      return '';
    },
    getQTAPPROVEFLAG(state) {
      return state.user?.QTAPPROVEFLAG;
    },
    getQTBOOKING(state) {
      return state.user?.QTBOOKING;
    },
    getQTOF(state) {
      return state.user?.QTOF;
    },
    getQTSP(state) {
      return state.user?.QTSP;
    },
    QTPLT(state) {
      return state.user?.QTPLT;
    },
    getQTEID(state) {
      return state.user?.QTEID.trim();
    },
    getISB2B(state) {
      return false;
      // if(state.status.loggedIn){
      //   return state.user.B2B?state.user.B2B.ISB2B:false;
      // }else{
      //   return false;
      // }
    },
    getB2BUUID(state) {
      return state.user.B2B?state.user.B2B.B2BUUID.trim():null;
    },
    getB2BREQUESTID(state) {
      return state.user.B2B?state.user.B2B.B2BREQUESTID.trim():null;
    },
    getB2BFIRSTNAME(state) {
      return state.user.B2B?state.user.B2B.B2BFIRSTNAME.trim():null;
    },
    getB2BLASTNAME(state) {
      return state.user.B2B?state.user.B2B.B2BLASTNAME.trim():null;
    },
    getB2BLOCALE(state) {
      return state.user.B2B?state.user.B2B.B2BLOCALE.trim():null;
    },
    getB2BSHIPTO(state) {
      return state.user.B2B?state.user.B2B.B2BSHIPTO.trim():null;
    },
    getB2BCUSTREF(state) {
      return state.user.B2B?state.user.B2B.B2BCUSTREF.trim():null;
    },
    getB2BEMAIL(state) {
      return state.user.B2B?state.user.B2B.B2BEMAIL.trim():null;
    },
    getQTETAX(state) {
      return state.user?.QTETAX;
    },
    getQTCHANGET1(state) {
      return state.user?.QTCHANGET1;
    },
    getQTOTP(state) {
      return state.user?.QTOTP;
    },
    getWSFLAG(state) {
      return state.user?.WSFLAG;
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
      // state.USERMAILB2B = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    setQTSUBSALESOURCE(state, val) {
      state.QTSUBSALESOURCE = val;
    },
    setUSERMAILB2B(state, val) {
      state.USERMAILB2B = '';
    },
    setQTETAX(state, val) {
      state.user.QTETAX = val;
    },
    setISTB(state, val) {
      state.ISTB = val;
    },
  }
};