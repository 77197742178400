<template>
  <div class="container">
    <ProdboardDetailComp :prodId="getProdID"  />
  </div>
</template>

<script>
// @ is an alias to /src
import ProdboardDetailComp from '@/components/Prodboard/ProdboardDetailComp.vue'
export default {
  name: 'ProdboardDetail',
  components: {
    ProdboardDetailComp
  },
  mounted() {
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-CRY3HG283C')
    document.head.appendChild(recaptchaScript)
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CRY3HG283C');
  },
   props: {
    getProdID: {
      type: String,
      required: true
    }
  },
}
</script>
