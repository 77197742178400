<template>
    <section>
        <div class="shadowDiv" style='overflow:hidden;'>
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th class='text-center' scope="col">เลขที่รายการ</th>
                  <th class='text-center' scope="col">วันที่สั่งซื้อ</th>
                  <th class='text-center' scope="col">สาขา</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th :class="`font-color-${buCodeByOrder} text-center`"><p class="mb-0"><span class="skeleton-box w-75" style="height: 30px"></span></p></th>
                  <td class='text-center'><p class="mb-0"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                  <td class='text-center'><p class="mb-0"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                </tr>
              </tbody>
            </table>
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th class='text-center' scope="col">ชื่อลูกค้า</th>
                  <th class='text-center' scope="col">โทรศัพท์</th>
                  <th class='text-center' scope="col">T1 Card</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class='text-center'><p class="mb-0"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                  <td class='text-center'><p class="mb-0"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                  <td class='text-center'><p class="mb-0"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                </tr>
              </tbody>
            </table>
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th class='text-center' scope="col">ประเภทส่วนลด</th>
                  <th class='text-center' scope="col">ประเภทการชำระเงิน</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p class="mb-0"><span class="skeleton-box w-100" style="height: 30px"></span></p>
                  </td>
                  <td>
                    <p class="mb-0"><span class="skeleton-box w-100" style="height: 30px"></span></p>
                  </td>
                </tr>
              </tbody>
            </table>
        </div>
        <br>
        <div class="shadowDiv" style='overflow:hidden;'>
            <p class="h5 mt-3 font-weight-bold">รายละเอียดสินค้า</p>
            <table class="table table-borderless" >
            <thead>
                <tr>
                <th scope="col"></th>
                <th scope="col">สินค้า</th>
                <th scope="col">ราคา</th>
                <th scope="col">จำนวน</th>
                <th scope="col">มูลค่าสินค้า</th>
                <th scope="col">ส่วนลดOTP</th>
                <th scope="col">บาท / เปอร์เซ็น</th>
                <th scope="col">ยอดรวม</th>
                <th scope="col">ราคาคู่แข่ง</th>
                <th scope="col">%Charge</th>
                <th scope="col">Charge Amount</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="py-1"></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                </tr>
                <tr>
                    <td class="py-1"></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                </tr>
                <tr>
                    <td class="py-1"></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                    <td class="py-1"><p class="mb-1"><span class="skeleton-box w-75" style="height: 30px"></span></p></td>
                </tr>
            </tbody>
            </table>
        </div>
        <br>
        <div class="shadowDiv" style='overflow:hidden;'>
            <p class="h5 mt-3 font-weight-bold">ส่วนลดที่มีผลกับ OTP</p>
            <p>ส่วนลด BMGN Promotion </p>
            <p>ส่วนลด On Top </p>
            <p v-show="false" >ส่วนลดผ่อนชำระ </p> 
        </div>
        <br>
        <div class="shadowDiv" style='overflow:hidden;'>
            <p class="h5 mt-3 font-weight-bold">รายละเอียด</p>
            <table class="table table-borderless">
                <tbody>
                <tr>
                    <td class='text-center w-50'>จำนวนสินค้า</td>
                    <td class='text-center'><p class="mb-1"><span class="skeleton-box w-50" style="height: 30px"></span></p></td>
                </tr>
                <tr>
                    <td class='text-center'>ยอดเงิน</td>
                    <td class='text-center'><p class="mb-1"><span class="skeleton-box w-50" style="height: 30px"></span></p></td>
                </tr>
                <tr>
                    <td class='text-center'>ส่วนลด OTP</td>
                    <td class='text-center'><p class="mb-1"><span class="skeleton-box w-50" style="height: 30px"></span></p></td>
                </tr>
                <tr>
                    <td class='text-center'>ส่วนลด PLPP</td>
                    <td class='text-center'><p class="mb-1"><span class="skeleton-box w-50" style="height: 30px"></span></p></td>
                </tr>
                <tr>
                    <td class='text-center'>ส่วนลด ON TOP</td>
                    <td class='text-center'><p class="mb-1"><span class="skeleton-box w-50" style="height: 30px"></span></p></td>
                </tr>
                <tr>
                    <td class='text-center'>ยอดส่วนลดรวม</td>
                    <td class='text-center'><p class="mb-1"><span class="skeleton-box w-50" style="height: 30px"></span></p></td>
                </tr>
                <tr>
                    <td class='text-center'>ยอดเงินสุทธิ</td>
                    <td class='text-center'><p class="mb-1"><span class="skeleton-box w-50" style="height: 30px"></span></p></td>
                </tr>
                </tbody>
            </table>
        </div>
        <br>
        <div class="row d-flex justify-content-end">
            <div class="col-2"><p class="mb-1"><span class="skeleton-box w-100" style="height: 30px"></span></p></div>
            <div class="col-2"><p class="mb-1"><span class="skeleton-box w-100" style="height: 30px"></span></p></div>
            <div class="col-2"><p class="mb-1"><span class="skeleton-box w-100" style="height: 30px"></span></p></div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'OTPRequestDetailSkeleton'
}
</script>

<style scoped>
    .shadowDiv {
        margin: 0px;
        background-color: white;
        padding: 10px;
        border-radius: 7px;
        height: auto;
    }
    .shadowDiv:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }
</style>