<template>
  <section>
    <div class="row">
      <div
        v-for="(e, index) in listData"
        :key="index"
        class="col-6 col-sm-6 col-md-4 row-height col-lg-3 p-1"
      >
        <ProductCard v-if="e.skcount == 1" :cardData="e" :key="$route.path" />
        <!-- <SpuCard v-else :cardData="e" /> -->
      </div>
    </div>
    <SkeletonProductCard v-if="isLoading" />

    <div v-else class="d-flex justify-content-center w-100 mb-3">
      <div v-if="MaxCount - startCount != 0">
        <a
          v-if="!isZeroRow"
          style="cursor:pointer"
          :class="`btn-border-${getBU}`"
          @click="showCategory()"
          >ดูเพิ่มเติม มีอีก {{ MaxCount - startCount }} รายการ</a
        >
      </div>
    </div>
  </section>
</template>

<script>
  import * as serviceAPI from "../services/API.service";
  import * as serviceMain from "../services/main.service";
  import ProductCard from "@/components/ProductCard.vue";
  import SpuCard from "@/components/SpuCard.vue";
  // import PropertyProduct from '@/components/PropertyProduct.vue'
  import SkeletonProductCard from "@/components/SkeletonProductCard.vue";
  export default {
    name: "ProductListB2B",
    data: function() {
      return {
        listData: [],
        isLoading: true,
        startCount: 0,
        wantShowCount: 24,
        isZeroRow: false,
        MaxCount: null,
      };
    },
    mounted() {
      this.showCategory();
      this.setShopItem();
    },
    components: {
      ProductCard,
      SpuCard,
      SkeletonProductCard,
      // PropertyProduct
    },
    props: [
      // "catcode"
    ],
    methods: {
      async showCategory() {
        try {
          let getFilter = this.$route.query.fill;
          let getSort = this.$route.query.sort;
          this.isLoading = true;
          if (this.MaxCount == null) {
            let data = {
              UUID: this.getB2BUUID,
            };
            let getAPI = await serviceAPI.call_API(
              "post",
              `b2b/product/spupaging`,
              data,
              "auth"
            );
            this.MaxCount = getAPI.data.dbitems[0].skcount;
          }
          let endCount = this.startCount + this.wantShowCount;
          if (endCount > this.MaxCount) {
            endCount = this.MaxCount;
            this.isZeroRow = true;
          }
          let data = {
            UUID: this.getB2BUUID,
            STCODE: this.getStoreCode,
            START: this.startCount,
            END: this.wantShowCount,
          };
          // console.log(data);
          let getAPI = await serviceAPI.call_API(
            "post",
            `b2b/product/spu`,
            data,
            "auth"
          );
          // console.log(getAPI);
          this.listData = [...this.listData, ...getAPI.data.dbitems];
          this.isLoading = false;
          this.startCount = endCount;
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      async setShopItem() {
        try {
          let data = {
            REF: this.getB2BCUSTREF,
            UUID: this.getB2BUUID,
          };
          // console.log(data);
          let getShopItem = await serviceAPI.call_API(
            "post",
            `b2b/cart/getShopItem`,
            data,
            "auth"
          );
          // console.log(getShopItem.data);
          this.qtShopItem = getShopItem.data;
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message);
        }
      },
      openNav() {
        $("#mySidenav").show();
        // document.getElementById("mySidenav").show();
      },
      closeNav() {
        $("#mySidenav").hide();
        // document.getElementById("mySidenav").hide();
      },
    },
    computed: {
      getB2BCUSTREF: function() {
        return this.$store.getters["auth/getB2BCUSTREF"];
      },
      getBU: function() {
        return this.$store.getters["customer/getQTBU"];
      },
      getStoreCode: function() {
        return this.$store.getters["service/getQTDFSTORE"];
      },
      getB2BUUID: function() {
        return this.$store.getters["auth/getB2BUUID"];
      },
      qtShopItem: {
        get() {
          return this.$store.getters["customer/getQTSHOPITEM"];
        },
        set(value) {
          console.log(value);
          this.$store.dispatch("customer/setQTSHOPITEM", value);
        },
      },
    },
    watch: {
      getStoreCode: function() {
        this.startCount = 0;
        this.listData = [];
        this.showCategory();
      },
    },
  };
</script>

<style scoped>
  .row-height {
    min-height: 100%;
  }
</style>
