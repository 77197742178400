<template>
  <div v-if="!$store.getters['auth/getISB2B']" id="categoreMenu">
      <div id="manubigban" @mouseleave="closeSmenu()" class="container-fluid col-12 d-none d-sm-block" style="padding: 0px; margin: 0px; height: 34vw; min-height:390px;" >
          <div class="row d-flex justify-content-center d-flex bd-highlight" style="height: 100%; padding: 0px; margin: 0px;">
            <div class="col-3 bd-highlight" style="background-color: white ; height: 100%; color: black; padding: 5px 0px 5px 15px; display: block;">
              
              <div id="mainmenu" style="height: 100%; overflow-y: scroll;" >
                <ul v-if="!isPro" class="p-0 m-0">
                  <li v-for="(e,index) in jSonM" :key="index" style="list-style-type:none;" >
                    <div @click="clickMenu(e.node,e.HAVENEXT)" @mouseover="loadnlevel(index)" class="px-2">
                      <img :src="`/img/lo/${e.node}.webp`" class="icon-width"  onerror="this.style.display='none'">
                      <a class="pl-1" style="cursor: pointer;font-size: 1.2rem;">{{ e.catname }}</a>
                    </div>
                  </li>
                </ul>
                <ul v-else class="p-0 m-0">
                  <li v-for="(e,index) in jSonMP" :key="index" style="list-style-type:none;" >
                    <div @click="clickMenu(e.node,e.HAVENEXT)" @mouseover="loadnlevel(index)" class="px-2">
                      <img :src="`/img/lo/${e.node}.webp`" class="icon-width"  onerror="this.style.display='none'">
                      <!-- <img style="width:15%; display: inline !important;" :src='`${qticon(e.node)}`' > -->
                      <a class="pl-1" style="cursor: pointer;font-size: 1.2rem;">{{ e.catname }}</a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-9 flex-grow-1 bd-highlight" style="height: 34vw; min-height: 390px; padding: 0px;">
              <div class="sec-category col-12" style="overflow-y: auto;" v-if="sMenu">
                <div class="row p-2">
                  <div class="col-md-6 col-xl-3" style="" v-for="(e,index) in jSonShowlv1" :key="index">
                    <div><a style="cursor: pointer;" @click="clickMenu(e.node,e.HAVENEXT)" ><div style="font-weight: bold;"> {{ e.catname }} </div></a></div>
                    <div class="d-inline-block" style="height: 160px;">
                      <ul class="p-0 ml-3">
                        <li v-for="(eli, indexli) in jSonShowlv2[index]" :key="indexli" style="list-style-type:none;"><a style="cursor: pointer;font-weight: normal;" @click="clickMenu(eli.node,false)" ><div> {{ eli.catname }} </div></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 p-0" style="height: 100%;" v-if="!sMenu">
                <img src="/img/baanring.jpg" style="height: 100%;width: 100%;" v-if="qtBu == 'bnb'">
                <img src="/img/bannering.jpg" style="height: 100%;width: 100%;" v-else>
              </div>
            </div>
          </div>      
        </div> 
      
  </div>
</template>

<script>
import * as serviceAPI from '../services/API.service';
import * as serviceMain from '../services/main.service';
import jsonMain from '../../public/loadmenu/jsonMain.json'
import jsonLv1 from '../../public/loadmenu/jsonLv1.json'
import jsonLv2 from '../../public/loadmenu/jsonLv2.json'
import jsonMain_Pro from '../../public/loadmenu/jsonMain_pro.json'
import jsonLv1_Pro from '../../public/loadmenu/jsonLv1_pro.json'
import jsonLv2_Pro from '../../public/loadmenu/jsonLv2_pro.json'
export default {
    name: 'categoreMenu',
    data() {
      return {
        listData: [],
        listDataS: [],
        listDatali: [],
        jSonM: jsonMain,
        jSonlv1: jsonLv1,
        jSonlv2: jsonLv2,
        jSonMP: jsonMain_Pro,
        jSonlv1P: jsonLv1_Pro,
        jSonlv2P: jsonLv2_Pro,
        jSonShowlv1: [],
        jSonShowlv2: [],
        templv1:[],
        sMenu: false
      }
    },
    props:[
    ],
    mounted() {
            // this.loadmmenu();
            
    },
    methods: {
      toggle(){
        this.sMenu = !this.sMenu;
      },
      openSmenu(){
        this.sMenu = true;
      },
      closeSmenu(){
        this.sMenu = false;
      },
      clickMenu(node,HAVENEXT){
        HAVENEXT ? this.$router.push('/Category/'+ node) : this.$router.push('/Product/'+ (node.length == 6 ? node : (node.length == 4 ? node+'00' : node+'0000')))
        this.$emit("ClickCatmenu")
        // this.isOpenCatmenuClick = false;
      },
      async loadmmenu(){
          try {
              let data={}
              let getAPI = await serviceAPI.call_API('get','product/category/',data,'auth');
              this.listData = getAPI.data.dbitems;
              // this.createJsonCatProd();
          } catch (error) {
              serviceMain.showErrorAlert(this, error.message)
          }
      },
      async loadnlevel(getCat){
          if(!this.isPro){
            this.listDataS = [];
            this.listDatali = [];
            this.openSmenu();
            this.jSonShowlv1 = jsonLv1[getCat]
            this.jSonShowlv2 = jsonLv2[getCat]
          }
          else{
            this.listDataS = [];
            this.listDatali = [];
            this.openSmenu();
            this.jSonShowlv1 = jsonLv1_Pro[getCat]
            this.jSonShowlv2 = jsonLv2_Pro[getCat]
          }
          // try {
          //     let data={}
          //     let getAPI = await serviceAPI.call_API('get',`product/category${(getCat)?'/'+getCat:''}`,data,'auth');
          //     // let getAPI = await serviceAPI.call_API('get','product/category/',data,'auth');
          //     this.listDataS = getAPI.data.dbitems;
          //     console.log(getAPI);
          //     for (let index = 0; index < this.listDataS.length; index++) {
          //       try {
          //           let data={}
          //           let getAPI = await serviceAPI.call_API('get',`product/category${(this.listDataS[index].node)?'/'+this.listDataS[index].node:''}`,data,'auth');
          //           // let getAPI = await serviceAPI.call_API('get','product/category/',data,'auth');
          //           this.listDatali[index] = getAPI.data.dbitems;
          //       } catch (error) {
          //           serviceMain.showErrorAlert(this, error.message)
          //       }
          //     }
          //     // console.log('listDatali');
          //     console.log(this.listDatali)
              

          // } catch (error) {
          //     serviceMain.showErrorAlert(this, error.message)
          // }
      },
      async createJsonMenu(){
        try {
          let data={}
          let lv1menu = [];
          let lv2menu = [];
          let lv1menutemp = [];
          for (let i = 0; i < this.listData.length; i++) {
            let lv2menutemp = [];
            let getAPI = await serviceAPI.call_API('get',`product/category/${(this.listData[i].node)}`,data,'auth');
            lv1menutemp = getAPI.data.dbitems
            lv1menu.push(getAPI.data.dbitems)
            console.log(lv1menutemp);
            for (let j = 0; j < lv1menutemp.length; j++) {
              console.log(lv1menutemp[j].node);
              let getAPI3 = await serviceAPI.call_API('get',`product/category/${lv1menutemp[j].node}`,data,'auth');
              lv2menutemp.push(getAPI3.data.dbitems)
            }
            lv2menu[i] = lv2menutemp
          }
          console.log(lv1menu);
          console.log(lv2menu);
          // let i = 40;
          // for (let j = 0; j < this.jSonlv1[i].length; j++) {
          //   let getAPI3 = await serviceAPI.call_API('get',`product/category${(this.jSonlv1[i][j].node)?'/'+this.jSonlv1[i][j].node:''}`,data,'auth');
          //   this.templv1[j] = getAPI3.data.dbitems
            // if(getAPI3.data.dbrows != 0){
            //   this.jSonlv2[0][j] = getAPI3.data.dbitems
            // } 
          // }
          
            
        } catch (error) {
            serviceMain.showErrorAlert(this, error.message)
        }
      },
      async createJsonCatProd(){
        try {
          let data={}
          let lv1menu = [];
          let lv2menu = [];
          let lv1menutemp = [];
          let searchcat = []
          for (let i = 0; i < this.listData.length; i++) {
            let lv2menutemp = [];
            let getAPI = await serviceAPI.call_API('get',`product/category/${(this.listData[i].node)}`,data,'auth');
            lv1menutemp = getAPI.data.dbitems
            lv1menu.push(getAPI.data.dbitems)
            console.log(lv1menutemp);
            for (let j = 0; j < lv1menutemp.length; j++) {
              console.log(lv1menutemp[j].node);
              if(!lv1menutemp[j].HAVENEXT){
                searchcat.push({
                    "node": lv1menutemp[j].node,
                    "catname": lv1menutemp[j].catname
                })
              }
              let getAPI3 = await serviceAPI.call_API('get',`product/category/${lv1menutemp[j].node}`,data,'auth');
              lv2menutemp.push(getAPI3.data.dbitems)
              for (let k = 0; k < getAPI3.data.dbitems.length; k++) {
                if(!getAPI3.data.dbitems[k].HAVENEXT){
                  searchcat.push({
                      "node": getAPI3.data.dbitems[k].node,
                      "catname": getAPI3.data.dbitems[k].catname
                  })
                }
              }
            }
            lv2menu[i] = lv2menutemp
          }
          console.log(lv1menu);
          console.log(lv2menu);
          console.log(searchcat);
          // let i = 40;
          // for (let j = 0; j < this.jSonlv1[i].length; j++) {
          //   let getAPI3 = await serviceAPI.call_API('get',`product/category${(this.jSonlv1[i][j].node)?'/'+this.jSonlv1[i][j].node:''}`,data,'auth');
          //   this.templv1[j] = getAPI3.data.dbitems
            // if(getAPI3.data.dbrows != 0){
            //   this.jSonlv2[0][j] = getAPI3.data.dbitems
            // } 
          // }
          
            
        } catch (error) {
            serviceMain.showErrorAlert(this, error.message)
        }
      }
    },
    computed: {
      qtBu: function () {
        return this.$store.getters['customer/getQTBU'];
      },
      isPro:function(){
        return process.env.NODE_ENV == 'production'
      }
    }

}
</script>

<style scpoe>
.sec-category {
    display: inline-block;
    /* width: 78%; */
    width: 99%;
    background-color: rgba(210, 210, 210, 0.2);
    position: relative;
    padding: .85rem 0;
    height: 100%;
    /* height: 465px; */
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: scroll
}
.sec-categoryM {
    /* display: inline-block; */
    /* width: 78%; */
    width: 100%;
    background-color: rgba(210, 210, 210, 0.2);
    position: relative;
    height: 100%;
    /* height: 465px; */
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: scroll
}
.icon-width{
  width: 10%;
}
  @media screen and (max-width: 768px) {
  .icon-width{
    width: 15%;
  }
}
</style>