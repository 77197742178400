<template>
  <section>
    <div class="container">
      <!-- <div class="row">
        <div class="col-12 col-md-6">

        </div>
        <div class="col-12 col-md-6">
          <PropertyProductSearch :srcTxt="getSrcTxt" v-if="!notFoundData"/>
        </div>
      </div> -->
      <ProductSearchListB2B @notFound="notFound" :srctxt="getSrcTxt" :key="getQuery"/>
    </div>
    <Comparefuc />
  </section>
</template>

<script>

import ProductSearchListB2B from '@/components/ProductSearchListB2B.vue'
// import PropertyProductSearch from '@/components/PropertyProductSearch.vue'
// import Comparefuc from '@/components/comparefuc.vue'
export default {
  name: 'ProductSearchB2B',
  components: {
    ProductSearchListB2B,
    // PropertyProductSearch,
    // Comparefuc
  },
  props: {
    
  },
    data: function () {
    return {
      notFoundData: false,
    }
  },
  mounted() {
    document.title = `${process.env.VUE_APP_TITLE} - Product Lists`
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-CRY3HG283C')
    document.head.appendChild(recaptchaScript)
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CRY3HG283C');
  },
  methods: {
    notFound(val){
      this.notFoundData = val
    }
  },
  computed:{
    getSrcTxt:function(){
      return this.$route.query.src
    },
    getQuery:function(){
      return this.$route.query;
    }
  },
}
</script>
