<template>
      <div class="modal fade" id="mapModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title font-weight-bold" id="exampleModalLabel">เลือกสาขา <a :class="`BTN-Refresh bg-PWB`" @click="startStore()">Refresh</a></h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <!-- 920,บางนา,13.6524,100.6767,02-101-5200,07.00,21.00,54 Moo 13   Bangkaew Bang Phli,09.00,19.00,twd -->
            <div class="modal-body ">
                <!-- <div class="row">
                    <div class="col-3 BTN-selectstore bg-black p-2">
                        <a >ทั้งหมด</a>
                    </div>
                    <div class="col-3 BTN-selectstore bg-twd p-2">
                        <a >ไทวัสดุ</a>
                    </div>
                    <div class="col-3 BTN-selectstore bg-bnb p-2">
                        <a >บ้านแอนด์บียอนด์</a>
                    </div>
                    <div class="col-3 BTN-selectstore bg-at1 p-2">
                        <a >Auto1</a>
                    </div>
                </div> -->
                <div class="row my-2">
                  <div class="col-12">
                    <input v-model="search" class="input-store-src text-center " type="text" placeholder="ค้นหาสาขาด้วยชื่อ หรือ store code" >
                  </div>
                </div>
                <div v-for="[stcode,stname,lat,lng,tel,openDate,closeDate,address,startPick,endPick,BU,stscode] in filteredList" class="row" :key="stcode" v-show="!isLoading">
                  <div  class="col-12 p-1">
                      <span :class="`font-weight-bold font-${BU}`" >{{`${BU=='PWB'?'สาขาพาวเวอร์บาย':'' }`}}{{`${BU=='bnb'?'บ้านแอนด์บียอนด์ สาขา':'' }`}}{{`${BU=='at1'?'Auto1 สาขา':'' }`}}{{stname}} - {{stscode}} {{`(${stcode})`}}</span>
                      <a :class="`BTN-selectstore bg-${BU}`" @click="selected(stcode,stname,BU)">เลือกสาขา</a>
                      <br>
                      <span  >เวลาทำการ {{openDate}} - {{closeDate}}</span>
                      <span hidden >{{lat}}{{lng}}{{tel}}{{address}}{{startPick}}{{endPick}}</span>
                  </div>
                  <div class="col-12">
                    <hr>
                  </div>
                  
                </div>
                <div class="loading"></div>
            </div>
            <!-- <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary">Save changes</button>
            </div> -->
          </div>
        </div>

      </div>
      
</template>


<script>
import * as serviceAPI from '../services/API.service';
import * as serviceMain from '../services/main.service';
  export default {
    name: 'SelectStore',
    data: function () {
      return {
        listStore:[],
        search:'',
        isLoading:false
      }
    },
    mounted() {
      $(".loading").hide();
      this.startStore()
      // this.selected('026','Online','PWB')
      // this.$store.dispatch('service/setQTDFSTORE', '026')
      // this.$store.dispatch('service/setStoreOnline')
    },
    methods: {
      async startStore(){
        let getQTSAVESTORE = this.$store.getters['service/getQTSAVESTORE']
        if(getQTSAVESTORE){
          let locationsTEMP=getQTSAVESTORE.split('|');
          this.listStore = locationsTEMP.map(e => e.split(','));
          // if(this.StoreCode != '026'){
          //   this.$store.dispatch('service/setStoreOnline')
          // }
        }
      },
      async selected(para_stcode,para_stname,para_BU){
        try {
          $(".loading").show();
          this.isLoading = true
          // console.log(this.getDSMMSSTORE,para_stcode);
          if(this.getDSMMSSTORE && this.getDSMMSSTORE != para_stcode){
            $(".loading").hide();
            this.isLoading = false
            throw new Error(`สาขาไม่ตรงกับJob`)
          }
          let data={
            "REF": this.CartRef, 
            "OLDSTORE":this.StoreCode , 
            "NEWSTORE":para_stcode
          }
          console.log(para_stcode,para_stname,para_BU);
          let getAPI = await serviceAPI.call_API('post',`service/changStore`,data,'auth');
          let getData = getAPI.data.dbitems[0]
          this.$store.dispatch('service/setQTDFSTORE',para_stcode)
          this.$store.dispatch('service/setQTSTORENAME',para_stname)
          this.$store.dispatch('customer/setQTBU',para_BU)
          $(".loading").hide();
          this.isLoading = false
          this.$store.dispatch('service/setReloadStore')
          $('#mapModal').modal('hide')
        } catch (error) {
          $(".loading").hide();
          this.isLoading = false
          serviceMain.showErrorAlert(this,error.message)
        }
      },
      
    },
    computed: {
      filteredList() {
        return this.listStore.filter(e => {
          if(e[1].toLowerCase().includes(this.search.toLowerCase())||e[0].toLowerCase().includes(this.search.toLowerCase())||e[11].toLowerCase().includes(this.search.toLowerCase())){
            return true
          }
          return false
          
        })
      },
      getQTSAVESTORE:function(){
        return this.$store.getters['service/getQTSAVESTORE']
      },
      StoreCode:function(){
        return this.$store.getters['service/getQTDFSTORE']
      },
      CartRef:function(){
        return this.$store.getters['customer/getQTCUSTREF']
      },
      getDSMMSSTORE:function(){
        return this.$store.getters['service/getDSMMSSTORE']
      },
      getQTSALESOURCE: function () {
        return this.$store.getters['auth/getQTSALESOURCE']
      },
      qtDfStore: function () {
          return this.$store.getters['service/getQTDFSTORE'];
      },
      getStoreOnline: function () {
          return this.$store.getters['service/getStoreOnline'];
      },
    },
    watch:{
      getQTSAVESTORE:function(){
        this.startStore()
      },
      getDSMMSSTORE:function(){
        if(this.getDSMMSSTORE){
          let index = this.listStore.findIndex(x => x[0] === this.getDSMMSSTORE);
          // let stcode = 
          // let stname = 
          // let BU = 
          let [stcode,stname,lat,lng,tel,openDate,closeDate,address,startPick,endPick,BU,stscode] =this.listStore[index]
          // console.log(stcode,stname,BU);
          this.selected(stcode,stname,BU)
        }
      },
      qtDfStore:function(){
        if(this.qtDfStore){
          let index = this.listStore.findIndex(x => x[0] === this.qtDfStore);
          // let stcode = 
          // let stname = 
          // let BU = 
          let [stcode,stname,lat,lng,tel,openDate,closeDate,address,startPick,endPick,BU,stscode] =this.listStore[index]
          // console.log(stcode,stname,BU);
          this.selected(stcode,stname,BU)
        }
      },
      getStoreOnline:function(){
        if(this.listStore){
          let index = this.listStore.findIndex(x => x[0] === '026');
          // let stcode = 
          // let stname = 
          // let BU = 
          // if(index){
            let [stcode,stname,lat,lng,tel,openDate,closeDate,address,startPick,endPick,BU,stscode] =this.listStore[index]
            // console.log(stcode,stname,BU);
            this.selected(stcode,stname,BU)
          // }
        }
      }
    } 
  }
       
</script>

<style scoped>
.modal-title{
  color: #55246c;
}
.modal-body{
  max-height: 70vh;
  overflow: auto;
}
hr{
  padding: 5px 0px;
  margin: 5px 0px;
}
.BTN-selectstore{
  padding: 5px 15px;
  border: none;
  margin-top: 10px;
  float: right;
  color: white;
  border-radius: 8px;
  cursor: pointer;
}
.BTN-Refresh{
  padding: 0px 15px;
  border: none;
  margin: 0px 15px;
  float: right;
  color: white;
  border-radius: 8px;
  cursor: pointer;
}
.input-store-src{
  width: 100%;
  border: 1px #55246c solid;
  border-radius: 8px;
}
.input-store-src:focus{
  border: 1px #55246c solid;
}
.loading {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: #000000;
  background-image: url("/img/loadsmall.gif");
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10000000;
  opacity: 0.2;
  filter: alpha(opacity=20);
}
</style>