<template>
  <section>
    <div class="container">
      <div class="row justify-content-md-center mb-3 mt-3">
        <div class="col-12 col-md-9">
          <B2BCartOrder :data="getB2BCartOrder"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import B2BCartOrder from "@/components/B2BCartOrder.vue";
  export default {
    name: "B2BFinished",
    components: {
      B2BCartOrder,
    },
    props: {},

    data: function() {
      return {};
    },
    mounted() {
      document.title = `${process.env.VUE_APP_TITLE} - B2B Finished`;
      // this.startProperty();
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-CRY3HG283C')
      document.head.appendChild(recaptchaScript)
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-CRY3HG283C');
    },
    methods: {
      // async startProperty() {
      //   // console.log(getB2BCartOrder);
      // },
    },
    computed: {
      getB2BCartOrder: function() {
        return this.$store.getters["b2b/getB2BCartOrder"];
      },
    },
  };
</script>
