// import AuthService from '../services/auth.service';
import * as main from '../services/main.service';
// import * as constant from '../services/constant';



export const customer = {
  namespaced: true,
  state: {
    QTMCID:main.getMCID('MCID'),
    QTCUSTREF:main.readCookieEncrypt("QTCUSTREF"),
    QTSHOPITEM:main.readCookieEncrypt("QTSHOPITEM"),
    QTBU:main.readCookieEncrypt("QTBU"),
    QTCUSTINFO:main.readCookieEncrypt("QTCUSTINFO"),
    QTCOMPARE:main.readCookieEncrypt("QTCOMPARE"),
    QTDLVTRTYPE: main.readCookieEncrypt("QTDLVTRTYPE"),
    EPCUSTINFO:main.readCookieEncrypt("EPCUSTINFO"),
    QTCOPYREF:main.readCookieEncrypt("QTCOPYREF"),
    QTCREDITBAL:main.readCookieEncrypt("QTCREDITBAL"),
    QTPONUM:main.readCookieEncrypt("QTPONUM"),
  },
  actions: {
    setQTCUSTREF({commit},QTCUSTREF) {
      main.saveCookieEncrypt("QTCUSTREF", QTCUSTREF)
      commit('setQTCUSTREF',QTCUSTREF)
    },
    setQTSHOPITEM({commit},QTSHOPITEM) {
      main.saveCookieEncrypt("QTSHOPITEM", QTSHOPITEM)
      commit('setQTSHOPITEM',QTSHOPITEM)
    },

    setQTBU({commit},QTBU) {
      main.saveCookieEncrypt("QTBU", QTBU)
      commit('setQTBU',QTBU)
    },
    setQTCUSTINFO({commit},QTCUSTINFO) {
      let EnCustinfo = JSON.stringify(QTCUSTINFO)
      main.saveCookieEncrypt("QTCUSTINFO",EnCustinfo )
      commit('setQTCUSTINFO',EnCustinfo)
    },
    setEPCUSTINFO({commit},EPCUSTINFO) {
      let EnCustinfo = JSON.stringify(EPCUSTINFO)
      main.saveCookieEncrypt("EPCUSTINFO",EnCustinfo )
      commit('setEPCUSTINFO',EnCustinfo)
    },
    setQTCOMPARE({commit},QTCOMPARE) {
      let EnCompare = JSON.stringify(QTCOMPARE)
      main.saveCookieEncrypt("QTCOMPARE",EnCompare )
      commit('setQTCOMPARE',EnCompare)
    },
    setQTDLVTRTYPE({commit}, QTDLVTRTYPE) {
      main.saveCookieEncrypt("QTDLVTRTYPE",QTDLVTRTYPE )
      commit('setQTDLVTRTYPE',QTDLVTRTYPE)
    },
    clearCustomer({commit}){
      main.removeCookie('QTCUSTREF')
      main.removeCookie('QTDLVTRTYPE')
      main.removeCookie('QTCUSTINFO')
      main.removeCookie('QTCOPYREF')
      main.removeCookie('QTPONUM')
      main.removeCookie('QTCREDITBAL')      
      main.removeCookie('EPCUSTINFO')
      main.saveCookieEncrypt("QTSHOPITEM", 0)
      main.removeCookie('QTBU')
      commit('clearCustomer')
    },
    clearCustomerB2B({commit}){
      main.removeCookie('QTCUSTREF')
      main.removeCookie('QTDLVTRTYPE')
      main.saveCookieEncrypt("QTSHOPITEM", 0)
      main.removeCookie('QTBU')
      commit('clearCustomer')
    },
    clearAfterConfirmCheckout({commit, dispatch}){
      dispatch('clearCustomer');
    },
    clearAfterConfirmCheckoutB2B({commit, dispatch}){
      dispatch('clearCustomerB2B');
    },
    clearAfterLogout({commit, dispatch}){
      main.removeCookie('QTCOMPARE')
      dispatch('clearCustomer');
      commit('clearAfterLogout')
    },
    setQTCOPYREF({commit},QTCOPYREF) {
      main.saveCookieEncrypt("QTCOPYREF", QTCOPYREF)
      commit('setQTCOPYREF',QTCOPYREF)
    },
    setQTPONUM({commit},QTPONUM) {
      main.saveCookieEncrypt("QTPONUM", QTPONUM)
      commit('setQTPONUM',QTPONUM)
    },
    setQTCREDITBAL({commit},QTCREDITBAL) {
      main.saveCookieEncrypt("QTCREDITBAL", QTCREDITBAL)
      commit('setQTCREDITBAL',QTCREDITBAL)
    },
  },
  getters: {
    getAll(state) {
      return state;
    },
    getQTSHOPITEM_LIMIT(state) {
      return state.QTSHOPITEM>99?'99+':state.QTSHOPITEM;
    },
    getQTSHOPITEM(state) {
      return state.QTSHOPITEM;
    },
    getQTCUSTREF(state) {
      return state.QTCUSTREF?state.QTCUSTREF:'';
    },
    getQTCOPYREF(state) {
      return state.QTCOPYREF?state.QTCOPYREF:'';
    },
    getQTPONUM(state) {
      return state.QTPONUM?state.QTPONUM:'';
    },
    getQTCREDITBAL(state) {
      return state.QTCREDITBAL?state.QTCREDITBAL:'';
    },
    getQTBU(state) {
      return state.QTBU?state.QTBU:'PWB';
    },
    getQTMCID(state) {
      return state.QTMCID;
    },
    getQTCUSTINFO(state) {
      return state.QTCUSTINFO ? 
      JSON.parse(state.QTCUSTINFO):
      {          
        CNAME:'',
        MOBILE:'',
        EMAIL:'',
        T1C:'',
        VIPID:'',
        SALESOURCE: '',
        EMPLOYEEBUSHORTCODE: '',
        EMPLOYEEID: '',
        ISSTAFF: '',
      };
    },
    getEPCUSTINFO(state) {
      return state.EPCUSTINFO ? 
      JSON.parse(state.EPCUSTINFO):
      {          
        COMPANYID:'',
        CUSTIDDS:'',
        STORE:''
      };
    },
    getQTCOMPARE(state) {
      return state.QTCOMPARE?state.QTCOMPARE:'';
    },
    getQTDLVTRTYPE(state) {
      return state.QTDLVTRTYPE;
    },
  },
  mutations: {
    setQTCUSTREF(state, QTCUSTREF) {
      state.QTCUSTREF = QTCUSTREF;
    },
    setQTCOPYREF(state, QTCOPYREF) {
      state.QTCOPYREF = QTCOPYREF;
    },
    setQTPONUM(state, QTPONUM) {
      state.QTPONUM = QTPONUM;
    },
    setQTCREDITBAL(state, QTCREDITBAL) {
      state.QTCREDITBAL = QTCREDITBAL;
    },
    setQTSHOPITEM(state, QTSHOPITEM) {
      state.QTSHOPITEM = QTSHOPITEM;
    },

    setQTCUSTINFO(state, QTCUSTINFO) {
      state.QTCUSTINFO = QTCUSTINFO;
    },
    setEPCUSTINFO(state, EPCUSTINFO) {
      state.EPCUSTINFO = EPCUSTINFO;
    },
    setQTBU(state, QTBU) {
      state.QTBU = QTBU;
    },
    setQTCOMPARE(state, QTCOMPARE) {
      state.QTCOMPARE = QTCOMPARE;
    },
    setQTDLVTRTYPE(state, QTDLVTRTYPE) {
      state.QTDLVTRTYPE = QTDLVTRTYPE;
    },
    clearCustomer(state) {
      state.QTSHOPITEM = 0;
      state.QTCUSTREF = null;
      state.QTCUSTINFO = null;
      state.EPCUSTINFO = null;
      state.QTDLVTRTYPE = null;
      state.QTBU = null;
    },
    clearCustomerB2B(state) {
      state.QTSHOPITEM = 0;
      state.QTCUSTREF = null;
      state.QTDLVTRTYPE = null;
      state.QTCOPYREF = null;
      state.QTPONUM = null;
      state.QTCREDITBAL = null;
      state.QTBU = null;
    },
    clearAfterLogout(state) {
      state.QTCOMPARE = null;
    }
  }
};