<template>
  <section>
    <button :disabled="!dlvcode" @click="openModal" type="button" :style="`${!dlvcode?'background-color: lightgray !important;cursor: not-allowed;':''}`" :class="`btn-visible-${buCode ? buCode : getBU} w-100 mb-1`">พิมพ์ใบเสนอราคา</button>

    <Modal ref="QuotationModalRef" size="90" :buCode="buCode">
      <template v-slot:header>
        <h3>ใบเสนอราคา</h3>
      </template>
      <template v-slot:body>
        <div :id='`quotation-detail-${cartref}`'>
          <div class="row px-3 mt-4">
            <div class="col-12 mb-2">
              <VueBarcode :value="cartref" :key="cartref">
                ไม่สามารถสร้าง Barcode ได้
              </VueBarcode>
            </div>
          </div>
          <div class="row px-3 mt-3 d-none d-sm-block">
            <div class="col-12 mb-2">
              <h4 class="ml-3 mt-3 font-weight-bold" style="background-color:#E4E4E4;">รายละเอียดสินค้า</h4>
              <table class="table table-borderless">
                <tbody>
                  <tr v-for="(e, index) in listDataOrder" :key="index" style="border-bottom: 1px solid #E7E7E7;">
                    <td>
                      <VueBarcode v-if="e.SKC" :value="e.SKC" :key="e.SKC" style="width:100px;">
                        ไม่สามารถสร้าง Barcode ได้
                      </VueBarcode>
                    </td>
                    <td class="p-0">
                      <ImageBySKU class="py-1" style="max-height: 10vh;" :sku="e.SKC" :key="e.SKC" :buCode="buCode"
                        v-if="!(e.SKC == '')" />
                    </td>
                    <td class="py-3">{{ e.PRN }} <br> รหัสสินค้า : {{ e.SKC }} ราคา {{e.UPC}} บาท/ชิ้น จำนวน {{e.QNT}} ชิ้น
                      <br>
                      <span class="line-low">OFflag: {{e.OFflag}}</span>
                      <span class="line-low" v-if="e.PORQ == 'P'"> PO: {{e.VENST}}</span>
                      <span class="line-low" v-if="e.PORQ == 'R'"> RQ: {{e.VENST}}</span>
                      <br v-if="e.SKC != '154338' && e.DLVTEXT != ''">
                      <span class="line-low" v-if="e.SKC != '154338' && e.DLVTEXT != ''">{{ e.DLVTEXT }} <span v-if="e.DLVDATE!=''">วันที่:{{ e.DLVDATE }}</span></span>
                      <br>
                      <span class="line-low" v-if="e.SLPRSN"> รหัสพนักงาน: {{e.SLPRSN}}</span>
                      <br>
                      <span class="line-low" v-if="e.REMARK"> REMARK: {{e.REMARK}}</span>
                    </td>
                    <!-- <td class="py-1"></td>
                    <td class="py-1"></td>
                    <td class="py-1"></td> -->
                    <td class="py-3 font-weight-bold text-center"> ยอดรวม {{ e.AMT }} บาท</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row px-3 mt-3">
            <div class="col-12 mb-2">
              <h4 class="ml-3 mt-3 p-2 font-weight-bold" style="background-color:#E4E4E4;">รายละเอียดการชำระเงิน</h4>
              <div class="container-fluid">
                <div class="row">
                  <div class="col">
                    <p>ราคารวม</p>
                    <p>ส่วนลดตามรายการ</p>
                    <p>ส่วนลด MKT</p>
                    <p>ส่วนลดพนักงาน</p>
                  </div>
                  <div class="col text-end">
                    <p>{{ listDataAMT.amount }} บาท</p>
                    <p>{{ discount }} บาท</p>
                    <p>{{ listDataAMT.discmkt }} <span>{{`${listDataAMT.DISCCODE ? listDataAMT.DISCCODE:''}`}}</span> บาท</p>
                    <p>{{ showDisStaff }} บาท</p>
                  </div>
                </div>
              </div>

              <div class="container-fluid">
                <div class="row font-weight-bold" style="background-color:#ABABAB;">
                  <div class="col">ราคารวมสุทธิ</div>
                  <div class="col text-end">{{ sumNET }} บาท</div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 mt-2">
                  <table class="table table-borderless mt-3 py-0">
                    <thead style="background-color:#ABABAB; width:100%;">
                      <tr>
                        <th scope="col">ลำดับ</th>
                        <th scope="col">ชื่อสินค้า</th>
                        <th scope="col">จำนวน</th>
                        <th scope="col">ส่วนลด</th>
                        <th scope="col">Remark</th>
                      </tr>
                    </thead>
                    <tbody v-if="listDataDIS">
                      <tr v-for="(e, index) in listDataDIS" :key="index">
                        <td class="py-1">{{ e.SEQ }}</td>
                        <td class="py-1">{{ e.SKNAME }}</td>
                        <td class="py-1">{{ e.QNT }}</td>
                        <td class="py-1">{{ e.DSC }}</td>
                        <td class="py-1">{{ e.REMARK }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-12" style="padding: 0px;">
                <p class="remark">หมายเหตุ</p>
                <p>{{ listDataAMT.REMARK }}</p>
              </div>

            </div>
          </div>
        </div>
        <!-- <img src="/img/no-img.jpg" alt="" class="py-1" style="max-height: 10vh;">
        <a href="#" onclick="printJS('/img/no-img.jpg', 'image');return false;">
            <i class="fa fa-print"></i>
        </a> -->
            <!-- <PrintQuotationDetail :cartref="cartref" :stcode="stcode" :key="cartref" :listDataAMT="listDataAMT" :listDataOrder="listDataOrder" :sorderref="sorderref"
            :listDataDIS="listDataDIS" :showDisStaff="showDisStaff" :sumNET="sumNET" :grouping="grouping" :groupName="groupName" :sendTotPrice="sendTotPrice" :buCode="buCode"
            @getSalesource="getSalesource" :dlvType="dlvType" :discount="discount"/> -->

        <div class="row m-3" v-show="!disPrint">
          <div class="col-12 col-md-4">
            <button type="button" :disabled="disPrint" :class="`btn bt w-100 bg-${buCode ? buCode : getBU}`" data-dismiss="modal"
              @click="goPrint(0)">พิมพ์ใบเสนอราคา</button>
          </div>
          <div class="col-12 col-md-4" v-if="!$store.getters['auth/getISTB']">
            <button type="button" :disabled="disPrint" :class="`btn bt w-100 bg-${buCode ? buCode : getBU}`" data-dismiss="modal"
              @click="goPrint(1)">พิมพ์ใบเสนอราคาแบบกำหนดเอง</button>
          </div>
          <div class="col-12 col-md-4" v-if="!$store.getters['auth/getISTB']">
            <button type="button" :disabled="disPrint" :class="`btn bt w-100 bg-${buCode ? buCode : getBU}`" data-dismiss="modal"
              @click="goPrint(2)">พิมพ์ใบเสนอราคาแบบบริษัท</button>
          </div>
        </div>
        <PrintQuotationDetailSkeleton v-show="disPrint"/>

        <!-- //////////////////////////// Print Page ////////////////////////////// -->
        <div v-show="false">
          <div class="container print-Div" :id="`printQuo-${cartref}`" v-if="listData.userid">
            <div class="row">
              <div class="col-2 pl-2 py-0 align-self-center">
                <!-- <img :src="`/img/${buCode == 'bnb'?'bnbqtlogo1.jpg': (buCode == 'at1'? 'at1qtlogo.jpg' : 'PWBqtlogo.jpeg')}`" style="max-height:120px; float:left; margin:0px 10px;width: 85px;" alt=""> -->
              </div>
              <div class="col-7 px-2 py-0">
                <p class="line-low m-0"> <b class="bhead">{{listData.sttname}}</b></p>
                <p class="line-low title-little m-0">{{listData.st_thaddr}} โทร. {{listData.sttel}}</p>
                <!-- <p class="line-low title-little m-0">เลขที่ประจำตัวผู้เสียภาษี 0105555021215</p>
                <p class="line-low title-little m-0">E-Mail : LineOA@chg.co.th Web Site : https://thaiwatsadu.com / https://www.bnbhome.com/</p> -->
              </div>
              <div class="col-3 px-2 py-0 align-self-center">
                <VueBarcode :value="cartref" :key="cartref" style="width:250px;  height:120px; margin-right:15px;">
                  ไม่สามารถสร้าง Barcode ได้
                </VueBarcode>
              </div>
            </div>
            <div class="row border">
              <div class="col-4 p-0 ">
                <p class=" title-name my-1 px-1" ><b>สาขา: </b>{{listData.stname}}</p>
              </div>
              <div class="col-4 p-0 ">
                <p class="text-center title-name my-1" >ใบเสนอราคา</p>
              </div>
              <div class="col-4 p-0 ">
                <p class="text-right title-name my-1 px-1" >{{cartref}} ( {{sorderref}} )</p>
              </div>
            </div>
            <div class="row border">
              <div class="col-6 px-3 p-0" >
                <p class="m-0 line-low"><b>วันที่เสนอราคา: </b>{{listData.qtdate}} </p>
                <p class="m-0 line-low"><b>วันหมดอายุ: </b>{{listData.expireddate}}</p>
                <p class="m-0 line-low"><b>รูปแบบการรับสินค้า: </b>{{dlvType.D ? 'จัดส่งสินค้า' : ''}} {{dlvType.D && dlvType.P ? '/' : ''}} {{dlvType.P ? 'รับที่สาขา' : ''}}</p>
                <p class="m-0 line-low"><b>รูปแบบใบกำกับภาษี: </b>{{ listData.reqtax ? invoiceShow : '-'}}</p>
                <!-- <p v-if="!dlvStatus && dlvStoreName.trim() != ''" class="m-0 line-low"><b>รับสินค้าที่สาขา: </b>{{dlvStoreName}}</p> -->
              </div>
              <div class="col-6 px-3 p-0">
                <p class="m-0 line-low"><b>อ้างอิงเลขที่ OTP: </b>{{listData.otp}}</p>
                <p class="m-0 line-low"><b>รหัสพนักงาน:</b> {{listData.saleperson}}  <b>ชื่อพนักงาน:</b> {{listData.empname}}</p>
                <p class="m-0 line-low"><b>Sale Source:</b> {{listData.salesource}}</p>
                <!-- <p class="m-0 line-low"><b>{{dlvDateShow}}</b>{{listData.dlvdate}}</p> -->
              </div>
            </div>
            <div class="row border">
              <div v-if="m2" class="col-12 px-3 p-0 border-right ">
                <p class="m-0 line-low"><b><u>ข้อมูลลูกค้า</u></b></p>
                <div class="row p-0 m-0">
                  <div class="col-3 p-0"><p class="m-0 line-low"><b>ผู้ติดต่อ:</b> {{customQT.nameC ? customQT.nameC : ''}}</p></div>
                  <div class="col-3 p-0"><p class="m-0 line-low"><b>ชื่อบริษัท:</b> {{customQT.comnameC ? customQT.comnameC : ''}}</p></div>
                  <div class="col-3 p-0"><p class="m-0 line-low"><b>โทรศัพท์:</b> {{customQT.phoneC ? customQT.phoneC : ''}}</p></div>
                  <div class="col-3 p-0"><p class="m-0 line-low"><b>แฟกซ์:</b> {{customQT.faxC ? customQT.faxC : ''}}</p></div>
                </div>
                <div class="row p-0 m-0">
                  <div class="col-9 p-0"><p class="m-0 line-low"><b>ที่อยู่:</b> {{customQT.addressC ? customQT.addressC : ''}}</p></div>
                  <div class="col-3 p-0"><p class="m-0 line-low"><b>เลขที่ประจำตัวผู้เสียภาษีอากร:</b> {{customQT.idnumC ? customQT.idnumC : ''}}</p></div>
                </div>
                <!-- <p class="m-0 line-low"><b>ชื่อ-นามสกุล:</b> {{listData.custname}}   <b>เบอร์มือถือ:</b> {{listData.mobile}}   <b>E-mail:</b> {{listData.email}}   <b>The1:</b> {{listData.The1}}</p> -->
                <p v-if="dlvType.D" class="m-0 line-low"><b>ที่อยู่ที่จัดส่ง:</b> {{dlvAddrShow}}</p>
                <p v-if="dlvType.P" class="m-0 line-low"><b>ที่อยู่สาขา:</b> {{listData.st_thaddr}}</p>
              </div>
              <div v-else-if="m1" class="col-12 px-3 p-0 border-right ">
                <p class="m-0 line-low"><b><u>ข้อมูลลูกค้า</u></b></p>
                <div class="row p-0 m-0">
                  <div class="col-3 p-0"><p class="m-0 line-low"><b>ชื่อ-นามสกุล:</b> {{customQT.nameU ? customQT.nameU : ''}}</p></div>
                  <div class="col-3 p-0"><p class="m-0 line-low"><b>เบอร์มือถือ:</b> {{customQT.phoneU ? customQT.phoneU : ''}}</p></div>
                  <div class="col-3 p-0"><p class="m-0 line-low"><b>E-mail:</b> {{customQT.emailU ? customQT.emailU : ''}}</p></div>
                  <div class="col-3 p-0"><p class="m-0 line-low"><b>The1:</b> {{customQT.the1U ? customQT.the1U : ''}}</p></div>
                </div>
                <!-- <p class="m-0 line-low"><b>ชื่อ-นามสกุล:</b> {{listData.custname}}   <b>เบอร์มือถือ:</b> {{listData.mobile}}   <b>E-mail:</b> {{listData.email}}   <b>The1:</b> {{listData.The1}}</p> -->
                <p v-if="dlvType.D" class="m-0 line-low"><b>ที่อยู่ที่จัดส่ง:</b> {{dlvAddrShow}}</p>
                <p v-if="dlvType.P" class="m-0 line-low"><b>ที่อยู่สาขา:</b> {{listData.st_thaddr}}</p>
              </div>
              <div v-else class="col-12 px-3 p-0 border-right ">
                <p class="m-0 line-low"><b><u>ข้อมูลลูกค้า</u></b></p>
                <div class="row p-0 m-0">
                  <div class="col-6 p-0 m-0"><p class="m-0 p-0 line-low"><b>ชื่อ-นามสกุล:</b> {{listData.custname}}</p></div>
                  <!-- <div class="col-3 p-0 m-0"><p class="m-0 p-0 line-low"><b>เบอร์มือถือ:</b> {{listData.mobile}}</p></div>
                  <div class="col-3 p-0 m-0"><p class="m-0 p-0 line-low"><b>E-mail:</b> {{listData.email}}</p></div> -->
                  <div class="col-6 p-0 m-0"><p class="m-0 p-0 line-low"><b>The1:</b> {{listData.The1}}</p></div>
                </div>
                <div class="row p-0 m-0">
                  <!-- <div class="col-3 p-0 m-0"><p class="m-0 p-0 line-low"><b>ชื่อ-นามสกุล:</b> {{listData.custname}}</p></div> -->
                  <div class="col-6 p-0 m-0"><p class="m-0 p-0 line-low"><b>เบอร์มือถือ:</b> {{listData.mobile}}</p></div>
                  <div class="col-6 p-0 m-0"><p class="m-0 p-0 line-low"><b>E-mail:</b> {{listData.email}}</p></div>
                  <!-- <div class="col-3 p-0 m-0"><p class="m-0 p-0 line-low"><b>The1:</b> {{listData.The1}}</p></div> -->
                </div>
                <!-- <p class="m-0 line-low"><b>ชื่อ-นามสกุล:</b> {{listData.custname}}   <b>เบอร์มือถือ:</b> {{listData.mobile}}   <b>E-mail:</b> {{listData.email}}   <b>The1:</b> {{listData.The1}}</p> -->
                <p v-if="dlvType.D" class="m-0 line-low"><b>ที่อยู่ที่จัดส่ง:</b> {{dlvAddrShow}}</p>
                <p v-if="dlvType.P" class="m-0 line-low"><b>ที่อยู่สาขา:</b> {{listData.st_thaddr}}</p>
              </div>
            </div>
            <div class="row px-3 border-right border-left border-bottom" v-if="!grouping">
              <div class="col-12 p-0 d-flex flex-column bd-highlight" >
                <!-- <table>
                  <thead>
                    <tr>
                      <th class="title-little  m-0 py-0 text-center"></th>
                      <th class="title-little  m-0 py-0 text-center"></th>
                      <th class="title-little  m-0 py-0 text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(e, index) in listDataOrder" :key="index" >
                      <td class=" text-center">
                        <VueBarcode v-if="e.SKC" :value="e.SKC" :key="e.SKC" style="width:200px; height:120px;">
                          ไม่สามารถสร้าง Barcode ได้
                        </VueBarcode>
                      </td>
                      <td class=" px-2 text-center">
                        <ImageBySKU class="py-1" style="max-height: 100px; margin-right:15px;" :sku="e.SKC" :key="e.SKC" :buCode="buCode" v-if="!(e.SKC == '')" />
                      </td>
                      <td class=" px-2">
                        <p class="title-name">{{ e.PRN }}</p>  
                        <p class="line-low">รหัสสินค้า : {{ e.SKC }} ราคา {{priceBaht(e.UPC)}} บาท/{{e.UNIT}} จำนวน {{e.QNT}} {{e.UNIT}}
                          <br>
                          <span class="line-low">OFflag: {{e.OFflag}}</span>
                          <span class="line-low" v-if="e.PORQ == 'P'"> PO: {{e.VENST}} </span>
                          <span class="line-low" v-if="e.PORQ == 'R'"> RQ: {{e.VENST}} </span>
                          <span class="line-low pl-1" v-if="e.SKC != '154338' && e.DLVTEXT != ''">{{ e.DLVTEXT }} <span v-if="e.DLVDATE!=''">วันที่:{{ e.DLVDATE }}</span></span>
                          <br>
                          <span class="line-low" v-if="e.SLPRSN"> รหัสพนักงาน: {{e.SLPRSN}}</span>
                          <br>
                          <span class="line-low" v-if="e.REMARK"> REMARK: {{e.REMARK}}</span>
                          <span class="line-low float-right text-right font-weight-bold">ยอดรวม {{ priceBaht(e.AMT) }} บาท</span></p>
                      </td>
                    </tr>
                  </tbody>
                </table> -->
                <div class="row py-2" v-for="(e, index) in listDataOrder" :key="index">
                  <div class="col-3">
                    <VueBarcode v-if="e.SKC" :value="e.SKC" :key="e.SKC" style="width:200px; height:120px;">
                      ไม่สามารถสร้าง Barcode ได้
                    </VueBarcode>
                  </div>
                  <div class="col-2 px-2">
                    <ImageBySKU class="py-1" style="max-height: 120px;" :sku="e.SKC" :key="e.SKC" :buCode="buCode" v-if="!(e.SKC == '')" />
                  </div>
                  <div class="col-7" style="overflow-wrap: break-word;">
                    <p class="title-name">{{ e.PRN }}</p>  
                    <p class="line-low">รหัสสินค้า : {{ e.SKC }} ราคา {{priceBaht(e.UPC)}} บาท/{{e.UNIT}} จำนวน {{e.QNT}} {{e.UNIT}}
                      <br>
                      <span class="line-low">OFflag: {{e.OFflag}}</span>
                      <span class="line-low" v-if="e.PORQ == 'P'"> PO: {{e.VENST}} </span>
                      <span class="line-low" v-if="e.PORQ == 'R'"> RQ: {{e.VENST}} </span>
                      <span class="line-low pl-1" v-if="e.SKC != '154338' && e.DLVTEXT != ''">{{ e.DLVTEXT }} <span v-if="e.DLVDATE!=''">วันที่:{{ e.DLVDATE }}</span></span>
                      <!-- <span class="line-low" v-if="e.ASSEMBLE"><br>จัดส่งแบบไม่ประกอบ</span> -->
                      <br>
                      <span class="line-low" v-if="e.SLPRSN"> รหัสพนักงาน: {{e.SLPRSN}}</span>
                      <br>
                      <span class="line-low" v-if="e.REMARK"> REMARK: {{e.REMARK}}</span>
                      <span class="line-low float-right text-right font-weight-bold">ยอดรวม {{ priceBaht(e.AMT) }} บาท</span></p>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="listDataDIS.length>0 && !grouping" class="row ">
              <div class="col-12 m-0 p-0">
                <table class="table m-0 p-0 table-bordered w-100">
                  <thead >
                    <tr>
                      <th class="title-little m-0 py-0 text-center">ลำดับ</th>
                      <th class="title-little m-0 py-0">ชื่อสินค้า</th>
                      <th class="title-little m-0 py-0 text-center">จำนวน</th>
                      <th class="title-little m-0 py-0 text-center" >ส่วนลด</th>
                      <th class="title-little m-0 py-0">Remark</th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr v-for="(e, index) in listDataDIS" :key="index">
                      <td class="title-little m-0 py-0 text-center">{{ e.SEQ }}</td>
                      <td class="title-little m-0 py-0">{{ e.SKNAME }}</td>
                      <td class="title-little m-0 py-0 text-center">{{ e.QNT }}</td>
                      <td class="title-little m-0 py-0 text-center">{{ e.DSC }}</td>
                      <td class="title-little m-0 py-0">{{ e.REMARK }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-if="listOTPDisc && !grouping" class="row ">
              <div class="col-12 m-0 p-0" v-if="listOTPDisc.length>0 ">
                <table class="table m-0 p-0 table-bordered w-100">
                  <thead >
                    <tr>
                      <th class="title-little m-0 py-0 text-center">ลำดับ</th>
                      <th class="title-little m-0 py-0">ชื่อสินค้า</th>
                      <th class="title-little m-0 py-0 text-center">จำนวน</th>
                      <th class="title-little m-0 py-0 text-center" >ส่วนลดOTP</th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr v-for="(e, index) in listOTPDisc" :key="index">
                      <td class="title-little m-0 py-0 text-center">{{ index+1 }}</td>
                      <td class="title-little m-0 py-0">{{ e.PRN }}</td>
                      <td class="title-little m-0 py-0 text-center">{{ e.QNT }}</td>
                      <td class="title-little m-0 py-0 text-center">{{ e.OTPDISC }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row border" v-if="grouping == '1'">
              <div class="col-6 px-3 p-0" >
                <p class="title-little m-0"><b>{{ groupName }}</b></p>
              </div>
              <div class="col-6 px-3 p-0" style="flex-end">
                <p class="title-little m-0 float-right text-right"><b>ราคารวม  {{ sendTotPrice }} บาท</b></p>
              </div>
            </div>
            <div class="row mb-0 pt-0  border" style="border-right:none;" >
              <div class="col-7">
                <p class="title-name m-0"><b><u>หมายเหตุ</u></b></p>
                <p class="line-low">{{ listDataAMT.REMARK }}</p>
              </div>
              <div class="col-5 m-0 p-0">
                <table class="table m-0 p-0 table-bordered w-100" >
                  <thead>
                    <tr>
                      <th colspan="2" class="m-0 py-0 title-name text-left">รายละเอียดการชำระเงิน</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- <tr>
                      <td colspan="2" class="m-0 py-0 title-name text-left">รายละเอียดการชำระเงิน</td>
                  
                    </tr> -->
                    <tr>
                      <td class="line-low m-0 py-0 text-left">ราคารวม</td>
                      <td class="line-low m-0 py-0 text-right">{{ listDataAMT.amount }} บาท</td>
                    </tr>
                    <tr>
                      <td class="line-low m-0 py-0 text-left">ส่วนลดตามรายการ</td>
                      <td class="line-low m-0 py-0 text-right">{{ discount }} บาท</td>
                    </tr>
                    <tr> 
                      <td class="line-low m-0 py-0 text-left">ส่วนลด MKT {{listDataAMT.DISCCODE ? listDataAMT.DISCCODE:''}}</td>
                      <td class="line-low m-0 py-0 text-right">{{ listDataAMT.discmkt }} บาท</td>
                    </tr>
                    <tr> 
                      <td class="line-low m-0 py-0 text-left">ส่วนลดพนักงาน</td>
                      <td class="line-low m-0 py-0 text-right">{{ showDisStaff }} บาท</td>
                    </tr>
                    <tr>
                      <td class="line-low m-0 py-0 text-left">ราคารวมก่อนVat.</td>
                      <td class="line-low m-0 py-0 text-right">{{ priceBaht(priceBeforeVat) }} บาท</td>
                    </tr>
                    <tr>
                      <td class="line-low m-0 py-0 text-left">VAT</td>
                      <td class="line-low m-0 py-0 text-right">{{ priceBaht(priceVat) }} บาท</td>
                    </tr>
                    <tr>
                      <td class="line-low m-0 py-0 title-name text-left">ราคารวมสุทธิ</td>
                      <td class="line-low m-0 py-0 title-name text-right">{{ sumNET }} บาท</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row  pt-0  border">
              <div class="col-12 mt-1" >
                <p class="title-little m-0 text-center">ค่าขนส่งเป็นราคาประเมิน หากมีการเปลี่ยนแปลงจะมีการติดต่อจากเจ้าหน้าที่ไปอีกครั้ง</p>
              </div>
            </div>
          </div>
    </div>
      </template>
    </Modal>
  </section>
</template>

<script>
  import * as serviceAPI from '@/services/API.service';
  import * as serviceMain from '@/services/main.service';
  import Modal from '@/components/Modal.vue'
  import ImageBySKU from '@/components/ImageBySKU.vue';
  import PrintQuotationDetail from '@/components/Menu/PrintQuotationDetail.vue';
  import PrintQuotationDetailSkeleton from '@/components/Menu/PrintQuotationDetailSkeleton.vue';
  import printJS from 'print-js'

  export default {
    name: "PrintQuotation",
    data: function() {
      return {
        listDataOrder: [],
        listDataAMT:[],
        listDataDIS:[],
        DisStaff:0,
        showDisStaff:'',
        sumNET:0,
        qtSalesource:'',
        dlvType:{P:false, D:false},
        discount:0,
        listData: {
          The1: '',
          custname: '',
          email: '',
          empname: '',
          expired: '',
          expireddate: '',
          mobile: '',
          otp: '',
          qtdate: '',
          salesource: '',
          saleperson: '',
          stname: '',
          userid: '',
          sttname: '',
          stename: '',
          st_thaddr: '',
          st_enaddr: '',
          sttel: '',
        },
        divToPrinttime: '',
        divToPrinthome: '',
        customQT: {
          nameC: '',
          comnameC: '',
          addressC: '',
          idnumC: '',
          phoneC: '',
          faxC: '',
          nameU: '',
          phoneU: '',
          emailU: '',
          the1U: ''
        },
        m0: false,
        m1: false,
        m2: false,
        getStyle:'',
        pickUpText:'',
        priceVat:'',
        priceBeforeVat:'',
        dlvDateShow:'',
        dlvAddrShow:'',
        dlvStatus: false,
        dlvStoreName:'',
        disPrint:false,
        customerCar:'',
        listDataotp:[],
        listOTPDisc:[],
        invoiceShow:'',
      };
    },
    components: {
      Modal,
      ImageBySKU,
      PrintQuotationDetail,
      PrintQuotationDetailSkeleton
    },
    props: [
      "cartref",
      "stcode",
      "grouping",
      "groupName",
      "sendTotPrice",
      "dlvcode",
      "buCode",
      "sorderref",
      "SkuList",
      // "listOTPDisc"
    ],
    methods: {
      async openModal() {
        try {
          this.$refs.QuotationModalRef.showmodal()
          this.disPrint = true
          await this.getOrderDetail()
          await this.setPrint()
          await this.checkListDlv()
          await this.setPrint()
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      async getOrderDetail() {
         try {
          let data = {
            "ref": this.cartref
          }
          let getAPI1 = await serviceAPI.call_API('post', `history/orderdetail`, data, 'auth');
          this.listDataOrder = getAPI1.data.dbitems;

          let getAPI2 = await serviceAPI.call_API('post', `history/getPriceAndRemarkByVfix`, data, 'auth');
          this.listDataAMT = getAPI2.data.dbitems[0];
          this.customerCar = this.listDataAMT.REMARK2 ? JSON.parse(this.listDataAMT.REMARK2) : ''
          // let getAPI4 = await serviceAPI.call_API('post', `history/getRemarkSeq`, data, 'auth');
          // this.listDataRemark = getAPI4.data.dbitems;
          let dataDIS = {
            "ref": this.cartref,
            "stc": this.stcode
          }
          let getAPI3 = await serviceAPI.call_API('post', `history/myplpp`, dataDIS, 'auth');
          this.listDataDIS = getAPI3.data.dbitems;

          for (let i = 0; i < this.listDataOrder.length; i++) {
            for (let j = 0; j < this.listDataDIS.length; j++) {
              // console.log(this.listDataOrder[i].SKUSEQ,this.listDataDIS[j].SKUSEQ);
              if(this.listDataOrder[i].SKUSEQ == this.listDataDIS[j].SKUSEQ){
                this.listDataOrder[i].DSC = this.listDataDIS[j].DSC
                if(!this.listDataDIS[j].SKNAME){
                  this.listDataDIS[j].SKNAME = this.listDataOrder[i].PRN
                }
              }
            }
          }
          
          let dataotp = {
            REF: this.cartref,
            STCODE: this.stcode,
            mode: 1
          }
          let getAPIotp = await serviceAPI.call_API('post', `otp/otpGetQuotationDetail`, dataotp, 'auth');
          this.listDataotp = getAPIotp.data.dbitems;
          this.listOTPDisc = []
          for (let i = 0; i < this.listDataotp.length; i++) {
              let OTPDISC = this.listDataotp[i].PRCDISC ? this.listDataotp[i].AMT * (this.listDataotp[i].PRCDISC)/100 : this.listDataotp[i].BHTDISC
              if(OTPDISC){
                this.listOTPDisc.push({
                  PRN: this.listDataotp[i].PRN,
                  QNT: this.listDataotp[i].QNT,
                  OTPDISC: await this.priceBath(OTPDISC)
                })
              }
          }

          let dataStaff = {
            REF:this.cartref,
            STCODE:this.stcode ,
          }
          let getAPIStaff = await serviceAPI.call_API('post', `checkout/getDiscMKTStaffResult`, dataStaff, 'auth');
          this.DisStaff = getAPIStaff.data.dbitems[0].Staffdisc;
          this.showDisStaff = await this.priceBath(this.DisStaff);
          let discplpp = this.listDataAMT.discplpp ? await this.price2Num(this.listDataAMT.discplpp) : 0
          let otpdisc = this.listDataAMT.otpdisc ? await this.price2Num(this.listDataAMT.otpdisc) : 0
          let discmkt = this.listDataAMT.discmkt ? await this.price2Num(this.listDataAMT.discmkt) : 0
          this.discount =  await this.priceBath(discplpp + otpdisc)
          let totallist = this.listDataAMT.total ? await this.price2Num(this.listDataAMT.total) : 0
          this.sumNET = await this.priceBath(totallist - this.DisStaff)
          let amt = parseFloat(totallist);
          let beforeVat = amt/1.07
          let vat = amt-beforeVat
          this.priceVat = vat
          // this.priceBeforeVat = beforeVat + discplpp + otpdisc + discmkt
          this.priceBeforeVat = beforeVat
          } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      async priceBath(para_price) {
        return para_price.toLocaleString(undefined, {minimumFractionDigits: 2})
      },
      async price2Num(para_price) {
        return Number(para_price.replace(/[^0-9.-]+/g,""))
      },
      async getSalesource(e) {
        this.qtSalesource = e;
      },
      async checkListDlv(){
        try {
          this.dlvType={P:false, D:false}
          let sklist = this.SkuList || this.listData.SkuList
          if(!sklist){
            return
          }
          let listDlv = JSON.parse(sklist)
          for (let i = 0; i < listDlv.length; i++) {
            let carrier = listDlv[i].trackingNo ? JSON.parse(listDlv[i].trackingNo)[0].carrier : ''
            let listSku = listDlv[i].skulist ? JSON.parse(listDlv[i].skulist) : ''
            let nitems = listDlv[i].nitems
            for (let j = 0; j < nitems; j++) {
              for (let k = 0; k < this.listDataOrder.length; k++) {
                if(listSku[j].SEQ == this.listDataOrder[k].SKUSEQ){
                  this.listDataOrder[k].DLV = carrier
                  this.listDataOrder[k].deliveryByStore = listSku[j].deliveryByStore
                  if(carrier == 'PICK'){
                    this.listDataOrder[k].DLVDATE = JSON.parse(listDlv[i].trackingNo)[0].pickupDate
                    this.listDataOrder[k].DLVTEXT = `รับที่สาขา ${listSku[j].deliveryByStore}`
                    this.dlvType.P = true
                  }else{
                    this.listDataOrder[k].DLVDATE = JSON.parse(listDlv[i].trackingNo)[0].deliveryDate
                    this.listDataOrder[k].DLVTEXT = `จัดส่งโดยสาขา ${listSku[j].deliveryByStore == '60004' ? 'DC' : listSku[j].deliveryByStore} (${carrier})`
                    if(carrier == 'VEN'){
                      this.listDataOrder[k].DLVTEXT = `จัดส่งโดยVendor`
                    }else if(carrier == 'DCS'){
                      this.listDataOrder[k].DLVTEXT = `จัดส่งโดย DC `
                    }
                    this.dlvType.D = true
                  }
                  // console.log(this.listDataOrder[k]);
                }
              }
            }
          }

        } catch (error) {
            serviceMain.showErrorAlert(this, error.message)
        }
      },
      async setPrint() {
        try {
          const NUMBER_OF_SPACES = 4;
          // this.checkPickUp()
          let data = {
            "REF": this.cartref,
            "STCODE": this.stcode
          }
          let getAPI = await serviceAPI.call_API('post', `history/getQuotationHeader`, data, 'auth');
          this.listData = getAPI.data.dbitems[0];
          let dlvCode = this.listData.dlvcode
          let dlvAddr = this.listData.dlvaddr
          let address = JSON.parse(dlvAddr.toString().replace(/\t/g, " ".repeat(NUMBER_OF_SPACES)))
          let invoiceE = []
          if(this.listData.SENDeTax){
            invoiceE = this.listData.SENDeTax.split(',')
          }
          this.invoiceShow = `${this.listData.InvoiceType == 'P' ? 'กระดาษ': this.listData.InvoiceType == 'E' ?'อิเล็คโทรนิคส์ ' + invoiceE[1] : ''}`
          this.dlvAddrShow = `${address?.company}${address.company?(address.ishq=='Y'?'(สำนักงานใหญ่)':'(สาขา '+address.idbranch+')'):''} ${address?.adrnum} ตึก ${address?.build} ซอย ${address?.soi} เขต ${address?.district} แขวง ${address?.subdistrict} ${address?.province} ${address?.zipcode}`
          this.dlvStoreName = `${address?.SnameTH || ''} ${address?.THADDRESS || ''}`

          if(dlvCode == 'D'){
            this.dlvDateShow = 'วันที่จัดส่ง :';
            this.dlvStatus = true
          }else if (dlvCode == 'P'){
            this.dlvDateShow = 'วันที่รับสินค้า :';
            this.dlvStatus = false
          }
          
          let data1 = {
            "ref": this.cartref
          }
          let getAPI1 = await serviceAPI.call_API('post', `history/ShowEditQuotation`, data1, 'auth');
          let getDataAPI1 = getAPI1.data.dbitems;
          this.customQT = (getAPI1.data.dbrows != 0 && getDataAPI1[0].EDITADDR) && (JSON.parse(getDataAPI1[0].EDITADDR))
          this.disPrint = false
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      async goPrint(mode) {
        try {
          this.disPrint = true
          this.m0 = mode == 0
          this.m1 = mode == 1
          this.m2 = mode == 2
          this.getStyle = `
            .title-name{
              font-weight: 900 !important;
              margin-bottom: 0 !important;
              font-size: 1.5rem;
            }
            @font-face {
              font-family: 'kittithadalight45regular';
              src: url('/fonts/kittithadalight45-webfont.woff2') format('woff2'),
                url('/fonts/kittithadalight45-webfont.woff') format('woff');
              font-weight: normal;
              font-style: normal;
            }

            * {
              font-family: 'kittithadalight45regular';
              font-size: 20px;
            }
            .title-little{
              font-size: 1rem !important;
              font-weight: 900 !important;
            }
            .line-low{
              font-size: 1.3rem;
              color: black;
              font-weight: 900 !important;
            }
        
          `;
          console.log(this.cartref);
    
          setTimeout(() => {
            printJS({
              printable: 'printQuo-' + this.cartref,
              type: 'html',
              maxWidth:1000,
              css: '/public/css/bt4forprint.css',
              style: this.getStyle,
              font_size:null,
              targetStyles: ['*']
            });
            this.disPrint = false
          }, 3000);
          
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      priceBaht(para_price) {
        return para_price.toLocaleString(undefined, {maximumFractionDigits: 2})
      },
      async checkPickUp(){
        let status = this.pickUpStatus
        if(status == 'PickupNow'){
          this.pickUpText = 'รับสินค้าที่สาขาทันที';
        } else if(status == 'Pickup'){
          this.pickUpText = 'รับสินค้าที่สาขาตามเวลา'
        } else if(status == 'Delivery'){
          this.pickUpText = 'จัดส่งสินค้า'
        }
      },

    },
    computed: {
      getBU: function () {
        return this.$store.getters['customer/getQTBU']
      },
    }
  };
</script>

<style scoped lang="scss">
  .input-History {
    border: 1px gray solid;
    padding: 0px 15px;
    border-radius: 4px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .track {
    position: relative;
    background-color: none;
    height: 1px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 50px;
    margin-top: 40px;
    //margin-left:-40px;
  }

  .line {
    position: relative;
    background-color: #CCC;
    height: 1px;
    width: 40%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .track .step {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 14%;
    margin-top: -18px;
    text-align: center;
    position: relative
  }

  .track .step.active .icon {
    background: #5cb85c;
    color: #fff;
    padding-top: 5px;
    font-size: 18px;
  }

  .track .step.cancel .icon {
    background: #55246c;
    color: #fff
  }

  .track .step.none .icon {
    background: none;
    color: #fff
  }

  .track .icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    position: relative;
    border-radius: 100%;
    background: #ddd
  }

  .icon>.fa {
    font-size: 15px !important;
    margin-top: 8px;
  }

  .track .step.active .text {
    font-weight: 400;
    color: #000;
  }

  .lineca.none {
    background: none;
  }

  .track .text {
    font-size: 15px !important;
    display: block;
    margin: 7px -20px 0 -20px;
  }

  .track .textr {
    font-size: 15px !important;
    display: block;
    margin: 7px -20px 0 -20px;
  }

  .remark {
    padding: 10px 0px;
    font-size: 25px;
    font-family: 'kittithadabold75regular';
    text-align: left;
  }

  .table thead th {
    padding: 0;
  }

  .bt {
    background-color: #55246c;
    font-weight: bold;
    color: white;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .print-Div{
    width: 1000px;
  }
</style>