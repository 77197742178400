<template>
  <section>
    
    <section>
    <div class="modal-overlay" style="display: block;" v-show="isOpen" @click="hidemodal"></div>  
    
    <!-- <a  @click.prevent="toggle">
      <slot name="button"></slot>
    </a> -->
    <div :class="`my-modal max-vw-${size} ${isOpen?'visible':''} `" >
      <div :class="`modal__header bg-${buCode ? buCode : getBU}`">
          <slot name="header"></slot>
          <a @click="hidemodal" class="js-close-modal">X</a>
      </div>
      <slot name="body"></slot>

    </div>

    
    </section>
  </section>
</template>


<script>
  
  export default {
    name: 'Modal',
    data(){
     return {
       isOpen:false
     }
   },
   props: [
      "cartref",
      "size",
      "buCode"
    ],
   methods:{
      toggle() {
          this.isOpen = !this.isOpen;
          if(this.isOpen){
            this.showmodal();
          } else {
            this.hidemodal();
          }
      },
      showmodal(){
        // document.querySelector('.my-modal').classList.add('visible')
        this.isOpen = true;
      },
      hidemodal(){
        // document.querySelector('.my-modal').classList.remove('visible')
        this.isOpen = false;
      },
      clickoutside(){
          window.addEventListener("mousedown",(event)=>{
            if(!event.target.closest('.my-modal,.js-open-modal')) {
                  document.getElementsByClassName('my-modal visible')[0].classList.remove('visible') 
                  this.isOpen = false;
            }
            console.log(event);
          });
      }
        
    },
    created(){
                //clickoutside
                // this.clickoutside();
    },
    computed:{
      getBU:function(){
        return this.$store.getters['customer/getQTBU']
      }
    }
  }

</script>

<style scoped lang="scss">


.my-modal {
  z-index:100;
  display: none;
  position:fixed;
  top: 50%;
  left: 50%;
  transform:translate(-50%,-50%);
  background: #fff;
  width: 100%;
  max-width: 80vw;
  // height: 320px;
  text-align: center;
  box-sizing:border-box;
  box-shadow:0 0 20px rgba(0,0,0,.2);
  overflow-y: auto; 
  max-height: 100%;
  &.visible {
    display: block;
  }
}

.modal__header {
  color:white;
  line-height: 50px;
  text-align: center;
  position: relative;
  height:50px;
  box-sizing:border-box;
  
  a {
    position: absolute;
    top: 0;
    right: 0;
    text-decoration: none;
    color:white;
    font-weight: bold;
    display: block;
    padding: 0 20px;
    font-size:16px;
    background: gray;
    height: 100%;  
  }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    opacity: 0.8;
    background: black;
}
@media only screen and (max-width: 768px) {
    .my-modal {
      max-width: 100vw;
    }
   }
</style>
