<template>
    <div>
        <div id="comparefooter" class="row" v-if="!getHaveList" :key="getCompare">
            <div id="morethanfour" class="col-12 d-none d-lg-block innercomparefooter" style="padding-top:0;padding-bottom:0;background-color: tomato;color: white;font-weight: lighter;" v-if="getFullList">
                รายการเปรียบเทียบสินค้าครบ 4 รายการแล้ว
            </div>
            <div class="col-12 innercomparefooter">
                <div class="row d-flex justify-content-between" style="padding: 0;">
                    <div id="comparetxt" class="col-12 col-lg-4 col-xl-3 px-0 d-none d-lg-block" style="padding-top: 6px;" v-if="!getFullList">
                        เลือกสินค้าที่ต้องการเปรียบเทียบ ({{ getComparelength }}/4)
                    </div>
                    <div class="col-12 col-lg-4 px-0 pt-10 d-none d-lg-block" style="padding-top: 10px;">
                        <div class="row" style="padding: 0;margin: 0;">
                            <div class="col-3 compareimg" style="padding: 0;margin: 0;" v-for="(item, index) in getCompare" :key="index">
                                <div style="border-radius:2px;border-width: 1px;border-style: solid;border-color: rgb(221, 221, 221);border-image: initial;width:70%;max-width:60px;margin-top:-.4em;">
                                    <a @click="gotodetail(item)" style="cursor: pointer;">
                                        <ImageBySKU  style="width:100%;max-width:60px;" :sku="item" />
                                    </a>
                                    <span @click="removecompare(item)" class="close-card-comp ">X</span>
                                </div>
                            </div>
                            <!-- <div id="compareimg-0" class="col-xs-3 compareimg" style="padding: 0;margin: 0;">
                            </div>
                            <div id="compareimg-1" class="col-xs-3 compareimg" style="padding: 0;margin: 0;">
                            </div>
                            <div id="compareimg-2" class="col-xs-3 compareimg" style="padding: 0;margin: 0;">
                            </div>
                            <div id="compareimg-3" class="col-xs-3 compareimg" style="padding: 0;margin: 0;">
                            </div> -->
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-5 pt-6 px-0">
                        <div class="row p-0 m-0">
                            <div class="col-8 col-md-8 text-center px-1" >
                                <button id="btncompare" type="button" @click="checkStore()" class="btn btn-compare" v-if="getHaveCom">
                                    <span id="btntxt" style="font-size: 1em;" class="d-none d-lg-block">เปรียบเทียบสินค้า</span>
                                    <span id="btntxtm" style="font-size: 1em;" class="d-lg-none">เปรียบเทียบสินค้า ({{ getComparelength }}/4)</span>
                                </button>
                                <button type="button" class="btn btn-precompare d-lg-none"  v-else>
                                    <span style="font-size: 1em;">เปรียบเทียบสินค้า (1/4)</span>
                                </button>
                            </div>
                            <div class="col-4 col-md-4 px-1">
                                <button type="reset" @click="resetcompare()" class="btn btn-outline-compare">รีเซต</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SelectStore />
    </div>
</template>

<script>
import * as serviceAPI from '../services/API.service';
import * as serviceMain from '../services/main.service';
import ImageBySKU from './ImageBySKU.vue';
import SelectStore from '@/components/SelectStore.vue';
export default {
    name: 'Comparefuc',
    data() {
        return {
            comparearr: [],
            oldcomparearr: '',
            jsonoldcomp: '',
            fulllist : false,
            havelist: false,
            listdata: [],
        }
    },
    components:{
        ImageBySKU,
        SelectStore,
    },
    mounted() {
        this.getdetailProdxProp
        // this.oldcomparearr = localStorage.getItem("comparearr");
        // this.jsonoldcomp = JSON.parse(oldcomparearr);
        // if(jsonoldcomp){
        //     if(jsonoldcomp.ver != '1.02'){
        //         localStorage.removeItem("comparearr");
        //     }
        // }
        // this.setdisplaycompare();
    },
    methods: {
        // $(document).ready(function () {
        //     //console.log(localStorage.getItem("comparearr"));
        //     var oldcomparearr = localStorage.getItem("comparearr");
        //     var jsonoldcomp = JSON.parse(oldcomparearr);
        //     if(jsonoldcomp){
        //         if(jsonoldcomp.ver != '1.02'){
        //             localStorage.removeItem("comparearr");
        //         }
        //     }
        //     setdisplaycompare();
        // });

        async removecompare(sku){
            let slistsku = this.getCompare
            let index = slistsku.indexOf(`${sku}`);
            if (index > -1) {
            slistsku.splice(index, 1);
            }
            this.$store.dispatch('customer/setQTCOMPARE',slistsku.toString());
            this.loadcomparepage();
        },

        async resetcompare(){
            this.$store.dispatch('customer/setQTCOMPARE','');
        },

        async test(){
            this.getHaveList()
            this.getFullList()
        },

        async checkStore(){
            if(!this.getStore) {
                $('#mapModal').modal('show')
            }
            else{
                this.$router.push({ name: 'CompareProduct'})
            }
        },
        
        async gotodetail(sku){
            try {
                let data = {
                    skcode: sku,
                    stcode: this.getStore,
                }
                let getAPI = await serviceAPI.call_API('post', `detail/getProdxProp`, data, 'auth');
                this.listdata = getAPI.data.dbitems[0];
                let proURL = this.listdata.PR_NAME + '-' + this.listdata.SKCODE
                proURL = proURL.replace(/ /g,"-").replace(/\//g,"|").replace(/#/g,"").replace(/"/g,"").replace(/'/g,"").replace(/\?/g,"").replace(/\*/g,"").replace(/\+/g,"").replace(/\:/g,"").replace(/\./g,"");
                window.location.href = `../ProductDetail/${proURL}`;
            } catch (error) {
                serviceMain.showErrorAlert(this, error.message)
            }
        },
    },
    computed:{
        getStore:function(){
            return this.$store.getters['service/getQTDFSTORE'];
        },
        getCompare:function(){
            let list = this.$store.getters['customer/getQTCOMPARE'].replace(/\\/g,'').replace(/"/g,'')
            return list.split(',')
        },
        getComparelength:function(){
            let list = this.$store.getters['customer/getQTCOMPARE'].replace(/\\/g,'').replace(/"/g,'')
            return list.split(',').length
        },
        getHaveList:function(){
            return this.getCompare == ''
        },
        getHaveCom:function(){
            return this.getCompare.length > 1
        },
        getFullList:function(){
            return this.getCompare.length == 4
        },
        
    },
}
</script>

<style>
    #comparefooter {
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: white;
        box-shadow: rgb(200, 200, 200) 0px 1px 8px 0px;
        padding: 0;
        margin: 0;
        z-index: 998;
    }
    .innercomparefooter{
        font-weight: bolder;
        padding: .5em 3em;
    }
    .btn-precompare {
        color: #55246c;
        border-color: #55246c;
        border-width: 1px;
        width: 100%;
        font-size: .8em;
        border-radius: 0;
    }
    .btn-compare {
        background-color: #55246c;
        color: white;
        border-width: 1px;
        width: 100%;
        font-size: .8em;
        border-radius: 0;
    }
    .btn-compare:hover {
        background-color: rgb(145, 33, 38);
        color: white;
    }

    .btn-compare:focus {
        color: white;
        outline: none;
        border-radius: 0;
    }

    .btn-compare:active {
        color: white;
        outline: none;
        border-radius: 0;
    }

    .btn-outline-compare {
        color: #55246c;
        background-color: transparent;
        background-image: none;
        border-color: #55246c;
        border-width: 1px;
        width: 100%;
        font-size: .8em;
        border-radius: 0;
        outline: none;
    }

    .btn-outline-compare:hover {
        color: #55246c;
        box-shadow:0 0 0 .2rem rgba(184, 41, 47,.5);
    }

    .btn-outline-compare:focus {
        color: #55246c;
        outline: none;
        border-radius: 0;
    }

    .btn-outline-compare:active {
        color: #55246c;
        outline: none;
        border-radius: 0;
    }
    
    .close-card-comp {
        font-size: 11px;
        color: rgb(255, 255, 255);
        font-weight: 700;
        text-align: center;
        letter-spacing: .025em;
        border-radius: 50%;
        background-color: #55246c;
        width: 18px;
        height: 18px;
        line-height: 18px;
        position: absolute;
        transition: all .2s ease-out;
        top: -1.5em;
        cursor: pointer;
    }

    .left55{
        left: 55%;
    }

    .left40{
        left: 40%;
    }

    @media screen and (min-width: 1850px) {
        .left55{
            left: 45%;
        }

        .left40{
            left: 35%;
        }
    }

    @media screen and (min-width: 2000px) {
        .left55{
            left: 40%;
        }

        .left40{
            left: 34%;
        }
    }

    @media screen and (min-width: 2300px) {
        .left55{
            left: 38%;
        }

        .left40{
            left: 28%;
        }
    }

    @media screen and (max-width: 992px) {
        .innercomparefooter{
            font-weight: bolder;
            padding: .5em 1em;
        }
        .px-sm-0 {
            padding-left: 0;
            padding-right: 0;
        }
        .pr-sm-0 {
            padding-right: 0;
        }
    }
</style>