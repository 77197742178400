<template>
<section>
  <div class="container">
    <HeaderSPN :catcode="getcatcode" :havenext="false"/>
    <div class="row">
      <div class="col-12 col-md-6">

      </div>
      <div class="col-12 col-md-6">
        <PropertyProduct :catcode="getcatcode" />
      </div>
    </div>
    <ProductList  :catcode="getcatcode" :key="$route.query" />
    
  </div>
  <Comparefuc />
</section>
</template>

<script>

import ProductList from '@/components/ProductList.vue'
import PropertyProduct from '@/components/PropertyProduct.vue'
import HeaderSPN from '@/components/HeaderSPN.vue'
import Comparefuc from '@/components/comparefuc.vue'
export default {
  name: 'Product',
  components: {
    ProductList,
    PropertyProduct,
    HeaderSPN,
    Comparefuc
  },
  props: {
    getcatcode: {
      type: String,
      required: true
    }
  },
 
    data: function () {
    return {
 
      }
  },
  mounted() {
    document.title = `${process.env.VUE_APP_TITLE} - Product Lists`
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-CRY3HG283C')
    document.head.appendChild(recaptchaScript)
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CRY3HG283C');
  },
  methods: {

  },
}
</script>
