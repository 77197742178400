<template>
  <Navbar  :path="$route.path"/>

  <!-- <Navbar class="d-none d-sm-none d-md-block" /> -->
  <router-view class="mainview" :key="$route.path" />
  <!-- <Footer  /> -->
</template>

<script >

import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer/Footer.vue'

export default {
  name: 'Home',
  components: {
    Navbar,
    Footer
  },
  mounted() {
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-CRY3HG283C')
    document.head.appendChild(recaptchaScript)
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-CRY3HG283C');
  },
}
</script>

<style lang="scss">


/***************************** All Variables ************************************/
$color-PWB: #55246c;
$color-bnb: #006e63;
$color-at1: #3866af;
$color-vfix: #fbd900;
$color-white: white;
$color-black: black;
$color-lightgray: rgb(235, 235, 235);
$color-df-lightgray: lightgray;
$border-btn: 2px;
$listBu: 'PWB' $color-PWB, 'bnb' $color-bnb, 'at1' $color-at1, 'vfix' $color-vfix;
/***************************** End Variables ************************************/


/***************************** All Fonts ************************************/
@font-face {
  font-family: 'markerfeltwide-plainregular';
  src: url('/fonts/hw-pricesign-webfont.woff2') format('woff2'),
    url('/fonts/hw-pricesign-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'kittithadamedium65regular';
  src: url('/fonts/kittithadamedium65-webfont.woff2') format('woff2'),
    url('/fonts/kittithadamedium65-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'kittithadathin35regular';
  src: url('/fonts/kittithadathin35-webfont.woff2') format('woff2'),
    url('/fonts/kittithadathin35-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'kittithadabold75regular';
  src: url('/fonts/kittithadabold75-webfont.woff2') format('woff2'),
    url('/fonts/kittithadabold75-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'kittithadalight45regular';
  src: url('/fonts/kittithadalight45-webfont.woff2') format('woff2'),
    url('/fonts/kittithadalight45-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
/***************************** End Fonts ************************************/

* {
  font-family: 'kittithadalight45regular';
  // font-size: 22px;
  font-size: clamp(18px, 4vw, 22px);
} 
body{
  background-color:rgb(241,241, 241);
}

/***************************** All Mixins ************************************/
@mixin price($color) {
  font-family: 'markerfeltwide-plainregular' !important;
  color:$color !important
}
@mixin underline($color) {
  border-bottom:4px $color solid ;
}
@mixin btn-invisible($color) {
  padding: 3px 15px;
  width: 100%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 0 0;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 900;
  border: $border-btn $color solid;
  color: $color;
  &:hover {
    @include svg-hover($color-white);
    color: $color-white;
    background-color: $color;
  }
}
@mixin svg-hover($color) {
  svg {
      path{
        fill: $color;
      }
    }
}
@mixin btn-visible($color) {
  border: none;
  
  width: 100%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border:none;
  cursor: pointer;
  border-radius: 4px;
  background-color: $color;
  color: white;
  font-weight: 900;
  &:hover {
    background-color: darken( $color, 20% )
  }
}
@mixin btn-find-customer($color){
  background-color: $color; 
  width:100%; 
  border: none;
  color: white;
  padding: 2px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 0px;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    color: #fff
  }
}
/***************************** End Mixins ************************************/

/***************************** All For loops ************************************/
@for $i from 1 through 100 {
  $maxWidth: 1% * $i ;
  .max-w-#{$i} { 
      max-width: $maxWidth !important;
  }
  @media only screen and (max-width: 768px) {
  /* For tablets: */
    .max-w-#{$i} { 
        max-width: 100% !important;
    }
  }
}
@for $i from 1 through 100 {
  $maxWidth: 1vw * $i ;
  .max-vw-#{$i} { 
      max-width: $maxWidth !important;
  }
  @media only screen and (max-width: 768px) {
  /* For tablets: */
    .max-vw-#{$i} { 
        max-width: 100% !important;
    }
  }
}
/***************************** End For loops ************************************/

/***************************** All Each loops ************************************/
@each $buItems in $listBu {
  .price-#{nth($buItems, 1)} { 
    @include price(nth($buItems, 2))
  }
  .underline-#{nth($buItems, 1)} {
    @include underline(nth($buItems, 2))
  }
  .btn-invisible-#{nth($buItems, 1)}{
    @include btn-invisible(nth($buItems, 2));
  }
  .btn-visible-#{nth($buItems, 1)}{
    @include btn-visible(nth($buItems, 2));
  }
  .btn-#{nth($buItems, 1)} {
    @include btn-find-customer(nth($buItems, 2));
  }
  .bg-#{nth($buItems, 1)}{
    background-color:nth($buItems, 2) !important;
  }
  .font-color-#{nth($buItems, 1)}{
    color:nth($buItems, 2) !important;
  }
  .btn-border-#{nth($buItems, 1)}{
    border: 2px nth($buItems, 2) solid;
    padding: 0px 15px;
    color: nth($buItems, 2);
  }
  .btn-border-#{nth($buItems, 1)}:hover{
    background-color:nth($buItems, 2) ;
    color: #FFF;
  }
  .font-hover-#{nth($buItems, 1)}{
    font-weight: normal;
    &:hover{
      color:nth($buItems, 2) !important;
      font-weight: bold;
    };
  }
  .lds-ellipsis-span-#{nth($buItems, 1)} {
    background:nth($buItems, 2) !important;
  }
  .checkmark-color-#{nth($buItems, 1)}{
    border-color: nth($buItems, 2) !important;
    &:after { 
      background: nth($buItems, 2) !important;
    }
  }
}
/***************************** End Each loops ************************************/

.font-price{
  font-family: 'markerfeltwide-plainregular' !important;
}
.font-kitti-bold{
  font-family: 'kittithadabold75regular' !important;
}
.font-kitti-medium{
  font-family: 'kittithadamedium65regular' !important;
}
.font-kitti-light{
  font-family: 'kittithadalight45regular' !important;
}
.font-kitti-thin{
  font-family: 'kittithadathin35regular' !important;
}
.hr-bold{
  border: 1px solid lightgray;
}
.pointer {cursor: pointer;}
.bg-PWB-white{
  background-color:white !important;
}
.bg-deeppurple{
  background-color:#331245 !important;
}
.bg-black{
  background-color:#000 !important;
}
.bg-gray{
  background-color:gray !important;
}
.bg-white{
  background-color:white !important;
}
.font-gray{
  color:gray !important;
}
.font-light-gray{
  color:#666 !important;
}
.font-white{
  color:$color-white !important;
}
.bg-PWB-black{
  background-color:black !important;
}
.font-PWB-white{
  color: white;
}
.btn-visible-black{
    @include btn-visible($color-black);
}
.btn-visible-df-lightgray{
    @include btn-visible($color-df-lightgray);
    color: $color-black;
}
.btn-eorder{
    
    border: none;
    color: #fff;
    padding: 5px 1em;
    width: 100%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 0 0;
    cursor: pointer;
    border-radius: 4px;
}
.btn-PWB-nocolor{
  width:100%; 
  border: none;
  color: white;
  padding: 2px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 0px;
  cursor: pointer;
  border-radius: 4px;
}
.mainview{
  min-height: 100vh;
}
.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;
}
.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  animation: shimmer 2s infinite;
  content: '';
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
}
}
.blog-post__headline {
  font-size: 1.25em;
  font-weight: bold;
}
.blog-post__meta {
  font-size: 0.85em;
  color: #6b6b6b;
}
.o-media {
  display: flex;
}
.o-media__body {
  flex-grow: 1;
  margin-left: 1em;
}
.o-vertical-spacing > * + * {
  margin-top: 0.75em;
}
.o-vertical-spacing--l > * + * {
  margin-top: 2em;
}
.container{
  max-width: 1366px !important;
}
a:hover{
  text-decoration:none;
}
/* #nav a.router-link-exact-active {
  color: #42b983;
} */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #6c757d; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6c757d; 
}

ul.sidebar-panel-nav {
   list-style-type: none;
 }

 ul.sidebar-panel-nav > li > a {
   color: #fff;
   text-decoration: none;
   font-size: 1.5rem;
   display: block;
   padding-bottom: 0.5em;
 }
 .atb-color{
  background-color: #F1C232;
}
.dism-color{
  background-color: #6AA84F;
}
.hxb-color{
  background-color: #A0BDD7;
}
.leadtme-color{
  background-color: #909090;
}
.stckavl-color{
  background-color: #e0bbe4;
}
.status-color{
  background-color: #DEB887;
}
.stclt-color{
  background-color: #000000;
  color: #ffffff;
}
.stcdc-color{
  background-color: #3700ffcb;
  color: #ffffff;
}
</style>
