<template>
      <a @click="addmoretocart($event)" href="">
        <slot>
          <div :class="`bg-${qtBu} buttoncart cardfont col-12`" >ใส่รถเข็น</div>
        </slot>
      </a>
</template>


<script>
  import * as serviceAPI from '../services/API.service';
  import * as serviceMain from '../services/main.service';

  export default {
    name: 'addMoretoCart',
    data: function () {
      return {

      }
    },
    mounted() {
     
    },
    props: [
      "sku",
      "setid"
    ],
    methods: {

      async addmoretocart(e){
        try {
          e.preventDefault();
          this.addAnimation()
          let getCREF = this.$store.getters['customer/getQTCUSTREF'];
          if(!getCREF) {
            if(this.$store.getters['auth/getWSFLAG']){
              this.$router.push({ name: 'OrderWholeSale' })
            }else{
              this.$router.push({ name: 'FindUser'})
            }
            throw new Error('ยังไม่มีเลขตะกร้า กรุณาเลือกลูกค้าก่อนครับ')
          }

          let getSTCODE = this.$store.getters['service/getQTDFSTORE']
          if(!getSTCODE) {
            this.validateSelectStoreFailed();
            throw new Error('ยังไม่เลือกสาขา กรุณาเลือกสาขาก่อนครับ')
          }

          if(this.$store.getters['customer/getQTPONUM']){
            throw new Error(`PO ${this.$store.getters['customer/getQTPONUM']} ไม่สามารถเพิ่มสินค้าได้`)
          }

          let data = {
            "REF":getCREF , 
            "SKU":this.sku , 
            "STCODE":getSTCODE , 
            "SETID":this.setid
          }
          let getAPI = await serviceAPI.call_API('post', `cart/addMoretoCart`, data,'auth');
          let getData = getAPI.data.dbitems[getAPI.data.dbrows-1]
          this.$store.dispatch('customer/setQTSHOPITEM',getData.SUMQNT)
          serviceMain.showSuccessAlertWithTime(this,`เพิ่ม ${getData.PRNAME} สำเร็จ`,1000)
          this.reloadCart()
          // CLASS: "540401"
          // CUSTREF: "QT2105000014"
          // NITEM: 8
          // PRBRAND: "ตรามือ              "
          // PRNAME: "งอลด 90 องศา ตรามือ รุ่น 50000 ขนาด 3/4 x 1/2 นิ้ว สีฟ้า"
          // PRPRICE: "9.00"
          // SUMQNT: 8
          // console.log(getAPI)
        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      async addAnimation(){

      },
      async validateSelectStoreFailed() {
          this.$emit("validateSelectStore", false)
      },
      async reloadCart() {
          this.$emit('closeModal')
          this.$store.dispatch('service/setReloadCart')
      },
      

    },
    computed: {
      qtBu: function () {
        return this.$store.getters['customer/getQTBU'];
      },
      getQTCUSTREF: function () {
        return this.$store.getters['customer/getQTCUSTREF'];
      }
    }
  }
       
</script>

<style scoped>

</style>