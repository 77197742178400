
export const display = {
  namespaced: true,
  state: {
    isMenu: false
  },  
  getters: {
    getMenu(state) {
      return state.isMenu;
    }
  },
  mutations: {
    SET_Menu(state){
      state.isMenu=!state.isMenu
    },
  },
  actions: {
    toggle_Menu({ commit }) {
      commit("SET_Menu");
    }
  },
  modules: {
  }
};
